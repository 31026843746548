import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { Router } from '@angular/router';
import { ServicePartner } from '../../../model/service-partner.model';
import { Address } from '../../../model/address.model';
import { User } from '../../../model/user.model';
import { HttpClient } from '@angular/common/http';
import { REST_SERVER } from 'src/app/config';

@Component({
  selector: 'app-register-form-frac',
  templateUrl: './register-form-frac.component.html',
  styleUrls: ['./register-form-frac.component.less']
})
export class RegisterFormFracComponent implements OnInit {

  @Input() requiresProof:boolean = true;
  @Input() requiresPassword:boolean = true;
  @Input() createButtonCaption:string = "Jetzt ServicePartner werden";
  @Input() restPath:string = '/service-partners/register';
  @Input() redirectSuccess:string[] = ['register', 'success'];
  public sendingRegistration:boolean;
  public success:boolean;
  public passwordCheck:string = "";
  public dragging:boolean = false;
  servicePartner = new ServicePartner();
  public fileErrors:string[] = [];
  public registrationErrors:string[] = [];
  @ViewChild("registerOrderForm") registerOrderForm;
  @ViewChild('registrationErrorsDisplay') registrationErrorsDisplay:ElementRef;

  constructor(
    private _router:Router,
    private _servicePartnerService:ServicePartnerService,
    private _http:HttpClient,
  ) { 
    this.servicePartner.invoiceAddress = new Address();
    this.servicePartner.user = new User();
  }

  ngOnInit() {
  }

  async onRegister():Promise<void> {
    this.registrationErrors = [];
    let success:boolean = false;
    this.servicePartner.invoiceAddress.name = this.servicePartner.name;
    //this.servicePartner.invoiceAddress.contact = this.servicePartner.user.firstName + " " + this.servicePartner.user.lastName;
    this.servicePartner.deliveryAddress = {...this.servicePartner.invoiceAddress};
    this.sendingRegistration = true;
    try {
      let res = <ServicePartner> await this._http.post(REST_SERVER + this.restPath, this.servicePartner).toPromise();
      success = true;
    } catch(exc) {
      console.log(exc);
      let code = exc.error && exc.error.error && exc.error.error.code;
      if(code === 'EMAIL_ALREADY_EXISTS') {
        this.registrationErrors.push("Die E-Mail-Adresse wird bereits verwendet");
      } else {
        this.registrationErrors.push("Unbekannter Fehler");
      }
      this.registrationErrorsDisplay.nativeElement.scrollIntoView(true);
      //window.scroll(0,0);
    }
    this.sendingRegistration = false;
    if(success) {
      const url = this._router.createUrlTree(['register', 'success']);
      window.open(url.toString(), '_blank');

      //this._router.navigate(this.redirectSuccess);
    }
  }

  get servicePartnerJson():string {
    return JSON.stringify(this.servicePartner);
  }

  async dropFile(evt):Promise<void> {
    evt.preventDefault();
    this.dragging = false;
    console.log(evt.dataTransfer.items);
    let files:File[] = [];
    for(let item of evt.dataTransfer.items) {
      files.push(item.getAsFile());
    }
    this._readFiles(files);
  }

  dragFile(evt) {
    evt.preventDefault();
    this.dragging = true;
  }

  dragFileEnded(evt) {
    evt.preventDefault();
    this.dragging = false;
  }

  private _readSingleFile(file:File):Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      var reader = new FileReader();
      
      // Closure to capture the file information.
      reader.onload = async (evt:any) => {
        let data:ArrayBuffer = evt.target.result;
        resolve(data);
      };

      // Read in the image file as a data URL.
      reader.readAsArrayBuffer(file);
    });
  }

  private async _readFiles(files:File[]) {
    this.fileErrors = [];
    let spAny:any = <any>this.servicePartner;
    for(let file of files) {
      let type:string = file.type;
      if(spAny.files && spAny.files.length >= 3) {
        break;
      }
      if(spAny.files && spAny.files.filter(existing => existing.filename === file.name).length > 0) {
        this.fileErrors.push(`Datei ${file.name} bereits hinzugefügt`);
        continue;
      }
      if(!file.type.match('image/jpeg') && !file.type.match('application/pdf')) {
        this.fileErrors.push(`Die Datei ${file.name} hat nicht den richtigen Typ`);
        continue;
      }
      if(file.size > 1024 * 1024) {
        this.fileErrors.push(`Die Datei ${file.name} ist zu groß`);
        continue;
      }
      let data:ArrayBuffer = await this._readSingleFile(file);
      if(!spAny.files) {
        spAny.files = [];
      }
      let arr:any = new Uint8Array(data);
      let base64:string = btoa(
        arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
      spAny.files.push({
        filename: file.name,
        data: base64,
      });
    }
  }

  getFilenames():string[] {
    let result = [];
    let spAny:any = <any>this.servicePartner;
    if(spAny.files) {
      result = spAny.files.map(f => f.filename);
    }
    return result;
  }

  handleFileSelect(evt):void {
    let files:File[] = evt.target.files;
    this._readFiles(files);
  }

  removeFile(idx):void {
    let spAny:any = <any>this.servicePartner;
    spAny.files.splice(idx, 1);
  }

  canSubmit():boolean {
    let result:boolean = true;
    result = result && ((this.servicePartner.user.password === this.passwordCheck) || !this.requiresPassword);
    result = result && this.registerOrderForm.form.valid;
    let spAny:any = <any>this.servicePartner;
    result = result && ((spAny.files && spAny.files.length > 0) || !this.requiresProof);
    result = result && !this.sendingRegistration;
    return result;
  }
}
