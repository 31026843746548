import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { REST_SERVER } from 'src/app/config';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { MessageDialogComponent } from 'src/app/components/ui/dialogs/message-dialog/message-dialog.component';
import { UserService } from 'src/app/service/user.service';
import { OrderIdPipe } from 'src/app/pipes/order-id.pipe';

@Component({
  selector: 'app-service-partner-order-details',
  templateUrl: './service-partner-order-details.component.html',
  styleUrls: ['./service-partner-order-details.component.less']
})
export class ServicePartnerOrderDetailsComponent implements OnInit {

  orderId:number;
  repairOrder:RepairOrder;
  repairOrderData = [];
  imageNamesBefore:string[] = [];
  imageNamesAfter:string[] = [];

  currentOrdersFilterJson:string = null;

  public servicePartner = null;

  constructor(
    activatedRoute:ActivatedRoute,
    private _location:Location,
    private _repairOrderService:RepairOrderService,
    private _modalService: BsModalService,
    private _userService:UserService,
  ) { 
    activatedRoute.params.subscribe((params) => {
      this.orderId = parseInt(params.id);
      this.loadCurrentOrder();
    });
  }

  async loadCurrentOrder():Promise<void> {
    let servicePartnerId:number = this._userService.user.servicePartnerId;
    this.repairOrder = await this._repairOrderService.loadForServicePartner(servicePartnerId, this.orderId);
    if(!this.repairOrder) {
      let initialState = {
        title: "Fehler",
        msg: `Auftrag Nr. ${this.orderId} wurde nicht gefunden`,
      }
      let ref:BsModalRef = this._modalService.show(MessageDialogComponent, {initialState});
      ref.content.onClose.subscribe(result => {
        this._location.back();
      });
    }

    let repairOrderData = [];
    let repairOrder:any = {...this.repairOrder};
    //delete repairOrder.servicePartnerReference;
    //delete repairOrder.servicePartnerMessage;
    delete repairOrder.servicePartner;
    delete repairOrder.servicePartnerId;
    delete repairOrder.invoiceIdent;
    delete repairOrder.repairCenterId;
    delete repairOrder.rimDiameter;
    delete repairOrder.rimWithTires;
    let orderIdPipe:OrderIdPipe = new OrderIdPipe();
    repairOrder.id = orderIdPipe.transform(repairOrder);
    repairOrder.state = 'repair_state_' + repairOrder.state.toLowerCase();
    repairOrder.rimVarnish = 'rim_varnish_' + repairOrder.rimVarnish.toLowerCase();
    //repairOrder.rimWithTires = 'rim_with_tires_' + repairOrder.rimWithTires;
    if(repairOrder.repairResult) {
      repairOrder.repairResult = 'repair_result_' + repairOrder.repairResult.toLowerCase();
    }
    for(let key in repairOrder) {
      let item = { key, value: repairOrder[key] };
      repairOrderData.push(item);
    }
    this.repairOrderData = repairOrderData;

    this._updateImages();
  }

  private async _updateImages():Promise<void> {
    if(this.repairOrder) {
      this.imageNamesBefore = await this._repairOrderService.getAbsoluteImageNamesForServicePartner(
        this.repairOrder, this._userService.user.servicePartnerId, true);
      this.imageNamesAfter = await this._repairOrderService.getAbsoluteImageNamesForServicePartner(
        this.repairOrder, this._userService.user.servicePartnerId, false);
    }
  }

  ngOnInit() {
  }

  getBaseUrlOrders():string {
    return REST_SERVER + "/repair-orders";
  }

  goBack() {
    this._location.back();
  }
}
