import { Component, Inject, OnInit } from '@angular/core';
import { PrinterService } from 'src/app/service/printer.service';
import { DOCUMENT} from '@angular/common';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { RepairOrderService } from 'src/app/service/repair-order.service';

@Component({
    selector: 'app-admin-repair-center-assignment',
    templateUrl: './admin-repair-center-assignment.component.html',
    styleUrls: ['./admin-repair-center-assignment.component.less']
    
})
export class AdminRepairCenterAssignmentComponent implements OnInit {

    orderModel:RepairOrder = new RepairOrder();
    isLogistician: boolean = false;

    results = [
        /*{isLog: true, repCenter: "viech1"},*/
    ]

    constructor(
        private _printerService:PrinterService,
        private _repairOrderService: RepairOrderService,
        @Inject(DOCUMENT) private document: Document
    ){}

    ngOnInit(){}

    async testPrinter()
    {   
        this._printerService.testPrinter();
    }

    async onCreateOrder()
    {   
        console.log("Input Test" + this.isLogistician);
        console.log("Input Test" + this.orderModel);
    
        let result = await this._repairOrderService.testRepairCenterAssignment({
            'isLogistician': this.isLogistician,
            'rimVarnish': this.orderModel.rimVarnish
        });
        //alert(result.repCenter);
        
        let varnishTransl:any = {
            silver: "lackiert",
            colored: "glanzgedreht/bicolor",
            polish: "glanzgedreht/poliert",
            luxury: "luxus",
        };

        let translatedVarnish = varnishTransl[this.orderModel.rimVarnish];

        this.results.unshift({
            isLog: this.isLogistician, 
            varnish: translatedVarnish,
            repCenter:result.repCenter,
        });
    }
}