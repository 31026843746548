import { Component, OnInit } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewerDialogComponent } from 'src/app/components/ui/dialogs/viewer-dialog.component';
import { RepairOrderService } from 'src/app/service/repair-order.service';

@Component({
  selector: 'app-service-partner-order-list',
  templateUrl: './service-partner-order-list.component.html',
  styleUrls: ['./service-partner-order-list.component.less']
})
export class ServicePartnerOrderListComponent implements OnInit {
  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public icon:string = '';
  public state:string;

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {
    id: {
      type: "string",
      comp: "orderId",
      label: "Auftragsnr.",
    },
    rimCount: {
      type: "string",
      comp: "eq",
      label: "Anz. Felgen",
    },
    state: {
      value: "NONE",
      comp: "eq",
    }
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _userService:UserService,
    private _modalService:BsModalService,
    private _repairOrderService:RepairOrderService
  ) { 
    activatedRoute.params.subscribe(params => {
      this.state = params.state;
      let filterDesc = {...this.filterDesc};
      filterDesc.state = {...this.filterDesc.state};
      if(params.state === 'new') {
        filterDesc.state.value = "NEW";
        this.title = 'Aktuelle Aufträge';
        this.icon = 'current-repair-orders';
      } else if(params.state === 'progress') {
        filterDesc.state.value = "REPAIR_IN_PROGRESS";
        this.title = 'Aufträge in Bearbeitung';
        this.icon = 'repair-orders-in-progress';
      } else if(params.state === 'finished') {
        filterDesc.state.value = "REPAIR_FINISHED";
        this.title = 'Abgeschlossene Aufträge';
        this.icon = 'completed-repair-orders';
      } else if(params.state === 'canceled') {
        filterDesc.state.value = "CANCELED";
        this.title = 'Stornierte Aufträge';
        this.icon = 'current-repair-orders';
      }
      this.filterDesc = filterDesc;
    });
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    let servicePartnerId = this._userService.user.servicePartnerId;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders`;
  }

  calcInvoiceDownloadHref(repairOrder:RepairOrder):string {
    let servicePartnerId = this._userService.user.servicePartnerId;
    let token:string = this._userService.token;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/invoices/${repairOrder.invoiceIdent}?access_token=${token}`;
  }

  calcAinDownloadHref(repairOrder:RepairOrder):string {
    let servicePartnerId = this._userService.user.servicePartnerId;
    let token:string = this._userService.token;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders/${repairOrder.id}/cover-letter?access_token=${token}`;
  }

  async getShippingLabelHref(repairOrder:RepairOrder):Promise<string> {
    return await this._repairOrderService.getShippingLabelHrefServicePartner(repairOrder.id);
  }

  async openShippingLabel(repairOrder) {
    let initialState = {
      title: "AuftragsIdentifikationsNummer",
      path: await this.getShippingLabelHref(repairOrder),
      msg: "Bitte ausdrucken und jeder einzelnen Felge beilegen",
    }
    let ref:BsModalRef = this._modalService.show(ViewerDialogComponent, {initialState, class: 'modal-lg'});
  }
}
