import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageDialogComponent } from 'src/app/components/ui/dialogs/message-dialog/message-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { UserService } from 'src/app/service/user.service';
import { ViewerDialogComponent } from 'src/app/components/ui/dialogs/viewer-dialog.component';
import { PrintDialogRepairCenterComponent } from 'src/app/components/ui/dialogs/print-dialog-repair-center.component';
import { FeedbackService } from 'src/app/service/feedback.service';
import { RepairCenter } from 'src/app/model/repair-center.model';
import { RepairCenterService } from 'src/app/service/repair-center.service';

@Component({
  selector: 'app-repair-center-order-process',
  templateUrl: './repair-center-order-process.component.html',
  styleUrls: ['./repair-center-order-process.component.less']
})
export class RepairCenterOrderProcessComponent implements OnInit {

  orderId:number;
  repairOrder:RepairOrder;
  step:number = -1;
  notRepairable:boolean = false;
  notRepairableReason:string = null;
  imageNames:string[] = [];

  fakePrinting:boolean = false;
  repairOrderCreated:boolean = false;

  _tires:boolean = null;

  ready:boolean = true;

  repairOrderCode:string;
  felgenheroOrderCode:string;

  constructor(
    private _location:Location,
    activatedRoute:ActivatedRoute,
    private _repairOrderService:RepairOrderService,
    private _modalService: BsModalService,
    private _http:HttpClient,
    private _userService:UserService,
    private _feedbackService:FeedbackService,
    private _repairCenterService: RepairCenterService,
  ) { 
    activatedRoute.params.subscribe((params) => {
      this.orderId = parseInt(params.id);
      this.loadCurrentOrder();
    });
  }

  ngOnInit() {

  }

  async loadCurrentOrder():Promise<void> {
    try {
      this.repairOrder = await this._repairOrderService.loadForRepairCenter(
        this.orderId, this._userService.user.repairCenterId);
    } catch(exc) {
      console.error(exc);
    }

    //repairOrder not found
    if(!this.repairOrder) {
      let initialState = {
        title: "Fehler",
        msg: `Auftrag Nr. ${this.orderId} wurde nicht gefunden`,
      }
      let ref:BsModalRef = this._modalService.show(MessageDialogComponent, {initialState});
      ref.content.onClose.subscribe(result => {
        this._location.back();
      });
      return;
    }

    //repairOrder cancelled
    if(this.repairOrder.state === "CANCELED") {
      let initialState = {
        title: "Fehler",
        msg: `Auftrag Nr. ${this.orderId} wurde storniert. Falls Sie die Felgen zu diesem Auftrag trotzdem erhalten haben, wenden Sie sich bitte an unseren Support.`,
      }
      let ref:BsModalRef = this._modalService.show(MessageDialogComponent, {initialState});
      ref.content.onClose.subscribe(result => {
        this._location.back();
      });
      return;
    }

    this.step = 0;
    if(this.repairOrder.state === "REPAIR_IN_PROGRESS") {
      this.step = 2;
    } else if(this.repairOrder.state === "REPAIR_FINISHED") {
      this.step = 5;
    }

    this._updateImages();

    await this.generateFelgenheroCode()
    await this.generateRepcenterOrderCode();
  }

  private async _updateImages():Promise<void> {
    if(this.repairOrder) {
      this.imageNames = await this._repairOrderService.getAbsoluteImageNamesForRepairCenter(
        this.repairOrder, this._userService.user.repairCenterId, true);
    }
  }

  private async recordWorkStart():Promise<void> {
    this.repairOrder = await this._repairOrderService.recordWorkStart(
      this.repairOrder, this.tires, this._userService.user.repairCenterId);
  }

  private async recordWorkFinished(success:boolean, notRepairableReason:string):Promise<void> {
    this.repairOrder = await this._repairOrderService.recordWorkFinished(
      this.repairOrder, success, notRepairableReason, this._userService.user.repairCenterId);
  }

  private async setNotRepairable():Promise<void> {
    let initialState = {
      title: "Bearbeitung beenden",
      msg: `Wollen Sie diesen Auftrag wirklich als nicht reparierbar zurückgeben?`,
    }
    let ref:BsModalRef = this._modalService.show(ConfirmDialogComponent, {initialState});
    ref.content.onClose.subscribe(async result => {
      if(result) {
        this.notRepairable = true;
        if(this.step < 4) {
          this.ready = false;
          await this.recordWorkStart();
          //await this.recordWorkFinished(!this.notRepairable, this.notRepairableReason);
          this.step = 4;
        } else {
          this.nextStep();
        }
      }
    });
  }

  public async nextStep():Promise<void> {
    if(this.step === 1) {
      await this.recordWorkStart();
    } else if(this.step === 4) {
      await this.recordWorkFinished(!this.notRepairable, this.notRepairableReason);
    }

    this.step++;

    if(this.step === 1) {
      this.ready = true;
      await this.generateFelgenheroCode()
      await this.generateRepcenterOrderCode();
    } else if(this.step === 2) {
      this._updateImages();
      await this.generateFelgenheroCode()
      await this.generateRepcenterOrderCode();
    } else if(this.step === 4) {
      this.ready = false;
      this.createShippingOrder(this.repairOrder.id);
    } 
  }

  public getNextLabel():string {
    let result:string = 'Weiter';
    if(this.step === 0) {
      result = "Zur Referenznummer";
    } else if(this.step === 1) {
      result = 'Bearbeitung starten';
    } else if(this.step === 2) {
      result = 'Bearbeitung abschließen';
    } else if(this.step === 3) {
      result = 'Rückversand vorbereiten';
    } else if(this.step === 4) {
      result = 'Auftrag abschließen';
    }
    return result;
  }

  private _waitFakePrinting():Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, 5000);
    })
  }

  public async createShippingOrder(repairOrderId:number):Promise<any> {
    try {
      await this._repairOrderService.createShippingOrderRepairCenter(repairOrderId);
      this.repairOrderCreated = true;
    } catch (error) {
      console.log(error);
      this._feedbackService.showError("Versand-Auftrag konnte nicht erstellt werden");
    }
  }

  public async printLabel():Promise<void> {
    
    let initialState = {
      title: "Versandlabel",
      msg: "Bitte Label auf dem Paket anbringen",
      path: await this.getShippingLabelHref(),
    }
    this._modalService.show(PrintDialogRepairCenterComponent, {initialState, class: 'modal-lg'});

    await this._waitFakePrinting();

    this.fakePrinting = false;
    this.ready = true;
  }

  async getShippingLabelHref():Promise<string> {
    return await this._repairOrderService.getShippingLabelHrefRepairCenter(this.repairOrder.id);
  }
  
  set tires(tires:boolean) {
    this.ready = true;
    this._tires = tires;
  }

  get tires():boolean {
    return this._tires;
  }

  async generateFelgenheroCode(): Promise<void> {
    //generate the order code for this repairCenter
    this.felgenheroOrderCode = 'A' + this.repairOrder.id.toString().padStart(6, '0');
  }

  async generateRepcenterOrderCode(): Promise<void> {
    //this is a temporary sollution
    
    //load the repcenter abbreviation
    const repairCenterId : number = this._userService.user.repairCenterId;
    const repairCenter : RepairCenter = await this._repairCenterService.load(repairCenterId);

    //We use the REP prefix in case the repaircenter has no abbreviation set
    const repCenterCode : string = repairCenter.abbreviation ? repairCenter.abbreviation : 'REP';
    
    //generate the order code for this repairCenter
    let repCenterCodeTemp =  repCenterCode + "10" + this.felgenheroOrderCode;
    console.log(repCenterCodeTemp);
    this.repairOrderCode = repCenterCodeTemp;
  }
}
