import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { ActivatedRoute } from '@angular/router';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RepairCenterService } from 'src/app/service/repair-center.service';
import { RepairCenter } from 'src/app/model/repair-center.model';

enum RepairCenterActions {
  INCREASE_PRIORITY,
  DECREASE_PRIORITY,
  //DELETE,
}

@Component({
  selector: 'app-admin-repair-center-list',
  templateUrl: './admin-repair-center-list.component.html',
  styleUrls: ['./admin-repair-center-list.component.less']
})
export class AdminRepairCenterListComponent implements OnInit {

  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  filterDesc = {
    email: {
      type: "string",
      comp: "like",
      label: "E-Mail",
    },
    name: {
      type: "string",
      comp: "like",
      label: "Firmenname",
    },
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _servicePartnerService:ServicePartnerService,
    private _repairCenterService:RepairCenterService,
  ) { 
    activatedRoute.params.subscribe(params => {
      this.state = params.state;
      let filterDesc:any = {...this.filterDesc};
      delete(filterDesc.state);
    });
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    return REST_SERVER + "/repair-centers";
  }

  public getActionsForServicePartner(servicePartner:ServicePartner):any {
    let result = [];
    result.push({key: RepairCenterActions.INCREASE_PRIORITY, val: 'Priorität erhöhen'});
    result.push({key: RepairCenterActions.DECREASE_PRIORITY, val: 'Priorität verringern'});
    return result;
  }

  public async actionSelected(repairCenter:RepairCenter, action):Promise<void> {
    switch(action.key) {
      case RepairCenterActions.INCREASE_PRIORITY:
        await this._repairCenterService.increasePriority(repairCenter);
        this._grid.update();
        break;
      case RepairCenterActions.DECREASE_PRIORITY:
        await this._repairCenterService.decreasePriority(repairCenter);
        this._grid.update();
        break;
    }
  }
}
