import { Component } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'frontend';
  isCollapsed = true;

  cookieHintAccepted = false;

  constructor(
    public userService:UserService,
    private _translate: TranslateService,
    router:Router,
  ) {
    _translate.setDefaultLang('de');

    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.isCollapsed = true;
      }
    });

    let store:string = localStorage.getItem("cookieHintAccepted");
    if(store) {
      try { 
        this.cookieHintAccepted = JSON.parse(store).accepted;
      } catch(exc) {
        // do nothing
      }
    }
  }

  acceptCookieHint():void {
    this.cookieHintAccepted = true;
    localStorage.setItem("cookieHintAccepted", JSON.stringify({accepted: true}));
  }
}
