import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FilterBase } from 'src/app/components/ui/filter-inputs/util/filter-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-filter-inputselect',
  templateUrl: './filter-inputselect.component.html',
  styleUrls: ['./filter-inputselect.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterInputselectComponent),
      multi: true
    }
  ],
})
export class FilterInputselectComponent extends FilterBase {

  @Input() items = [];

  ngOnInit() {
  }

}
