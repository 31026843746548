import { Component, OnInit } from '@angular/core';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { UserService } from 'src/app/service/user.service';
import { ServicePartnerService } from 'src/app/service/service-partner.service';

@Component({
    selector: 'app-service-partner-dashboard',
    templateUrl: './service-partner-dashboard.component.html',
    styleUrls: ['./service-partner-dashboard.component.less']

})
export class ServicePartnerDashboardComponent implements OnInit {

    servicePartner: any;
    user: any;

    constructor(
        private _userService: UserService,
        private _servicePartnerService: ServicePartnerService
    ){}
    
    ngOnInit() {
        
        this.user = this._userService.user;
        this.loadServicePartner();
    }

    async loadServicePartner()
    {   
        this.servicePartner = await this._servicePartnerService.load(this.user.servicePartnerId);
    }

    openInfoPdf(){
        window.open("assets/InfoblattFelgenaufbereitung2021-05.pdf", '_blank');
    }

}