import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { Router } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { CmsService } from 'src/app/service/cms.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';

enum RepairOrderActions {
  CREATE_INVOICE
};

@Component({
  selector: 'app-cms-settings-list',
  templateUrl: './admin-cms-settings-list.component.html',
  styleUrls: ['./admin-cms-settings-list.component.less']
})
export class AdminCmsSettingsListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {};

  constructor(
    private _cmsService:CmsService,
    private _router:Router,
  ) { 
    
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    return REST_SERVER + "/cms/admin/settings";
  }

  public saveSetting(setting) {
    this._cmsService.saveSetting(setting);
  }
}
