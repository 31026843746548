export class User {

    email:string;
    password:string;
    salutation:string;
    firstName:string;
    lastName:string;
    role:string;
    activated:boolean;
    lastLogin:string;
}