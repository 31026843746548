import { Injectable } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { UserService } from './user.service';
import { ServicePartner } from 'src/app/model/service-partner.model';

@Injectable({
  providedIn: 'root'
})
export class RepairOrderService {

  constructor(
    private _http:HttpClient,
    private _userService:UserService,
  ) { }

  public async load(id:number):Promise<any> {
    return await this._http.get(`${REST_SERVER}/repair-orders/${id}`).toPromise();
  }

  public async loadForRepairCenter(id:number, repairCenterId:number):Promise<any> {
    return await this._http.get(`${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${id}`).toPromise();
  }

  public async loadForRepairCenterFiltered(filter:any, repairCenterId:number):Promise<any> {
    let filterStr:string = encodeURIComponent(JSON.stringify(filter));
    return await this._http.get(`${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/?filter=${filterStr}`).toPromise();
  }

  public async loadForServicePartner(servicePartnerId:number, id:number):Promise<any> {
    let res = null;
    try {
      res = await this._http.get(`${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders/${id}`).toPromise();
    } catch(exc) {
      console.error(exc);
    }
    return res;
  }

  public async createRepairOrder(repairOrder:RepairOrder):Promise<RepairOrder> {
    let user = this._userService.user;
    let newRepairOrder : RepairOrder;
    try {
      newRepairOrder = <RepairOrder> await this._http.post(REST_SERVER + "/service-partners/" + user.servicePartnerId
      + "/repair-orders", repairOrder).toPromise();
      return newRepairOrder;
    } catch(error)
    {
      throw error.error;
    }
    
  }

  public async cancelOrder(repairOrder:RepairOrder) :Promise<void> {
    await this._http.post(
      `${REST_SERVER}/admin/repair-orders/${repairOrder.id}/cancel`,{}
    ).toPromise();
  }

  public async recordWorkStart(repairOrder:RepairOrder, withTires:boolean, repairCenterId:number):Promise<RepairOrder> {
    return <RepairOrder>(await this._http.post(
      `${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${repairOrder.id}/process/start-work`, 
      { withTires }).toPromise());
  }

  public async recordWorkFinished(repairOrder:RepairOrder, success:boolean, notRepairableReason:string, repairCenterId:number):Promise<RepairOrder> {
    return <RepairOrder>(await this._http.post(
      `${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${repairOrder.id}/process/finish-work`, { success, notRepairableReason }).toPromise());
  }

  public async uploadImage(repairOrder:RepairOrder, type:string, data:ArrayBuffer, before:boolean):Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  type,
      })
    };
    let state:string = before ? 'before' : 'after';
    let res = await this._http.post(`${REST_SERVER}/repair-orders/${repairOrder.id}/images/${state}`, data, httpOptions).toPromise();
    console.log("Upload completed", data);
  }

  public async uploadImageForRepairCenter(
      repairOrder:RepairOrder, type:string, data:ArrayBuffer, before:boolean, repairCenterId:number):Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  type,
      })
    };
    let state:string = before ? 'before' : 'after';
    let res = await this._http.post(`${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${repairOrder.id}/images/${state}`, data, httpOptions).toPromise();
    console.log("Upload completed", data);
  }

  public async deleteImage(repairOrder:RepairOrder, path:string):Promise<void> {
    let res = await this._http.delete(path).toPromise();
  }

  public async getImageNames(repairOrder:RepairOrder, before:boolean):Promise<string[]> {
    let state = before ? 'before' : 'after';
    let res:any = await this._http.get(`${REST_SERVER}/repair-orders/${repairOrder.id}/images/${state}`).toPromise();
    return res.images;
  }

  public async getImageNamesForServicePartner(repairOrder:RepairOrder, servicePartnerId:number, before:boolean):Promise<string[]> {
    let state = before ? 'before' : 'after';
    let res:any = await this._http.get(`${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders/${repairOrder.id}/images/${state}`).toPromise();
    return res.images;
  }

  public async getImageNamesForRepairCenter(repairOrder:RepairOrder, repairCenterId:number, before:boolean):Promise<string[]> {
    let state = before ? 'before' : 'after';
    let res:any = await this._http.get(`${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${repairOrder.id}/images/${state}`).toPromise();
    return res.images;
  }

  public async getAbsoluteImageNames(repairOrder:RepairOrder, before:boolean):Promise<string[]> {
    let filenames:string[] = await this.getImageNames(repairOrder, before);
    let state = before ? 'before' : 'after';
    let result:string[] = [];
    for(let filename of filenames) {
      result.push(`${REST_SERVER}/repair-orders/${repairOrder.id}/images/${state}/${filename}`);
    }
    return result;
  }

  public async getAbsoluteImageNamesForServicePartner(repairOrder:RepairOrder, servicePartnerId:number, before:boolean):Promise<string[]> {
    let filenames:string[] = await this.getImageNamesForServicePartner(repairOrder, servicePartnerId, before);
    let state = before ? 'before' : 'after';
    let result:string[] = [];
    for(let filename of filenames) {
      result.push(`${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders/${repairOrder.id}/images/${state}/${filename}`);
    }
    return result;
  }

  public async getAbsoluteImageNamesForRepairCenter(repairOrder:RepairOrder, repairCenterId:number, before:boolean):Promise<string[]> {
    let filenames:string[] = await this.getImageNamesForRepairCenter(repairOrder, repairCenterId, before);
    let result:string[] = [];
    for(let filename of filenames) {
      let state:string = before ? 'before' : 'after';
      result.push(`${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${repairOrder.id}/images/${state}/${filename}`);
    }
    return result;
  }

  public async getLabelReturn(repairOrder:RepairOrder):Promise<any> {
    let res = await this._http.get('/assets/label.pdf', { responseType: 'blob' }).toPromise();
    console.log(res);
    return res;
  }

  public async createShippingOrderRepairCenter(repairOrderId:number):Promise<any> {
    let user = this._userService.user;
    return await this._http.post(REST_SERVER + 
      "/repair-centers/" + user.repairCenterId +
      "/repair-orders/" + repairOrderId +  
      "/create_shipping_order", repairOrderId).toPromise();
  }

  public async getShippingLabelHrefServicePartner(repairOrderId:number):Promise<string> {
    let servicePartnerId = this._userService.user.servicePartnerId;
    let token:string = this._userService.token;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders/${repairOrderId}/shipping_label?access_token=${token}`;
  }

  public async getShippingLabelHrefRepairCenter(repairOrderId:number):Promise<string> {
    let repairCenterId = this._userService.user.repairCenterId;
    let token:string = this._userService.token;
    return `${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders/${repairOrderId}/shipping_label?access_token=${token}`;
  }

  public async testRepairCenterAssignment(config?:any):Promise<any> {
    const headers = {'content-type': 'application/json'};
    //let configStr:string = encodeURIComponent(JSON.stringify(config));

    let configStr = {'name':'test'};
    let result = await this._http.post(
                      `${REST_SERVER}/repair-orders/testAssignment`, 
                      config, 
                      {headers }).toPromise();
    
    return result;
  }
}
