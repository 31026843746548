import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-collapsable-header',
  template: `<ng-content></ng-content>`
})
export class CollapsableHeaderComponent { 

}

@Component({
  selector: 'app-collapsable-content',
  template: `<ng-content></ng-content>`
})
export class CollapsableContentComponent { 

}

@Component({
  selector: 'app-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.less']
})
export class CollapsableComponent implements OnInit {

  @Input() collapsed:boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
