import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterComponent),
      multi: true
    }
  ],
})
export class FilterComponent implements OnInit, ControlValueAccessor {

  filterChangeWaitTimeout = null;

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filter = {
    status: null,
    email: null,
    name: null,
  }

  @Input() filterDesc = {};

  filterJson:string = null;

  private _lastFilterRows = null;

  private _value:any = {};
  
  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  public filterRows = null;

  constructor() {}

  ngOnInit() {
    let result:any = [];
    let idx = 0;
    for(let key in this.filterDesc) {
      if(key !== 'state')
      {
        if(idx % 3 == 0) {
          result.push([]);
        }
        this.filterDesc[key].key = key;
        result[result.length - 1].push(this.filterDesc[key]);
        idx++;
      }
    }
    this.filterRows = result;
  }

  get value():any {
    return this._value;
  }

  set value(value: any) {
    if(value != this._value) {
      this._value = value;
      this._onChange(value);
    }
  }

  setFilterValue(key:string, value:string):void {
    if(this._value) {
      this._value[key] = value;
      this._onChange(this._value);
    } else {
      console.error("Value is null!");
    }
  }

  getFilterValue(key:string):string {
    return this._value ? this._value[key] : null;
  }

  writeValue(value: string):void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }
}
