import { ServicePartner } from 'src/app/model/service-partner.model';


export class RepairOrder {
    id?: number;
    servicePartner:ServicePartner;
    orderDate:string;
    rimCount:number;
    rimDiameter:number;
    rimVarnish:string;
    rimWithTires:boolean;
    servicePartnerReference:string;
    servicePartnerMessage:string;
    state:string;
    repairResult:string;
    invoiceIdent:string;
}