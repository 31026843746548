import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { EnumService } from 'src/app/service/enum.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-imageselect',
  templateUrl: './imageselect.component.html',
  styleUrls: ['./imageselect.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageselectComponent),
      multi: true
    }
  ],
})
export class ImageselectComponent implements OnInit, ControlValueAccessor {

  private static _idCnt = 1;

  public id:string;

  @Input() icon;
  @Input() label;
  @Input() name;
  @Input("enum") enumKey;
  @Input() inline:boolean = false;
  @Input() labels:[];
  @Input() tooltips:null;
  @Input() horizontal:boolean = false;

  private _enum = [];

  private _value:string = null;
  
  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  constructor(
    public enumService:EnumService
  ) { 
    this.id = 'imgselect-' + ImageselectComponent._idCnt++;
  }

  ngOnInit() {
    console.log("tooltips", this.tooltips);
  }

  get items() {
    if(this._enum.length == 0 && !this.enumService.isLoading()) {
      this._enum = [];
      let elems:string[] = this.enumService.getEnum(this.enumKey);
      for(let elem of elems) {
        this._enum.push({ key: elem, val: this.enumKey + "_" + elem });
      }
    }
    return this._enum;
  }

  set value(value: string) {
    if(value != this._value) {
      this._value = value;
      this._onChange(value);
    }
  }

  get value():string {
    return this._value;
  }

  writeValue(value: string):void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }

}
