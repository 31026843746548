import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { CmsService } from 'src/app/service/cms.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { REST_SERVER } from 'src/app/config';

@Component({
  selector: 'app-admin-cms-css-js',
  templateUrl: './admin-cms-css-js.component.html',
  styleUrls: ['./admin-cms-css-js.component.less']
})
export class AdminCmsCssJsComponent implements OnInit {

  expanded:any = {};
  showHtml:any = {};

  css:string = '';
  js:string = '';

  currentOrdersFilterJson:string = null;

  public servicePartner = null;
  servicePartnerData = [];

  constructor(
    activatedRoute:ActivatedRoute,
    private _location:Location,
    private _cmsService:CmsService,
    private _modalService: BsModalService,
  ) { 
    activatedRoute.params.subscribe((params) => {
      this._loadCss();
      this._loadJs();
    });
  }

  private async _loadCss():Promise<void> {
    this.css = await this._cmsService.loadAdminCss();
  }

  private async _loadJs():Promise<void> {
    this.js = await this._cmsService.loadAdminJs();
  }

  ngOnInit() {
  }


  async saveCss():Promise<void> {
    await this._cmsService.saveAdminCss(this.css);
  }

  async saveJs():Promise<void> {
    await this._cmsService.saveAdminJs(this.js);
  }
}
