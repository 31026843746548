import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { REST_SERVER } from 'src/app/config';
import { ServicePartner } from '../model/service-partner.model';

@Injectable({
  providedIn: 'root'
})
export class ServicePartnerService {

  constructor(
    private _http:HttpClient
  ) { }

  public async deleteServicePartner(servicePartner:ServicePartner):Promise<void> {
    await this._http.delete(`${REST_SERVER}/service-partners/${servicePartner.id}`).toPromise();
  }

  public async confirmEmail(confirmEmailData:any):Promise<void> {
    await this._http.post(REST_SERVER + '/service-partners/confirm-email', confirmEmailData).toPromise();
  }

  public async loadAll(filter = {}):Promise<ServicePartner[]> {
    let filterStr:string = encodeURIComponent(JSON.stringify(filter));
    return <ServicePartner[]> await this._http.get(`${REST_SERVER}/service-partners/public?filter=${filterStr}`).toPromise();
  }

  public async load(id:number):Promise<any> {
    return await this._http.get(REST_SERVER + '/service-partners/' + id).toPromise();
  }

  public async getDocumentNames(id:number):Promise<any> {
    let res:any = await this._http.get(`${REST_SERVER}/service-partners/${id}/documents`).toPromise();
    return res.documents;
  }

  public async deleteDocument(servicePartnerId:number, filename:string):Promise<any> {
    filename = encodeURIComponent(filename);
    await this._http.delete(`${REST_SERVER}/service-partners/${servicePartnerId}/documents/${filename}`).toPromise();
  }

  public async addDocument(servicePartnerId:number, filename:string, type:string, data:ArrayBuffer):Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  type,
      })
    };
    filename = encodeURIComponent(filename);
    await this._http.put(`${REST_SERVER}/service-partners/${servicePartnerId}/documents/${filename}`, data, httpOptions).toPromise();
  } 

  public async getAbsoluteDocumentNames(id:number):Promise<string[]> {
    let filenames:string[] = await this.getDocumentNames(id);
    let result:string[] = [];
    for(let filename of filenames) {
      result.push(`${REST_SERVER}/repair-orders/${id}/images/${filename}`);
    }
    return result;
  }

  public async loadSettings(id:number):Promise<any> {
    return await this._http.get(`${REST_SERVER}/service-partners/${id}/settings`).toPromise();
  }

  public async saveSettings(id:number, settings:any):Promise<any> {
    return await this._http.post(`${REST_SERVER}/service-partners/${id}/settings`, settings).toPromise();
  }

  public async saveDisplayOnMap(id:number, displayOnMap:boolean):Promise<any> {
    return await this._http.post(`${REST_SERVER}/service-partners/${id}/settings/displayOnMap`, {displayOnMap}).toPromise();
  }

  public async activate(servicePartner):Promise<any> {
    await this._http.post(REST_SERVER + '/service-partners/' + servicePartner.id + '/activate', {}).toPromise();
  }

  public async denyActivation(servicePartner):Promise<any> {
    await this._http.post(REST_SERVER + '/service-partners/' + servicePartner.id + '/deny-activation', {}).toPromise();
  }

  public async placeMarketingMaterialOrder(servicePartnerId:number, orderFlyerBox:boolean, orderFlyers:boolean) {
    await this._http.post(`${REST_SERVER}/service-partners/${servicePartnerId}/place-marketing-material-order`, {orderFlyerBox, orderFlyers}).toPromise();
  }

  public async requestPricelist(personalData: any): Promise<any> {
    const headers = {'content-type': 'application/json'};
    let result = await this._http.post(
      `${REST_SERVER}/service-partners/requestPricelist`,
      personalData,
      {headers}
    ).toPromise();
  }
}
