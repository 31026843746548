import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  private _enums = null;

  constructor() { 
    setTimeout(() => {
      this._enums = {
        rim_count: [1, 2, 3, 4],
        rim_diameter: [17, 18],
        rim_with_tires: [
          false, true
        ],
        rim_varnish: [
          "silver",
          "colored",
          "polish",
          "luxury",
        ],
        yes_no: [
          'yes',
          'no',
        ],
        salutation: [
          'mr',
          'mrs',
        ],
        notRepairableReason: [
          'outOfRoundRadially',
          'outOfRoundLaterally',
          'moreThan1mm',
        ],
        invoiceType: [
          'invoice',
          'credit',
        ],
        partnerType: [
          'servicePartner',
          'repairCenter',
        ],
      };
    }, 1);
  }

  isLoading():boolean {
    return !this._enums;
  }

  getEnum(key:string):string[] {
    let result:string[] = [];
    if(this._enums && key in this._enums) {
      result = this._enums[key];
    }
    return result;
  }

}
