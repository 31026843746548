import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { RepairCenter } from 'src/app/model/repair-center.model';
import { REST_SERVER } from 'src/app/config';

export class SelectPartnerResult {
  success:boolean;
  servicePartner:ServicePartner;
  repairCenter:RepairCenter;
}

@Component({
    selector: 'select-partner-dialog',
    templateUrl: './select-partner-dialog.component.html'
  })
   
  export class SelectPartnerDialogComponent implements OnInit {
    title: string;
    closeBtnName: string = "OK";
    msg:string;

    servicePartnerFilterDesc = {
      name: {
        type: "string",
        comp: "like",
        label: "Firmenname",
      },
    }

    partnerType:string = "servicePartner";

    public onClose: Subject<SelectPartnerResult> = new Subject<SelectPartnerResult>();
   
    constructor(public bsModalRef: BsModalRef) {}
   
    ngOnInit() {
      
    }

    public getServicePartnerBaseUrl():string {
      return REST_SERVER + "/service-partners";
    }

    public getRepairCenterBaseUrl():string {
      return REST_SERVER + "/repair-centers";
    }

    public selectServicePartner(servicePartner:ServicePartner):void {
      let result = new SelectPartnerResult();
      result.success = true;
      result.servicePartner = servicePartner;
      this.onClose.next(result);
      this.bsModalRef.hide();
    }

    public selectRepairCenter(repairCenter:RepairCenter):void {
      let result = new SelectPartnerResult();
      result.success = true;
      result.repairCenter = repairCenter;
      this.onClose.next(result);
      this.bsModalRef.hide();
    }

    public onCancel():void {
      let result = new SelectPartnerResult();
      result.success = false;
      this.onClose.next(result);
      this.bsModalRef.hide();
    }
  }