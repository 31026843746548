import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-cms-public-page',
  template: `
  <app-cms-page [pageIdent]="path"></app-cms-page>
  `,
})
export class CmsPublicPageComponent {

  path:string;

  constructor(
    router:Router,
    activatedRoute:ActivatedRoute,
  ) { 
    activatedRoute.params.subscribe(params => {

    });

    router.events.pipe(
      filter(event => event instanceof ChildActivationEnd)
    ).subscribe(event=> {
      const url:string = (<ChildActivationEnd>event).snapshot['_routerState'].url;
      console.log("Change url", url);
      this.path = url.substr(1);
    });
  }

}
