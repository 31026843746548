import { Injectable } from '@angular/core';
import { User } from 'src/app/model/user.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { REST_SERVER } from 'src/app/config';
import { FeedbackService } from './feedback.service';

const STORAGE_KEY_TOKEN:string = "token";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user = null;
  private _token = null;
  private _redirectAfterLogin = null;

  private _loginPages = {
    "service-partner": ["service-partner", "dashboard"],
    "admin": ["admin", "service-partners", "list", "active"],
    "repair-center": ["repair-center", "dashboard"],
  };

  constructor(
    private _router:Router,
    private _http:HttpClient,
    private _feedbackService: FeedbackService,
  ) { 
    var token:string = null;
    let m = /access_token=(.*?)(&.*)?$/.exec(location.href);
    if(m) {
      token = m[1];
      localStorage.setItem(STORAGE_KEY_TOKEN, token);
    }
    if(!token) {
      token = localStorage.getItem(STORAGE_KEY_TOKEN);
    }
    
    this._token = token;
    if(token) {
      //console.log("Token found");
      this._user = this._parseJwt(token);
      this._setRefreshTimeout();
      if(this.getValidUser()) {
        setTimeout(() => this.refreshToken(), 0);
      }
    }
  }

  private _setRefreshTimeout():void {
    setTimeout(() => this.refreshToken(), 5 * 60 * 1000);
  }

  public async login(user:User):Promise<any> {
    let httpResponse:any = await this._http.post(REST_SERVER + "/login", user).toPromise();

    let token:string = httpResponse.token;
    
    localStorage.setItem(STORAGE_KEY_TOKEN, token);

    this._token = token;
    this._user = this._parseJwt(token);
    //console.log("User", this._user);

    var role:string = this._user.role;
    if(this._redirectAfterLogin) {
      this._router.navigateByUrl(this._redirectAfterLogin);
      this._redirectAfterLogin = null;
    } else {
      this._router.navigate(this._loginPages[role]);
    }

    this._setRefreshTimeout();
    
    return { success: true };
  }

  public async load(id:number):Promise<any> {
    let res = null;
    try {
      res = await this._http.get(REST_SERVER + '/users/' + id).toPromise();
    } catch(exc) {
      console.error(exc);
    }
    return res;
  }
  
  public async forgotPassword(forgotPasswordData:any):Promise<any> {
    const result:any = await this._http.post(REST_SERVER + "/forgot-password", forgotPasswordData).toPromise();    
    if(!result.userFound) {
      throw "Password reset failed. Most likely caused by wrong email";
    } 
  }

  public async changePassword(changePasswordData:any):Promise<any> {
    await this._http.post(REST_SERVER + "/change-password", changePasswordData).toPromise();    
    return { success: true };
  }

  private async refreshToken() {
    let httpResponse:any = await this._http.post(REST_SERVER + "/refresh", {}).toPromise();
    
    let token:string = httpResponse.token;
    
    localStorage.setItem(STORAGE_KEY_TOKEN, token);

    this._token = token;

    this._setRefreshTimeout();
  }

  public logout():void {
    this._token = null;
    this._user = null;
    localStorage.removeItem(STORAGE_KEY_TOKEN);
    this._router.navigate(["/"]);
  }

  public ensureRole(roles:string[], url:string):boolean {
    var result:boolean = false;
    let user = this.getValidUser();
    if(user && roles.indexOf(user.role) >= 0) {
      result = true;
    } else {
      this._redirectAfterLogin = url;
      this._router.navigate(['/login']);
      result = false;
    }
    return result;
  }

  private getValidUser() {
    //console.log("Checking if user is valid");
    if(this._user && new Date().valueOf() / 1000 > this._user.exp) {
      console.log("User expired", this._user);
      this._user = null;
    }
    if(this._user && this._user.role === 'repair-center' && !this._user.repairCenterId) {
      this._user.repairCenterId = 1;
    }
    return this._user;
  }

  public hasRole(role:string):boolean {
    let user = this.getValidUser();
    return user && user.role == role;
  }
  
  private _b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  private _parseJwt(token):void {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    var decoded = this._b64DecodeUnicode(base64);
    return JSON.parse(decoded);
  }

  public get user() {
    return this.getValidUser();
  }

  public get token():string {
    return this._token;
  }

  public async savePassword(pswds:any):Promise<any> {
    return { success: <boolean>(await this._http.post(REST_SERVER + "/users/" + this._user.id
      + "/settings/password", pswds).toPromise()) };
  }

  public async addRecipientToNewsletter(recipientData : any): Promise<any>{
    const headers = {'content-type': 'application/json'};
    try {
      let result = await this._http.post(
        `${REST_SERVER}/newsLetterRecipient`,
        recipientData,
        {headers}
      ).toPromise();
    } catch (error) {
      this._feedbackService.showError("Email ungültig oder bereits in der Newsletter-Liste vorhanden");
    }
    
  }

}