import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/service/settings.service';
import { FeedbackService } from 'src/app/service/feedback.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RalColorPickerDialogComponent } from 'src/app/components/ui/dialogs/ral-color-picker-dialog.component';

@Component({
  selector: 'app-service-partner-order-form',
  templateUrl: './service-partner-order-form.component.html',
  styleUrls: ['./service-partner-order-form.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ServicePartnerOrderFormComponent implements OnInit {

  sendingOrder:boolean = false;
  orderModel:RepairOrder = new RepairOrder();
  private _settings:any;
  acceptBusinessTerms:boolean;
  acceptDataProtection:boolean;
  private _priceData = {
    pricePerRimNet: 0,
    priceTotalNet: 0,
    priceTotalVat: 0,
    priceTotalGross: 0,
  }
  previousOrder:any;

  tooltipsVarnish:string[] = [
    'schön und zeitlos, immer noch am verbreitetsten',
    'schwarze oder graue Farbtöne beispielsweise können je nach Hersteller sehr unterschiedlich sein. FelgenHero verwendet deshalb nur Originallacke! ',
    'sehr hochwertig, der regenbogenartige Schimmer des Aluminiums wird durch das Glanzdrehen sichtbar',
    'die High-End Klasse, hochwertiges individuelles Design und beste Qualität'
  ];

  constructor(
    private activatedRoute:ActivatedRoute,
    private _router:Router,
    private _repairOrderService:RepairOrderService,
    private _settingsService:SettingsService,
    private _feedbackService:FeedbackService,
    private _modalService:BsModalService
  ) { }

  async ngOnInit() {
    this._settings = await this._settingsService.getServicePartnerSettingsDict();

    const orderParam = this.activatedRoute.snapshot.paramMap.get('currentOrder');
    this.previousOrder = JSON.parse(orderParam);
    
    //user requested another rim with same parameters
    if(this.previousOrder != null) {
      this.orderModel.rimDiameter = this.previousOrder.rimDiameter;
      this.orderModel.rimVarnish = this.previousOrder.rimVarnish;
      this.orderModel.rimWithTires = this.previousOrder.rimWithTires;
      this.orderModel.servicePartnerReference = this.previousOrder.servicePartnerReference;
      this.acceptBusinessTerms = this.previousOrder.acceptBusinessTerms;
      this.acceptDataProtection = this.previousOrder.acceptDataProtection;
    }
    //set here to trigger the price calculation
    this.orderModel.rimDiameter = 17;
  }

  async onCreateOrder():Promise<void> {
    this.sendingOrder = true;

    this.orderModel.rimWithTires = false;

    let repairOrders = [];
    let newRepairOrder;
    let ordersCreated = 0;

    while(ordersCreated < this.orderModel.rimCount)
    {
      try {
        newRepairOrder = await this._repairOrderService.createRepairOrder(this.orderModel);
        ordersCreated = repairOrders.push(newRepairOrder);
      } catch (error)
      {
        this._feedbackService.showError(error.error.message);
        this.resetForm();
        return;
      }
    }
    
    this.sendingOrder = false;

    let ordersJson = {};
    repairOrders.map((currentOrder, index) => {
      ordersJson[index] = {
        "id": currentOrder.id,
        "rimDiameter": this.orderModel.rimDiameter,
        "rimVarnish": this.orderModel.rimVarnish,
        "rimWithTires": this.orderModel.rimWithTires,
        "servicePartnerReference": this.orderModel.servicePartnerReference,
        "acceptBusinessTerms": this.acceptBusinessTerms,
        "acceptDataProtection": this.acceptDataProtection
      }
    })

    console.log(ordersJson);
    console.log(Object.keys(ordersJson).length);

    this._router.navigate(['service-partner', 'order', 'success', {currentOrder: JSON.stringify(ordersJson)}]);
  }

  resetForm() {
    this.sendingOrder = false;
  }

  get priceData() {
    let key = `price_${this.orderModel.rimVarnish}_${this.orderModel.rimDiameter}_b2b_net`;
    //console.log("Checking key: ", key, this.prices);
    if(this._settings && this._settings[key]) {
      let price = Math.round(parseFloat(this._settings[key].value.replace(',', '.')) * 100);
      let priceWithTires = 0;
      if(this.orderModel.rimWithTires) {
        priceWithTires = Math.round(parseFloat(this._settings['price_tires_b2b_net'].value.replace(',', '.')) * 100);
      }
      this._priceData.pricePerRimNet = (price + priceWithTires);
      this._priceData.priceTotalNet = (price + priceWithTires) * (this.orderModel.rimCount ? this.orderModel.rimCount : 1);
      this._priceData.priceTotalVat = Math.floor(this._priceData.priceTotalNet * 0.19);
      this._priceData.priceTotalGross = Math.floor(this._priceData.priceTotalNet * 1.19);
      //console.log("Price found: ", this.prices[key]);
    }
    return this._priceData;
  }

  showColorPicker()
  {
    let ref: BsModalRef = this._modalService.show(RalColorPickerDialogComponent, {class: 'modal-lg'});
    ref.content.event.subscribe((data) => this.orderModel.servicePartnerMessage = data.colorString);
  }
}
