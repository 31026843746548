import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { IndexComponent } from './components/pages/public/index/index.component';
import { RegisterFormComponent } from './components/pages/public/register/form/register-form.component';
import { RegisterSuccessComponent } from './components/pages/public/register/success/register-success.component';
import { GridComponent, GridRowComponent, GridDataComponent, GridHeaderComponent } from 'src/app/components/ui/grid/grid.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from 'src/app/util/auth-http-interceptor';
import { InputfieldComponent } from 'src/app/components/ui/inputs/inputfield/inputfield.component';
import { InputselectComponent } from 'src/app/components/ui/inputs/inputselect/inputselect.component';
import { 
  CollapsableComponent, 
  CollapsableHeaderComponent, 
  CollapsableContentComponent, 
} from './components/ui/collapsable/collapsable.component';
import { FilterInputfieldComponent } from './components/ui/filter-inputs/filter-inputfield/filter-inputfield.component';
import { FilterInputselectComponent } from './components/ui/filter-inputs/filter-inputselect/filter-inputselect.component';
import { FilterTrashComponent } from './components/ui/filter-inputs/util/filter-trash/filter-trash.component';
import { FilterComponent } from './components/ui/filter/filter.component';
import { DatePipe } from './pipes/date.pipe';
import { DateIfPipe } from './pipes/dateIf.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { InputselectenumComponent } from './components/ui/inputs/inputselectenum/inputselectenum.component';
import { ServicePartnerOrderFormComponent } from 'src/app/components/pages/service-partner/order/form/service-partner-order-form.component';
import { ServicePartnerOrderSuccessComponent } from 'src/app/components/pages/service-partner/order/success/service-partner-order-success.component';
import { RepairCenterDashboardComponent } from 'src/app/components/pages/repair-center/dashboard/repair-center-dashboard.component';
import { RepairCenterOrderProcessComponent } from 'src/app/components/pages/repair-center/repair-order/process/repair-center-order-process.component';
import { RepairCenterOrderProcessImageUploadComponent } from 'src/app/components/pages/repair-center/repair-order/process/image-upload/repair-center-order-process-image-upload.component';
import { RepairCenterOrderListComponent } from 'src/app/components/pages/repair-center/repair-order/list/repair-center-order-list.component';
import { RepairCenterOrderDetailsComponent } from 'src/app/components/pages/repair-center/repair-order/details/repair-center-order-details.component';
import { MessageDialogComponent } from 'src/app/components/ui/dialogs/message-dialog/message-dialog.component';
import { AdminRepairOrderListComponent } from 'src/app/components/pages/admin/repair-order/list/admin-repair-order-list.component';
import { AdminRepairOrderDetailsComponent } from 'src/app/components/pages/admin/repair-order/details/admin-repair-order-details.component';
import { AdminInvoiceListComponent } from 'src/app/components/pages/admin/invoice/list/admin-invoice-list.component';
import { AmountPipe } from 'src/app/pipes/amount.pipe';
import { AdminInvoiceDetailsComponent } from 'src/app/components/pages/admin/invoice/details/admin-invoice-details.component';
import { ServicePartnerOrderListComponent } from 'src/app/components/pages/service-partner/repair-order/list/service-partner-order-list.component';
import { ServicePartnerOrderDetailsComponent } from 'src/app/components/pages/service-partner/repair-order/details/service-partner-order-details.component';
import { ServicePartnerInvoiceListComponent } from 'src/app/components/pages/service-partner/invoice/list/service-partner-invoice-list.component';
import { SearchComponent } from 'src/app/components/pages/public/search/search.component';
import { GOOGLE_MAPS_API_KEY } from 'src/app/config';
import { ServicePartnerSettingsComponent } from 'src/app/components/pages/service-partner/settings/service-partner-settings.component';
import { ResetPasswordComponent } from 'src/app/components/pages/public/reset-password/reset-password.component';
import { ConfirmEmailComponent } from 'src/app/components/pages/public/register/confirm-email/confirm-email.component';
import { AdminServicePartnersComponent } from 'src/app/components/pages/admin/admin-service-partners/list/admin-service-partners.component';
import { OrderIdPipe } from 'src/app/pipes/order-id.pipe';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { AdminServicePartnerDetailsComponent } from 'src/app/components/pages/admin/admin-service-partners/details/admin-service-partner-details.component';
import { ImageselectComponent } from 'src/app/components/ui/inputs/imageselect/imageselect.component';
import { ServicePartnerOrderPackagingComponent } from 'src/app/components/pages/service-partner/order/packaging/service-partner-order-packaging.component';
import { ServicePartnerOrderPickupComponent } from 'src/app/components/pages/service-partner/order/pickup/service-partner-order-pickup.component';
import { ViewerDialogComponent } from 'src/app/components/ui/dialogs/viewer-dialog.component';
import { CmsPageComponent } from 'src/app/components/ui/cms-page/cms-page.component';
import { SanitizeHtmlPipe } from 'src/app/pipes/sanitizeHtml.pipe';
import { RegisterFormFracComponent } from 'src/app/components/ui/register-form-frac/register-form-frac.component';
import { AdminServicePartnerCreateComponent } from 'src/app/components/pages/admin/admin-service-partners/create/admin-service-partner-create.component';
import { AdminRepairCenterDetailsComponent } from 'src/app/components/pages/admin/repair-center/details/admin-repair-center-details.component';
import { AdminCmsPagesListComponent } from './components/pages/admin/cms/pages/list/admin-cms-pages-list.component';
import { AdminCmsPagesDetailsComponent } from './components/pages/admin/cms/pages/details/admin-cms-pages-details.component';
import { AdminCmsPreviewDialogComponent } from './components/pages/admin/cms/pages/details/admin-cms-preview-dialog.component';
import { CmsPublicPageComponent } from './components/pages/public/cms-public-page/cms-public-page.component';
import { AdminCmsSettingsListComponent } from './components/pages/admin/cms/settings/list/admin-cms-settings-list.component';
import { AdminCmsFilesListComponent } from './components/pages/admin/cms/files/list/admin-cms-files-list.component';
import { AdminCmsCssJsComponent } from './components/pages/admin/cms/css-js/admin-cms-css-js.component';
import { AdminRepairCenterListComponent } from 'src/app/components/pages/admin/repair-center/list/admin-repair-center-list.component';
import { InputnumberfieldComponent } from 'src/app/components/ui/inputs/inputnumberfield/inputnumberfield.component';
import { AdminInvoiceCreateComponent } from 'src/app/components/pages/admin/invoice/create/admin-invoice-create.component';
import { SelectPartnerDialogComponent } from 'src/app/components/ui/dialogs/select-partner-dialog/select-partner-dialog.component';
import { InputcurrencyfieldComponent } from 'src/app/components/ui/inputs/inputcurrencyfield/inputcurrencyfield.component';
import { RepairCenterInvoiceListComponent } from 'src/app/components/pages/repair-center/invoice/list/repair-center-invoice-list.component';
import { PrintDialogRepairCenterComponent } from 'src/app/components/ui/dialogs/print-dialog-repair-center.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorComponent } from './service/feedback.service';
import { OnePagerComponent } from './components/pages/public/one-pager/one-pager.component';
import { NavbarPublicComponent } from './components/ui/navbars/navbar-public/navbar-public.component';
import { FooterPublicComponent } from './components/ui/footer/footer-public/footer-public.component';
import { ServicePartnerDashboardComponent } from './components/pages/service-partner/dashboard/service-partner-dashboard.component';
import { AdminRepairCenterAssignmentComponent } from './components/pages/admin/repair-center/assignment-test/admin-repair-center-assignment.component';
import { PriceListRequestDialogComponent } from 'src/app/components/ui/dialogs/price-list-request-dialog.component';
import { NewsletterDialogComponent } from 'src/app/components/ui/dialogs/newsletter-dialog.component';
import { GeneralConfirmDialogComponent } from './components/ui/dialogs/general-confirm-dialog.component';
import { MultiLabelViewerComponent } from './components/ui/dialogs/multi-label-viewer.component';
import { RalColorPickerDialogComponent } from './components/ui/dialogs/ral-color-picker-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    IndexComponent,
    SearchComponent,
    RegisterFormComponent,
    InputfieldComponent,
    InputnumberfieldComponent,
    InputcurrencyfieldComponent,
    ServicePartnerOrderFormComponent,
    ServicePartnerOrderSuccessComponent,
    ServicePartnerOrderPackagingComponent,
    ServicePartnerOrderPickupComponent,
    InputselectComponent,
    ImageselectComponent,
    RegisterSuccessComponent,
    AdminServicePartnersComponent,
    GridComponent,
    GridRowComponent,
    GridDataComponent,
    GridHeaderComponent,
    AdminServicePartnerDetailsComponent,
    CollapsableComponent,
    CollapsableHeaderComponent,
    CollapsableContentComponent,
    FilterInputfieldComponent,
    FilterInputselectComponent,
    FilterTrashComponent,
    FilterComponent,
    AdminRepairOrderListComponent,
    AdminRepairOrderDetailsComponent,
    AdminInvoiceListComponent,
    AdminInvoiceDetailsComponent,
    AdminInvoiceCreateComponent,
    AdminServicePartnerCreateComponent,
    AdminRepairCenterListComponent,
    AdminRepairCenterDetailsComponent,
    DatePipe,
    DateIfPipe,
    SecurePipe,
    CamelCasePipe,
    AmountPipe,
    OrderIdPipe,
    SanitizeHtmlPipe,
    InputselectenumComponent,
    RepairCenterDashboardComponent,
    RepairCenterOrderProcessComponent,
    RepairCenterOrderProcessImageUploadComponent,
    RepairCenterOrderListComponent,
    RepairCenterOrderDetailsComponent,
    RepairCenterInvoiceListComponent,
    AdminRepairCenterAssignmentComponent,
    ServicePartnerOrderListComponent,
    ServicePartnerOrderDetailsComponent,
    ServicePartnerInvoiceListComponent,
    ServicePartnerSettingsComponent,
    ServicePartnerDashboardComponent,
    MessageDialogComponent,
    ConfirmDialogComponent,
    ViewerDialogComponent,
    PrintDialogRepairCenterComponent,
    SelectPartnerDialogComponent,
    CmsPageComponent,
    RegisterFormFracComponent,
    AdminCmsPagesListComponent,
    AdminCmsPagesDetailsComponent,
    AdminCmsPreviewDialogComponent,
    CmsPublicPageComponent,
    AdminCmsSettingsListComponent,
    AdminCmsFilesListComponent,
    AdminCmsCssJsComponent,
    ErrorComponent,
    OnePagerComponent,
    NavbarPublicComponent,
    FooterPublicComponent,
    PriceListRequestDialogComponent,
    NewsletterDialogComponent,
    GeneralConfirmDialogComponent,
    MultiLabelViewerComponent,
    RalColorPickerDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    AngularFontAwesomeModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    AgmCoreModule.forRoot({
        apiKey: GOOGLE_MAPS_API_KEY,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    MessageDialogComponent,
    ConfirmDialogComponent,
    ViewerDialogComponent,
    PrintDialogRepairCenterComponent,
    SelectPartnerDialogComponent,
    AdminCmsPreviewDialogComponent,
    ErrorComponent,
    PriceListRequestDialogComponent,
    NewsletterDialogComponent,
    GeneralConfirmDialogComponent,
    MultiLabelViewerComponent,
    RalColorPickerDialogComponent
  ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
