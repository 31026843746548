import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export class FilterBase implements OnInit, ControlValueAccessor {
    
      @Input() label:string;
    
      private _value:string;
    
      private _onChange = (value: string) => {};
      private _onTouched = () => {};
    
      constructor() { }
    
      ngOnInit() {
      }
    
      get value():string {
        return this._value;
      }
    
      set value(value: string) {
        if(value != this._value) {
          this._value = value;
          this._onChange(value);
        }
      }
    
      writeValue(value: string):void {
        this.value = value;
      }
    
      registerOnChange(onChange: (value: string) => void):void {
        this._onChange = onChange;
      }
    
      registerOnTouched(onTouched: () => void):void {
        this._onTouched = onTouched;
      }
    
      onTouched():void {
        this._onTouched();
      }
    
    }