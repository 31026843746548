import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var d:Date = new Date(value);
    var result:string = "";
    result += ("00" + d.getDate()).substr(-2) + ".";
    result += ("00" + (d.getMonth() + 1)).substr(-2) + ".";
    result += d.getFullYear(); /*+ " ";
    result += ("00" + d.getHours()).substr(-2) + ":";
    result += ("00" + d.getMinutes()).substr(-2) + ":";
    result += ("00" + d.getSeconds()).substr(-2);*/
    return result;
  }

}
