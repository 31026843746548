import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageDialogComponent } from 'src/app/components/ui/dialogs/message-dialog/message-dialog.component';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { Invoice } from 'src/app/model/invoice.model';
import { Address } from 'src/app/model/address.model';
import { UserService } from 'src/app/service/user.service';
import { ServicePartnerService } from 'src/app/service/service-partner.service';

enum InvoiceAction {
  BOOK_PAYMENT,
};

@Component({
  selector: 'app-service-partner-settings',
  templateUrl: './service-partner-settings.component.html',
  styleUrls: ['./service-partner-settings.component.less']
})
export class ServicePartnerSettingsComponent {

  public sendingSettings:boolean;
  public success:boolean;
  public passwordCheck:string = "";
  @ViewChild("formTop") formTop:ElementRef = null;

  settings = {
    deliveryAddress: new Address(),
    invoiceAddress: new Address(),
    phone: "",
    email:"",
    displayOnMap: false,
  };

  pswds = {
    oldPswd: "",
    newPswd: ""
  }

  constructor(
	  private _router:Router,
    private _servicePartnerService:ServicePartnerService,
    private _userService:UserService,
  ) {
    this._loadServicePartnerSettings();
  }

  private async _loadServicePartnerSettings():Promise<void> {
    let servicePartnerId:number = this._userService.user.servicePartnerId;
    this.settings = await this._servicePartnerService.loadSettings(servicePartnerId);
    
  }

  async changePswd():Promise<void> {
    this.sendingSettings = true;
    var result = await this._userService.changePassword(this.pswds);
    this.sendingSettings = false;
    //this._router.navigate(['settings', 'success']);
    console.log(result);
  }

  async saveSettings():Promise<void> {
    this.sendingSettings = true;
    let servicePartnerId:number = this._userService.user.servicePartnerId;
    await this._servicePartnerService.saveSettings(servicePartnerId, this.settings);
    /*
    if(this.pswds.newPswd != "" && this.pswds.oldPswd != "") {
      let result  = await this._userService.savePassword(this.pswds);
      console.log(result);
    }
    */
    this.sendingSettings = false;
    
    this._loadServicePartnerSettings();
    this.success = true;
    this.formTop.nativeElement.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      this.success = false;  
    }, 3000);
  }
}
