import { Injectable } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { Invoice } from 'src/app/model/invoice.model';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    constructor(
        private _http:HttpClient,
    ) { }

    async createInvoice(invoice:Invoice):Promise<Invoice> {
        return <Invoice>await this._http.post(`${REST_SERVER}/invoices`, invoice).toPromise();
    }

    async createInvoiceForRepairOrder(repairOrder:RepairOrder):Promise<Invoice> {
        return <Invoice>await this._http.post(`${REST_SERVER}/repair-orders/${repairOrder.id}/invoice`, {}).toPromise();
    }

    async markInvoicePayed(invoice:Invoice):Promise<Invoice> {
        return <Invoice>await this._http.post(`${REST_SERVER}/invoices/${invoice.ident}/process/payed`, {}).toPromise();
    }

    async load(invoiceIdent:string):Promise<Invoice> {
        return <Invoice>await this._http.get(`${REST_SERVER}/invoices/${invoiceIdent}`).toPromise();
    }

    async createDunnings():Promise<void> {
        await this._http.post(`${REST_SERVER}/invoices/dunnings`, {}).toPromise();
    }

    /* async generateRepairCenterCredits():Promise<void> {
        await this._http.post(`${REST_SERVER}/system/generate-repair-center-credits`, {}).toPromise();
    } */

    async generateRepairCenterCredits():Promise<void> {
        await this._http.post(`${REST_SERVER}/invoices/repair-center-credits`, {}).toPromise();
    }

    async generateServicePartnerInvoices():Promise<void> {
        await this._http.post(`${REST_SERVER}/invoices/service-partner-invoices`, {}).toPromise();
    }
}