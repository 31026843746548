import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-public',
  templateUrl: './navbar-public.component.html',
  styleUrls: ['./navbar-public.component.less']
})
export class NavbarPublicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scroll(targetId: string){

    const elem  = document.getElementById(targetId);
    elem.scrollIntoView({behavior:"smooth"});
  }

}
