import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { Router } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CmsPage } from 'src/app/model/cms-page.model';
import { CmsService } from 'src/app/service/cms.service';

enum CmsPageActions {
  DELETE
};

@Component({
  selector: 'app-cms-pages-list',
  templateUrl: './admin-cms-pages-list.component.html',
  styleUrls: ['./admin-cms-pages-list.component.less']
})
export class AdminCmsPagesListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {};

  constructor(
    private _invoiceService:InvoiceService,
    private _router:Router,
    private _modalService:BsModalService,
    private _cmsService:CmsService,
  ) { 
    
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    return REST_SERVER + "/cms/admin/pages";
  }

  public getActionsForRepairOrder(repairOrder:RepairOrder) {
    let result = [];
    if(!repairOrder.invoiceIdent) {
      result.push({ key: CmsPageActions.DELETE, val: 'Seite löschen' });
    }
    return result;
  }

  public async actionSelected(cmsPage:CmsPage, action):Promise<void> {
    switch(action.key) {
      case CmsPageActions.DELETE:
        let initialState = {
          title: "Seite löschen",
          msg: `Wollen Sie wirklich diese Seite löschen?`,
        }
        let ref:BsModalRef = this._modalService.show(ConfirmDialogComponent, {initialState});
        ref.content.onClose.subscribe(async result => {
          if(result) {
            await this._cmsService.deletePage(cmsPage);
            this._grid.update();
          }
        });
        break;
    }
  }

  public newPage() {
    this._router.navigate(["/admin/cms/pages/details"]);
  }
}
