import { Component, OnInit } from '@angular/core';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { REST_SERVER } from 'src/app/config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewerDialogComponent } from 'src/app/components/ui/dialogs/viewer-dialog.component';
import { PrintDialogRepairCenterComponent } from 'src/app/components/ui/dialogs/print-dialog-repair-center.component';
import { MultiLabelViewerComponent } from 'src/app/components/ui/dialogs/multi-label-viewer.component';

@Component({
  selector: 'app-service-partner-order-success',
  templateUrl: './service-partner-order-success.component.html',
  styleUrls: ['./service-partner-order-success.component.less']
})
export class ServicePartnerOrderSuccessComponent implements OnInit {

  sendingOrder:boolean = false;
  orderId:number = 0;
  currentOrder:any;
  orders:any = [];

  constructor(
    private activatedRoute:ActivatedRoute,
    private _repairOrderService:RepairOrderService,
    private _userService:UserService,
    private _modalService:BsModalService,
    private _router:Router,
  ) { }

  ngOnInit() {
    const orderParam = this.activatedRoute.snapshot.paramMap.get('currentOrder');
    
    this.orders = Object.values(JSON.parse(orderParam));

    this.decideShippingLabelWindow();
  }

  calcAinDownloadHref():string {
    let servicePartnerId = this._userService.user.servicePartnerId;
    let token:string = this._userService.token;

    this.orderId = this.currentOrder.id;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/repair-orders/${this.orderId}/cover-letter?access_token=${token}`;
  }

  async getShippingLabelHref(order: any):Promise<string> {
    
    return await this._repairOrderService.getShippingLabelHrefServicePartner(order.id);
  }

  async getAllShippingLabelHrefs(): Promise<any> {
    
    let labelHrefs = [];

    for(let order of this.orders)
    {
      labelHrefs.push(await this.getShippingLabelHref(order));
    }

    return labelHrefs;
  }

  async openShippingLabel() {

    let initialState = {
      title: "Versandlabel",
      msg: "Bitte Label auf dem Paket anbringen",
      path: await this.getShippingLabelHref(this.orders[0]),
    }
    this._modalService.show(ViewerDialogComponent, {initialState, class: 'modal-lg'});
  }

  async openMultiShippingLabelView() {
    let initialState = {
      title: "Versandlabel",
      paths: await this.getAllShippingLabelHrefs(),
    }
    this._modalService.show(MultiLabelViewerComponent, {initialState, class: 'modal-lg'});
  }

  goToPackaging(){
    this._router.navigate(['service-partner', 'order', 'packaging', {currentOrders: JSON.stringify(this.orders)}]);
  }

  decideShippingLabelWindow()
  {
    if(this.orders.length > 1)
    {
      this.openMultiShippingLabelView();
    } else 
    {
      this.openShippingLabel();
    }
  }

} 
