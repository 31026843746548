import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { ActivatedRoute } from '@angular/router';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

enum ServicePartnerActions {
  ACTIVATE,
  DENY_ACTIVATION,
  DELETE,
}

@Component({
  selector: 'app-admin-service-partners',
  templateUrl: './admin-service-partners.component.html',
  styleUrls: ['./admin-service-partners.component.less']
})
export class AdminServicePartnersComponent implements OnInit {

  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {
    email: {
      type: "string",
      comp: "like",
      label: "E-Mail",
    },
    name: {
      type: "string",
      comp: "like",
      label: "Firmenname",
    },
    state: {
      value: "ACTIVE",
      comp: "eq",
    },
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _servicePartnerService:ServicePartnerService,
    private _modalService:BsModalService,
  ) { 
    activatedRoute.params.subscribe(params => {
      this.state = params.state;
      let filterDesc:any = {...this.filterDesc};
      delete(filterDesc.state);

      if(params.state === 'new') {
        filterDesc.state = {
          value:  ["NEW", "EMAIL_CONFIRMED"],
          comp: "inq",
        };
        this.title = 'Unbezahlte Rechnungen';
      } else if(params.state === 'denied') {
        filterDesc.state = {
          value: "ACTIVATION_DENIED",
          comp: "eq",
        }
        this.title = 'Abgelehnte Anfragen';
      } else if(params.state === 'active') {
        filterDesc.state = {
          value:  "ACTIVE",
          comp: "eq",
        };
        this.title = 'Bezahlte Rechnungen';
      } 
      this.filterDesc = filterDesc;
    });
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    return REST_SERVER + "/service-partners";
  }

  public getActionsForServicePartner(servicePartner:ServicePartner):any {
    let result = [];
    if(servicePartner.state === 'EMAIL_CONFIRMED') {
      result.push({ key: ServicePartnerActions.ACTIVATE, val: 'Kunde freigeben' });
      result.push({ key: ServicePartnerActions.DENY_ACTIVATION, val: 'Kunde ablehnen' });
    } else if(servicePartner.state === 'ACTIVATION_DENIED') {
      result.push({ key: ServicePartnerActions.ACTIVATE, val: 'Kunde freigeben' });
    }
    result.push({ key: ServicePartnerActions.DELETE, val: 'Kunde löschen'});
    return result;
  }

  public async actionSelected(servicePartner, action):Promise<void> {
    switch(action.key) {
      case ServicePartnerActions.ACTIVATE:
        await this._servicePartnerService.activate(servicePartner);
        this._grid.update();
        break;
      case ServicePartnerActions.DENY_ACTIVATION:
        await this._servicePartnerService.denyActivation(servicePartner);
        this._grid.update();
        break;
      case ServicePartnerActions.DELETE:
        let initialState = {
          title: "Kunden löschen",
          msg: `Wollen Sie diesen Kunden wirklich löschen?`,
        }
        let ref:BsModalRef = this._modalService.show(ConfirmDialogComponent, {initialState});
        ref.content.onClose.subscribe(async result => {
          if(result) {
            await this._servicePartnerService.deleteServicePartner(servicePartner);
            this._grid.update();
          }
        });
        break;
    }
  }

  public getFormatedLoginDate(data:any):string
  {
    if(data.user.lastLogin)
    {
      let dateTest: Date = new Date(data.user.lastLogin);
      return dateTest.toLocaleDateString('de-DE', {day: 'numeric', month: 'short', year: 'numeric', hour:'numeric', minute: 'numeric'});
    } else 
    {
      return "kein login";
    }
  }
}
