import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'modal-content',
    template: `
    <div class="modal-header">
        <h4 class="modal-title pull-left">RAL-Farben</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>

    <div class="modal-body">
        <p style="padding-bottom: 10px">Suchen Sie die gewünschte RAL Farbe heraus. Bei der Auftragserstellung wird diese dem aufbereitenden Betrieb mitgeteilt</p>


      <div class="col farben farben_vollbild farben_alle">
    <div (click)="test($event)" class="farbe " style="background-color:#414141" data-brightness="66.95">
       RAL 000 15 00
       <div class="subtext">Tintenschwarz<br>Ink black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#474646" data-brightness="68.412">
       RAL 000 20 00
       <div class="subtext">Schieferschwarz<br>Slate black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#504F4F" data-brightness="73.196">
       RAL 000 25 00
       <div class="subtext">Onyxschwarz<br>Onyx black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#565656" data-brightness="83.43">
       RAL 000 30 00
       <div class="subtext">Mittelschwarz<br>Medium black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F5F5F" data-brightness="83.43">
       RAL 000 35 00
       <div class="subtext">Brikettgrau<br>Briquette grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#696968" data-brightness="100.486">
       RAL 000 40 00
       <div class="subtext">Dunkelgrau<br>Dark grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#757473" data-brightness="118.14">
       RAL 000 45 00
       <div class="subtext">Architekturgrau<br>Architecture grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7E7F7E" data-brightness="117.564">
       RAL 000 50 00
       <div class="subtext">Stahlgrau<br>Steel grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#898989" data-brightness="132.87">
       RAL 000 55 00
       <div class="subtext">Mittelgrau<br>Medium grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#959595" data-brightness="149.35">
       RAL 000 60 00
       <div class="subtext">Aschgrau<br>Ash grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A3A3A3" data-brightness="165.83">
       RAL 000 65 00
       <div class="subtext">Mörtelgrau<br>Mortar grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B0B0B0" data-brightness="182.31">
       RAL 000 70 00
       <div class="subtext">Leichtgrau<br>Light grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BDBDBD" data-brightness="182.31">
       RAL 000 75 00
       <div class="subtext">Marmorgrau<br>Marble grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CACAC9" data-brightness="199.366">
       RAL 000 80 00
       <div class="subtext">Nebelgrau<br>Foggy grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D7D7D5" data-brightness="215.846">
       RAL 000 85 00
       <div class="subtext">Schattenweiß<br>Shadow white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2E3E1" data-brightness="233.5">
       RAL 000 90 00
       <div class="subtext">Winterweiß<br>Winter white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#524446" data-brightness="72.897">
       RAL 010 20 10
       <div class="subtext">Wengeschwarz<br>Wenge black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#564144" data-brightness="73.052">
       RAL 010 20 15
       <div class="subtext">Kirschenschwarz<br>Cherry black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B3F43" data-brightness="63.66">
       RAL 010 20 20
       <div class="subtext">Dunkelmahagoni<br>Dark mahogany</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5E3E43" data-brightness="62.486">
       RAL 010 20 25
       <div class="subtext">Rostrot<br>Rusty red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#665255" data-brightness="89.233">
       RAL 010 30 10
       <div class="subtext">Holzschwarzrot<br>Laurel nut rusty brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A5054" data-brightness="89.964">
       RAL 010 30 15
       <div class="subtext">Nachtmauve<br>Night mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#714E53" data-brightness="85.356">
       RAL 010 30 20
       <div class="subtext">Rosigbraun<br>Pinkish brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#774A52" data-brightness="85.057">
       RAL 010 30 25
       <div class="subtext">Kastanienrot<br>Chestnut red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7C4851" data-brightness="85.356">
       RAL 010 30 30
       <div class="subtext">Lederrot<br>Leather red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7E454F" data-brightness="83.052">
       RAL 010 30 35
       <div class="subtext">Anthrazitrot<br>Anthracite red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#86404F" data-brightness="87.836">
       RAL 010 30 40
       <div class="subtext">Braunmagenta<br>Brown magenta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8B3D4F" data-brightness="78.444">
       RAL 010 30 44
       <div class="subtext">Atlasrot<br>Atlas red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#796567" data-brightness="105.724">
       RAL 010 40 10
       <div class="subtext">Caputmortuumgraurot<br>Caput mortuum grey red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#806165" data-brightness="111.228">
       RAL 010 40 15
       <div class="subtext">Rostbraun<br>Rust brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#875F64" data-brightness="101.836">
       RAL 010 40 20
       <div class="subtext">Abendrot<br>Sunset red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D5C65" data-brightness="101.404">
       RAL 010 40 25
       <div class="subtext">Mineralrot<br>Mineral red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#945963" data-brightness="104.859">
       RAL 010 40 30
       <div class="subtext">Trübmagenta<br>Dull magenta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#975460" data-brightness="106.62">
       RAL 010 40 35
       <div class="subtext">Samtrot<br>Velvet red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9F5161" data-brightness="106.332">
       RAL 010 40 40
       <div class="subtext">Algenrot<br>Algae red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A54D61" data-brightness="102.012">
       RAL 010 40 45
       <div class="subtext">Himbeereisrot<br>Raspberry ice cream red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AB4960" data-brightness="102.012">
       RAL 010 40 50
       <div class="subtext">Fuchsiarot<br>Fuchsia red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AE465E" data-brightness="99.132">
       RAL 010 40 53
       <div class="subtext">Urrot<br>Primal red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F797D" data-brightness="122.924">
       RAL 010 50 10
       <div class="subtext">Altmahagoni<br>Old mahogany</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#96757A" data-brightness="127.708">
       RAL 010 50 15
       <div class="subtext">Trübaltrosa<br>Dull dusky pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9D737A" data-brightness="127.708">
       RAL 010 50 20
       <div class="subtext">Backsteinrot<br>Brickwork red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A67079" data-brightness="132.492">
       RAL 010 50 25
       <div class="subtext">Mattkarmin<br>Matte carmine</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AE6C78" data-brightness="123.1">
       RAL 010 50 30
       <div class="subtext">Marmorrot<br>Marble red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B36876" data-brightness="127.452">
       RAL 010 50 35
       <div class="subtext">Geranienrot<br>Geranium red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B86576" data-brightness="127.452">
       RAL 010 50 40
       <div class="subtext">Schieferrosa<br>Slate pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#BD5F74" data-brightness="118.492">
       RAL 010 50 45
       <div class="subtext">Tulipanrot<br>Tulip red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C65C74" data-brightness="121.515">
       RAL 010 50 50
       <div class="subtext">Lichtrot<br>Vibrant red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A89093" data-brightness="155.884">
       RAL 010 60 10
       <div class="subtext">Fliedergrau<br>Lilac grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B18E93" data-brightness="151.276">
       RAL 010 60 15
       <div class="subtext">Eisorchideenrot<br>Ice orchid red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BA8B92" data-brightness="149.515">
       RAL 010 60 20
       <div class="subtext">Kalkrosa<br>Lime pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C08690" data-brightness="155.484">
       RAL 010 60 25
       <div class="subtext">Lippenstiftrosa<br>Lipstick pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C7828F" data-brightness="153.756">
       RAL 010 60 30
       <div class="subtext">Japankoralle<br>Japanese coral</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D07F8D" data-brightness="148.428">
       RAL 010 60 35
       <div class="subtext">Rosenrot<br>Rose red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D67B8C" data-brightness="149.148">
       RAL 010 60 40
       <div class="subtext">Erdbeershakerot<br>Strawberry milkshake red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DF768B" data-brightness="149.148">
       RAL 010 60 45
       <div class="subtext">Leuchtendrosa<br>Luminous pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3AAAE" data-brightness="176.561">
       RAL 010 70 10
       <div class="subtext">Blassmauve<br>Pale mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAA7AB" data-brightness="177.148">
       RAL 010 70 15
       <div class="subtext">Puderrosé<br>Powder rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4A3AB" data-brightness="181.932">
       RAL 010 70 20
       <div class="subtext">Silberrosé<br>Silver rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DC9FA9" data-brightness="172.108">
       RAL 010 70 25
       <div class="subtext">Flamingorosa<br>Flamingo pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E39CA8" data-brightness="177.324">
       RAL 010 70 30
       <div class="subtext">Kirschblütenrosa<br>Cherry blossom pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EB96A5" data-brightness="177.324">
       RAL 010 70 35
       <div class="subtext">Babyrosa<br>Baby pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DEC3C5" data-brightness="205.324">
       RAL 010 80 10
       <div class="subtext">Schlammpink<br>Mud pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5C0C4" data-brightness="210.108">
       RAL 010 80 15
       <div class="subtext">Eispink<br>Ice hot pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFBCC3" data-brightness="200.716">
       RAL 010 80 20
       <div class="subtext">Pastellrosa<br>Pastel pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DED1D3" data-brightness="217.02">
       RAL 010 85 05
       <div class="subtext">Perlrosé<br>Pearl rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4CDD1" data-brightness="212.412">
       RAL 010 85 10
       <div class="subtext">Lachsrosé<br>Salmon rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFCCD2" data-brightness="211.825">
       RAL 010 85 15
       <div class="subtext">Milchshakerosa<br>Milkshake pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F8CBD2" data-brightness="217.196">
       RAL 010 85 20
       <div class="subtext">Inkarnatrosé<br>Carnation pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFE0DF" data-brightness="230.62">
       RAL 010 90 05
       <div class="subtext">Rosécreme<br>Rose cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F2DBD8" data-brightness="226.588">
       RAL 010 90 10
       <div class="subtext">Hellapricot<br>Light apricot</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1E6E8" data-brightness="238.284">
       RAL 010 93 05
       <div class="subtext">Weißrötlich<br>White-red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4C4545" data-brightness="68.412">
       RAL 020 20 05
       <div class="subtext">Tiefbraun<br>Deep brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#524343" data-brightness="73.196">
       RAL 020 20 10
       <div class="subtext">Nachtröte<br>Night red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B4041" data-brightness="73.484">
       RAL 020 20 20
       <div class="subtext">Dunkelrotbraun<br>Dark red brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#633C40" data-brightness="68.876">
       RAL 020 20 29
       <div class="subtext">Burgunderrot<br>Burgundy</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D5454" data-brightness="84.892">
       RAL 020 30 05
       <div class="subtext">Rhodonitbraun<br>Rhodonite brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#645151" data-brightness="89.676">
       RAL 020 30 10
       <div class="subtext">Budapesterbraun<br>Budapest brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F4B4C" data-brightness="78.268">
       RAL 020 30 20
       <div class="subtext">Kremlrot<br>Kremlin red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#78464A" data-brightness="83.052">
       RAL 020 30 30
       <div class="subtext">Kristalldunkelrot<br>Crystal dark red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#844048" data-brightness="87.116">
       RAL 020 30 40
       <div class="subtext">Amaranthblüte<br>Amaranth blossom</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8C3C46" data-brightness="77.27">
       RAL 020 30 48
       <div class="subtext">Vogelkirschenrot<br>Sweet cherry red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#726868" data-brightness="106.156">
       RAL 020 40 05
       <div class="subtext">Graubräunlich<br>Greyish brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#786464" data-brightness="106.156">
       RAL 020 40 10
       <div class="subtext">Nussbaumbraun<br>Nut brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#855E60" data-brightness="101.836">
       RAL 020 40 20
       <div class="subtext">Antikrot<br>Antique red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#92585C" data-brightness="104.316">
       RAL 020 40 30
       <div class="subtext">Hermosarosa<br>Hermosa pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9B5157" data-brightness="104.316">
       RAL 020 40 40
       <div class="subtext">Oktoberrot<br>October red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A74751" data-brightness="98.534">
       RAL 020 40 50
       <div class="subtext">Knallrot<br>Bright red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#897D7C" data-brightness="122.924">
       RAL 020 50 05
       <div class="subtext">Zirkongrau<br>Zircon grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#907879" data-brightness="126.988">
       RAL 020 50 10
       <div class="subtext">Sandsteinrotgrau<br>Sandstone red grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9F7274" data-brightness="127.708">
       RAL 020 50 20
       <div class="subtext">Rotgrau<br>Red grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AC6C70" data-brightness="121.926">
       RAL 020 50 30
       <div class="subtext">Venezianerrot<br>Venetian red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B6646B" data-brightness="124.86">
       RAL 020 50 40
       <div class="subtext">Schwedenkleerot<br>Alsike clover red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C25C67" data-brightness="119.211">
       RAL 020 50 50
       <div class="subtext">Flammenrot<br>Flame red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#D25766" data-brightness="125.612">
       RAL 020 50 58
       <div class="subtext">Preiselbeerenrot<br>Lingonberry red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F9493" data-brightness="151.1">
       RAL 020 60 05
       <div class="subtext">Kugeldistelgraurosé<br>Globe thistle grey rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A78E8F" data-brightness="144.188">
       RAL 020 60 10
       <div class="subtext">Blechrosa<br>Tin pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B88A8C" data-brightness="148.972">
       RAL 020 60 20
       <div class="subtext">Retrorosa<br>Retro pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C68186" data-brightness="153.18">
       RAL 020 60 30
       <div class="subtext">Begonienrostrosé<br>Begonia rusty rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D37A82" data-brightness="149.148">
       RAL 020 60 40
       <div class="subtext">Lotusrot<br>Lotus red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9ADAC" data-brightness="172.364">
       RAL 020 70 05
       <div class="subtext">Fashionmauve<br>Fashion mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0A7A8" data-brightness="177.148">
       RAL 020 70 10
       <div class="subtext">Turmalinmauve<br>Tourmaline mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4A2A5" data-brightness="181.932">
       RAL 020 70 20
       <div class="subtext">Rosenholzapricot<br>Rosewood apricot</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E49BA0" data-brightness="177.324">
       RAL 020 70 30
       <div class="subtext">Markerrosa<br>Marker pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5C7C6" data-brightness="205.324">
       RAL 020 80 05
       <div class="subtext">Auroragrau<br>Aurora grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCC1C2" data-brightness="205.324">
       RAL 020 80 10
       <div class="subtext">Quarzrosé<br>Quartz rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EEBCBE" data-brightness="197.393">
       RAL 020 80 20
       <div class="subtext">Marzipanrosa<br>Marzipan pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DFD1D1" data-brightness="216.732">
       RAL 020 85 05
       <div class="subtext">Mandelblütenrosa<br>Almond blossom pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E7CECE" data-brightness="207.627">
       RAL 020 85 10
       <div class="subtext">Lachscreme<br>Salmon cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FBC9CA" data-brightness="214.892">
       RAL 020 85 20
       <div class="subtext">Elegantlichtrosé<br>Elegant light rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F0E2DE" data-brightness="235.548">
       RAL 020 90 05
       <div class="subtext">Muschelweiß<br>Mussel white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F3DDD9" data-brightness="226.001">
       RAL 020 90 10
       <div class="subtext">Pfirsichcreme<br>Peach cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6E6E3" data-brightness="237.11">
       RAL 020 93 05
       <div class="subtext">Blütenweiß<br>Blossom white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#635351" data-brightness="88.79">
       RAL 030 30 10
       <div class="subtext">Laurelnussrostbraun<br>Laurel nut rusty brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E4D4B" data-brightness="78.268">
       RAL 030 30 20
       <div class="subtext">Herbstlaubrot<br>Autumn leaf red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#784845" data-brightness="81.878">
       RAL 030 30 30
       <div class="subtext">Macorefurnierrot<br>Macore veneer red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#854343" data-brightness="87.548">
       RAL 030 30 40
       <div class="subtext">Karmesinrot<br>Crimson red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#863E3F" data-brightness="76.14">
       RAL 030 30 45
       <div class="subtext">Blutrot<br>Blood red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#766361" data-brightness="106.444">
       RAL 030 40 10
       <div class="subtext">Torfrotbraun<br>Peat red brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#86605C" data-brightness="108.924">
       RAL 030 40 20
       <div class="subtext">Moosbeerenrot<br>Cranberry red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#925955" data-brightness="102.123">
       RAL 030 40 30
       <div class="subtext">Backsteinbraun<br>Brick brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9D5351" data-brightness="104.316">
       RAL 030 40 40
       <div class="subtext">Scharfrot<br>Spicy red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A84C4C" data-brightness="97.116">
       RAL 030 40 50
       <div class="subtext">Hibiskusrot<br>Hibiscus red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B14548" data-brightness="102.188">
       RAL 030 40 60
       <div class="subtext">Kaiserkirschenrot<br>Emperor cherry red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F7875" data-brightness="121.163">
       RAL 030 50 10
       <div class="subtext">Erdrot<br>Earth red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9D736F" data-brightness="125.404">
       RAL 030 50 20
       <div class="subtext">Terracottarotbraun<br>Terracotta red brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AB6D68" data-brightness="120.796">
       RAL 030 50 30
       <div class="subtext">Lehmrot<br>Clay red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B86762" data-brightness="125.58">
       RAL 030 50 40
       <div class="subtext">Zinnoberrot<br>Vermilion red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C25F5C" data-brightness="117.948">
       RAL 030 50 50
       <div class="subtext">Ahornrot<br>Maple red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#CF5654" data-brightness="118.668">
       RAL 030 50 60
       <div class="subtext">Hollandrot<br>Holland red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6908E" data-brightness="153.58">
       RAL 030 60 10
       <div class="subtext">Sturmröte<br>Storm red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B78A86" data-brightness="148.972">
       RAL 030 60 20
       <div class="subtext">Wüstenrot<br>Desert red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5827C" data-brightness="150.732">
       RAL 030 60 30
       <div class="subtext">Antikrosa<br>Antique pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D37D77" data-brightness="144.651">
       RAL 030 60 40
       <div class="subtext">Helltomate<br>Light tomato</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0746E" data-brightness="148.604">
       RAL 030 60 50
       <div class="subtext">Kalypsorot<br>Calypso red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0A8A6" data-brightness="177.148">
       RAL 030 70 10
       <div class="subtext">Floridagrau<br>Florida grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D2A19C" data-brightness="179.628">
       RAL 030 70 20
       <div class="subtext">Trübapricot<br>Dull apricot</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E29A94" data-brightness="175.02">
       RAL 030 70 30
       <div class="subtext">Lachsrosarot<br>Salmon pink red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F0938C" data-brightness="177.5">
       RAL 030 70 40
       <div class="subtext">Flamingorot<br>Flamingo red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBC2BF" data-brightness="203.02">
       RAL 030 80 10
       <div class="subtext">Salzrosa<br>Salt pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ECBBB6" data-brightness="197.825">
       RAL 030 80 20
       <div class="subtext">Magnolienrosa<br>Magnolia pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DFD0CB" data-brightness="214.716">
       RAL 030 85 05
       <div class="subtext">Mandelcreme<br>Almond cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5CEC9" data-brightness="208.347">
       RAL 030 85 10
       <div class="subtext">Softeisrosé<br>Soft ice rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6CBC3" data-brightness="214.892">
       RAL 030 85 20
       <div class="subtext">Pfirsichrot<br>Peach red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFE1DC" data-brightness="231.196">
       RAL 030 90 05
       <div class="subtext">Antikweiß<br>Antique white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F5DFD9" data-brightness="224.827">
       RAL 030 90 10
       <div class="subtext">Hochzeitsrosa<br>Wedding pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7E7E3" data-brightness="237.11">
       RAL 030 93 05
       <div class="subtext">Pergamentweiß<br>Parchment white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A433F" data-brightness="71.18">
       RAL 040 20 19
       <div class="subtext">Wildbraun<br>Wild brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D5552" data-brightness="83.419">
       RAL 040 30 05
       <div class="subtext">Basaltschwarz<br>Basalt black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#62524E" data-brightness="86.486">
       RAL 040 30 10
       <div class="subtext">Kaviarschwarz<br>Caviar black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6D4E47" data-brightness="78.268">
       RAL 040 30 20
       <div class="subtext">Kaffeebraun<br>Coffee brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#774B43" data-brightness="82.753">
       RAL 040 30 30
       <div class="subtext">Wurzelbraun<br>Root brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#80463E" data-brightness="85.532">
       RAL 040 30 40
       <div class="subtext">Korrosionsrot<br>Corrosion red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#726865" data-brightness="106.444">
       RAL 040 40 05
       <div class="subtext">Aschbraun<br>Ash brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#776560" data-brightness="106.145">
       RAL 040 40 10
       <div class="subtext">Somalibraun<br>Somali brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#84625A" data-brightness="108.924">
       RAL 040 40 20
       <div class="subtext">Vandyckbraun<br>Vandyck brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D5A4E" data-brightness="97.228">
       RAL 040 40 30
       <div class="subtext">Maronenbraun<br>Chestnut brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#985447" data-brightness="102.012">
       RAL 040 40 40
       <div class="subtext">Ziegelrot<br>Brick red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A35041" data-brightness="106.796">
       RAL 040 40 50
       <div class="subtext">Hennarot<br>Henna red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AA473B" data-brightness="94.801">
       RAL 040 40 60
       <div class="subtext">Kupferrot<br>Copper red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B24539" data-brightness="99.884">
       RAL 040 40 67
       <div class="subtext">Chinarot<br>China red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#877C79" data-brightness="122.924">
       RAL 040 50 05
       <div class="subtext">Nomadengrau<br>Nomad grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8E7A74" data-brightness="122.924">
       RAL 040 50 10
       <div class="subtext">Umbrasand<br>Umbra sand</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9C746A" data-brightness="125.404">
       RAL 040 50 20
       <div class="subtext">Achatbraun<br>Agate brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AA6F61" data-brightness="120.497">
       RAL 040 50 30
       <div class="subtext">Rostfarben<br>Rust coloured</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B36857" data-brightness="123.276">
       RAL 040 50 40
       <div class="subtext">Ameisenrot<br>Ant red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C0624F" data-brightness="125.756">
       RAL 040 50 50
       <div class="subtext">Englischrot<br>English red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#CE5E4A" data-brightness="115.19">
       RAL 040 50 60
       <div class="subtext">Fuchsrot<br>Fox red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#D55845" data-brightness="120.572">
       RAL 040 50 70
       <div class="subtext">Pompejirot<br>Pompeii red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F9491" data-brightness="151.1">
       RAL 040 60 05
       <div class="subtext">Warmgrau<br>Warm grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A8918B" data-brightness="153.58">
       RAL 040 60 10
       <div class="subtext">Hellkaramell<br>Light caramel</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B4897E" data-brightness="146.668">
       RAL 040 60 20
       <div class="subtext">Sienagelblich<br>Sienna yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C48677" data-brightness="151.308">
       RAL 040 60 30
       <div class="subtext">Zedernrot<br>Cedar red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CF806E" data-brightness="149.148">
       RAL 040 60 40
       <div class="subtext">Terraorange<br>Terra orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DC7B64" data-brightness="144.54">
       RAL 040 60 50
       <div class="subtext">Mandarinorange<br>Mandarin orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E67359" data-brightness="147.02">
       RAL 040 60 60
       <div class="subtext">Korallenorange<br>Coral orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B7ACA9" data-brightness="172.364">
       RAL 040 70 05
       <div class="subtext">Passepartoutgrau<br>Passepartout grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C2A9A3" data-brightness="177.148">
       RAL 040 70 10
       <div class="subtext">Mohairmauve<br>Mohair mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D2A499" data-brightness="179.484">
       RAL 040 70 20
       <div class="subtext">Sanftsiena<br>Soft sienna</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E09D8D" data-brightness="172.428">
       RAL 040 70 30
       <div class="subtext">Industrierosé<br>Industrial rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E99885" data-brightness="172.716">
       RAL 040 70 40
       <div class="subtext">Aprikosenrot<br>Apricot red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FA9079" data-brightness="174.764">
       RAL 040 70 50
       <div class="subtext">Fruchtrot<br>Fruit red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4C7C3" data-brightness="205.324">
       RAL 040 80 05
       <div class="subtext">Naturseidegrau<br>Natural silk grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DEC4BC" data-brightness="202.588">
       RAL 040 80 10
       <div class="subtext">Thulitrosé<br>Thulite rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1C1B4" data-brightness="211.414">
       RAL 040 80 20
       <div class="subtext">Krapporange<br>Madder orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FEBAA9" data-brightness="200.892">
       RAL 040 80 30
       <div class="subtext">Naturapricot<br>Nature apricot</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E1D2CE" data-brightness="218.78">
       RAL 040 85 05 
       <div class="subtext">Pandoragrau<br>Pandora grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAD2CA" data-brightness="218.924">
       RAL 040 85 10
       <div class="subtext">Feinalabaster<br>Fine alabaster</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FACCBD" data-brightness="212.001">
       RAL 040 85 20
       <div class="subtext">Zartsüßapricot<br>Delicate sweet apricot</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1E2DC" data-brightness="235.98">
       RAL 040 90 05
       <div class="subtext">Saharalichtrot<br>Sahara light red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7E0D7" data-brightness="235.404">
       RAL 040 90 10
       <div class="subtext">Zartrosé<br>Delicate rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F9EAE4" data-brightness="238.284">
       RAL 040 93 05
       <div class="subtext">Naturweiß<br>Natural white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#504541" data-brightness="71.723">
       RAL 050 20 10
       <div class="subtext">Granitbraun<br>Granite brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#56443E" data-brightness="70.593">
       RAL 050 20 16
       <div class="subtext">Nachtbraun<br>Night brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F504A" data-brightness="82.876">
       RAL 050 30 10
       <div class="subtext">Obsidianbraun<br>Obsidian brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A4F44" data-brightness="77.836">
       RAL 050 30 20
       <div class="subtext">Tropenholzbraun<br>Tropical wood brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#744C3E" data-brightness="80.748">
       RAL 050 30 30
       <div class="subtext">Tabakbraun<br>Tobacco brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A4B3C" data-brightness="80.748">
       RAL 050 30 36
       <div class="subtext">Palisanderbraun<br>Rosewood brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#76645D" data-brightness="104.14">
       RAL 050 40 10
       <div class="subtext">Mokkaschwarz<br>Mocha black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#806153" data-brightness="108.924">
       RAL 050 40 20
       <div class="subtext">Florentinerbraun<br>Florentine brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#895E4D" data-brightness="97.228">
       RAL 050 40 30
       <div class="subtext">Currybraun<br>Curry brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#945943" data-brightness="100.251">
       RAL 050 40 40
       <div class="subtext">Madeirabraun<br>Madeira brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9D5640" data-brightness="102.012">
       RAL 050 40 50
       <div class="subtext">Herbstrot<br>Autumn red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D7B73" data-brightness="122.924">
       RAL 050 50 10
       <div class="subtext">Teakholzbraun<br>Teakwood brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#997767" data-brightness="124.086">
       RAL 050 50 20
       <div class="subtext">Milchkaffeebraun<br>Milk coffee brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A5725C" data-brightness="127.884">
       RAL 050 50 30
       <div class="subtext">Goldbraun<br>Golden brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AE6C50" data-brightness="118.492">
       RAL 050 50 40
       <div class="subtext">Kupfermetallrot<br>Copper-metal red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B96746" data-brightness="120.54">
       RAL 050 50 50
       <div class="subtext">Goldlackbraun<br>Gold varnish brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#BD623D" data-brightness="118.092">
       RAL 050 50 60
       <div class="subtext">Tizianrot<br>Titian red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#CC5C36" data-brightness="112">
       RAL 050 50 70
       <div class="subtext">Mohnrot<br>Poppy red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#D16032" data-brightness="128.236">
       RAL 050 50 78
       <div class="subtext">Persischorange<br>Persian orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59188" data-brightness="153.436">
       RAL 050 60 10
       <div class="subtext">Ecruocker<br>Ecru ochre</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B18C7B" data-brightness="145.948">
       RAL 050 60 20
       <div class="subtext">Karamellbraun<br>Caramel brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C28971" data-brightness="151.452">
       RAL 050 60 30
       <div class="subtext">Mittelbraun<br>Medium brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C98365" data-brightness="149.148">
       RAL 050 60 40
       <div class="subtext">Apricotbraun<br>Apricot brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D67F59" data-brightness="142.236">
       RAL 050 60 50
       <div class="subtext">Orangegelb<br>Orange yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E17B4E" data-brightness="143.996">
       RAL 050 60 60
       <div class="subtext">Kamelrot<br>Camel red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E67045" data-brightness="144.716">
       RAL 050 60 70
       <div class="subtext">Karottenorange<br>Carrot orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F26D3F" data-brightness="137.084">
       RAL 050 60 80
       <div class="subtext">Gerberarot<br>Gerbera red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0ACA2" data-brightness="175.387">
       RAL 050 70 10
       <div class="subtext">Bambusbeige<br>Bamboo beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CEA796" data-brightness="174.844">
       RAL 050 70 20
       <div class="subtext">Bernsteingrau<br>Amber grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBA188" data-brightness="177.18">
       RAL 050 70 30
       <div class="subtext">Sienaocker<br>Sienna ochre</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E99B7B" data-brightness="170.124">
       RAL 050 70 40
       <div class="subtext">Amberhellorange<br>Light amber orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F39670" data-brightness="175.196">
       RAL 050 70 50
       <div class="subtext">Melonenrot<br>Melon red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FE9063" data-brightness="172.892">
       RAL 050 70 60
       <div class="subtext">Mangoorange<br>Mango orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBC5BB" data-brightness="202.444">
       RAL 050 80 10
       <div class="subtext">Blasssiena<br>Pale sienna</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E9BFAC" data-brightness="196.108">
       RAL 050 80 20
       <div class="subtext">Sanftorange<br>Soft orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6B99F" data-brightness="197.868">
       RAL 050 80 30
       <div class="subtext">Fahlorange<br>Pallid orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2D3CC" data-brightness="219.356">
       RAL 050 85 05
       <div class="subtext">Meersand<br>Ocean sand</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E6CFC3" data-brightness="210.108">
       RAL 050 85 10
       <div class="subtext">Reinbeige<br>Pure beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6CBB7" data-brightness="212.588">
       RAL 050 85 20
       <div class="subtext">Biscuitcreme<br>Biscuit cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EDE0D8" data-brightness="231.196">
       RAL 050 90 05
       <div class="subtext">Eierschalenweiß<br>Eggshell white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FBE2D6" data-brightness="235.98">
       RAL 050 90 10
       <div class="subtext">Hellpfirsichrosé<br>Light peach rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F9EBE4" data-brightness="238.284">
       RAL 050 93 05
       <div class="subtext">Tüllweiß<br>Tulle white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A4643" data-brightness="68.7">
       RAL 060 20 05
       <div class="subtext">Industrieschwarz<br>Industrial black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C5551" data-brightness="83.419">
       RAL 060 30 05
       <div class="subtext">Karosseriegrau<br>Vehicle body grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#60534B" data-brightness="87.66">
       RAL 060 30 10
       <div class="subtext">Nutriafellbraun<br>Nutria fur brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#665042" data-brightness="87.361">
       RAL 060 30 20
       <div class="subtext">Torfbraun<br>Peat brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F5141" data-brightness="87.372">
       RAL 060 30 27
       <div class="subtext">Zinnsteinbraun<br>Cassiterite brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#716863" data-brightness="106.444">
       RAL 060 40 05
       <div class="subtext">Zinkgrau<br>Zinc grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#75655C" data-brightness="102.966">
       RAL 060 40 10
       <div class="subtext">Mooreichengrau<br>Moor oak grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7F6351" data-brightness="104.14">
       RAL 060 40 20
       <div class="subtext">Kaffeebohnenbraun<br>Coffee bean brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#876048" data-brightness="105.9">
       RAL 060 40 30
       <div class="subtext">Brasilbraun<br>Brazilian brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#915F41" data-brightness="101.436">
       RAL 060 40 40
       <div class="subtext">Platanenbraun<br>Plane brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#877D77" data-brightness="122.348">
       RAL 060 50 05
       <div class="subtext">Chinchillagrau<br>Chinchilla grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8C7C71" data-brightness="121.75">
       RAL 060 50 10
       <div class="subtext">Sandsteingrau<br>Sandstone grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#967965" data-brightness="123.643">
       RAL 060 50 20
       <div class="subtext">Champignonbraun<br>Mushroom brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A2775A" data-brightness="126.577">
       RAL 060 50 30
       <div class="subtext">Senfbraun<br>Mustard brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AA714D" data-brightness="125.281">
       RAL 060 50 40
       <div class="subtext">Kamelbraun<br>Camel brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B37045" data-brightness="130.364">
       RAL 060 50 50
       <div class="subtext">Dattelfruchtbraun<br>Date fruit brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B66838" data-brightness="118.38">
       RAL 060 50 60
       <div class="subtext">Rüsterbraunrot<br>Elm brown red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C16933" data-brightness="123.308">
       RAL 060 50 70
       <div class="subtext">Trockenlehm<br>Dry clay</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A09790" data-brightness="155.308">
       RAL 060 60 05
       <div class="subtext">Estrichgrau<br>Screed grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59387" data-brightness="153.58">
       RAL 060 60 10
       <div class="subtext">Eichenbraun<br>Oak brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B4907B" data-brightness="155.34">
       RAL 060 60 20
       <div class="subtext">Topashellocker<br>Light topaz ochre</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BC8D6D" data-brightness="144.076">
       RAL 060 60 30
       <div class="subtext">Cognacbraun<br>Cognac brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CB8C5E" data-brightness="145.083">
       RAL 060 60 40
       <div class="subtext">Ahornsirupbraun<br>Maple syrup brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CC814E" data-brightness="144.241">
       RAL 060 60 50
       <div class="subtext">Kurkumarot<br>Turmeric red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D77F43" data-brightness="139.932">
       RAL 060 60 60
       <div class="subtext">Bitterorange<br>Bitter orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DC7C37" data-brightness="136.022">
       RAL 060 60 70
       <div class="subtext">Goldorange<br>Gold orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E6752A" data-brightness="140.108">
       RAL 060 60 80
       <div class="subtext">Akzentorange<br>Accent orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9AFA9" data-brightness="171.788">
       RAL 060 70 05
       <div class="subtext">Zementgreige<br>Cement greige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0ADA1" data-brightness="177.148">
       RAL 060 70 10
       <div class="subtext">Kittgrau<br>Putty grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CBA891" data-brightness="174.844">
       RAL 060 70 20
       <div class="subtext">Erdnusscreme<br>Peanut butter</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D3A281" data-brightness="177.324">
       RAL 060 70 30
       <div class="subtext">Pfirsichgelb<br>Peach yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E1A074" data-brightness="179.804">
       RAL 060 70 40
       <div class="subtext">Kerzengelb<br>Candle yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EB9C67" data-brightness="168.108">
       RAL 060 70 50
       <div class="subtext">Topasgelb<br>Topaz yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F19758" data-brightness="170.588">
       RAL 060 70 60
       <div class="subtext">Melonenorange<br>Melon orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FC9348" data-brightness="168.284">
       RAL 060 70 70
       <div class="subtext">Indischgelb<br>Indian yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D3C9C1" data-brightness="205.324">
       RAL 060 80 05
       <div class="subtext">Chamoishellbeige<br>Light chamois beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D8C6B9" data-brightness="203.02">
       RAL 060 80 10
       <div class="subtext">Sanftgreige<br>Soft greige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E7C2AA" data-brightness="205.356">
       RAL 060 80 20
       <div class="subtext">Keksbeige<br>Biscuit beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4BE9A" data-brightness="198.588">
       RAL 060 80 30
       <div class="subtext">Mildorange<br>Mild orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FDB889" data-brightness="196.284">
       RAL 060 80 40
       <div class="subtext">Apricotorange<br>Apricot orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0D4CC" data-brightness="219.356">
       RAL 060 85 05
       <div class="subtext">Champagnerrosé<br>Champagne rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8D3C5" data-brightness="219.5">
       RAL 060 85 10
       <div class="subtext">Maismehlbeige<br>Cornmeal beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F3CFB6" data-brightness="210.827">
       RAL 060 85 20
       <div class="subtext">Teiggelb<br>Dough yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFCCA5" data-brightness="209.398">
       RAL 060 85 30
       <div class="subtext">Safranhellorange<br>Light saffron orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFE3D9" data-brightness="231.196">
       RAL 060 90 05
       <div class="subtext">Kornweiß<br>Grain white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7E0D2" data-brightness="235.98">
       RAL 060 90 10
       <div class="subtext">Vanillecreme<br>Vanilla cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FCDCC9" data-brightness="223.11">
       RAL 060 90 15
       <div class="subtext">Aprikosencreme<br>Apricot cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7EBE2" data-brightness="238.284">
       RAL 060 93 05
       <div class="subtext">Wollweiß<br>Wool white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C5148" data-brightness="82.876">
       RAL 070 30 10 
       <div class="subtext">Mineralbraun<br>Mineral brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#655444" data-brightness="87.372">
       RAL 070 30 20
       <div class="subtext">Buchenbraun<br>Beech brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#71655A" data-brightness="104.14">
       RAL 070 40 10
       <div class="subtext">Nerzfellbraun<br>Mink brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A634E" data-brightness="101.836">
       RAL 070 40 20
       <div class="subtext">Elsbeerenbraun<br>Huckleberry brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#826448" data-brightness="105.9">
       RAL 070 40 30
       <div class="subtext">Ackerbraun<br>Arable brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#88613D" data-brightness="104.017">
       RAL 070 40 40
       <div class="subtext">Herbstgold<br>Autumn gold</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#887B6E" data-brightness="120.321">
       RAL 070 50 10
       <div class="subtext">Sarukgrau<br>Saruk grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#927961" data-brightness="123.643">
       RAL 070 50 20
       <div class="subtext">Aschgold<br>Ash gold</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#997552" data-brightness="122.801">
       RAL 070 50 30
       <div class="subtext">Löwenmähnenblond<br>Lion's mane blonde</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A17344" data-brightness="125.436">
       RAL 070 50 40
       <div class="subtext">Altgold<br>Antique gold</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A2723D" data-brightness="122.102">
       RAL 070 50 50
       <div class="subtext">Bühnengold<br>Stage gold</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AA7034" data-brightness="122.977">
       RAL 070 50 55
       <div class="subtext">Theatergold<br>Theatre gold</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A19285" data-brightness="153.58">
       RAL 070 60 10
       <div class="subtext">Hellmahagoni<br>Light mahogany</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AB9178" data-brightness="151.276">
       RAL 070 60 20
       <div class="subtext">Dunkelblond<br>Dark blond</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B38C67" data-brightness="144.364">
       RAL 070 60 30
       <div class="subtext">Eichenhellbraun<br>Light oak brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BB8B58" data-brightness="139.568">
       RAL 070 60 40
       <div class="subtext">Kornbraun<br>Grain brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C48949" data-brightness="144.252">
       RAL 070 60 50
       <div class="subtext">Schlammgelb<br>Mud yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CC863B" data-brightness="141.937">
       RAL 070 60 60
       <div class="subtext">Senfgelb<br>Mustard yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CF8432" data-brightness="142.236">
       RAL 070 60 70
       <div class="subtext">Sanddorngelbbraun<br>Sea buckthorn yellow brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1812A" data-brightness="143.542">
       RAL 070 60 75
       <div class="subtext">Herbstlauborange<br>Autumn leaf orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BAAC9D" data-brightness="170.06">
       RAL 070 70 10
       <div class="subtext">Ingwergraugelb<br>Ginger grey yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5A98D" data-brightness="172.54">
       RAL 070 70 20
       <div class="subtext">Eschehellbraun<br>Light ash brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0A77E" data-brightness="175.02">
       RAL 070 70 30
       <div class="subtext">Goldbeige<br>Golden beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D19F6A" data-brightness="163.324">
       RAL 070 70 40
       <div class="subtext">Dechantsbirnengelb<br>Dechant pear yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DFA15F" data-brightness="169.836">
       RAL 070 70 50
       <div class="subtext">Honigwabengelb<br>Honeycomb yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E9A04F" data-brightness="172.892">
       RAL 070 70 60
       <div class="subtext">Ginstergelborange<br>Gorse yellow orange</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EB9A3F" data-brightness="161.196">
       RAL 070 70 70
       <div class="subtext">Neapelgelb<br>Naples yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F09630" data-brightness="165.404">
       RAL 070 70 80
       <div class="subtext">Safrangold<br>Saffron gold</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5C5B6" data-brightness="201.846">
       RAL 070 80 10
       <div class="subtext">Flachsbeige<br>Flax beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4C5A8" data-brightness="205.5">
       RAL 070 80 20
       <div class="subtext">Butterblumengelb<br>Buttercup yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ECC196" data-brightness="203.196">
       RAL 070 80 30
       <div class="subtext">Goldhaferfarben<br>Golden oat coloured</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6C088" data-brightness="205.532">
       RAL 070 80 40
       <div class="subtext">Aprikosengelb<br>Apricot yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FEBC75" data-brightness="193.98">
       RAL 070 80 50
       <div class="subtext">Warmapricot<br>Warm apricot</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFBA65" data-brightness="191.377">
       RAL 070 80 60
       <div class="subtext">Goldregengelb<br>Golden rain yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DED4CA" data-brightness="214.716">
       RAL 070 85 05
       <div class="subtext">Mandelkernbeige<br>Almond beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5D3C5" data-brightness="218.924">
       RAL 070 85 10
       <div class="subtext">Silberdistelbeige<br>Silver thistle beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFD0B2" data-brightness="217.196">
       RAL 070 85 20
       <div class="subtext">Sandelholzbeige<br>Sandalwood beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FACFA2" data-brightness="208.523">
       RAL 070 85 30
       <div class="subtext">Haarblond<br>Hair blonde</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EDE3DA" data-brightness="231.196">
       RAL 070 90 05
       <div class="subtext">Offwhite<br>Off white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F3E2D2" data-brightness="235.692">
       RAL 070 90 10
       <div class="subtext">Hellmais<br>Light corn</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FEDEC0" data-brightness="223.11">
       RAL 070 90 20
       <div class="subtext">Kreidegelb<br>Chalk yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7ECE2" data-brightness="238.284">
       RAL 070 93 05
       <div class="subtext">Anemonenweiß<br>Anemone white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#71685D" data-brightness="104.14">
       RAL 075 40 10
       <div class="subtext">Baumrindenbraun<br>Tree bark brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#786551" data-brightness="104.14">
       RAL 075 40 20
       <div class="subtext">Kümmelfruchtbraun<br>Caraway brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D6346" data-brightness="101.404">
       RAL 075 40 30
       <div class="subtext">Borkenbraun<br>Bark brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#81633E" data-brightness="104.316">
       RAL 075 40 38
       <div class="subtext">Eidechsenbraun<br>Lizard brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#887D71" data-brightness="122.625">
       RAL 075 50 10
       <div class="subtext">Roggenteigbraun<br>Rye dough brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#907A62" data-brightness="125.404">
       RAL 075 50 20
       <div class="subtext">Chinazimt<br>China cinnamon</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#987954" data-brightness="121.339">
       RAL 075 50 30
       <div class="subtext">Chamottegelb<br>Grog yellow</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9E7749" data-brightness="119.489">
       RAL 075 50 40
       <div class="subtext">Bernsteinbraun<br>Amber brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A3753C" data-brightness="123.276">
       RAL 075 50 50
       <div class="subtext">Zimtbraun<br>Cinnamon brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A47232" data-brightness="122.988">
       RAL 075 50 58
       <div class="subtext">Kreuzkümmelocker<br>Cumin ochre</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A29487" data-brightness="153.58">
       RAL 075 60 10
       <div class="subtext">Kittgelb<br>Putty yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AA9278" data-brightness="150.977">
       RAL 075 60 20
       <div class="subtext">Walnussschalenbraun<br>Walnut shell brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B29069" data-brightness="153.324">
       RAL 075 60 30
       <div class="subtext">Lehmocker<br>Clay ochre</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B98E5A" data-brightness="142.06">
       RAL 075 60 40
       <div class="subtext">Funchalgelb<br>Funchal yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BE8C49" data-brightness="139.756">
       RAL 075 60 50
       <div class="subtext">Mangobraun<br>Mango brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3893A" data-brightness="142.236">
       RAL 075 60 60
       <div class="subtext">Kurkumabraun<br>Turmeric brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CA872A" data-brightness="139.356">
       RAL 075 60 70
       <div class="subtext">Bambusbraun<br>Bamboo brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BAAD9F" data-brightness="170.06">
       RAL 075 70 10
       <div class="subtext">Flachsfasergrau<br>Flax fibre grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C4AA8C" data-brightness="171.233">
       RAL 075 70 20
       <div class="subtext">Kürbishellbraun<br>Light pumpkin brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCA87E" data-brightness="169.937">
       RAL 075 70 30
       <div class="subtext">Golddistelgelb<br>Golden thistle yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D3A56C" data-brightness="172.716">
       RAL 075 70 40
       <div class="subtext">Backsteingelb<br>Brick yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAA45E" data-brightness="170.412">
       RAL 075 70 50
       <div class="subtext">Bambustiefgelb<br>Deep bamboo yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2A14B" data-brightness="172.892">
       RAL 075 70 60
       <div class="subtext">Intensivgelb<br>Intense yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAA139" data-brightness="170.588">
       RAL 075 70 70
       <div class="subtext">Kürbisgelb<br>Pumpkin yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EB9F2B" data-brightness="158.316">
       RAL 075 70 80
       <div class="subtext">Herbstgelb<br>Autumn yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D7C8B8" data-brightness="202.732">
       RAL 075 80 10
       <div class="subtext">Kalkbeige<br>Chalk beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E1C6A7" data-brightness="205.5">
       RAL 075 80 20
       <div class="subtext">Hellweizengelb<br>Light corn yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8C295" data-brightness="203.196">
       RAL 075 80 30
       <div class="subtext">Dunkelgelb<br>Dark yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F0C186" data-brightness="205.676">
       RAL 075 80 40
       <div class="subtext">Eschengelb<br>Ash yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6BD73" data-brightness="193.98">
       RAL 075 80 50
       <div class="subtext">Orientgelb<br>Orient yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFBB63" data-brightness="190.79">
       RAL 075 80 60
       <div class="subtext">Kutschengelb<br>Carriage yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4D4C3" data-brightness="218.326">
       RAL 075 85 10
       <div class="subtext">Seerosenweiß<br>Water lily white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EED2B2" data-brightness="216.609">
       RAL 075 85 20
       <div class="subtext">Bananeneiscreme<br>Banana ice cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7D0A2" data-brightness="219.676">
       RAL 075 85 30
       <div class="subtext">Ahornbeige<br>Maple beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FECD90" data-brightness="207.98">
       RAL 075 85 40
       <div class="subtext">Goldrutengelb<br>Goldenrod yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F5E5D2" data-brightness="234.806">
       RAL 075 90 10
       <div class="subtext">Süßspeisecreme<br>Dessert cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FCE0C0" data-brightness="233.388">
       RAL 075 90 20
       <div class="subtext">Butterweiß<br>Butter white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4ECE3" data-brightness="238.284">
       RAL 075 93 05
       <div class="subtext">Vanilleweiß<br>Vanilla white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A4743" data-brightness="68.7">
       RAL 080 20 05
       <div class="subtext">Nachtbraunschwarz<br>Night brown black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D4740" data-brightness="68.7">
       RAL 080 20 10
       <div class="subtext">Vanilleschotenbraun<br>Vanilla bean brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A5550" data-brightness="83.419">
       RAL 080 30 05
       <div class="subtext">Erdschwarz<br>Earth black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C544A" data-brightness="82.876">
       RAL 080 30 10
       <div class="subtext">Olivschwarz<br>Olive black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#615341" data-brightness="87.084">
       RAL 080 30 20
       <div class="subtext">Nelkengelbbraun<br>Clove yellow brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#64533C" data-brightness="85.356">
       RAL 080 30 26
       <div class="subtext">Raucheichenbraun<br>Smoked oak brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E6962" data-brightness="101.66">
       RAL 080 40 05
       <div class="subtext">Bürograu<br>Office grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#71685B" data-brightness="104.14">
       RAL 080 40 10
       <div class="subtext">Steinbraun<br>Stone brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#76654D" data-brightness="101.836">
       RAL 080 40 20
       <div class="subtext">Pimentkornbraun<br>Pimento grain brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B6442" data-brightness="101.836">
       RAL 080 40 30
       <div class="subtext">Ockergrün<br>Ochre green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#82653A" data-brightness="104.316">
       RAL 080 40 40
       <div class="subtext">Herbstlaubbraun<br>Autumn leaf brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#837E76" data-brightness="122.924">
       RAL 080 50 05
       <div class="subtext">Dämmergrau<br>Dusk grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#877C6F" data-brightness="120.62">
       RAL 080 50 10
       <div class="subtext">Roggenbraun<br>Rye brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8E7B60" data-brightness="120.62">
       RAL 080 50 20
       <div class="subtext">Graugelb<br>Greyish yellow</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#937A53" data-brightness="122.524">
       RAL 080 50 30
       <div class="subtext">Chiligrün<br>Chili green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#977845" data-brightness="120.796">
       RAL 080 50 40
       <div class="subtext">Schmutzgelb<br>Dirt yellow</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9C7839" data-brightness="117.772">
       RAL 080 50 50
       <div class="subtext">Gamsgelb<br>Chamois yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9B958E" data-brightness="148.796">
       RAL 080 60 05
       <div class="subtext">Flanellgrau<br>Flannel grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9E9384" data-brightness="148.796">
       RAL 080 60 10
       <div class="subtext">Hellkhaki<br>Light khaki</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A79375" data-brightness="151.276">
       RAL 080 60 20
       <div class="subtext">Dinkelkornbraun<br>Spelt grain brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AD9165" data-brightness="148.972">
       RAL 080 60 30
       <div class="subtext">Goldquarzocker<br>Golden quartz ochre</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B18E58" data-brightness="141.628">
       RAL 080 60 40
       <div class="subtext">Bambusgelb<br>Bamboo yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B78E49" data-brightness="139.756">
       RAL 080 60 50
       <div class="subtext">Messinggelb<br>Brass yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BD8E39" data-brightness="137.452">
       RAL 080 60 60
       <div class="subtext">Feigensenfgelb<br>Fig mustard yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C08D2D" data-brightness="139.644">
       RAL 080 60 70
       <div class="subtext">Gelbgold<br>Yellow gold</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B3AEA6" data-brightness="172.364">
       RAL 080 70 05
       <div class="subtext">Knoblauchbeige<br>Garlic beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8AC9C" data-brightness="169.772">
       RAL 080 70 10
       <div class="subtext">Feingreige<br>Fine greige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C1AB8B" data-brightness="172.252">
       RAL 080 70 20
       <div class="subtext">Gelbbraun<br>Yellow brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C7A97B" data-brightness="170.236">
       RAL 080 70 30
       <div class="subtext">Senfkornbeige<br>Mustard seed beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFA86A" data-brightness="167.5">
       RAL 080 70 40
       <div class="subtext">Diamantgelb<br>Diamond yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4A75A" data-brightness="169.98">
       RAL 080 70 50
       <div class="subtext">Antikmessing<br>Antique brass</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBA74B" data-brightness="167.532">
       RAL 080 70 60
       <div class="subtext">Zucchinigelb<br>Courgette yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DFA639" data-brightness="165.804">
       RAL 080 70 70
       <div class="subtext">Grapefruitgelb<br>Grapefruit yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0A226" data-brightness="168.284">
       RAL 080 70 80
       <div class="subtext">Sonnenblumengelb<br>Sunflower yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5A10E" data-brightness="162.956">
       RAL 080 70 88
       <div class="subtext">Arnikagelb<br>Arnica yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFC9C0" data-brightness="200.54">
       RAL 080 80 05
       <div class="subtext">Glimmerhellgrau<br>Micaceous light grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D6C8B7" data-brightness="203.02">
       RAL 080 80 10
       <div class="subtext">Pastellsand<br>Pastel sand</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDC5A4" data-brightness="200.417">
       RAL 080 80 20
       <div class="subtext">Naturreisbeige<br>Natural rice beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E3C393" data-brightness="201.446">
       RAL 080 80 30
       <div class="subtext">Gelbbeige<br>Yellow beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBC281" data-brightness="200.892">
       RAL 080 80 40
       <div class="subtext">Strohgelb<br>Straw yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F2C172" data-brightness="202.796">
       RAL 080 80 50
       <div class="subtext">Mirabellengelb<br>Mirabelle yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F8C05F" data-brightness="198.044">
       RAL 080 80 60
       <div class="subtext">Vollgelb<br>Full yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FBBD4E" data-brightness="187.068">
       RAL 080 80 70
       <div class="subtext">Birnengelb<br>Pear yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFBA36" data-brightness="184.465">
       RAL 080 80 80
       <div class="subtext">Brandgelb<br>Fire yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFBA1F" data-brightness="179.137">
       RAL 080 80 90
       <div class="subtext">Sommergelb<br>Summer yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBD4CA" data-brightness="214.716">
       RAL 080 85 05
       <div class="subtext">Weizenmehlweiß<br>Wheat flour white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0D4C2" data-brightness="219.5">
       RAL 080 85 10
       <div class="subtext">Zwiebelweiß<br>Onion white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAD3B2" data-brightness="217.196">
       RAL 080 85 20
       <div class="subtext">Nashibirnenbeige<br>Nashi pear beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1D0A0" data-brightness="219.388">
       RAL 080 85 30
       <div class="subtext">Vespagelb<br>Vespa yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F9CF8D" data-brightness="203.915">
       RAL 080 85 40
       <div class="subtext">Blätterteiggelb<br>Puff pastry yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EDE5DA" data-brightness="231.196">
       RAL 080 90 05
       <div class="subtext">Japanweiß<br>Japanese white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFE1CF" data-brightness="228.316">
       RAL 080 90 10
       <div class="subtext">Champignonweiß<br>Mushroom white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ECD6B6" data-brightness="216.908">
       RAL 080 90 20
       <div class="subtext">Macadamiabeige<br>Macadamia beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFDFAE" data-brightness="217.616">
       RAL 080 90 30
       <div class="subtext">Meerrettichgelb<br>Horseradish yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F3EAE1" data-brightness="238.284">
       RAL 080 93 05
       <div class="subtext">Milchsternweiß<br>Milk star white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#716A5E" data-brightness="104.14">
       RAL 085 40 10
       <div class="subtext">Mineralgrün<br>Mineral green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#74674F" data-brightness="100.075">
       RAL 085 40 20
       <div class="subtext">Khakigrün<br>Khaki green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A6847" data-brightness="101.116">
       RAL 085 40 30
       <div class="subtext">Moosbraun<br>Moss brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#867E6F" data-brightness="120.62">
       RAL 085 50 10
       <div class="subtext">Korianderocker<br>Coriander ochre</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8C7D61" data-brightness="120.62">
       RAL 085 50 20
       <div class="subtext">Pyritschiefergrün<br>Pyrite slate green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#927D54" data-brightness="123.1">
       RAL 085 50 30
       <div class="subtext">Sepiagelb<br>Sepia yellow</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#947B48" data-brightness="120.796">
       RAL 085 50 40
       <div class="subtext">Sumpfgrün<br>Marshy green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#987B3D" data-brightness="118.492">
       RAL 085 50 50
       <div class="subtext">Honiggelbgrün<br>Honey yellow green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9E9587" data-brightness="148.796">
       RAL 085 60 10
       <div class="subtext">Mattoliv<br>Matte olive</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59476" data-brightness="151.276">
       RAL 085 60 20
       <div class="subtext">Teichgrün<br>Pond green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AB9367" data-brightness="148.972">
       RAL 085 60 30
       <div class="subtext">Holzgrün<br>Wood green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AF9358" data-brightness="146.668">
       RAL 085 60 40
       <div class="subtext">Flechtengrün<br>Lichen green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B4934A" data-brightness="149.148">
       RAL 085 60 50
       <div class="subtext">Mineralumbra<br>Mineral umber</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9923B" data-brightness="146.124">
       RAL 085 60 60
       <div class="subtext">Lodengelb<br>Loden yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6AD9C" data-brightness="170.06">
       RAL 085 70 10
       <div class="subtext">Naturbastgreige<br>Raffia greige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFAC8C" data-brightness="167.468">
       RAL 085 70 20
       <div class="subtext">Feldspatgrau<br>Feldspar grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5AC7A" data-brightness="168.043">
       RAL 085 70 30
       <div class="subtext">Heugelb<br>Hay yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9AA6B" data-brightness="167.345">
       RAL 085 70 40
       <div class="subtext">Winterbirnenbeige<br>Winter pear beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CEA95A" data-brightness="165.196">
       RAL 085 70 50
       <div class="subtext">Herbstapfelgelb<br>Autumn apple yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0A848" data-brightness="167.82">
       RAL 085 70 60
       <div class="subtext">Pitmastonbirnengelb<br>Pitmaston pear yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4A738" data-brightness="165.804">
       RAL 085 70 70
       <div class="subtext">Strohblumengelb<br>Immortelle yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAA92D" data-brightness="162.78">
       RAL 085 70 75
       <div class="subtext">Goldberyllgelb<br>Golden beryl yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1C7B5" data-brightness="203.02">
       RAL 085 80 10
       <div class="subtext">Samtbeige<br>Velvet beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D8C6A4" data-brightness="200.716">
       RAL 085 80 20
       <div class="subtext">Mineralbeige<br>Mineral beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E1C692" data-brightness="203.196">
       RAL 085 80 30
       <div class="subtext">Mondscheingelb<br>Moonlight yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E6C582" data-brightness="200.892">
       RAL 085 80 40
       <div class="subtext">Tafelbirnengelb<br>Table pear yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E9C370" data-brightness="198.588">
       RAL 085 80 50
       <div class="subtext">Fruchtgelb<br>Fruit yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EEC25D" data-brightness="193.681">
       RAL 085 80 60
       <div class="subtext">Adonisröschengelb<br>Adonis rose yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F2C14B" data-brightness="196.46">
       RAL 085 80 70
       <div class="subtext">Berberitzengelb<br>Barberry yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4BF36" data-brightness="183.003">
       RAL 085 80 80
       <div class="subtext">Löwenzahngelb<br>Dandelion yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F5BF2C" data-brightness="180.699">
       RAL 085 80 85
       <div class="subtext">Dekorgelb<br>Decor yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDD2BF" data-brightness="212.113">
       RAL 085 85 10
       <div class="subtext">Alabasterweiß<br>Alabaster white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5D2B0" data-brightness="217.196">
       RAL 085 85 20
       <div class="subtext">Hellblond<br>Light blond</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EDD19E" data-brightness="211.868">
       RAL 085 85 30
       <div class="subtext">Weidenblütengelb<br>Willow-flower yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4D28E" data-brightness="215.068">
       RAL 085 85 40
       <div class="subtext">Ingwerhellgelb<br>Light ginger yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F0E5D3" data-brightness="235.98">
       RAL 085 90 10
       <div class="subtext">Tulpenweiß<br>Tulip white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6E1BE" data-brightness="230.94">
       RAL 085 90 20
       <div class="subtext">Alpenbeerengelb<br>Alpine berry yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FBDEAB" data-brightness="219.1">
       RAL 085 90 30
       <div class="subtext">Porzellanerdengelb<br>China clay yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F2ECE3" data-brightness="238.284">
       RAL 085 93 05
       <div class="subtext">Altweiß<br>Vintage white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B554A" data-brightness="81.115">
       RAL 090 30 10
       <div class="subtext">Limonitbraun<br>Limonite brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F5641" data-brightness="82.876">
       RAL 090 30 20
       <div class="subtext">Borkengrün<br>Bark green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#706A5D" data-brightness="104.14">
       RAL 090 40 10
       <div class="subtext">Wackersteinbraun<br>Boulder brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#73684D" data-brightness="101.836">
       RAL 090 40 20
       <div class="subtext">Pflaumengrün<br>Plum green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#766843" data-brightness="101.836">
       RAL 090 40 30
       <div class="subtext">Weinblättergrün<br>Vine leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#847E70" data-brightness="122.924">
       RAL 090 50 10
       <div class="subtext">Graphitgraugrün<br>Graphite grey green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#887D61" data-brightness="120.321">
       RAL 090 50 20
       <div class="subtext">Pestogrün<br>Pesto green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8E7E51" data-brightness="117.142">
       RAL 090 50 30
       <div class="subtext">Riesenkaktusgrün<br>Giant cactus green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#917E45" data-brightness="120.796">
       RAL 090 50 40
       <div class="subtext">Auberginengrün<br>Aubergine green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9B9485" data-brightness="148.796">
       RAL 090 60 10
       <div class="subtext">Austerngrau<br>Oyster grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A29474" data-brightness="150.988">
       RAL 090 60 20
       <div class="subtext">Manzanillaoliv<br>Manzanilla olive</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59467" data-brightness="148.972">
       RAL 090 60 30
       <div class="subtext">Tarnoliv<br>Camouflage olive</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A89356" data-brightness="146.668">
       RAL 090 60 40
       <div class="subtext">Lorbeergrün<br>Laurel green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A89043" data-brightness="144.364">
       RAL 090 60 50
       <div class="subtext">Fahlgrün<br>Faint green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AF9538" data-brightness="142.06">
       RAL 090 60 60
       <div class="subtext">Titanitgelb<br>Titanite yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B5AE9D" data-brightness="170.06">
       RAL 090 70 10
       <div class="subtext">Staubgelb<br>Dusty yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BBAD8A" data-brightness="167.025">
       RAL 090 70 20
       <div class="subtext">Barbadosbeige<br>Barbados beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BEAB7B" data-brightness="162.971">
       RAL 090 70 30
       <div class="subtext">Rhabarberblattgrün<br>Rhubarb leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5AE6A" data-brightness="167.5">
       RAL 090 70 40
       <div class="subtext">Igelkaktusgelbgrün<br>Hedgehog cactus yellow green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C8AD5A" data-brightness="165.196">
       RAL 090 70 50
       <div class="subtext">Stachelbeergelb<br>Gooseberry yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAAC47" data-brightness="161.563">
       RAL 090 70 60
       <div class="subtext">Knospengrün<br>Bud green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDAC33" data-brightness="159.115">
       RAL 090 70 70
       <div class="subtext">Birkenkätzchengelb<br>Catkin yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFAB22" data-brightness="158.572">
       RAL 090 70 80
       <div class="subtext">Prehnitgelb<br>Prehnite yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0C7B3" data-brightness="203.02">
       RAL 090 80 10
       <div class="subtext">Hellbeige<br>Light beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5C6A3" data-brightness="200.716">
       RAL 090 80 20
       <div class="subtext">Champagnerbeige<br>Champagne beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCC894" data-brightness="198.412">
       RAL 090 80 30
       <div class="subtext">Zitronensorbetgelb<br>Lemon sorbet yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0C984" data-brightness="200.892">
       RAL 090 80 40
       <div class="subtext">Blütengelb<br>Blossom yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5C76D" data-brightness="196.284">
       RAL 090 80 50
       <div class="subtext">Tasmanhoniggelb<br>Tasman honey yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E7C558" data-brightness="193.98">
       RAL 090 80 60
       <div class="subtext">Neugelb<br>New yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBC648" data-brightness="191.676">
       RAL 090 80 70
       <div class="subtext">Fashiongelb<br>Fashion yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAC42C" data-brightness="186.636">
       RAL 090 80 80
       <div class="subtext">Plakatgelb<br>Poster yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F0C503" data-brightness="187.244">
       RAL 090 80 90
       <div class="subtext">Kontrastgelb<br>Contrasting yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAD4CA" data-brightness="214.14">
       RAL 090 85 05
       <div class="subtext">Pfefferweiß<br>Pepper white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DFD5C2" data-brightness="214.716">
       RAL 090 85 10
       <div class="subtext">Paellanaturweiß<br>Paella natural white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4D5B0" data-brightness="217.196">
       RAL 090 85 20
       <div class="subtext">Cidregelb<br>Cider yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAD59F" data-brightness="212.588">
       RAL 090 85 30
       <div class="subtext">Palmzuckergelb<br>Palm sugar yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EDD38C" data-brightness="210.284">
       RAL 090 85 40
       <div class="subtext">Märzgelb<br>March yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F2D479" data-brightness="212.764">
       RAL 090 85 50
       <div class="subtext">Pirolgelb<br>Oriole yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAE4D9" data-brightness="231.196">
       RAL 090 90 05
       <div class="subtext">Marzipanweiß<br>Marzipan white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBE4D1" data-brightness="231.196">
       RAL 090 90 10
       <div class="subtext">Primelweiß<br>Primrose white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F2E3BD" data-brightness="231.372">
       RAL 090 90 20
       <div class="subtext">Cremegelb<br>Cream yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6E2AC" data-brightness="229.068">
       RAL 090 90 30
       <div class="subtext">Wachsgelb<br>Wax yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FCE299" data-brightness="226.62">
       RAL 090 90 40
       <div class="subtext">Zitronencreme<br>Lemon cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FDDE82" data-brightness="215.068">
       RAL 090 90 50
       <div class="subtext">Tigergelb<br>Tiger yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FEDC70" data-brightness="212.764">
       RAL 090 90 60
       <div class="subtext">Sonnenröschengelb<br>Sunrose yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F0EBE0" data-brightness="237.708">
       RAL 090 93 05
       <div class="subtext">Sahneweiß<br>Cream white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E6A5D" data-brightness="99.356">
       RAL 095 40 10
       <div class="subtext">Schattengrün<br>Shady green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#71684E" data-brightness="101.836">
       RAL 095 40 20
       <div class="subtext">Waldgrün<br>Forest green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#726843" data-brightness="101.836">
       RAL 095 40 30
       <div class="subtext">Bohnengrün<br>Bean green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#837E70" data-brightness="122.924">
       RAL 095 50 10
       <div class="subtext">Stumpfoliv<br>Dull olive</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#877E62" data-brightness="120.62">
       RAL 095 50 20
       <div class="subtext">Kohlgrün<br>Cabbage green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8B7F54" data-brightness="118.316">
       RAL 095 50 30
       <div class="subtext">Kaperngrün<br>Caper green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D7E45" data-brightness="116.012">
       RAL 095 50 40
       <div class="subtext">Gartensalatgrün<br>Garden lettuce green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F813C" data-brightness="123.1">
       RAL 095 50 50
       <div class="subtext">Artischockengrün<br>Artichoke green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9C9686" data-brightness="148.508">
       RAL 095 60 10
       <div class="subtext">Bleichgrüngrau<br>Pale green grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F9676" data-brightness="146.204">
       RAL 095 60 20
       <div class="subtext">Blassgrün<br>Pale green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A39565" data-brightness="148.684">
       RAL 095 60 30
       <div class="subtext">Zypressengrün<br>Cypress green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59556" data-brightness="145.494">
       RAL 095 60 40
       <div class="subtext">Traubengrün<br>Grape green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A69746" data-brightness="143.788">
       RAL 095 60 50
       <div class="subtext">Stachelbeergrün<br>Gooseberry green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59439" data-brightness="141.628">
       RAL 095 60 60
       <div class="subtext">Guavenfruchtgrün<br>Guava green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7962A" data-brightness="139.036">
       RAL 095 60 70
       <div class="subtext">Romanescogrün<br>Romaine green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6B09E" data-brightness="179.452">
       RAL 095 70 10
       <div class="subtext">Sandgrau<br>Sand grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BAB08F" data-brightness="177.148">
       RAL 095 70 20
       <div class="subtext">Krokodilgrün<br>Crocodile green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BEB07E" data-brightness="174.268">
       RAL 095 70 30
       <div class="subtext">Chicoréegrün<br>Chicory green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0AF6A" data-brightness="167.5">
       RAL 095 70 40
       <div class="subtext">Bananengrün<br>Banana green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0AF5C" data-brightness="164.908">
       RAL 095 70 50
       <div class="subtext">Sternfruchtgelbgrün<br>Star fruit yellow green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0AE4C" data-brightness="162.604">
       RAL 095 70 60
       <div class="subtext">Papayagelbgrün<br>Papaya yellow green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5B037" data-brightness="170.412">
       RAL 095 70 70
       <div class="subtext">Bronzegrün<br>Bronze green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFC9B8" data-brightness="198.236">
       RAL 095 80 10
       <div class="subtext">Saphirlichtgelb<br>Sapphire light yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5CAA7" data-brightness="200.716">
       RAL 095 80 20
       <div class="subtext">Blassoliv<br>Pale olive</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBCC96" data-brightness="197.825">
       RAL 095 80 30
       <div class="subtext">Spargelgelb<br>Asparagus yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DECB83" data-brightness="196.108">
       RAL 095 80 40
       <div class="subtext">Erbsengrün<br>Pea green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DECA72" data-brightness="193.804">
       RAL 095 80 50
       <div class="subtext">Williamsbirnengelb<br>Williams pear yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCC75F" data-brightness="189.196">
       RAL 095 80 60
       <div class="subtext">Grünlichgelb<br>Greenish yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DFC94B" data-brightness="186.892">
       RAL 095 80 70
       <div class="subtext">Mimosengelb<br>Mimosa yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAC438" data-brightness="184.588">
       RAL 095 80 80
       <div class="subtext">Sorbetgelb<br>Sorbet yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCD6C4" data-brightness="214.716">
       RAL 095 85 10
       <div class="subtext">Schwarzwurzelweiß<br>Salsify white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2D7B4" data-brightness="217.196">
       RAL 095 85 20
       <div class="subtext">Trübhellgelb<br>Dull light yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E6D6A0" data-brightness="213.718">
       RAL 095 85 30
       <div class="subtext">Blattgelb<br>Leaf yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBD78D" data-brightness="209.852">
       RAL 095 85 40
       <div class="subtext">Naturgelb<br>Natural yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ECD67C" data-brightness="207.404">
       RAL 095 85 50
       <div class="subtext">Sportgelb<br>Sport yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ECE5D0" data-brightness="231.196">
       RAL 095 90 10
       <div class="subtext">Atlasweiß<br>Atlas white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1E4C0" data-brightness="233.676">
       RAL 095 90 20
       <div class="subtext">Perlgelb<br>Pearl yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6E3AC" data-brightness="229.068">
       RAL 095 90 30
       <div class="subtext">Zitroneneisgelb<br>Lemon ice cream yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7E297" data-brightness="226.188">
       RAL 095 90 40
       <div class="subtext">Frischgelb<br>Fresh yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FDE588" data-brightness="224.316">
       RAL 095 90 50
       <div class="subtext">Leuchtendgelb<br>Luminescent yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#FFE479" data-brightness="221.857">
       RAL 095 90 59
       <div class="subtext">Dynamischgelb<br>Dynamic yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EEEBE2" data-brightness="233.201">
       RAL 095 93 05
       <div class="subtext">Kreppseidenweiß<br>Crepe silk white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A4945" data-brightness="68.7">
       RAL 100 20 05
       <div class="subtext">Nachtgrün<br>Night green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#56554F" data-brightness="81.115">
       RAL 100 30 05
       <div class="subtext">Vulkansteingrün<br>Volcanic stone green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#585449" data-brightness="82.876">
       RAL 100 30 10
       <div class="subtext">Zinnobergrün<br>Vermilion green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A5741" data-brightness="82.876">
       RAL 100 30 20
       <div class="subtext">Uniformgrün<br>Uniform green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A6962" data-brightness="101.66">
       RAL 100 40 05
       <div class="subtext">Taubengrau<br>Doves grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C695B" data-brightness="99.356">
       RAL 100 40 10
       <div class="subtext">Schlickgrün<br>Slick green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6D694F" data-brightness="97.052">
       RAL 100 40 20
       <div class="subtext">Brokkoligrün<br>Broccoli green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#706A43" data-brightness="101.836">
       RAL 100 40 30
       <div class="subtext">Hochwaldgrün<br>High forest green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#716B3B" data-brightness="99.244">
       RAL 100 40 40
       <div class="subtext">Rosenkohlgrün<br>Brussels sprout green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#817F77" data-brightness="122.492">
       RAL 100 50 05
       <div class="subtext">Waldbodenkhaki<br>Forest floor khaki</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#827E6E" data-brightness="120.332">
       RAL 100 50 10
       <div class="subtext">Kiebitzgraugrün<br>Lapwing grey green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#848060" data-brightness="129.724">
       RAL 100 50 20
       <div class="subtext">Grünspechtoliv<br>Green woodpecker olive</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#847E52" data-brightness="118.316">
       RAL 100 50 30
       <div class="subtext">Steppengrün<br>Steppe green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#888045" data-brightness="125.105">
       RAL 100 50 40
       <div class="subtext">Faiencegrün<br>Faience green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#868039" data-brightness="123.1">
       RAL 100 50 50
       <div class="subtext">Werkzeuggrün<br>Tool green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#99978E" data-brightness="148.497">
       RAL 100 60 05
       <div class="subtext">Rauchgraugrün<br>Smoky grey green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#979483" data-brightness="148.796">
       RAL 100 60 10
       <div class="subtext">Olivingrau<br>Olivine grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9D9776" data-brightness="146.492">
       RAL 100 60 20
       <div class="subtext">Grauspechtgrün<br>Grey-headed woodpecker green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9D9664" data-brightness="144.188">
       RAL 100 60 30
       <div class="subtext">Kardamomgrün<br>Cardamom green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F9857" data-brightness="141.884">
       RAL 100 60 40
       <div class="subtext">Salatgrün<br>Lettuce green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F9946" data-brightness="137.819">
       RAL 100 60 50
       <div class="subtext">Artnouveaugrün<br>Art Nouveau green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9B9532" data-brightness="137.276">
       RAL 100 60 60
       <div class="subtext">Smoothiegrün<br>Smoothie green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B3B1A8" data-brightness="181.756">
       RAL 100 70 05
       <div class="subtext">Rauchweiß<br>Smoky white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B4B09E" data-brightness="179.452">
       RAL 100 70 10
       <div class="subtext">Anisgraugelb<br>Anise grey yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6B08D" data-brightness="177.148">
       RAL 100 70 20
       <div class="subtext">Sandbraun<br>Sand brown</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6B07B" data-brightness="174.124">
       RAL 100 70 30
       <div class="subtext">Hippiegrün<br>Hippie green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BAB26C" data-brightness="172.54">
       RAL 100 70 40
       <div class="subtext">Lindgrün<br>Linden green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8AF57" data-brightness="160.844">
       RAL 100 70 50
       <div class="subtext">Dillgrün<br>Dill green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6AF44" data-brightness="158.396">
       RAL 100 70 60
       <div class="subtext">Neugrün<br>New green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAC9BF" data-brightness="198.236">
       RAL 100 80 05
       <div class="subtext">Naturgrau<br>Natural grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CECAB5" data-brightness="198.236">
       RAL 100 80 10
       <div class="subtext">Blassbeige<br>Pale beige</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4CCA7" data-brightness="200.129">
       RAL 100 80 20
       <div class="subtext">Weichgrün<br>Soft green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D3CA93" data-brightness="197.836">
       RAL 100 80 30
       <div class="subtext">Silbergrün<br>Silver green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4CB81" data-brightness="196.108">
       RAL 100 80 40
       <div class="subtext">Märztulpengrün<br>March tulip green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4CB6E" data-brightness="191.5">
       RAL 100 80 50
       <div class="subtext">Leichtoliv<br>Light olive</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D9CD5B" data-brightness="187.435">
       RAL 100 80 60
       <div class="subtext">Reklamegrün<br>Advertisement green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D8CD48" data-brightness="184.555">
       RAL 100 80 70
       <div class="subtext">LEDgrün<br>LED green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5CE33" data-brightness="184.444">
       RAL 100 80 80
       <div class="subtext">Märzgrün<br>March green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D9D7CD" data-brightness="213.996">
       RAL 100 85 05
       <div class="subtext">Sellerieweiß<br>Celery white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBD6C2" data-brightness="214.716">
       RAL 100 85 10
       <div class="subtext">Palmherzencreme<br>Palm heart cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDD6B0" data-brightness="212.113">
       RAL 100 85 20
       <div class="subtext">Beigegrün<br>Beige green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E1D7A0" data-brightness="214.892">
       RAL 100 85 30
       <div class="subtext">Kristallgelb<br>Crystal yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8E5D9" data-brightness="231.196">
       RAL 100 90 05
       <div class="subtext">Brillantgelb<br>Brilliant yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EAE5D1" data-brightness="231.196">
       RAL 100 90 10
       <div class="subtext">Holunderweiß<br>Elderberry white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EEE5BF" data-brightness="226.289">
       RAL 100 90 20
       <div class="subtext">Designercremegelb<br>Designer cream yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EEE4AA" data-brightness="223.841">
       RAL 100 90 30
       <div class="subtext">Buchsbaumgelb<br>Boxwood yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F3E699" data-brightness="226.62">
       RAL 100 90 40
       <div class="subtext">Primelgelb<br>Primrose yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1E586" data-brightness="224.46">
       RAL 100 90 50
       <div class="subtext">Frühlingsgelb<br>Spring yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EFEDE3" data-brightness="233.5">
       RAL 100 93 05
       <div class="subtext">Nebelweiß<br>Fog white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#484840" data-brightness="67.526">
       RAL 110 20 10
       <div class="subtext">Metallbaugrün<br>Metal construction green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#57574C" data-brightness="81.702">
       RAL 110 30 10
       <div class="subtext">Dunkelolivgrün<br>Dark olive green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#585945" data-brightness="82.156">
       RAL 110 30 20
       <div class="subtext">Norialgenschwarzgrün<br>Nori seaweed green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A6B5D" data-brightness="99.356">
       RAL 110 40 10
       <div class="subtext">Stumpfgrün<br>Stump green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#67694D" data-brightness="97.052">
       RAL 110 40 20
       <div class="subtext">Antikgrün<br>Antique green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#696C46" data-brightness="96.332">
       RAL 110 40 30
       <div class="subtext">Gärtnergrün<br>Gardener green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#686E3C" data-brightness="94.748">
       RAL 110 40 40
       <div class="subtext">Kirchnergrün<br>Kirchner green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7F7F70" data-brightness="116.966">
       RAL 110 50 10
       <div class="subtext">Novembergrün<br>November green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7E8061" data-brightness="125.228">
       RAL 110 50 20
       <div class="subtext">Alexandritgrün<br>Alexandrite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D8153" data-brightness="122.924">
       RAL 110 50 30
       <div class="subtext">Pflanzengrün<br>Plant green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B8244" data-brightness="120.476">
       RAL 110 50 40
       <div class="subtext">Reifegrün<br>Ripe green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#79833A" data-brightness="118.316">
       RAL 110 50 50
       <div class="subtext">Portugalgrün<br>Portuguese green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#798533" data-brightness="118.172">
       RAL 110 50 55
       <div class="subtext">Flaggengrün<br>Flag green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#959685" data-brightness="148.22">
       RAL 110 60 10
       <div class="subtext">Graugrün<br>Grey green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#939777" data-brightness="146.204">
       RAL 110 60 20
       <div class="subtext">Erbsenschotengrün<br>Peapod green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#969A69" data-brightness="143.468">
       RAL 110 60 30
       <div class="subtext">Indischgrün<br>Indian green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#929A58" data-brightness="141.884">
       RAL 110 60 40
       <div class="subtext">Blatterbsengrün<br>Winterpea green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#909C49" data-brightness="138.86">
       RAL 110 60 50
       <div class="subtext">Airlinegrün<br>Airline green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#909F3B" data-brightness="137.276">
       RAL 110 60 60
       <div class="subtext">Waldmeistergrün<br>Woodruff green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#91A033" data-brightness="146.524">
       RAL 110 60 65
       <div class="subtext">Ampelgrün<br>Traffic light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B0B19E" data-brightness="179.452">
       RAL 110 70 10
       <div class="subtext">Chinateegrün<br>Chinese tea green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AFB28E" data-brightness="172.364">
       RAL 110 70 20
       <div class="subtext">Wandgrün<br>Wall green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ACB17D" data-brightness="170.06">
       RAL 110 70 30
       <div class="subtext">Wasabigrün<br>Wasabi green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ACB46E" data-brightness="167.756">
       RAL 110 70 40
       <div class="subtext">Aprilgrün<br>April green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ACB65D" data-brightness="165.452">
       RAL 110 70 50
       <div class="subtext">Delaunaygrün<br>Delaunay green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A8B64C" data-brightness="163.148">
       RAL 110 70 60
       <div class="subtext">Limettengrün<br>Lime green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1B62B" data-brightness="158.108">
       RAL 110 70 70
       <div class="subtext">Chlorophyllgrün<br>Chlorophyll green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A0B42D" data-brightness="158.54">
       RAL 110 70 77
       <div class="subtext">Markergrün<br>Marker green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C7C9B7" data-brightness="197.66">
       RAL 110 80 10
       <div class="subtext">Frühlingsgrau<br>Spring grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CBCCA7" data-brightness="194.171">
       RAL 110 80 20
       <div class="subtext">Minzeisgrün<br>Mint ice green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9CE95" data-brightness="193.628">
       RAL 110 80 30
       <div class="subtext">Lenzgrün<br>Springtide green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C7CF84" data-brightness="191.324">
       RAL 110 80 40
       <div class="subtext">Limettensorbetgrün<br>Lime sorbet green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C8D172" data-brightness="198.412">
       RAL 110 80 50
       <div class="subtext">Ostergrün<br>Easter green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C4D160" data-brightness="196.108">
       RAL 110 80 60
       <div class="subtext">Clowngrün<br>Clown green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C2D24C" data-brightness="189.606">
       RAL 110 80 70
       <div class="subtext">Saftgrün<br>Juice green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5D6C3" data-brightness="214.716">
       RAL 110 85 10
       <div class="subtext">Veltlinerweiß<br>Veltliner white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5D7B1" data-brightness="212.412">
       RAL 110 85 20
       <div class="subtext">Kohlrabigrün<br>Kohlrabi green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D7DAA1" data-brightness="210.108">
       RAL 110 85 30
       <div class="subtext">Burgundergrau<br>Burgundy grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5DA8E" data-brightness="205.5">
       RAL 110 85 40
       <div class="subtext">Wachsgrün<br>Wax green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D2DA7A" data-brightness="202.908">
       RAL 110 85 50
       <div class="subtext">Sprossengrün<br>Sprout green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4E2D7" data-brightness="231.196">
       RAL 110 90 05
       <div class="subtext">Baumwollweiß<br>Cotton white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E6E6D2" data-brightness="230.022">
       RAL 110 90 10
       <div class="subtext">Farnhellgrün<br>Light fern green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E6E7BF" data-brightness="226.588">
       RAL 110 90 20
       <div class="subtext">Lilienduftgrün<br>Lily scent green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5E7AC" data-brightness="224.284">
       RAL 110 90 30
       <div class="subtext">Kiwieisgrün<br>Kiwi ice cream green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4E99B" data-brightness="221.98">
       RAL 110 90 40
       <div class="subtext">Limettensaftgrün<br>Lime juice green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBEBE1" data-brightness="232.326">
       RAL 110 93 05
       <div class="subtext">Bastweiß<br>Raffia white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4F3EF" data-brightness="246.956">
       RAL 110 96 02
       <div class="subtext">Waschweiß<br>Laundry white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#545650" data-brightness="85.18">
       RAL 120 30 05
       <div class="subtext">Racinggrün<br>Racing green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#53574B" data-brightness="82.876">
       RAL 120 30 10
       <div class="subtext">Landhausgrün<br>Country house green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#515842" data-brightness="82.876">
       RAL 120 30 20
       <div class="subtext">Avocadodunkelgrün<br>Avocado dark green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#686962" data-brightness="101.66">
       RAL 120 40 05
       <div class="subtext">Englischgrün<br>English green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#66695B" data-brightness="99.057">
       RAL 120 40 10
       <div class="subtext">Mandelgrün<br>Almond green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#636B51" data-brightness="99.356">
       RAL 120 40 20
       <div class="subtext">Kaktusgrün<br>Cactus green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#626E48" data-brightness="97.052">
       RAL 120 40 30
       <div class="subtext">Basilikumgrün<br>Basil green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C703B" data-brightness="99.356">
       RAL 120 40 40
       <div class="subtext">Paprikagrün<br>Pepper green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7E7F77" data-brightness="117.42">
       RAL 120 50 05
       <div class="subtext">Rauchgrün<br>Smoke green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B8071" data-brightness="127.532">
       RAL 120 50 10
       <div class="subtext">Samtgrüngrau<br>Velvet green grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#788262" data-brightness="124.94">
       RAL 120 50 20
       <div class="subtext">Papageiengrün<br>Parrot green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#758254" data-brightness="122.924">
       RAL 120 50 30
       <div class="subtext">Quarzgrün<br>Quartz green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#72864C" data-brightness="120.62">
       RAL 120 50 40
       <div class="subtext">Heckengrün<br>Hedge green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C863E" data-brightness="111.771">
       RAL 120 50 50
       <div class="subtext">Birkenblattgrün<br>Birch leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#979A90" data-brightness="151.1">
       RAL 120 60 05
       <div class="subtext">Bläulichgrau<br>Bluish grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#929787" data-brightness="148.508">
       RAL 120 60 10
       <div class="subtext">Lavendelblattgrün<br>Lavender leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#909978" data-brightness="144.731">
       RAL 120 60 20
       <div class="subtext">Mostbirnengrün<br>Cider pear green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8C9D6B" data-brightness="139.404">
       RAL 120 60 30
       <div class="subtext">Vesuviangrün<br>Vesuvian green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#899F5D" data-brightness="137.1">
       RAL 120 60 40
       <div class="subtext">Bambusgrasgrün<br>Bamboo grass green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#83A04D" data-brightness="144.188">
       RAL 120 60 50
       <div class="subtext">Aloeveragrün<br>Aloe vera green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7DA03E" data-brightness="137.1">
       RAL 120 60 60
       <div class="subtext">Mambagrün<br>Mamba green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7CA23D" data-brightness="137.1">
       RAL 120 60 63
       <div class="subtext">Leuchtendgrün<br>Luminescent green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AFB1A7" data-brightness="176.972">
       RAL 120 70 05
       <div class="subtext">Lorbeergrau<br>Laurel grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ACB19E" data-brightness="174.668">
       RAL 120 70 10
       <div class="subtext">Nilgrün<br>Nile green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A8B38F" data-brightness="172.364">
       RAL 120 70 20
       <div class="subtext">Filigrangrün<br>Filigree green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6B682" data-brightness="171.19">
       RAL 120 70 30
       <div class="subtext">Serpentingrün<br>Serpentine green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1B872" data-brightness="170.06">
       RAL 120 70 40
       <div class="subtext">Birkenhellgrün<br>Light birch green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CB960" data-brightness="161.211">
       RAL 120 70 50
       <div class="subtext">Waldrebengrün<br>Clematis green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#95B751" data-brightness="160.668">
       RAL 120 70 60
       <div class="subtext">Turmalingrün<br>Tourmaline green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#92BC42" data-brightness="157.788">
       RAL 120 70 70
       <div class="subtext">Cocktailgrün<br>Cocktail green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8DB836" data-brightness="149.515">
       RAL 120 70 75
       <div class="subtext">Brillantgrün<br>Brilliant green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C8CBC1" data-brightness="200.54">
       RAL 120 80 05
       <div class="subtext">Staubgrün<br>Dust green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5CAB6" data-brightness="198.236">
       RAL 120 80 10
       <div class="subtext">Jugendstilgrün<br>Modern Style green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C4CEA9" data-brightness="195.932">
       RAL 120 80 20
       <div class="subtext">Avocadocreme<br>Avocado cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BECF97" data-brightness="188.844">
       RAL 120 80 30
       <div class="subtext">Naturgrün<br>Natural green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BBD289" data-brightness="195.633">
       RAL 120 80 40
       <div class="subtext">Sommergrün<br>Summer green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6D478" data-brightness="193.628">
       RAL 120 80 50
       <div class="subtext">Modegrün<br>Fashion green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B1D669" data-brightness="191.324">
       RAL 120 80 60
       <div class="subtext">Neongrün<br>Neon green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4D6CC" data-brightness="214.572">
       RAL 120 85 05
       <div class="subtext">Kreidegrün<br>Chalk green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1D7C4" data-brightness="214.716">
       RAL 120 85 10 
       <div class="subtext">Federleichtgrün<br>Light feather green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0DAB4" data-brightness="212.412">
       RAL 120 85 20
       <div class="subtext">Seegrün<br>Lake green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCDBA2" data-brightness="205.025">
       RAL 120 85 30
       <div class="subtext">Aquagrün<br>Aqua green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2E4D9" data-brightness="231.196">
       RAL 120 90 05
       <div class="subtext">Maiglöckchenweiß<br>Lily of the valley white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4E7D3" data-brightness="231.196">
       RAL 120 90 10
       <div class="subtext">Hellpistazie<br>Pale pistachio</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DEE8C2" data-brightness="224.108">
       RAL 120 90 20
       <div class="subtext">Kiwisorbetgrün<br>Kiwi sorbet green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAEBB1" data-brightness="221.804">
       RAL 120 90 30
       <div class="subtext">Eiskaltgrün<br>Ice-cold green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E9ECE1" data-brightness="233.5">
       RAL 120 93 05
       <div class="subtext">Hauchweißgrün<br>Touch of white green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#53584D" data-brightness="82.876">
       RAL 130 30 10
       <div class="subtext">Aramealgengrün<br>Arame seaweed green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4E5945" data-brightness="77.66">
       RAL 130 30 20
       <div class="subtext">Seetanggrün<br>Kelp green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#666C5F" data-brightness="99.057">
       RAL 130 40 10
       <div class="subtext">Sanftoliv<br>Soft olive</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D6C53" data-brightness="94.572">
       RAL 130 40 20
       <div class="subtext">Alpenseegrün<br>Alpine lake green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A6F4B" data-brightness="92.268">
       RAL 130 40 30
       <div class="subtext">Edelsteingrün<br>Gemstone green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#788072" data-brightness="127.532">
       RAL 130 50 10
       <div class="subtext">Dubuffetgrün<br>Dubuffet green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#758468" data-brightness="124.796">
       RAL 130 50 20
       <div class="subtext">Spanischgrün<br>Spanish green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C855B" data-brightness="118.14">
       RAL 130 50 30
       <div class="subtext">Algengrün<br>Algae green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#65884F" data-brightness="115.249">
       RAL 130 50 40
       <div class="subtext">Peperonigrün<br>Hot pepper green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D8947" data-brightness="111.052">
       RAL 130 50 50
       <div class="subtext">Smaragdklargrün<br>Emerald clear green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#909989" data-brightness="146.315">
       RAL 130 60 10
       <div class="subtext">Salbeimattgrün<br>Matte sage green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8A9B7D" data-brightness="141.708">
       RAL 130 60 20
       <div class="subtext">Altgrün<br>Old green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#839D71" data-brightness="141.708">
       RAL 130 60 30
       <div class="subtext">Erbsauberginengrün<br>Pea aubergine green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7C9F64" data-brightness="134.62">
       RAL 130 60 40
       <div class="subtext">Grashüpfergrün<br>Grasshopper green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#74A256" data-brightness="141.708">
       RAL 130 60 50
       <div class="subtext">Parisergrün<br>Parisian green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#69A449" data-brightness="134.044">
       RAL 130 60 60
       <div class="subtext">Urgrün<br>Primal green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A9B3A3" data-brightness="176.684">
       RAL 130 70 10
       <div class="subtext">Kalkgrün<br>Limestone green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A4B695" data-brightness="174.668">
       RAL 130 70 20
       <div class="subtext">Seifengrün<br>Soap green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9BB787" data-brightness="167.292">
       RAL 130 70 30
       <div class="subtext">Aquarellgrün<br>Watercolour green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#94B979" data-brightness="162.795">
       RAL 130 70 40
       <div class="subtext">Vangoghgrün<br>Vangogh green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8DBC6E" data-brightness="158.188">
       RAL 130 70 50
       <div class="subtext">Apfelgrün<br>Apple green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#84BE5F" data-brightness="155.884">
       RAL 130 70 60
       <div class="subtext">Ultragrün<br>Ultra green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3CCBB" data-brightness="196.331">
       RAL 130 80 10
       <div class="subtext">Jadehellgrün<br>Jade light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BDCEAD" data-brightness="190.572">
       RAL 130 80 20
       <div class="subtext">Emaillegrün<br>Enamel green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6D1A0" data-brightness="200.54">
       RAL 130 80 30
       <div class="subtext">Pastellgrün<br>Pastel green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AFD592" data-brightness="193.452">
       RAL 130 80 40
       <div class="subtext">Jasmingrün<br>Jasmine green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7D683" data-brightness="191.148">
       RAL 130 80 50
       <div class="subtext">Kristallgrün<br>Crystal green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1D7CD" data-brightness="213.996">
       RAL 130 85 05
       <div class="subtext">Traubenölgrün<br>Grape oil green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFD9C6" data-brightness="209.932">
       RAL 130 85 10
       <div class="subtext">Duchamplichtgrün<br>Duchamp light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9DBB8" data-brightness="207.628">
       RAL 130 85 20
       <div class="subtext">Bleichhellgrün<br>Pallid light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5DEAC" data-brightness="204.604">
       RAL 130 85 30
       <div class="subtext">Viridingrün<br>Viridine green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0E4DA" data-brightness="231.196">
       RAL 130 90 05
       <div class="subtext">Silberpappelweiß<br>Silver poplar white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0E3D9" data-brightness="231.196">
       RAL 130 90 10
       <div class="subtext">Mediterrangrün<br>Mediterranean green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D8E9C6" data-brightness="224.108">
       RAL 130 90 20
       <div class="subtext">Frühlingsgrün<br>Spring green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8ECE3" data-brightness="233.5">
       RAL 130 93 05
       <div class="subtext">Bohnenweiß<br>Bean white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#454845" data-brightness="68.124">
       RAL 140 20 05
       <div class="subtext">Melanitschwarzgrün<br>Melanite black green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#424941" data-brightness="68.7">
       RAL 140 20 10
       <div class="subtext">Gebirgsgrün<br>Mountain range green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#414B3E" data-brightness="66.396">
       RAL 140 20 20
       <div class="subtext">Bayrischgrün<br>Bavarian green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#555954" data-brightness="84.881">
       RAL 140 30 05
       <div class="subtext">Schwarzwaldgrün<br>Black Forest green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#51594F" data-brightness="82.876">
       RAL 140 30 10
       <div class="subtext">Kupferkiesgrün<br>Copper pyrite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4B5C48" data-brightness="78.092">
       RAL 140 30 20
       <div class="subtext">Brennnesselgrün<br>Nettle green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#425E3F" data-brightness="75.788">
       RAL 140 30 30
       <div class="subtext">Edelkastaniengrün<br>Chestnut green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#395E3C" data-brightness="71.004">
       RAL 140 30 40
       <div class="subtext">Förstergrün<br>Ranger green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#676C66" data-brightness="100.94">
       RAL 140 40 05
       <div class="subtext">Steingrün<br>Stone green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#626C60" data-brightness="101.66">
       RAL 140 40 10
       <div class="subtext">Lodengrün<br>Loden green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#586D55" data-brightness="93.852">
       RAL 140 40 20
       <div class="subtext">Akaziengrün<br>Acacia green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#51704C" data-brightness="101.66">
       RAL 140 40 30
       <div class="subtext">Pfaugrün<br>Peacock green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#487547" data-brightness="96.444">
       RAL 140 40 40
       <div class="subtext">Radikalgrün<br>Radical green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#407740" data-brightness="95.713">
       RAL 140 40 50
       <div class="subtext">Brasilgrün<br>Brazilian green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A8079" data-brightness="127.532">
       RAL 140 50 05
       <div class="subtext">Silberahorngrün<br>Silver maple green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#768174" data-brightness="127.532">
       RAL 140 50 10
       <div class="subtext">Edeltannengrün<br>Fir spruce green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E8368" data-brightness="120.012">
       RAL 140 50 20
       <div class="subtext">Silberweidengrün<br>Silver willow green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#65875F" data-brightness="118.14">
       RAL 140 50 30
       <div class="subtext">Wandergrün<br>Rambling green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#588853" data-brightness="112.182">
       RAL 140 50 40
       <div class="subtext">Guineagrün<br>Guinean green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F8C4C" data-brightness="105.98">
       RAL 140 50 50
       <div class="subtext">Bergwiesengrün<br>Mountain meadow green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#408E42" data-brightness="106.268">
       RAL 140 50 60
       <div class="subtext">Temperamentgrün<br>Temperamental green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#919791" data-brightness="150.524">
       RAL 140 60 05
       <div class="subtext">Camouflagegrün<br>Camouflage green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8B9889" data-brightness="141.819">
       RAL 140 60 10
       <div class="subtext">Silberlindengrau<br>Silver linden grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#859E80" data-brightness="144.012">
       RAL 140 60 20
       <div class="subtext">Spinatgrün<br>Spinach green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7BA174" data-brightness="146.316">
       RAL 140 60 30
       <div class="subtext">Schwarzdorngrün<br>Blackthorn green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#72A369" data-brightness="144.012">
       RAL 140 60 40
       <div class="subtext">Frischgrün<br>Fresh green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#64A45E" data-brightness="135.75">
       RAL 140 60 50
       <div class="subtext">Petersiliengrün<br>Parsley green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#59A754" data-brightness="132.14">
       RAL 140 60 60
       <div class="subtext">Oreganogrün<br>Oregano green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4AA74B" data-brightness="125.052">
       RAL 140 60 70
       <div class="subtext">Ballongrün<br>Balloon green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AAB0A9" data-brightness="176.673">
       RAL 140 70 05
       <div class="subtext">Ginkgogrün<br>Ginkgo green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A3B1A3" data-brightness="176.396">
       RAL 140 70 10
       <div class="subtext">Wermutgrün<br>Wormwood green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CB697" data-brightness="169.884">
       RAL 140 70 20
       <div class="subtext">Taugrün<br>Dew green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#91B98C" data-brightness="165.819">
       RAL 140 70 30
       <div class="subtext">Anisblattgrün<br>Aniseed leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#89BD80" data-brightness="162.796">
       RAL 140 70 40
       <div class="subtext">Sauergrün<br>Sour green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7CBF74" data-brightness="155.708">
       RAL 140 70 50
       <div class="subtext">Acidgrün<br>Acid green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#71C067" data-brightness="162.076">
       RAL 140 70 60
       <div class="subtext">Dropsgrün<br>Drop green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5CCC2" data-brightness="198.779">
       RAL 140 80 05
       <div class="subtext">Weidengrün<br>Willow green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFCCBC" data-brightness="192.433">
       RAL 140 80 10
       <div class="subtext">Pastellgraugrün<br>Pastel grey green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6D0B0" data-brightness="202.556">
       RAL 140 80 20
       <div class="subtext">Giopontigrün<br>Gio Ponti green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABD3A4" data-brightness="195.756">
       RAL 140 80 30
       <div class="subtext">Entspannungsgrün<br>Relaxation green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9DD697" data-brightness="188.668">
       RAL 140 80 40
       <div class="subtext">Dentistgrün<br>Dentist green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0D8CF" data-brightness="214.716">
       RAL 140 85 05
       <div class="subtext">Taubnesselweiß<br>Deadnettle white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCDACA" data-brightness="209.345">
       RAL 140 85 10
       <div class="subtext">Lauchweiß<br>Leek white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3DEBD" data-brightness="207.196">
       RAL 140 85 20
       <div class="subtext">Kalkschiefer<br>Limestone slate</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6DFB0" data-brightness="202.844">
       RAL 140 85 30
       <div class="subtext">Minzcocktailgrün<br>Mint cocktail green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0E6DB" data-brightness="231.196">
       RAL 140 90 05
       <div class="subtext">Rosmarinweiß<br>Rosemary white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCE8D6" data-brightness="226.412">
       RAL 140 90 10
       <div class="subtext">Hauchgrün<br>Touch of green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8EEE4" data-brightness="231.739">
       RAL 140 93 05
       <div class="subtext">Kastanienweiß<br>Chestnut white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#515C53" data-brightness="85.18">
       RAL 150 30 10
       <div class="subtext">Thymiangrün<br>Thyme green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#475E4E" data-brightness="77.804">
       RAL 150 30 20
       <div class="subtext">Moselgrün<br>Moselle green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3D5E46" data-brightness="73.308">
       RAL 150 30 30
       <div class="subtext">Berggrün<br>Mountain green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#616D63" data-brightness="101.66">
       RAL 150 40 10
       <div class="subtext">Filzgrün<br>Felt green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#58715C" data-brightness="103.964">
       RAL 150 40 20
       <div class="subtext">Prallgrün<br>Firm green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A7254" data-brightness="98.46">
       RAL 150 40 30
       <div class="subtext">Genevergrün<br>Genever green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C744C" data-brightness="91.516">
       RAL 150 40 40
       <div class="subtext">Tintengrün<br>Ink green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#307649" data-brightness="92.092">
       RAL 150 40 50
       <div class="subtext">Irlandgrün<br>Ireland green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#738176" data-brightness="127.532">
       RAL 150 50 10
       <div class="subtext">Salbeigrüngrau<br>Sage green grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#69866E" data-brightness="118.683">
       RAL 150 50 20
       <div class="subtext">Koreaminze<br>Korean mint</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#608866" data-brightness="119.137">
       RAL 150 50 30
       <div class="subtext">Feldgrün<br>Field green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#518D63" data-brightness="115.66">
       RAL 150 50 40
       <div class="subtext">Selleriegrün<br>Celery green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E8F58" data-brightness="103.356">
       RAL 150 50 50
       <div class="subtext">Sattgrün<br>Rich green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#239151" data-brightness="108.108">
       RAL 150 50 60
       <div class="subtext">Kaltgrün<br>Cold green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8C9B8E" data-brightness="144.012">
       RAL 150 60 10
       <div class="subtext">Spitzkohlgrün<br>Pointed cabbage green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7F9E86" data-brightness="139.228">
       RAL 150 60 20
       <div class="subtext">Orientgrün<br>Orient green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#73A07D" data-brightness="146.316">
       RAL 150 60 30
       <div class="subtext">Rosmaringrün<br>Rosemary green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#65A474" data-brightness="141.244">
       RAL 150 60 40
       <div class="subtext">Mosaikgrün<br>Mosaic green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#58A96F" data-brightness="134.444">
       RAL 150 60 50
       <div class="subtext">Flummigrün<br>Bouncy ball green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3AA861" data-brightness="124.876">
       RAL 150 60 60
       <div class="subtext">Energiegrün<br>Energy green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A3B3A6" data-brightness="175.798">
       RAL 150 70 10
       <div class="subtext">Teegrün<br>Tea green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#98B89F" data-brightness="168.71">
       RAL 150 70 20
       <div class="subtext">Liebermanngrün<br>Liebermann green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8ABD95" data-brightness="165.1">
       RAL 150 70 30
       <div class="subtext">Wachstuchgrün<br>Oilcloth green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7CBE8C" data-brightness="157.436">
       RAL 150 70 40
       <div class="subtext">Jadesteingrün<br>Jade stone green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6CC181" data-brightness="162.332">
       RAL 150 70 50
       <div class="subtext">Grünspanfarben<br>Verdigris coloured</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BDCEC0" data-brightness="195.756">
       RAL 150 80 10
       <div class="subtext">Organzagrün<br>Organza green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B1D2B7" data-brightness="202.844">
       RAL 150 80 20
       <div class="subtext">Pastellminzgrün<br>Pastel mint green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A3D6AF" data-brightness="195.756">
       RAL 150 80 30
       <div class="subtext">Knallgrün<br>Bright green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#95DAA5" data-brightness="190.396">
       RAL 150 80 40
       <div class="subtext">Perlmuttgrün<br>Mother-of-pearl green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCD7CE" data-brightness="209.633">
       RAL 150 85 05
       <div class="subtext">Spargelweiß<br>Asparagus white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9DACA" data-brightness="209.644">
       RAL 150 85 10
       <div class="subtext">Transparentweiß<br>Transparent white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFDEC1" data-brightness="205.148">
       RAL 150 85 20
       <div class="subtext">Bleichgrün<br>Pallid green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCE5DA" data-brightness="226.412">
       RAL 150 90 05
       <div class="subtext">Mineralweiß<br>Mineral white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D9E9D8" data-brightness="225.238">
       RAL 150 90 10
       <div class="subtext">Alabastergrün<br>Alabaster green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4EDE4" data-brightness="232.924">
       RAL 150 93 05
       <div class="subtext">Quarkweiß<br>Quark white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#464A48" data-brightness="68.7">
       RAL 160 20 05
       <div class="subtext">Beryllschwarzgrün<br>Beryl black green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#404B45" data-brightness="68.7">
       RAL 160 20 10
       <div class="subtext">Schalmuschelgrün<br>Mussel green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#394A43" data-brightness="63.196">
       RAL 160 20 15
       <div class="subtext">Kürbisgrünschwarz<br>Pumpkin green black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#394942" data-brightness="62.742">
       RAL 160 20 20
       <div class="subtext">Opulentgrün<br>Opulent green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#535955" data-brightness="84.46">
       RAL 160 30 05
       <div class="subtext">Sattoliv<br>Rich olive</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4E5A53" data-brightness="80.396">
       RAL 160 30 10
       <div class="subtext">Exklusivgrün<br>Exclusive green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#485A4F" data-brightness="78.092">
       RAL 160 30 15
       <div class="subtext">Blattgrün<br>Leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#415A4D" data-brightness="78.092">
       RAL 160 30 20
       <div class="subtext">Bergminze<br>Mountain mint</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3B5C4A" data-brightness="73.308">
       RAL 160 30 25
       <div class="subtext">Ordnungsgrün<br>Order green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#345E48" data-brightness="73.308">
       RAL 160 30 30
       <div class="subtext">Trollgrün<br>Troll green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2C5E45" data-brightness="68.092">
       RAL 160 30 35
       <div class="subtext">Spieltischgrün<br>Card table green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#235F44" data-brightness="68.38">
       RAL 160 30 40
       <div class="subtext">Zitronenmelissengrün<br>Lemon balm green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#656C68" data-brightness="101.66">
       RAL 160 40 05
       <div class="subtext">Jaspisgrün<br>Jasper green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D6D65" data-brightness="94.982">
       RAL 160 40 10
       <div class="subtext">Sibirischgrün<br>Siberian green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#576E62" data-brightness="96.876">
       RAL 160 40 15
       <div class="subtext">Gurkengrün<br>Cucumber green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#506F5F" data-brightness="94.284">
       RAL 160 40 20
       <div class="subtext">Serbischgrün<br>Serbian green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#49705B" data-brightness="99.18">
       RAL 160 40 25
       <div class="subtext">Schwarzkiefergrün<br>Black pine green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#427259" data-brightness="98.006">
       RAL 160 40 30
       <div class="subtext">Kürbisgrün<br>Pumpkin green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3A7457" data-brightness="93.964">
       RAL 160 40 35
       <div class="subtext">Jademuschelgrün<br>Jade mussel green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#317656" data-brightness="94.108">
       RAL 160 40 40
       <div class="subtext">Kleegrün<br>Clover green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#287451" data-brightness="89.612">
       RAL 160 40 45
       <div class="subtext">Froschgrün<br>Frog green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#16754E" data-brightness="82.524">
       RAL 160 40 50
       <div class="subtext">Universalgrün<br>Universal green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#05784D" data-brightness="77.74">
       RAL 160 40 55
       <div class="subtext">Säuleneichengrün<br>Column of oak green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#79817C" data-brightness="127.532">
       RAL 160 50 05
       <div class="subtext">Arktisflechtengrün<br>Arctic lichen green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#708278" data-brightness="127.1">
       RAL 160 50 10
       <div class="subtext">Atlaszedergrün<br>Atlas cedar green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A8476" data-brightness="122.028">
       RAL 160 50 15
       <div class="subtext">Steinzypressengrün<br>Stone cypress green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#638674" data-brightness="120.987">
       RAL 160 50 20
       <div class="subtext">Wacholdergrün<br>Juniper green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B8770" data-brightness="117.964">
       RAL 160 50 25
       <div class="subtext">Sportplatzgrün<br>Sports field green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#54896E" data-brightness="115.66">
       RAL 160 50 30
       <div class="subtext">Freizeitgrün<br>Leisure green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4E8B6B" data-brightness="110.588">
       RAL 160 50 35
       <div class="subtext">Adamingrün<br>Adamite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3F8C68" data-brightness="105.66">
       RAL 160 50 40
       <div class="subtext">Metallgrün<br>Metallic green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#398F68" data-brightness="105.66">
       RAL 160 50 45
       <div class="subtext">Vitalgrün<br>Vital green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#298E62" data-brightness="100.588">
       RAL 160 50 50
       <div class="subtext">Golfgrün<br>Golf green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#109161" data-brightness="103.435">
       RAL 160 50 55
       <div class="subtext">Islandgrün<br>Iceland green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00905C" data-brightness="96.768">
       RAL 160 50 60
       <div class="subtext">Echttürkis<br>Real turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8F9893" data-brightness="144.555">
       RAL 160 60 05
       <div class="subtext">Sandsteingraugrün<br>Sandstone grey green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#86998F" data-brightness="143.425">
       RAL 160 60 10
       <div class="subtext">Schiefergrün<br>Slate green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#809D8D" data-brightness="143.724">
       RAL 160 60 15
       <div class="subtext">Mildgrün<br>Mild green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#779E89" data-brightness="138.497">
       RAL 160 60 20
       <div class="subtext">Douglasiengrün<br>Douglas fir green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#709F84" data-brightness="139.228">
       RAL 160 60 25
       <div class="subtext">Praxisgrün<br>Practice green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#68A183" data-brightness="143.836">
       RAL 160 60 30
       <div class="subtext">Memphisgrün<br>Memphis green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5FA582" data-brightness="137.291">
       RAL 160 60 35
       <div class="subtext">Werbegrün<br>Advertising green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#55A47E" data-brightness="136.449">
       RAL 160 60 40
       <div class="subtext">Jägergrün<br>Hunter green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4CA77C" data-brightness="131.388">
       RAL 160 60 45
       <div class="subtext">Linoleumgrün<br>Linoleum green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3BA878" data-brightness="126.892">
       RAL 160 60 50
       <div class="subtext">Sittichgrün<br>Parakeet green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#32A774" data-brightness="127.18">
       RAL 160 60 55
       <div class="subtext">Smaragdhellgrün<br>Emerald light green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1CA971" data-brightness="116.892">
       RAL 160 60 58
       <div class="subtext">Alhambragrün<br>Alhambra green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7B2AC" data-brightness="176.972">
       RAL 160 70 05
       <div class="subtext">Grünlichgrau<br>Greenish grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9EB3A7" data-brightness="172.188">
       RAL 160 70 10
       <div class="subtext">Schwachgrün<br>Weak green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#96B4A3" data-brightness="172.188">
       RAL 160 70 15
       <div class="subtext">Andeerschiefer<br>Andean slate</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#90B7A1" data-brightness="172.188">
       RAL 160 70 20
       <div class="subtext">Keramikgrün<br>Ceramic green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#88BB9F" data-brightness="164.214">
       RAL 160 70 25
       <div class="subtext">Wassergrün<br>Water green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7DBC9C" data-brightness="160.028">
       RAL 160 70 30
       <div class="subtext">Bergseegrün<br>Mountain lake green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#74BD99" data-brightness="160.172">
       RAL 160 70 35
       <div class="subtext">Amerikanischgrün<br>American green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6AC096" data-brightness="164.204">
       RAL 160 70 40
       <div class="subtext">Technotürkis<br>Techno turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5EC292" data-brightness="159.852">
       RAL 160 70 45
       <div class="subtext">Grellgrün<br>Garish green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#54C391" data-brightness="160.14">
       RAL 160 70 50
       <div class="subtext">Malachitgrün<br>Malachite green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFCAC4" data-brightness="195.756">
       RAL 160 80 05
       <div class="subtext">Nebelgrün<br>Fog green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8CDC0" data-brightness="195.756">
       RAL 160 80 10
       <div class="subtext">Jademattgrün<br>Matte jade green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AFCFBD" data-brightness="187.494">
       RAL 160 80 15
       <div class="subtext">Sonnengrün<br>Sun yellow</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7D2BA" data-brightness="197.34">
       RAL 160 80 20
       <div class="subtext">Federngrün<br>Feather green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1D4B8" data-brightness="198.06">
       RAL 160 80 25
       <div class="subtext">Mentholgrün<br>Menthol green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#99D7B6" data-brightness="192.977">
       RAL 160 80 30
       <div class="subtext">Seidengrün<br>Silk green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAD6CF" data-brightness="209.932">
       RAL 160 85 05
       <div class="subtext">Leichtgrün<br>Light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3D8CC" data-brightness="209.212">
       RAL 160 85 10
       <div class="subtext">Glimmergrün<br>Micaceous green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BBDBC7" data-brightness="203.088">
       RAL 160 85 15
       <div class="subtext">Mondsteingrün<br>Moonstone green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBE5DC" data-brightness="226.412">
       RAL 160 90 05
       <div class="subtext">Aquarellweiß<br>Watercolour white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D3E8D9" data-brightness="226.412">
       RAL 160 90 10
       <div class="subtext">Mondscheingrün<br>Moonlight green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCEAD6" data-brightness="221.329">
       RAL 160 90 15
       <div class="subtext">Gletscherwassergrün<br>Glacial water green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0ECE6" data-brightness="233.5">
       RAL 160 93 05
       <div class="subtext">Bergkristallsilber<br>Mountain crystal silver</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E4A46" data-brightness="63.916">
       RAL 170 20 10
       <div class="subtext">Tiefgrün<br>Deep green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#394A45" data-brightness="63.916">
       RAL 170 20 15
       <div class="subtext">Intensivgrün<br>Intense green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#334C45" data-brightness="63.617">
       RAL 170 20 20
       <div class="subtext">Wagengrün<br>Coach green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F4C42" data-brightness="58.412">
       RAL 170 20 25
       <div class="subtext">Offroadgrün<br>Off-road green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4C5B55" data-brightness="79.964">
       RAL 170 30 10
       <div class="subtext">Granatschwarzgrün<br>Garnet black green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#435B52" data-brightness="80.396">
       RAL 170 30 15
       <div class="subtext">Dunkelgrün<br>Dark green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E5B51" data-brightness="75.612">
       RAL 170 30 20
       <div class="subtext">Distelgrün<br>Thistle green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#355E50" data-brightness="75.612">
       RAL 170 30 25
       <div class="subtext">Himbeerblattgrün<br>Raspberry leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F5E50" data-brightness="70.828">
       RAL 170 30 30
       <div class="subtext">Chrysokolldunkelgrün<br>Chrysocolla dark green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#26604F" data-brightness="77.916">
       RAL 170 30 35
       <div class="subtext">Trappergrün<br>Trapper green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#16624E" data-brightness="73.132">
       RAL 170 30 40
       <div class="subtext">Permanentgrün<br>Permanent green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B6C65" data-brightness="96.156">
       RAL 170 40 10
       <div class="subtext">Chalcedongrün<br>Chalcedony green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#536D63" data-brightness="96.3">
       RAL 170 40 15
       <div class="subtext">Schlossgrün<br>Palace green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4B7063" data-brightness="101.484">
       RAL 170 40 20
       <div class="subtext">Breitwegerichgrün<br>Plantain green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#457060" data-brightness="101.196">
       RAL 170 40 25
       <div class="subtext">Erzgebirgsgrün<br>Ore mountains green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3D7561" data-brightness="96.7">
       RAL 170 40 30
       <div class="subtext">Traktorgrün<br>Tractor green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F745E" data-brightness="89.612">
       RAL 170 40 35
       <div class="subtext">Viktoriagrün<br>Victoria green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#20755E" data-brightness="89.612">
       RAL 170 40 40
       <div class="subtext">Gerätegrün<br>Device green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#08765D" data-brightness="80.044">
       RAL 170 40 45
       <div class="subtext">Umweltgrün<br>Environmental green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00765A" data-brightness="79.745">
       RAL 170 40 50
       <div class="subtext">Vegangrün<br>Vegan green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E827B" data-brightness="122.748">
       RAL 170 50 10
       <div class="subtext">Rödelerzgrün<br>Bournonite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#67867A" data-brightness="120.987">
       RAL 170 50 15
       <div class="subtext">Fuchsitgrün<br>Fuchsite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F8678" data-brightness="117.532">
       RAL 170 50 20
       <div class="subtext">Brombeerblattgrün<br>Blackberry leaf green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#568876" data-brightness="116.801">
       RAL 170 50 25
       <div class="subtext">Turmalinblau<br>Tourmaline blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A8A75" data-brightness="112.006">
       RAL 170 50 30
       <div class="subtext">Traumgrün<br>Dream green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E8B73" data-brightness="107.676">
       RAL 170 50 35
       <div class="subtext">Akzentgrünblau<br>Accent green blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#308D71" data-brightness="108.396">
       RAL 170 50 40
       <div class="subtext">Spektralgrün<br>Spectral green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1E8E71" data-brightness="96.934">
       RAL 170 50 45
       <div class="subtext">Stockentengrün<br>Mallard green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00916F" data-brightness="100.833">
       RAL 170 50 50
       <div class="subtext">Chagallgrün<br>Chagall green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00906D" data-brightness="99.072">
       RAL 170 50 55
       <div class="subtext">Absinthtürkis<br>Absinthe turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#849A92" data-brightness="146.316">
       RAL 170 60 10
       <div class="subtext">Quantumgrün<br>Quantum green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7E9D90" data-brightness="141.532">
       RAL 170 60 15
       <div class="subtext">Bitterkleegrün<br>Bitter clover green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#739F8F" data-brightness="138.94">
       RAL 170 60 20
       <div class="subtext">Cyperngrün<br>Cyprus green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6AA08E" data-brightness="143.836">
       RAL 170 60 25
       <div class="subtext">Saftiggrün<br>Succulent green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5EA28C" data-brightness="139.052">
       RAL 170 60 30
       <div class="subtext">Lebendiggrün<br>Vibrant green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#50A68A" data-brightness="138.62">
       RAL 170 60 35
       <div class="subtext">Klimtgrün<br>Klimt green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#44A689" data-brightness="133.969">
       RAL 170 60 40
       <div class="subtext">Persischgrün<br>Persian green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#33A685" data-brightness="129.185">
       RAL 170 60 45
       <div class="subtext">Sportgrün<br>Sport green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1BAA87" data-brightness="119.329">
       RAL 170 60 50
       <div class="subtext">Aktivgrün<br>Active green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9DB4AB" data-brightness="171.756">
       RAL 170 70 10
       <div class="subtext">Liebstöckelgrün<br>Lovage green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#95B6A8" data-brightness="172.188">
       RAL 170 70 15
       <div class="subtext">Chrysopalhellgrün<br>Chrysopal light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8BB9A7" data-brightness="167.404">
       RAL 170 70 20
       <div class="subtext">Quellgrün<br>Source green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#83BBA6" data-brightness="166.817">
       RAL 170 70 25
       <div class="subtext">Orientmosaikgrün<br>Orient mosaic green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#75BEA5" data-brightness="162.044">
       RAL 170 70 30
       <div class="subtext">Minzkaltgrün<br>Mint cold green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6ABEA2" data-brightness="157.836">
       RAL 170 70 35
       <div class="subtext">Emeraldgrün<br>Emerald green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5FBFA0" data-brightness="151.878">
       RAL 170 70 40
       <div class="subtext">Expressionismusgrün<br>Expressionism green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B7CEC5" data-brightness="195.756">
       RAL 170 80 10
       <div class="subtext">Fluoritblau<br>Fluorite blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ACD0C3" data-brightness="200.364">
       RAL 170 80 15
       <div class="subtext">Schwimmbadgrün<br>Swimming pool green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A4D5C2" data-brightness="200.364">
       RAL 170 80 20
       <div class="subtext">Himmelgrün<br>Sky green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#99D4BF" data-brightness="192.977">
       RAL 170 80 25
       <div class="subtext">Larimargrün<br>Larimar green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CBD7D2" data-brightness="212.236">
       RAL 170 85 05
       <div class="subtext">Silikathelltürkis<br>Silicate light turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3DAD0" data-brightness="212.236">
       RAL 170 85 10
       <div class="subtext">Topasgrün<br>Topaz green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8DDCD" data-brightness="204.129">
       RAL 170 85 15
       <div class="subtext">Kühlschrankgrün<br>Refrigerator green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B0E0CB" data-brightness="212.646">
       RAL 170 85 20
       <div class="subtext">Kristallglasgrün<br>Crystal glass green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAE5DE" data-brightness="225.98">
       RAL 170 90 05
       <div class="subtext">Kristallsalzweiß<br>Crystal salt white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1E9DD" data-brightness="225.692">
       RAL 170 90 10
       <div class="subtext">Türkisweiß<br>Turquoise white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2EDE7" data-brightness="233.5">
       RAL 170 93 05
       <div class="subtext">Schneekristallgrün<br>Snow crystal green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#454C4B" data-brightness="68.7">
       RAL 180 20 05
       <div class="subtext">Pechgrün<br>Pitch green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E4A47" data-brightness="63.916">
       RAL 180 20 10
       <div class="subtext">Lackgrün<br>Lacquer green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#374B48" data-brightness="63.916">
       RAL 180 20 15
       <div class="subtext">Zaungrün<br>Fence green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#304C48" data-brightness="63.916">
       RAL 180 20 20
       <div class="subtext">Myrtengrün<br>Myrtle green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#535B59" data-brightness="85.18">
       RAL 180 30 05
       <div class="subtext">Amazonasgrün<br>Amazon green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A5A56" data-brightness="79.222">
       RAL 180 30 10
       <div class="subtext">Sattgrautürkis<br>Rich grey turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#435C57" data-brightness="80.396">
       RAL 180 30 15
       <div class="subtext">Tafelgrün<br>Blackboard green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C5D56" data-brightness="75.612">
       RAL 180 30 20
       <div class="subtext">Schwedengrün<br>Swedish green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#335E56" data-brightness="75.313">
       RAL 180 30 25
       <div class="subtext">Fjordgrün<br>Fjord green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#295F56" data-brightness="70.828">
       RAL 180 30 30
       <div class="subtext">Stadtgrün<br>Urban green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#196054" data-brightness="75.436">
       RAL 180 30 35
       <div class="subtext">Dschungelgrün<br>Jungle green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#626C69" data-brightness="101.66">
       RAL 180 40 05
       <div class="subtext">Erdgrün<br>Earth green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A6E69" data-brightness="96.876">
       RAL 180 40 10
       <div class="subtext">Kiefergrün<br>Pine green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#516F68" data-brightness="96.876">
       RAL 180 40 15
       <div class="subtext">Forstgrün<br>Forest greenery</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#477168" data-brightness="101.484">
       RAL 180 40 20
       <div class="subtext">Meergrün<br>Sea green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E7268" data-brightness="96.7">
       RAL 180 40 25
       <div class="subtext">Eukalyptusgrün<br>Eucalyptus green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F7469" data-brightness="91.916">
       RAL 180 40 30
       <div class="subtext">Efeufrischgrün<br>Fresh ivy green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1C7566" data-brightness="86.988">
       RAL 180 40 35
       <div class="subtext">Chrysokollmittelgrün<br>Chrysocolla medium green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007565" data-brightness="81.761">
       RAL 180 40 40
       <div class="subtext">Plakatgrün<br>Poster green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007666" data-brightness="81.761">
       RAL 180 40 45
       <div class="subtext">Petroldunkelgrün<br>Teal dark green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#788280" data-brightness="129.836">
       RAL 180 50 05
       <div class="subtext">Dämmergrün<br>Dusk green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6D827D" data-brightness="122.172">
       RAL 180 50 10
       <div class="subtext">Erquickungsgrün<br>Refreshing green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#63857D" data-brightness="122.748">
       RAL 180 50 15
       <div class="subtext">Stumpftürkis<br>Dull turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#59867D" data-brightness="117.964">
       RAL 180 50 20
       <div class="subtext">Azuritwassergrün<br>Azurite water green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D897E" data-brightness="113.18">
       RAL 180 50 25
       <div class="subtext">Kupfermineralgrün<br>Copper mineral green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#408B7C" data-brightness="113.18">
       RAL 180 50 30
       <div class="subtext">Glasgrün<br>Glass green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#318C7D" data-brightness="108.396">
       RAL 180 50 35
       <div class="subtext">Sommertürkis<br>Summer turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1B8C7B" data-brightness="98.252">
       RAL 180 50 40
       <div class="subtext">Weihnachtsgrün<br>Christmas green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00907B" data-brightness="101.376">
       RAL 180 50 45
       <div class="subtext">Industrietürkis<br>Industrial turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00907A" data-brightness="101.376">
       RAL 180 50 50
       <div class="subtext">Ozeangrün<br>Ocean green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8D9996" data-brightness="145.729">
       RAL 180 60 05
       <div class="subtext">Marmorgrüngrau<br>Marble green-grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#829A95" data-brightness="146.316">
       RAL 180 60 10
       <div class="subtext">Kachelgrün<br>Tile green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#789D94" data-brightness="141.532">
       RAL 180 60 15
       <div class="subtext">Kupferdachgrün<br>Copper roof green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6E9F94" data-brightness="136.748">
       RAL 180 60 20
       <div class="subtext">Salbeigrün<br>Sage green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#61A093" data-brightness="146.14">
       RAL 180 60 25
       <div class="subtext">Petrolgrün<br>Petrol green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#54A394" data-brightness="140.78">
       RAL 180 60 30
       <div class="subtext">Dioptasgrün<br>Dioptase green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#46A593" data-brightness="136.572">
       RAL 180 60 35
       <div class="subtext">Stempelkissengrün<br>Stamp pad green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2FA794" data-brightness="127.004">
       RAL 180 60 40
       <div class="subtext">Leuchttürkis<br>Light turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#02A994" data-brightness="117.436">
       RAL 180 60 45
       <div class="subtext">Karibikgrün<br>Caribbean green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00AA93" data-brightness="116.55">
       RAL 180 60 50
       <div class="subtext">Brillanttürkis<br>Brilliant turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6B1AE" data-brightness="176.972">
       RAL 180 70 05
       <div class="subtext">Feldspatsilber<br>Feldspar silver</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#98B3AD" data-brightness="172.188">
       RAL 180 70 10
       <div class="subtext">Grazilgrün<br>Delicate green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8FB6AD" data-brightness="167.404">
       RAL 180 70 15
       <div class="subtext">Silikatgrün<br>Silicate green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#85B9AD" data-brightness="167.404">
       RAL 180 70 20
       <div class="subtext">Mintbonbongrün<br>Mint bonbon green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#78BAAC" data-brightness="162.62">
       RAL 180 70 25
       <div class="subtext">Gletschergrün<br>Glacial green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6DBDAD" data-brightness="156.086">
       RAL 180 70 30
       <div class="subtext">Badtürkis<br>Bath turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5BBFAC" data-brightness="153.052">
       RAL 180 70 35
       <div class="subtext">Bergseeazur<br>Mountain lake azure</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4AC2AD" data-brightness="157.66">
       RAL 180 70 40
       <div class="subtext">Reincyan<br>Pure cyan</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFCBC6" data-brightness="193.995">
       RAL 180 80 05
       <div class="subtext">Aragonitweiß<br>Aragonite white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B4CEC7" data-brightness="195.756">
       RAL 180 80 10
       <div class="subtext">Lichtpetrol<br>Light teal</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABD2C8" data-brightness="200.364">
       RAL 180 80 15
       <div class="subtext">Whirlpoolgrün<br>Whirlpool green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CD3C6" data-brightness="195.58">
       RAL 180 80 20
       <div class="subtext">Opaltürkis<br>Opal turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#91D5C6" data-brightness="195.58">
       RAL 180 80 25
       <div class="subtext">Caprihellgrün<br>Light Capri green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#89DAC8" data-brightness="190.076">
       RAL 180 80 30
       <div class="subtext">Zarttürkis<br>Tender turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAD7D3" data-brightness="212.236">
       RAL 180 85 05
       <div class="subtext">Meerdunstgrau<br>Sea haze grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0DAD3" data-brightness="212.236">
       RAL 180 85 10
       <div class="subtext">Feuerlandmeergrün<br>Tierra del Fuego sea green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8DED2" data-brightness="207.452">
       RAL 180 85 15
       <div class="subtext">Bergseehelltürkis<br>Pale mountain lake turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AADFCF" data-brightness="199.777">
       RAL 180 85 20
       <div class="subtext">Korallgrün<br>Coral green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBE7E2" data-brightness="228.716">
       RAL 180 90 05
       <div class="subtext">Transparentgrün<br>Transparent green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0ECE3" data-brightness="228.716">
       RAL 180 90 10
       <div class="subtext">Eiswassergrün<br>Ice water green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2EEEA" data-brightness="231.739">
       RAL 180 93 05
       <div class="subtext">Arktisweiß<br>Arctic white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F4D4A" data-brightness="59.132">
       RAL 190 20 20
       <div class="subtext">Nachttürkis<br>Night turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#415C5A" data-brightness="80.396">
       RAL 190 30 15
       <div class="subtext">Kutschengrün<br>Carriage green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#375D5B" data-brightness="75.612">
       RAL 190 30 20
       <div class="subtext">Lattenzaungrün<br>Stockade green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2D5E5B" data-brightness="70.828">
       RAL 190 30 25
       <div class="subtext">Aktionsgrün<br>Action green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#20605D" data-brightness="79.046">
       RAL 190 30 30
       <div class="subtext">Myrtentiefgrün<br>Myrtle deep green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#0E605D" data-brightness="68.891">
       RAL 190 30 35
       <div class="subtext">Planktongrün<br>Plankton green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#586C6A" data-brightness="96.876">
       RAL 190 40 10
       <div class="subtext">Schlammgrün<br>Mud green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F6F6D" data-brightness="90.918">
       RAL 190 40 15
       <div class="subtext">Moorteichgrün<br>Moor pond green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#44716E" data-brightness="101.185">
       RAL 190 40 20
       <div class="subtext">Üppiggrün<br>Lush green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#37726F" data-brightness="96.7">
       RAL 190 40 25
       <div class="subtext">Schmetterlingsgrün<br>Butterfly green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#267470" data-brightness="94.22">
       RAL 190 40 30
       <div class="subtext">Modeblau<br>Fashion blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#097671" data-brightness="84.652">
       RAL 190 40 35
       <div class="subtext">Officeblaugrün<br>Office blue green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007771" data-brightness="83.766">
       RAL 190 40 40
       <div class="subtext">Leguangrün<br>Iguana green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007A73" data-brightness="84.353">
       RAL 190 40 45
       <div class="subtext">Dunkelpetrol<br>Dark teal</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6D8481" data-brightness="125.052">
       RAL 190 50 10
       <div class="subtext">Sturmgrün<br>Storm green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#628682" data-brightness="122.715">
       RAL 190 50 15
       <div class="subtext">Dunkeltürkis<br>Dark turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#578884" data-brightness="119.681">
       RAL 190 50 20
       <div class="subtext">Tannenblau<br>Fir blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#488984" data-brightness="114.764">
       RAL 190 50 25
       <div class="subtext">Apatitblau<br>Apatite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#368B86" data-brightness="110.124">
       RAL 190 50 30
       <div class="subtext">Ägyptischgrün<br>Egyptian green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#208C86" data-brightness="105.916">
       RAL 190 50 35
       <div class="subtext">Schmucktürkis<br>Ornamental turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#008F89" data-brightness="96.049">
       RAL 190 50 40
       <div class="subtext">Lidschattentürkis<br>Eyeshadow turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00908A" data-brightness="103.68">
       RAL 190 50 45
       <div class="subtext">Klartürkis<br>Clear turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#829B98" data-brightness="145.596">
       RAL 190 60 10
       <div class="subtext">Dezenttürkis<br>Subtle turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#779D98" data-brightness="141.233">
       RAL 190 60 15
       <div class="subtext">Hellgrünspan<br>Pale verdigris</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6EA09B" data-brightness="146.14">
       RAL 190 60 20
       <div class="subtext">Gänseteichgrün<br>Goose pond green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5EA39D" data-brightness="141.356">
       RAL 190 60 25
       <div class="subtext">Turmalintürkis<br>Tourmaline turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4DA49E" data-brightness="134.811">
       RAL 190 60 30
       <div class="subtext">Baltikgrün<br>Baltic green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#39A6A0" data-brightness="134.092">
       RAL 190 60 35
       <div class="subtext">Keramikbleutürkis<br>Ceramic blue turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#0CA8A1" data-brightness="119.74">
       RAL 190 60 40
       <div class="subtext">Baltiktürkis<br>Baltic turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00A8A0" data-brightness="118.721">
       RAL 190 60 45
       <div class="subtext">Tirolitblaugrün<br>Tyrolite blue-green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9AB4B1" data-brightness="174.492">
       RAL 190 70 10
       <div class="subtext">Marmorgrün<br>Marble green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8FB6B1" data-brightness="169.708">
       RAL 190 70 15
       <div class="subtext">Jadegrün<br>Jade green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#82B8B2" data-brightness="167.371">
       RAL 190 70 20
       <div class="subtext">Quellwassertürkis<br>Spring water turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#75BBB5" data-brightness="163.761">
       RAL 190 70 25
       <div class="subtext">Floridatürkis<br>Florida turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#69BEB8" data-brightness="160.14">
       RAL 190 70 30
       <div class="subtext">Hygienegrün<br>Hygiene green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#55BFB7" data-brightness="155.057">
       RAL 190 70 35
       <div class="subtext">Arktisgrün<br>Arctic green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B3CFCA" data-brightness="195.756">
       RAL 190 80 10
       <div class="subtext">Andenopalgrün<br>Andean opal green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A9D2CD" data-brightness="199.932">
       RAL 190 80 15
       <div class="subtext">Kalttürkis<br>Cold turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9BD2CD" data-brightness="195.148">
       RAL 190 80 20
       <div class="subtext">Petroleumblau<br>Teal blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8FD5CF" data-brightness="190.22">
       RAL 190 80 25
       <div class="subtext">Poolgrün<br>Pool green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5D9D6" data-brightness="212.236">
       RAL 190 85 05
       <div class="subtext">Morgentauweiß<br>Morning dew white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BADCD6" data-brightness="207.452">
       RAL 190 85 10
       <div class="subtext">Badesalzgrün<br>Bath salt green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AADFD6" data-brightness="202.369">
       RAL 190 85 15
       <div class="subtext">Capriwasserblau<br>Capri water blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CE2D7" data-brightness="207.276">
       RAL 190 85 20
       <div class="subtext">Horizontblau<br>Horizon blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBE6E2" data-brightness="228.716">
       RAL 190 90 05
       <div class="subtext">Kaltweiß<br>Cool white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFEAE5" data-brightness="223.932">
       RAL 190 90 10
       <div class="subtext">Quellblau<br>Source blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E3EDEB" data-brightness="233.5">
       RAL 190 93 05
       <div class="subtext">Ägyptischweiß<br>Egyptian white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#444A4A" data-brightness="68.113">
       RAL 200 20 05
       <div class="subtext">Tarngrün<br>Concealed green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C4A4B" data-brightness="63.916">
       RAL 200 20 10
       <div class="subtext">Dunkelkiefergrün<br>Dark pine green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#374A4B" data-brightness="63.916">
       RAL 200 20 15
       <div class="subtext">Dachziegelgrün<br>Roof tile green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#304C4E" data-brightness="63.916">
       RAL 200 20 20
       <div class="subtext">Buschgrün<br>Shrub green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#264D4F" data-brightness="59.132">
       RAL 200 20 23
       <div class="subtext">Zollgrün<br>Customs green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F5858" data-brightness="80.108">
       RAL 200 30 05
       <div class="subtext">Finsternisgrün<br>Darkness green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#485B5B" data-brightness="80.108">
       RAL 200 30 10
       <div class="subtext">Baumrindengrün<br>Tree bark green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#405D5D" data-brightness="80.108">
       RAL 200 30 15
       <div class="subtext">Hornblendegrün<br>Hornblende green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#385E5F" data-brightness="75.612">
       RAL 200 30 20
       <div class="subtext">Malachitblautürkis<br>Malachite blue turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#285D5E" data-brightness="70.828">
       RAL 200 30 25
       <div class="subtext">Antiktürkis<br>Antique turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1A5F62" data-brightness="68.348">
       RAL 200 30 30
       <div class="subtext">Wolltürkis<br>Wool turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#006164" data-brightness="72.657">
       RAL 200 30 33
       <div class="subtext">Eisdunkeltürkis<br>Ice dark turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#616C6C" data-brightness="101.372">
       RAL 200 40 05
       <div class="subtext">Kalkspatgraugrün<br>Calcite grey green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#596D6E" data-brightness="96.876">
       RAL 200 40 10
       <div class="subtext">Brochantitgrün<br>Brochantite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F6F6F" data-brightness="90.342">
       RAL 200 40 15
       <div class="subtext">Eisenkiesgrün<br>Pyrite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#427172" data-brightness="103.212">
       RAL 200 40 20
       <div class="subtext">Alpendämmerblau<br>Dusky alpine blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#377374" data-brightness="97.243">
       RAL 200 40 25
       <div class="subtext">Badgrün<br>Bath green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#237477" data-brightness="93.788">
       RAL 200 40 30
       <div class="subtext">Mayablau<br>Mayan blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007579" data-brightness="84.353">
       RAL 200 40 35
       <div class="subtext">Byzantinischblau<br>Byzantine blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00787D" data-brightness="84.353">
       RAL 200 40 40
       <div class="subtext">Aktivtürkis<br>Active turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#748080" data-brightness="129.548">
       RAL 200 50 05
       <div class="subtext">Nordgrau<br>North grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C8384" data-brightness="125.052">
       RAL 200 50 10
       <div class="subtext">Augengrau<br>Eye grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#618686" data-brightness="122.571">
       RAL 200 50 15
       <div class="subtext">Nickelerzgrün<br>Nickel ore green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#548788" data-brightness="119.836">
       RAL 200 50 20
       <div class="subtext">Gischtgrün<br>Seafoam green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#46898C" data-brightness="115.484">
       RAL 200 50 25
       <div class="subtext">Kandinskytürkis<br>Kandinsky turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#308B8D" data-brightness="110.7">
       RAL 200 50 30
       <div class="subtext">Karibiktürkis<br>Caribbean turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#118E92" data-brightness="103.137">
       RAL 200 50 35
       <div class="subtext">Brunnenblau<br>Well blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#008F93" data-brightness="98.353">
       RAL 200 50 40
       <div class="subtext">Industrieblau<br>Industrial blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#009096" data-brightness="105.984">
       RAL 200 50 45
       <div class="subtext">Indiablau<br>India blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8C9999" data-brightness="145.297">
       RAL 200 60 05
       <div class="subtext">Schattengrau<br>Shady grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#829A9B" data-brightness="146.316">
       RAL 200 60 10
       <div class="subtext">Nordkapgrau<br>North Cape grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#769D9E" data-brightness="141.532">
       RAL 200 60 15
       <div class="subtext">Waidindigo<br>Woad indigo</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6AA0A1" data-brightness="148.444">
       RAL 200 60 20
       <div class="subtext">Jugendstiltürkis<br>Art nouveau turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#59A1A2" data-brightness="143.66">
       RAL 200 60 25
       <div class="subtext">Kühlboxeistürkis<br>Coolbox ice turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#48A4A5" data-brightness="137.115">
       RAL 200 60 30
       <div class="subtext">Aquamarinblau<br>Aquamarine blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#33A5A9" data-brightness="133.793">
       RAL 200 60 35
       <div class="subtext">Arktisblau<br>Arctic blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#05A8AC" data-brightness="119.74">
       RAL 200 60 40
       <div class="subtext">Wachsstiftblau<br>Wax crayon blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A5B1B1" data-brightness="178.988">
       RAL 200 70 05
       <div class="subtext">Prinzengrau<br>Prince grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9AB5B5" data-brightness="174.204">
       RAL 200 70 10
       <div class="subtext">Calcitblau<br>Calcite blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8CB6B6" data-brightness="169.42">
       RAL 200 70 15
       <div class="subtext">Kaltfrontgrün<br>Cold front green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#82B9B9" data-brightness="169.42">
       RAL 200 70 20
       <div class="subtext">Eisvogeltürkis<br>Kingfisher turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#74BCBF" data-brightness="164.924">
       RAL 200 70 25
       <div class="subtext">Frischbleu<br>Fresh soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFCBCA" data-brightness="193.995">
       RAL 200 80 05
       <div class="subtext">Chinalichtgrün<br>China light green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B2CECE" data-brightness="195.468">
       RAL 200 80 10
       <div class="subtext">Barockkalkbleu<br>Baroque chalk soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6CFCE" data-brightness="190.972">
       RAL 200 80 15
       <div class="subtext">Eisboutiquetürkis<br>Ice boutique turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9AD4D3" data-brightness="197.884">
       RAL 200 80 20
       <div class="subtext">Sommerbleu<br>Summer soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8CD5D6" data-brightness="193.1">
       RAL 200 80 25
       <div class="subtext">Bergseeblau<br>Mountain lake blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAD7D7" data-brightness="211.948">
       RAL 200 85 05
       <div class="subtext">Bastlichtgrau<br>Raffia light grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0DADA" data-brightness="211.948">
       RAL 200 85 10
       <div class="subtext">Kaltlufttürkis<br>Cold air turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B3DDDD" data-brightness="206.433">
       RAL 200 85 15
       <div class="subtext">Frosthellbleu<br>Frosty soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6E0DF" data-brightness="212.06">
       RAL 200 85 20
       <div class="subtext">Eiskristallblau<br>Ice crystal blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBE8E5" data-brightness="228.716">
       RAL 200 90 05
       <div class="subtext">Frostgrün<br>Frosty green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CEE9E5" data-brightness="223.932">
       RAL 200 90 10
       <div class="subtext">Frostweißblau<br>Frosty white blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2EEEB" data-brightness="231.739">
       RAL 200 93 05
       <div class="subtext">Klirrendweiß<br>Chilly white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A5A5C" data-brightness="79.222">
       RAL 210 30 10
       <div class="subtext">Graphitschwarzgrün<br>Graphite black green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#415C60" data-brightness="82.7">
       RAL 210 30 15
       <div class="subtext">Stockentenblau<br>Mallard blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#365E64" data-brightness="77.916">
       RAL 210 30 20
       <div class="subtext">Transportergrün<br>Transporter green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2B5E65" data-brightness="72.7">
       RAL 210 30 25
       <div class="subtext">Tiefatlantikblau<br>Deep Atlantic blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#175F68" data-brightness="68.348">
       RAL 210 30 30
       <div class="subtext">Kaliblau<br>Kali blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#586C6F" data-brightness="96.876">
       RAL 210 40 10
       <div class="subtext">Leinengrau<br>Linen grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F6F74" data-brightness="92.502">
       RAL 210 40 15
       <div class="subtext">Chinagrünblau<br>China green blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#437177" data-brightness="103.356">
       RAL 210 40 20
       <div class="subtext">Dämmerblau<br>Dusk blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#347178" data-brightness="99.004">
       RAL 210 40 25
       <div class="subtext">Hosenblau<br>Trouser blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1E737D" data-brightness="89.436">
       RAL 210 40 30
       <div class="subtext">Indigoblau<br>Indigo blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007681" data-brightness="86.657">
       RAL 210 40 35
       <div class="subtext">Kaltblau<br>Cold blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007481" data-brightness="86.657">
       RAL 210 40 38
       <div class="subtext">Marienblau<br>Mary blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6B8386" data-brightness="124.332">
       RAL 210 50 10
       <div class="subtext">Uniformgrüngrau<br>Uniform green grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#628488" data-brightness="124.62">
       RAL 210 50 15
       <div class="subtext">Labradoritgrün<br>Labradorite green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#54868D" data-brightness="120.268">
       RAL 210 50 20
       <div class="subtext">Emailleantikgrün<br>Enamel antique green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#458990" data-brightness="117.788">
       RAL 210 50 25
       <div class="subtext">Cranachblau<br>Cranach blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2D8A94" data-brightness="108.22">
       RAL 210 50 30
       <div class="subtext">Chinablau<br>China blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#148C98" data-brightness="103.004">
       RAL 210 50 35
       <div class="subtext">Gletscherblau<br>Glacier blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#008E9B" data-brightness="98.353">
       RAL 210 50 40
       <div class="subtext">Curaçaoblau<br>Curaï¿½ao blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#008F9E" data-brightness="98.353">
       RAL 210 50 45
       <div class="subtext">Atlantikblau<br>Atlantic blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#829A9D" data-brightness="146.316">
       RAL 210 60 10
       <div class="subtext">Aschblau<br>Ash blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#769CA0" data-brightness="143.836">
       RAL 210 60 15
       <div class="subtext">Baltikblau<br>Baltic blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6BA1A7" data-brightness="148.444">
       RAL 210 60 20
       <div class="subtext">Strandblau<br>Beach blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5AA4AB" data-brightness="143.66">
       RAL 210 60 25
       <div class="subtext">Basilikablau<br>Basilica blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#48A5AF" data-brightness="138.876">
       RAL 210 60 30
       <div class="subtext">Donaublau<br>Danube blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#2CA5B1" data-brightness="131.612">
       RAL 210 60 35
       <div class="subtext">Altdorferhimmelblau<br>Altdorf sky blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00A8B5" data-brightness="121.745">
       RAL 210 60 40
       <div class="subtext">Grellblau<br>Garish blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9BB4B7" data-brightness="174.492">
       RAL 210 70 10
       <div class="subtext">Sturmgrau<br>Storm grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8FB6BA" data-brightness="169.708">
       RAL 210 70 15
       <div class="subtext">Persischblau<br>Persian blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#81B9BF" data-brightness="169.708">
       RAL 210 70 20
       <div class="subtext">Aquablau<br>Aqua blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#73BBC3" data-brightness="166.065">
       RAL 210 70 25
       <div class="subtext">Maritimbleu<br>Maritime soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#61BEC8" data-brightness="162.444">
       RAL 210 70 30
       <div class="subtext">Bergblau<br>Mountain blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4CC0CB" data-brightness="162.268">
       RAL 210 70 35
       <div class="subtext">Weitsichtblau<br>Panorama blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B7D0D1" data-brightness="207.452">
       RAL 210 80 10
       <div class="subtext">Türkisgrau<br>Turquoise grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AAD2D4" data-brightness="202.369">
       RAL 210 80 15
       <div class="subtext">Delphinblau<br>Dolphin blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CD3D8" data-brightness="197.884">
       RAL 210 80 20
       <div class="subtext">Duftblau<br>Aroma blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8FD6DD" data-brightness="192.668">
       RAL 210 80 25
       <div class="subtext">Knallbleu<br>Vibrant soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9D8D9" data-brightness="211.66">
       RAL 210 85 05
       <div class="subtext">Siestaweiß<br>Siesta white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BADDDE" data-brightness="206.865">
       RAL 210 85 10
       <div class="subtext">Mintblau<br>Mint blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B5DEDF" data-brightness="207.452">
       RAL 210 85 15
       <div class="subtext">Feinblau<br>Fine blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7E0E1" data-brightness="214.364">
       RAL 210 85 20
       <div class="subtext">Blassblau<br>Pale blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAE7E5" data-brightness="228.716">
       RAL 210 90 05
       <div class="subtext">Tüllbleu<br>Tulle soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDE9E7" data-brightness="223.932">
       RAL 210 90 10
       <div class="subtext">Antarktisblau<br>Antarctic blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#43494B" data-brightness="68.7">
       RAL 220 20 05
       <div class="subtext">Navyschwarz<br>Navy black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C4A4E" data-brightness="63.916">
       RAL 220 20 10
       <div class="subtext">Indigokarmin<br>Indigo carmine</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#344A50" data-brightness="66.22">
       RAL 220 20 15
       <div class="subtext">Künstlerblau<br>Artist blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2D4B53" data-brightness="61.436">
       RAL 220 20 20
       <div class="subtext">Naturindigo<br>Natural indigo</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#51595A" data-brightness="85.18">
       RAL 220 30 05
       <div class="subtext">Pandaschwarz<br>Panda black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A5B5E" data-brightness="80.396">
       RAL 220 30 10
       <div class="subtext">Pallasitblau<br>Pallasite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#435D64" data-brightness="81.98">
       RAL 220 30 15
       <div class="subtext">Orkangrünblau<br>Hurricane green blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#385E66" data-brightness="77.772">
       RAL 220 30 20
       <div class="subtext">Aquarellblau<br>Watercolour blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2E606B" data-brightness="82.524">
       RAL 220 30 25
       <div class="subtext">Korallenblau<br>Coral blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#155F6D" data-brightness="68.348">
       RAL 220 30 30
       <div class="subtext">Sandelholzblau<br>Sandalwood grey blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#626C6E" data-brightness="101.66">
       RAL 220 40 05
       <div class="subtext">Limousinengraublau<br>Limousine grey blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A6E72" data-brightness="99.18">
       RAL 220 40 10
       <div class="subtext">Korundblau<br>Corundum blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F6F77" data-brightness="93.078">
       RAL 220 40 15
       <div class="subtext">Kittelblau<br>Smock blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#45717B" data-brightness="103.788">
       RAL 220 40 20
       <div class="subtext">Beizeblau<br>Mordant blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#37737F" data-brightness="97.243">
       RAL 220 40 25
       <div class="subtext">Baumwollindigo<br>Cotton indigo</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1F7586" data-brightness="91.74">
       RAL 220 40 30
       <div class="subtext">Fjordblau<br>Fjord blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007689" data-brightness="86.657">
       RAL 220 40 35
       <div class="subtext">Azurgrünblau<br>Azure green blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00788E" data-brightness="86.657">
       RAL 220 40 40
       <div class="subtext">Cyanblau<br>Cyan blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#788284" data-brightness="129.836">
       RAL 220 50 05
       <div class="subtext">Winterwolkengrau<br>Winter cloud grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F8388" data-brightness="124.62">
       RAL 220 50 10
       <div class="subtext">Kadettgrau<br>Cadet grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#62858D" data-brightness="125.052">
       RAL 220 50 15
       <div class="subtext">Holbeinblaugrau<br>Holbein blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#558791" data-brightness="122.273">
       RAL 220 50 20
       <div class="subtext">Schattenblau<br>Shady blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#488895" data-brightness="116.614">
       RAL 220 50 25
       <div class="subtext">Frescoblau<br>Fresco blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#318899" data-brightness="112.273">
       RAL 220 50 30
       <div class="subtext">Handwerkerblau<br>Craftsman blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#198CA0" data-brightness="105.74">
       RAL 220 50 35
       <div class="subtext">Markerblau<br>Marker blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#008EA5" data-brightness="100.657">
       RAL 220 50 40
       <div class="subtext">Spielzeugblau<br>Toy blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8E989A" data-brightness="144.555">
       RAL 220 60 05
       <div class="subtext">Tiefseegrau<br>Deep sea grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#839BA0" data-brightness="148.62">
       RAL 220 60 10
       <div class="subtext">Kachelblau<br>Tile blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#799DA5" data-brightness="143.836">
       RAL 220 60 15
       <div class="subtext">Morgenblau<br>Morning blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#699FAB" data-brightness="139.052">
       RAL 220 60 20
       <div class="subtext">Frostblau<br>Frost blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5BA1B0" data-brightness="145.964">
       RAL 220 60 25
       <div class="subtext">Sommertürkisblau<br>Summer turquoise blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4AA4B5" data-brightness="139.419">
       RAL 220 60 30
       <div class="subtext">Fischerblau<br>Fischer blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#36A3B8" data-brightness="134.635">
       RAL 220 60 35
       <div class="subtext">Planetblau<br>Planet blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00A5BD" data-brightness="121.745">
       RAL 220 60 40
       <div class="subtext">Januarblau<br>January blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6B2B5" data-brightness="179.276">
       RAL 220 70 05
       <div class="subtext">Temperiertgrau<br>Tempered grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9DB5BB" data-brightness="174.06">
       RAL 220 70 10
       <div class="subtext">Mohairbleugrau<br>Mohair soft blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#90B8C0" data-brightness="176.22">
       RAL 220 70 15
       <div class="subtext">Küchenblau<br>Kitchen blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#84B9C4" data-brightness="171.436">
       RAL 220 70 20
       <div class="subtext">Gastronomieblau<br>Culinary blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#72BAC9" data-brightness="167.228">
       RAL 220 70 25
       <div class="subtext">Blousonblau<br>Blouson blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#61BACC" data-brightness="162.3">
       RAL 220 70 30
       <div class="subtext">Gebirgsblau<br>Mountain range blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4BBFD4" data-brightness="154.46">
       RAL 220 70 35
       <div class="subtext">Ferienblau<br>Holiday blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFCCCD" data-brightness="195.169">
       RAL 220 80 05
       <div class="subtext">Pastellblau<br>Pastel blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B5D0D5" data-brightness="206.876">
       RAL 220 80 10
       <div class="subtext">Klinischbleu<br>Clinical soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A6CFD8" data-brightness="193.276">
       RAL 220 80 15
       <div class="subtext">Kinderbleu<br>Childrenï¿½s soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9ED4E0" data-brightness="200.188">
       RAL 220 80 20
       <div class="subtext">Turmalinwasserblau<br>Tourmaline water blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#92D7E3" data-brightness="200.188">
       RAL 220 80 25
       <div class="subtext">Frischblau<br>Fresh blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCD9DC" data-brightness="211.217">
       RAL 220 85 05
       <div class="subtext">Transparentbleu<br>Transparent blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0DCE1" data-brightness="212.779">
       RAL 220 85 10
       <div class="subtext">Eisscherbenbleu<br>Ice shard soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B3DEE6" data-brightness="209.756">
       RAL 220 85 15
       <div class="subtext">Polarblau<br>Polar blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAE7E6" data-brightness="228.716">
       RAL 220 90 05
       <div class="subtext">Kaltlichtbleu<br>Cold soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDE7E7" data-brightness="223.644">
       RAL 220 90 10
       <div class="subtext">Vollmondgrau<br>Full moon grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3F4B50" data-brightness="66.22">
       RAL 230 20 10
       <div class="subtext">Erzblauschwarz<br>Ore bluish black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#384B53" data-brightness="65.5">
       RAL 230 20 15
       <div class="subtext">Firmamentblau<br>Firmanent blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2D4955" data-brightness="61.292">
       RAL 230 20 20
       <div class="subtext">Preußischblau<br>Prussian blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D5B61" data-brightness="82.7">
       RAL 230 30 10
       <div class="subtext">Galenitblau<br>Galenite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#435B65" data-brightness="82.268">
       RAL 230 30 15
       <div class="subtext">Beerenblaugrün<br>Berry blue green</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3A5D6A" data-brightness="77.34">
       RAL 230 30 20
       <div class="subtext">Petroldunkelblau<br>Teal dark blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2D5E6F" data-brightness="73.132">
       RAL 230 30 25
       <div class="subtext">Gewitterblau<br>Thunderstorm blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B6D74" data-brightness="99.18">
       RAL 230 40 10
       <div class="subtext">Zapfengrünblau<br>Cone green blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#536E79" data-brightness="99.18">
       RAL 230 40 15
       <div class="subtext">Wacholderbeerenblau<br>Juniper berry blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#48717F" data-brightness="103.788">
       RAL 230 40 20
       <div class="subtext">Sterntauchertürkis<br>Loon turquoise</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#387184" data-brightness="101.308">
       RAL 230 40 25
       <div class="subtext">Werkstattblau<br>Workshop blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2C738A" data-brightness="96.524">
       RAL 230 40 30
       <div class="subtext">Pilotenblau<br>Pilot blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007590" data-brightness="88.241">
       RAL 230 40 35
       <div class="subtext">Tintenblau<br>Ink blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007593" data-brightness="88.961">
       RAL 230 40 40
       <div class="subtext">Technoblau<br>Techno blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F838A" data-brightness="125.052">
       RAL 230 50 10
       <div class="subtext">Kiesgraublau<br>Gravel grey blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#658690" data-brightness="125.595">
       RAL 230 50 15
       <div class="subtext">Fichtenblau<br>Spruce blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A8797" data-brightness="122.284">
       RAL 230 50 20
       <div class="subtext">Freizeitblau<br>Casual blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D889B" data-brightness="117.201">
       RAL 230 50 25
       <div class="subtext">Vergissmeinnichtblau<br>Forget-me-not blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#378BA3" data-brightness="114.588">
       RAL 230 50 30
       <div class="subtext">Zirkonblau<br>Zircon blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1F8BA6" data-brightness="105.74">
       RAL 230 50 35
       <div class="subtext">Lagunenblau<br>Lagoon blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#008CAD" data-brightness="100.657">
       RAL 230 50 40
       <div class="subtext">Azorenblau<br>Azores blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#869CA4" data-brightness="148.62">
       RAL 230 60 10
       <div class="subtext">Eisvogelgrau<br>Kingfisher grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7B9CA7" data-brightness="143.116">
       RAL 230 60 15
       <div class="subtext">Silbertannenblau<br>Silver fir blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6E9EAE" data-brightness="137.302">
       RAL 230 60 20
       <div class="subtext">Pyjamablau<br>Pyjama blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5E9FB4" data-brightness="136.572">
       RAL 230 60 25
       <div class="subtext">Sternblütenblau<br>Starflower blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4FA0B9" data-brightness="141.18">
       RAL 230 60 30
       <div class="subtext">Sportblau<br>Sports blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#37A3C1" data-brightness="136.939">
       RAL 230 60 35
       <div class="subtext">Griechischblau<br>Greek blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00A4C7" data-brightness="124.049">
       RAL 230 60 40
       <div class="subtext">Alpenblau<br>Alpine blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A0B6BE" data-brightness="179.276">
       RAL 230 70 10
       <div class="subtext">Eismövengraublau<br>Ice gull grey blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#91B6C2" data-brightness="176.796">
       RAL 230 70 15
       <div class="subtext">Distelblütenbleu<br>Thistleblossom soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#85B8C9" data-brightness="170.251">
       RAL 230 70 20
       <div class="subtext">Tennisblau<br>Tennis blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7ABACF" data-brightness="166.054">
       RAL 230 70 25
       <div class="subtext">Oldtimerblau<br>Vintage blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#68BBD4" data-brightness="164.161">
       RAL 230 70 30
       <div class="subtext">Fitnessblau<br>Fitness blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B7CFD6" data-brightness="198.06">
       RAL 230 80 10
       <div class="subtext">Bleichblau<br>Pallid blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABCEDA" data-brightness="192.556">
       RAL 230 80 15
       <div class="subtext">Alpenmorgenblau<br>Alpine morning blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1D2E2" data-brightness="204.684">
       RAL 230 80 20
       <div class="subtext">Hellblau<br>Light blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CED9DD" data-brightness="211.804">
       RAL 230 85 05
       <div class="subtext">Polarkaltbleu<br>Polar soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C1D9E1" data-brightness="213.964">
       RAL 230 85 10
       <div class="subtext">Waschpulverweiß<br>Washing powder white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8DCE7" data-brightness="209.756">
       RAL 230 85 15
       <div class="subtext">Himalayaweißblau<br>Himalaya white blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABDEEE" data-brightness="204.684">
       RAL 230 85 20
       <div class="subtext">Atmosphärenbleu<br>Atmospheric soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBE6E6" data-brightness="228.428">
       RAL 230 90 05
       <div class="subtext">Strahlendweiß<br>Brilliant white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#454A4D" data-brightness="68.7">
       RAL 240 20 05
       <div class="subtext">Nachtschattenblau<br>Nightshade blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3F4A51" data-brightness="66.22">
       RAL 240 20 10
       <div class="subtext">Holunderschwarz<br>Elderberry black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#394A55" data-brightness="66.076">
       RAL 240 20 15
       <div class="subtext">Metallblau<br>Metal blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#334B59" data-brightness="65.921">
       RAL 240 20 20
       <div class="subtext">Krähenbeerenblau<br>Crowberry blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#294A5B" data-brightness="61.436">
       RAL 240 20 22
       <div class="subtext">Sattblau<br>Rich blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#52595C" data-brightness="85.18">
       RAL 240 30 05
       <div class="subtext">Diamantgrau<br>Diamond grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4C5A62" data-brightness="82.7">
       RAL 240 30 10
       <div class="subtext">Schmutzblau<br>Dirty blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#455B67" data-brightness="82.7">
       RAL 240 30 15
       <div class="subtext">Tiefseeblau<br>Deep sea blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C5C6C" data-brightness="76.166">
       RAL 240 30 20
       <div class="subtext">Lasuritblau<br>Lasurite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F5C72" data-brightness="74.716">
       RAL 240 30 25
       <div class="subtext">Arbeitsblau<br>Work blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#215D77" data-brightness="75.292">
       RAL 240 30 30
       <div class="subtext">Schmucksteinblau<br>Gemstone blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#065F7C" data-brightness="65.868">
       RAL 240 30 35
       <div class="subtext">Kapitänsblau<br>Captain blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#646B70" data-brightness="103.964">
       RAL 240 40 05
       <div class="subtext">Blauanthrazit<br>Blue anthracite</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C6D76" data-brightness="98.748">
       RAL 240 40 10
       <div class="subtext">Schwarzdornblau<br>Blackthorn blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#536E7D" data-brightness="99.18">
       RAL 240 40 15
       <div class="subtext">Celesteblau<br>Celeste blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#486F82" data-brightness="96.7">
       RAL 240 40 20
       <div class="subtext">Jeansblau<br>Denim blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C708A" data-brightness="101.308">
       RAL 240 40 25
       <div class="subtext">Orientblau<br>Orient blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#28718F" data-brightness="96.524">
       RAL 240 40 30
       <div class="subtext">Matrosenblau<br>Sailor blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#007295" data-brightness="88.961">
       RAL 240 40 35
       <div class="subtext">Steinblau<br>Stone blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00749A" data-brightness="88.961">
       RAL 240 40 40
       <div class="subtext">Reinbleu<br>Pure light blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#798186" data-brightness="129.836">
       RAL 240 50 05
       <div class="subtext">Rauchblau<br>Smoky blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F828C" data-brightness="125.052">
       RAL 240 50 10
       <div class="subtext">Werkzeugblau<br>Tool blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#668494" data-brightness="126.769">
       RAL 240 50 15
       <div class="subtext">Technikblau<br>Technical blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A859A" data-brightness="120.235">
       RAL 240 50 20
       <div class="subtext">Coelinblau<br>Coelin blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4C86A0" data-brightness="120.092">
       RAL 240 50 25
       <div class="subtext">Grönlandblau<br>Greenland blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3C87A6" data-brightness="115.308">
       RAL 240 50 30
       <div class="subtext">Sommerblau<br>Summer blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1F88AD" data-brightness="105.153">
       RAL 240 50 35
       <div class="subtext">Werbeblau<br>Advertising blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#0088B2" data-brightness="102.374">
       RAL 240 50 40
       <div class="subtext">Tuschblau<br>Indian inc blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8F999E" data-brightness="145.729">
       RAL 240 60 05
       <div class="subtext">Brieftaubenblau<br>Carrier pigeon blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8297A2" data-brightness="148.044">
       RAL 240 60 10
       <div class="subtext">Vogelblau<br>Bird blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7A9AAA" data-brightness="142.086">
       RAL 240 60 15
       <div class="subtext">Architekturblau<br>Architecture blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#709DB3" data-brightness="146.14">
       RAL 240 60 20
       <div class="subtext">Hemdblau<br>Shirt blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#639EBA" data-brightness="141.356">
       RAL 240 60 25
       <div class="subtext">Stahlhellblau<br>Steel light blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#519FC0" data-brightness="138.876">
       RAL 240 60 30
       <div class="subtext">Märchenblau<br>Fairytale blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#3CA0C8" data-brightness="138.7">
       RAL 240 60 35
       <div class="subtext">Prinzessblau<br>Princess blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1BA3CB" data-brightness="128.556">
       RAL 240 60 40
       <div class="subtext">Klarblau<br>Clear blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7B1B7" data-brightness="178.7">
       RAL 240 70 05
       <div class="subtext">Wäscheblau<br>Laundry blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9EB2BD" data-brightness="174.492">
       RAL 240 70 10
       <div class="subtext">Augenblau<br>Eye blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#93B5C5" data-brightness="176.508">
       RAL 240 70 15
       <div class="subtext">Bermudablau<br>Bermuda blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#88B5CB" data-brightness="171.281">
       RAL 240 70 20
       <div class="subtext">Meerblau<br>Sea blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7BB8D3" data-brightness="169.532">
       RAL 240 70 25
       <div class="subtext">Madonnablau<br>Madonna blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6BB8D8" data-brightness="164.46">
       RAL 240 70 30
       <div class="subtext">Karibikblau<br>Caribbean blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFCACF" data-brightness="195.18">
       RAL 240 80 05
       <div class="subtext">Dunstblau<br>Hazy blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9CFD9" data-brightness="197.484">
       RAL 240 80 10
       <div class="subtext">Topashellbleu<br>Light topaz soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AECFDE" data-brightness="192.7">
       RAL 240 80 15
       <div class="subtext">Babyblau<br>Baby blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1D1E6" data-brightness="203.798">
       RAL 240 80 20
       <div class="subtext">Kristallblau<br>Crystal blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDD7DD" data-brightness="211.66">
       RAL 240 85 05
       <div class="subtext">Eisgrau<br>Ice grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C2D8E3" data-brightness="214.54">
       RAL 240 85 10
       <div class="subtext">Luftblau<br>Air blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B7DBEB" data-brightness="207.275">
       RAL 240 85 15
       <div class="subtext">Pariserblau<br>Paris blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DCE7E9" data-brightness="228.716">
       RAL 240 90 05
       <div class="subtext">Bleuweiß<br>Soft blue white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CFE8EF" data-brightness="223.356">
       RAL 240 90 10
       <div class="subtext">Kalkbleu<br>Chalky blue white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#334A5B" data-brightness="65.921">
       RAL 250 20 20
       <div class="subtext">Kosmosblau<br>Cosmos blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F4A5F" data-brightness="60.86">
       RAL 250 20 25
       <div class="subtext">Dunkeljeansblau<br>Dark denim blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#455968" data-brightness="82.7">
       RAL 250 30 15
       <div class="subtext">Schwarzwaldblau<br>Black Forest blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3B5A6E" data-brightness="77.916">
       RAL 250 30 20
       <div class="subtext">Schwalbenblau<br>Swallow blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#335B74" data-brightness="79.921">
       RAL 250 30 25
       <div class="subtext">Marinedunkelblau<br>Navy dark blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#235B7B" data-brightness="75.148">
       RAL 250 30 30
       <div class="subtext">Pompejiblau<br>Pompeii blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5E6C77" data-brightness="99.036">
       RAL 250 40 10
       <div class="subtext">Trekkingblau<br>Trekking blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#556C7D" data-brightness="98.881">
       RAL 250 40 15
       <div class="subtext">Stahlblaugrau<br>Steel blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A6C83" data-brightness="96.7">
       RAL 250 40 20
       <div class="subtext">Beerenblau<br>Berry blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3F6E8B" data-brightness="91.916">
       RAL 250 40 25
       <div class="subtext">Leinenblau<br>Linen blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#336F93" data-brightness="93.201">
       RAL 250 40 30
       <div class="subtext">Poolblau<br>Pool blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1A6F97" data-brightness="84.652">
       RAL 250 40 35
       <div class="subtext">Eisvogelblau<br>Kingfisher blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#00719C" data-brightness="88.961">
       RAL 250 40 40
       <div class="subtext">Europablau<br>Europe blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#72818D" data-brightness="129.836">
       RAL 250 50 10
       <div class="subtext">Metallgrau<br>Metal grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#688194" data-brightness="127.356">
       RAL 250 50 15
       <div class="subtext">Titanblau<br>Titanium blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#60839B" data-brightness="127.356">
       RAL 250 50 20
       <div class="subtext">Mallorcablau<br>Mallorca blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5583A3" data-brightness="124.289">
       RAL 250 50 25
       <div class="subtext">Linoleumblau<br>Linoleum blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4684AB" data-brightness="118.331">
       RAL 250 50 30
       <div class="subtext">Prominentblau<br>Prominent blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3386B2" data-brightness="117.313">
       RAL 250 50 35
       <div class="subtext">Türkischblau<br>Turkish blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#0487B9" data-brightness="103.26">
       RAL 250 50 40
       <div class="subtext">Urblau<br>Primal blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8798A5" data-brightness="146.859">
       RAL 250 60 10
       <div class="subtext">Vogelblaugrau<br>Bird blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7F99AD" data-brightness="143.249">
       RAL 250 60 15
       <div class="subtext">Sanftblau<br>Gentle blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#749AB4" data-brightness="145.564">
       RAL 250 60 20
       <div class="subtext">Aragonitblau<br>Aragonite blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#699BBC" data-brightness="141.356">
       RAL 250 60 25
       <div class="subtext">Perlblau<br>Pearl blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5C9DC3" data-brightness="138.876">
       RAL 250 60 30
       <div class="subtext">Topasblau<br>Topaz blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#4D9DCA" data-brightness="132.918">
       RAL 250 60 35
       <div class="subtext">Stratosblau<br>Stratos blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#3A9FD3" data-brightness="130.892">
       RAL 250 60 40
       <div class="subtext">Bauwerkblau<br>Structural blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9FB2BE" data-brightness="174.492">
       RAL 250 70 10
       <div class="subtext">Nebelblau<br>Foggy blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#97B3C7" data-brightness="176.22">
       RAL 250 70 15
       <div class="subtext">Kreideblau<br>Chalk blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8CB3CE" data-brightness="172.012">
       RAL 250 70 20
       <div class="subtext">Mittelblau<br>Medium blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#82B5D5" data-brightness="174.028">
       RAL 250 70 25
       <div class="subtext">Wiesenblütenblau<br>Meadow blossom blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#75B5DC" data-brightness="168.358">
       RAL 250 70 30
       <div class="subtext">Ginsterfalterblau<br>Broom butterfly blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9CBD8" data-brightness="196.299">
       RAL 250 80 10
       <div class="subtext">Hellcyan<br>Light cyan</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B2CEE0" data-brightness="200.364">
       RAL 250 80 15
       <div class="subtext">Himalayablau<br>Himalaya blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A4CBE5" data-brightness="195.58">
       RAL 250 80 20
       <div class="subtext">Samtblau<br>Velvet blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCD4DA" data-brightness="211.937">
       RAL 250 85 05
       <div class="subtext">Weißblau<br>White blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C2D5E2" data-brightness="213.964">
       RAL 250 85 10
       <div class="subtext">Waschmittelbleu<br>Washing powder soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BCD8EA" data-brightness="209.756">
       RAL 250 85 15
       <div class="subtext">Diamantbleu<br>Diamond soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBE3E7" data-brightness="228.716">
       RAL 250 90 05
       <div class="subtext">Eisweiß<br>Ice white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#474A4E" data-brightness="68.7">
       RAL 260 20 05
       <div class="subtext">Blauschwarz<br>Blue-black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#424952" data-brightness="70.428">
       RAL 260 20 10
       <div class="subtext">Natoblau<br>Nato blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3B4856" data-brightness="66.22">
       RAL 260 20 15
       <div class="subtext">Anzugblau<br>Suit blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#384A5C" data-brightness="66.22">
       RAL 260 20 20
       <div class="subtext">Halitblau<br>Halite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#54595E" data-brightness="85.18">
       RAL 260 30 05
       <div class="subtext">Bergentengrau<br>Alpine duck grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#505A65" data-brightness="86.764">
       RAL 260 30 10
       <div class="subtext">Bronzeblau<br>Bronze blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A596A" data-brightness="82.124">
       RAL 260 30 15
       <div class="subtext">Berlinerblau<br>Berlin blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#445A70" data-brightness="84.705">
       RAL 260 30 20
       <div class="subtext">Frankreichblau<br>French blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3A5A75" data-brightness="78.614">
       RAL 260 30 25
       <div class="subtext">Tansanitblau<br>Tanzanite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#345B7D" data-brightness="80.22">
       RAL 260 30 30
       <div class="subtext">Opalblau<br>Opal blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1E5B85" data-brightness="72.524">
       RAL 260 30 35
       <div class="subtext">Königsblau<br>Royal blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#666A70" data-brightness="103.665">
       RAL 260 40 05
       <div class="subtext">Schwedenblau<br>Swedish blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#626D79" data-brightness="103.964">
       RAL 260 40 10
       <div class="subtext">Schotenblau<br>Sheet blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C6D80" data-brightness="101.484">
       RAL 260 40 15
       <div class="subtext">Zwetschgenblau<br>Plum blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#526C85" data-brightness="100.764">
       RAL 260 40 20
       <div class="subtext">Kadettblau<br>Cadet blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4B6E8C" data-brightness="96.7">
       RAL 260 40 25
       <div class="subtext">Emailleblau<br>Enamel blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#426E92" data-brightness="98.428">
       RAL 260 40 30
       <div class="subtext">Kopenhagenerblau<br>Copenhagen blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#356E99" data-brightness="94.076">
       RAL 260 40 35
       <div class="subtext">Bauhausblau<br>Bauhaus blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1E70A3" data-brightness="96.348">
       RAL 260 40 40
       <div class="subtext">Marineblau<br>Navy blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#0070A9" data-brightness="89.504">
       RAL 260 40 45
       <div class="subtext">LEDblau<br>LED blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#797E85" data-brightness="120.444">
       RAL 260 50 05
       <div class="subtext">Spitzbergenblau<br>Spitsbergen blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#75808D" data-brightness="129.836">
       RAL 260 50 10
       <div class="subtext">Zypressengraublau<br>Cypress grey blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E8194" data-brightness="127.356">
       RAL 260 50 15
       <div class="subtext">Mahonienbeerenblau<br>Mahonia berry blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A849D" data-brightness="127.356">
       RAL 260 50 20
       <div class="subtext">Kröpperblau<br>Doves blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6084A6" data-brightness="128.94">
       RAL 260 50 25
       <div class="subtext">Biedermeierblau<br>Biedermeier blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5485AF" data-brightness="123.115">
       RAL 260 50 30
       <div class="subtext">Pekingblau<br>Beijing blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4784B3" data-brightness="120.635">
       RAL 260 50 35
       <div class="subtext">Vermeerblau<br>Vermeer blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3586BD" data-brightness="117.612">
       RAL 260 50 40
       <div class="subtext">Porzellanblau<br>Meissen blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#91979E" data-brightness="151.1">
       RAL 260 60 05
       <div class="subtext">Seehundgrau<br>Seal grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8B97A5" data-brightness="148.62">
       RAL 260 60 10
       <div class="subtext">Diopsidblau<br>Diopside blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#879BB0" data-brightness="150.924">
       RAL 260 60 15
       <div class="subtext">Nordlandblau<br>Nordland blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7E9AB5" data-brightness="146.14">
       RAL 260 60 20
       <div class="subtext">Moorbeerenblau<br>Cranberry blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#759BBE" data-brightness="146.14">
       RAL 260 60 25
       <div class="subtext">Lupinenblau<br>Lupine blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6B9CC6" data-brightness="142.94">
       RAL 260 60 30
       <div class="subtext">Sadebeerenbleu<br>Blueberry soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#5F9DCD" data-brightness="138.588">
       RAL 260 60 35
       <div class="subtext">Leuchtendblau<br>Brilliant blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABB1B8" data-brightness="179.276">
       RAL 260 70 05
       <div class="subtext">Nordlichtgrau<br>Northern light grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A5B2C0" data-brightness="181.58">
       RAL 260 70 10
       <div class="subtext">Sturmvogelblaugrau<br>Petrel blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CB2C8" data-brightness="176.796">
       RAL 260 70 15
       <div class="subtext">Nordlandhellblau<br>Nordland light blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#96B4D0" data-brightness="179.1">
       RAL 260 70 20
       <div class="subtext">Mattblau<br>Matte blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8CB4D8" data-brightness="173.596">
       RAL 260 70 25
       <div class="subtext">Pazifikblau<br>Pacific blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3CAD1" data-brightness="202.844">
       RAL 260 80 05
       <div class="subtext">Lichtblaugrau<br>Light blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BDCBD9" data-brightness="196.299">
       RAL 260 80 10
       <div class="subtext">Puderbleu<br>Powder soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B5CCE0" data-brightness="199.777">
       RAL 260 80 15
       <div class="subtext">Windblau<br>Wind blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDD3D9" data-brightness="212.236">
       RAL 260 85 05
       <div class="subtext">Satinweiß<br>Satin white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C8D7E5" data-brightness="214.54">
       RAL 260 85 10
       <div class="subtext">Meeresbrisengrün<br>Sea breeze green</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDE3E7" data-brightness="228.417">
       RAL 260 90 05
       <div class="subtext">Milchblau<br>Milk blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3A475C" data-brightness="66.22">
       RAL 270 20 20
       <div class="subtext">Krähenschwarzblau<br>Crow black blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#344660" data-brightness="68.524">
       RAL 270 20 25
       <div class="subtext">Indigoschwarz<br>Indigo black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2E4667" data-brightness="63.74">
       RAL 270 20 29
       <div class="subtext">Kraftblau<br>Effervescent blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4C5768" data-brightness="82.7">
       RAL 270 30 15
       <div class="subtext">Mitternachtsblau<br>Midnight blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#47576F" data-brightness="81.526">
       RAL 270 30 20
       <div class="subtext">Dunkelblau<br>Dark blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#415776" data-brightness="85.004">
       RAL 270 30 25
       <div class="subtext">Traubenblau<br>Grape blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#39567C" data-brightness="80.22">
       RAL 270 30 30
       <div class="subtext">Mediciblau<br>Medici blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#2F5682" data-brightness="77.02">
       RAL 270 30 35
       <div class="subtext">Kolibriblau<br>Kolibri blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#1F568A" data-brightness="72.956">
       RAL 270 30 40
       <div class="subtext">Prinzenblau<br>Prince blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F6B7F" data-brightness="98.604">
       RAL 270 40 15
       <div class="subtext">Zinngrau<br>Pewter grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#576A85" data-brightness="100.764">
       RAL 270 40 20
       <div class="subtext">Salonblau<br>Parlour blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#536B8D" data-brightness="101.484">
       RAL 270 40 25
       <div class="subtext">Funktionsblau<br>Functional blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A6A94" data-brightness="97.11">
       RAL 270 40 30
       <div class="subtext">Schminkblau<br>Make-up blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#406A9B" data-brightness="99.004">
       RAL 270 40 35
       <div class="subtext">Lapislazuliblau<br>Lapis lazuli blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3469A2" data-brightness="96.524">
       RAL 270 40 40
       <div class="subtext">Zauberblau<br>Magic blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#77808F" data-brightness="129.537">
       RAL 270 50 10
       <div class="subtext">Brokatblau<br>Brocade blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#738197" data-brightness="131.42">
       RAL 270 50 15
       <div class="subtext">Edelblau<br>Noble blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6D819E" data-brightness="127.356">
       RAL 270 50 20
       <div class="subtext">Delfterblau<br>Delft blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6780A6" data-brightness="128.94">
       RAL 270 50 25
       <div class="subtext">Cocktailblau<br>Cocktail blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5E80AD" data-brightness="124.876">
       RAL 270 50 30
       <div class="subtext">Salbeiblütenblau<br>Sage blossom blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#557FB2" data-brightness="117.489">
       RAL 270 50 35
       <div class="subtext">Wienerblau<br>Viennese blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A81BD" data-brightness="122.396">
       RAL 270 50 40
       <div class="subtext">Kornblumenblau<br>Cornflower blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8C97A7" data-brightness="148.332">
       RAL 270 60 10
       <div class="subtext">Fantasiegrau<br>Fantasy grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8998B0" data-brightness="149.163">
       RAL 270 60 15
       <div class="subtext">Fliederblau<br>Lilac blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8398B7" data-brightness="149.163">
       RAL 270 60 20
       <div class="subtext">Lavendelblau<br>Lavender blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7D98BF" data-brightness="146.14">
       RAL 270 60 25
       <div class="subtext">Ritterspornblau<br>Delphinium blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7699C8" data-brightness="147.857">
       RAL 270 60 30
       <div class="subtext">Lidschattenblau<br>Eyeshadow blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6B97CE" data-brightness="143.66">
       RAL 270 60 35
       <div class="subtext">Argusfalterblau<br>Butterfly blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7B1C1" data-brightness="181.292">
       RAL 270 70 10
       <div class="subtext">Federbleu<br>Feather soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1B1C9" data-brightness="180.406">
       RAL 270 70 15
       <div class="subtext">Satinbleu<br>Satin soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9CB2D2" data-brightness="178.812">
       RAL 270 70 20
       <div class="subtext">Engelblau<br>Angel blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#93AFD8" data-brightness="169.708">
       RAL 270 70 25
       <div class="subtext">Knabenblau<br>Boy blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C1CAD9" data-brightness="202.844">
       RAL 270 80 10
       <div class="subtext">Perlhellbleu<br>Light pearl soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9C9E1" data-brightness="199.19">
       RAL 270 80 15
       <div class="subtext">Retinableu<br>Retina soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1D4DA" data-brightness="217.02">
       RAL 270 85 05
       <div class="subtext">Engelshaarsilber<br>Angel hair silver</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAD4E4" data-brightness="214.252">
       RAL 270 85 10
       <div class="subtext">Perlmuttsilber<br>Mother-of-pearl silver</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C4D4EC" data-brightness="212.646">
       RAL 270 85 15
       <div class="subtext">Muranobleu<br>Murano soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0E3E8" data-brightness="233.5">
       RAL 270 90 05
       <div class="subtext">Bleichweiß<br>Bleached white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A4A4E" data-brightness="67.526">
       RAL 280 20 05
       <div class="subtext">Granitschwarz<br>Granite black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#474A53" data-brightness="71.004">
       RAL 280 20 10
       <div class="subtext">Diamantschwarz<br>Diamond black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#444958" data-brightness="70.705">
       RAL 280 20 15
       <div class="subtext">Krähenschwarz<br>Crow black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#41485B" data-brightness="71.004">
       RAL 280 20 20
       <div class="subtext">Manganschwarz<br>Manganese black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3F4862" data-brightness="68.524">
       RAL 280 20 25
       <div class="subtext">Elitenblau<br>Elite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3A4868" data-brightness="68.236">
       RAL 280 20 30
       <div class="subtext">Ambassadorblau<br>Ambassador blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#57585D" data-brightness="85.18">
       RAL 280 30 05
       <div class="subtext">Smokingnachtblau<br>Smoking night blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#555965" data-brightness="86.465">
       RAL 280 30 10
       <div class="subtext">Meteoritschwarzblau<br>Meteorite black blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#52596B" data-brightness="87.484">
       RAL 280 30 15
       <div class="subtext">Kohlesteinblau<br>Anthracite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F5972" data-brightness="85.004">
       RAL 280 30 20
       <div class="subtext">Granatsteinblau<br>Garnet stone blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4C5977" data-brightness="84.86">
       RAL 280 30 25
       <div class="subtext">Wasserstoffblau<br>Hydrogen blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#45577E" data-brightness="85.004">
       RAL 280 30 30
       <div class="subtext">Tiefblau<br>Deep blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#425783" data-brightness="87.308">
       RAL 280 30 35
       <div class="subtext">Theaterblau<br>Theatre blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3D588A" data-brightness="82.524">
       RAL 280 30 40
       <div class="subtext">Plakatblau<br>Poster blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#696B71" data-brightness="103.964">
       RAL 280 40 05
       <div class="subtext">Galeriegrau<br>Gallery grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#666A78" data-brightness="103.665">
       RAL 280 40 10
       <div class="subtext">Wildlederindigo<br>Suede indigo</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#636A7F" data-brightness="103.964">
       RAL 280 40 15
       <div class="subtext">Dunkellavendel<br>Dark lavender</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F6A86" data-brightness="101.052">
       RAL 280 40 20
       <div class="subtext">Sternenhimmelblau<br>Starry sky blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D6B8E" data-brightness="101.484">
       RAL 280 40 25
       <div class="subtext">Indigomarineblau<br>Indigo navy blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#586A94" data-brightness="103.788">
       RAL 280 40 30
       <div class="subtext">Abendblau<br>Evening blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#526B9B" data-brightness="103.5">
       RAL 280 40 35
       <div class="subtext">Portugalblau<br>Portuguese blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4A6AA3" data-brightness="100.134">
       RAL 280 40 40
       <div class="subtext">Neptunblau<br>Neptune blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4169AA" data-brightness="101.164">
       RAL 280 40 45
       <div class="subtext">Hyazinthenblau<br>Hyacinth blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D8087" data-brightness="129.116">
       RAL 280 50 05
       <div class="subtext">Nobelgrau<br>Noble grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B808F" data-brightness="129.836">
       RAL 280 50 10
       <div class="subtext">Eisblaugrau<br>Ice blue grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#788096" data-brightness="132.14">
       RAL 280 50 15
       <div class="subtext">Granitblau<br>Granite blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#747F9E" data-brightness="122.748">
       RAL 280 50 20
       <div class="subtext">Lavendellila<br>Lavender mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#717FA5" data-brightness="125.052">
       RAL 280 50 25
       <div class="subtext">Kostümblau<br>Costume blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C7FAD" data-brightness="120.268">
       RAL 280 50 30
       <div class="subtext">Helllila<br>Light mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#667EB3" data-brightness="122.273">
       RAL 280 50 35
       <div class="subtext">Saphirschimmerblau<br>Sapphire shimmer blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#6180BE" data-brightness="131.964">
       RAL 280 50 40
       <div class="subtext">Divenviolett<br>Diva violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#94979E" data-brightness="151.1">
       RAL 280 60 05
       <div class="subtext">Tüllgrau<br>Tulle grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9196A6" data-brightness="153.116">
       RAL 280 60 10
       <div class="subtext">Süßlavendel<br>Sweet lavender</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8E96AE" data-brightness="148.044">
       RAL 280 60 15
       <div class="subtext">Marmorblau<br>Marble blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8B96B7" data-brightness="150.924">
       RAL 280 60 20
       <div class="subtext">Nebelviolett<br>Misty violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8695BE" data-brightness="150.924">
       RAL 280 60 25
       <div class="subtext">Opalviolett<br>Opal violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8296C5" data-brightness="153.228">
       RAL 280 60 30
       <div class="subtext">Persischviolett<br>Persian violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#7E97CF" data-brightness="146.683">
       RAL 280 60 35
       <div class="subtext">Organzaviolett<br>Organza violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ACAEB6" data-brightness="169.884">
       RAL 280 70 05
       <div class="subtext">Taupegrau<br>Taupe grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABB0BF" data-brightness="179.276">
       RAL 280 70 10
       <div class="subtext">Tüllviolett<br>Tulle violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A8B0C8" data-brightness="181.004">
       RAL 280 70 15
       <div class="subtext">Sandglöckchenviola<br>Foxflower viola</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A3AFD1" data-brightness="174.492">
       RAL 280 70 20
       <div class="subtext">Fliederduftbleu<br>Lilac scent soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1B0DA" data-brightness="183.164">
       RAL 280 70 25
       <div class="subtext">Asterblütenblau<br>Aster flower blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C8CAD1" data-brightness="202.844">
       RAL 280 80 05
       <div class="subtext">Violaeisgrau<br>Viola ice grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C4C9D9" data-brightness="202.556">
       RAL 280 80 10
       <div class="subtext">Hyazinthenweißbleu<br>Hyacinth white soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFC8E0" data-brightness="200.364">
       RAL 280 80 15
       <div class="subtext">Veilchenduftbleu<br>Violet scent soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D2D5DB" data-brightness="217.02">
       RAL 280 85 05
       <div class="subtext">Kieselbleuweiß<br>Pebble soft blue white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDD4E4" data-brightness="214.252">
       RAL 280 85 10
       <div class="subtext">Schmuckweiß<br>Jewellery white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0E3E8" data-brightness="233.5">
       RAL 280 90 05
       <div class="subtext">Lilaweiß<br>Mauve white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8EAF0" data-brightness="235.804">
       RAL 280 93 05
       <div class="subtext">Porzellanweiß<br>Porcelain white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#454650" data-brightness="71.004">
       RAL 290 20 10
       <div class="subtext">Spinellsteinschwarz<br>Spinel stone black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#474655" data-brightness="70.86">
       RAL 290 20 15
       <div class="subtext">Schwarzviolett<br>Black violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#47485D" data-brightness="71.004">
       RAL 290 20 20
       <div class="subtext">Tropennachtblau<br>Tropical night blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#434661" data-brightness="73.308">
       RAL 290 20 25
       <div class="subtext">Indigoviolett<br>Indigo violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#404465" data-brightness="71.547">
       RAL 290 20 30
       <div class="subtext">Ultramarinviolett<br>Ultramarine violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#3E446C" data-brightness="67.937">
       RAL 290 20 35
       <div class="subtext">Trollingerblau<br>Schiava blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#565562" data-brightness="85.723">
       RAL 290 30 10
       <div class="subtext">Flintsteinblau<br>Flintstone blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#555669" data-brightness="87.185">
       RAL 290 30 15
       <div class="subtext">Spinellschwarz<br>Spinel black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#52536D" data-brightness="87.484">
       RAL 290 30 20
       <div class="subtext">Kirchenblau<br>Church blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#525475" data-brightness="89.068">
       RAL 290 30 25
       <div class="subtext">Finsternisblau<br>Gloomy blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F537B" data-brightness="85.004">
       RAL 290 30 30
       <div class="subtext">Lembergerblau<br>Lviv blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D5483" data-brightness="85.547">
       RAL 290 30 35
       <div class="subtext">Britischlila<br>British mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#495389" data-brightness="86.732">
       RAL 290 30 40
       <div class="subtext">Amtsviolett<br>Official violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#696876" data-brightness="103.244">
       RAL 290 40 10
       <div class="subtext">Sturmtaucherschwarz<br>Shearwater black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#68687D" data-brightness="102.79">
       RAL 290 40 15
       <div class="subtext">Achatviolett<br>Agate violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#656785" data-brightness="105.692">
       RAL 290 40 20
       <div class="subtext">Tieflavendel<br>Deep lavender</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#63658B" data-brightness="106.268">
       RAL 290 40 25
       <div class="subtext">Dämmerungslila<br>Dusk mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#606592" data-brightness="108.572">
       RAL 290 40 30
       <div class="subtext">Reichviolett<br>Rich violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5E6498" data-brightness="103.788">
       RAL 290 40 35
       <div class="subtext">Zwiebelschalenblau<br>Onion skin blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B65A1" data-brightness="104.331">
       RAL 290 40 40
       <div class="subtext">Schwertlilienblau<br>Iris blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5664A7" data-brightness="106.092">
       RAL 290 40 45
       <div class="subtext">Veilchenblau<br>Violet blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7C7C8C" data-brightness="118.694">
       RAL 290 50 10
       <div class="subtext">Aschlila<br>Ash mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B7C92" data-brightness="122.748">
       RAL 290 50 15
       <div class="subtext">Sturmlila<br>Stormy mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#797A9B" data-brightness="122.748">
       RAL 290 50 20
       <div class="subtext">Seidenkrepplila<br>Silk crepe mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#797BA4" data-brightness="125.052">
       RAL 290 50 25
       <div class="subtext">Hyazinthenlila<br>Hyacinth mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#777AAA" data-brightness="124.465">
       RAL 290 50 30
       <div class="subtext">Bergblumenlila<br>Mountain flower mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7379B2" data-brightness="127.356">
       RAL 290 50 35
       <div class="subtext">Chalcedonviolett<br>Chalcedony violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7079BA" data-brightness="127.356">
       RAL 290 50 40
       <div class="subtext">Gladiolenblau<br>Gladiola blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9293A5" data-brightness="153.404">
       RAL 290 60 10
       <div class="subtext">Lavendelblütengrau<br>Lavender blossom grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9393AC" data-brightness="152.23">
       RAL 290 60 15
       <div class="subtext">Grazilviolett<br>Delicate violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8F91B5" data-brightness="150.924">
       RAL 290 60 20
       <div class="subtext">Opernlila<br>Opera mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9092BD" data-brightness="155.708">
       RAL 290 60 25
       <div class="subtext">Bleulavendel<br>Soft blue lavender</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8D91C4" data-brightness="153.228">
       RAL 290 60 30
       <div class="subtext">Plisseelila<br>Pleated mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#888FCA" data-brightness="143.537">
       RAL 290 60 35
       <div class="subtext">Echtlavendel<br>True lavender</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ADADBF" data-brightness="168.71">
       RAL 290 70 10
       <div class="subtext">Cremeviolett<br>Cream violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#ABABC7" data-brightness="171.014">
       RAL 290 70 15
       <div class="subtext">Glyzinienhellbleu<br>Wisteria light soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A8ABCE" data-brightness="172.188">
       RAL 290 70 20
       <div class="subtext">Blütenlila<br>Blossom mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A7AAD7" data-brightness="172.155">
       RAL 290 70 25
       <div class="subtext">Sorbeteislila<br>Sorbet ice mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C6C6D7" data-brightness="201.67">
       RAL 290 80 10
       <div class="subtext">Tulipanbleu<br>Tulip soft blue</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C6C6DE" data-brightness="201.67">
       RAL 290 80 15
       <div class="subtext">Blasslila<br>Pale lilac</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5D5DA" data-brightness="215.846">
       RAL 290 85 05
       <div class="subtext">Kalklila<br>Limestone mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0D3E9" data-brightness="219.324">
       RAL 290 85 10
       <div class="subtext">Orchideenweiß<br>Orchid white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DADBE0" data-brightness="219.324">
       RAL 290 90 05
       <div class="subtext">Violettweiß<br>Violet white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8E9EF" data-brightness="233.5">
       RAL 290 93 05
       <div class="subtext">Fliederweiß<br>Lilac white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4B494E" data-brightness="68.7">
       RAL 300 20 05
       <div class="subtext">Nachtschwarz<br>Night black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#494650" data-brightness="71.004">
       RAL 300 20 10
       <div class="subtext">Reiherblau<br>Heron blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#494655" data-brightness="70.86">
       RAL 300 20 15
       <div class="subtext">Violaschwarz<br>Viola black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#49455A" data-brightness="71.004">
       RAL 300 20 20
       <div class="subtext">Brombeerschwarz<br>Blackberry black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#49445C" data-brightness="69.243">
       RAL 300 20 25
       <div class="subtext">Kardinallila<br>Cardinal mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#474364" data-brightness="72.588">
       RAL 300 20 30
       <div class="subtext">Zeremonienpurpur<br>Ceremonial purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#59575D" data-brightness="85.18">
       RAL 300 30 05
       <div class="subtext">Nachtgrau<br>Night grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#585562" data-brightness="85.723">
       RAL 300 30 10
       <div class="subtext">Trauerviolett<br>Mourning violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#595466" data-brightness="87.34">
       RAL 300 30 15
       <div class="subtext">Clubgrau<br>Club grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#59536C" data-brightness="87.484">
       RAL 300 30 20
       <div class="subtext">Maulbeerlilaschwarz<br>Mulberry mauve black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#595272" data-brightness="89.5">
       RAL 300 30 25
       <div class="subtext">Abendlila<br>Evening mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#595278" data-brightness="89.788">
       RAL 300 30 30
       <div class="subtext">Opernblau<br>Opera blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#57517E" data-brightness="89.788">
       RAL 300 30 35
       <div class="subtext">Tiefviolett<br>Deep violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#565084" data-brightness="92.092">
       RAL 300 30 40
       <div class="subtext">Salbeiviolett<br>Sage violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C6A70" data-brightness="103.964">
       RAL 300 40 05
       <div class="subtext">Barockgrau<br>Baroque grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6B6775" data-brightness="103.964">
       RAL 300 40 10
       <div class="subtext">Gobelinlila<br>Gobelin mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6B657B" data-brightness="103.388">
       RAL 300 40 15
       <div class="subtext">Orchideengrau<br>Orchid grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C6581" data-brightness="106.268">
       RAL 300 40 20
       <div class="subtext">Altlila<br>Old mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C6488" data-brightness="106.124">
       RAL 300 40 25
       <div class="subtext">Parmalila<br>Parma mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C638E" data-brightness="106.268">
       RAL 300 40 30
       <div class="subtext">Wollviolett<br>Wool violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6B6293" data-brightness="108.572">
       RAL 300 40 35
       <div class="subtext">Edelpurpur<br>Fine purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#696099" data-brightness="107.996">
       RAL 300 40 40
       <div class="subtext">Clematisblau<br>Clematis blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6860A1" data-brightness="110.876">
       RAL 300 40 45
       <div class="subtext">Krönungsblau<br>Coronation blue</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#807E85" data-brightness="125.228">
       RAL 300 50 05
       <div class="subtext">Falkengrau<br>Hawk grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#817C8B" data-brightness="125.228">
       RAL 300 50 10
       <div class="subtext">Veilchengrau<br>Violet grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#817B92" data-brightness="127.532">
       RAL 300 50 15
       <div class="subtext">Auerhahnlila<br>Capercaillie mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#817999" data-brightness="127.244">
       RAL 300 50 20
       <div class="subtext">Pariserviolett<br>Parisian violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8178A0" data-brightness="128.075">
       RAL 300 50 25
       <div class="subtext">Tulpenviolett<br>Tulip violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8177A7" data-brightness="128.817">
       RAL 300 50 30
       <div class="subtext">Spielzeuglila<br>Toy mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8277AE" data-brightness="129.249">
       RAL 300 50 35
       <div class="subtext">Winterzeitlila<br>Wintertime mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#8075B4" data-brightness="132.14">
       RAL 300 50 40
       <div class="subtext">Klarlila<br>Clear mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#98969E" data-brightness="151.1">
       RAL 300 60 05
       <div class="subtext">Rebhuhngrau<br>Partridge grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9893A3" data-brightness="153.116">
       RAL 300 60 10
       <div class="subtext">Rosengrau<br>Rose grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9992AB" data-brightness="153.105">
       RAL 300 60 15
       <div class="subtext">Antikviola<br>Antique viola</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9990B1" data-brightness="155.409">
       RAL 300 60 20
       <div class="subtext">Ritterspornviolett<br>Larkspur violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#988EB8" data-brightness="145.74">
       RAL 300 60 25
       <div class="subtext">Seidenflieder<br>Silk lilac</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#988DC0" data-brightness="148.62">
       RAL 300 60 30
       <div class="subtext">Heliotroplila<br>Heliotropic mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#978BC6" data-brightness="148.62">
       RAL 300 60 35
       <div class="subtext">Amethystgrau<br>Amethyst grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B0AEB7" data-brightness="174.668">
       RAL 300 70 05
       <div class="subtext">Samtgrau<br>Velvet grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B1ABBC" data-brightness="172.907">
       RAL 300 70 10
       <div class="subtext">Morgendunstgrau<br>Morning mist grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B1AAC4" data-brightness="176.385">
       RAL 300 70 15
       <div class="subtext">Lidschattenviola<br>Eyeshadow viola</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B2A9CB" data-brightness="176.252">
       RAL 300 70 20
       <div class="subtext">Lavendelviolett<br>Lavender violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B2A7D2" data-brightness="178.7">
       RAL 300 70 25
       <div class="subtext">Pinklavendel<br>Pink lavender</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CAC8D0" data-brightness="202.844">
       RAL 300 80 05
       <div class="subtext">Dunstlila<br>Hazy mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9C5D8" data-brightness="202.844">
       RAL 300 80 10
       <div class="subtext">Blassviolett<br>Pale violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CBC4DE" data-brightness="202.844">
       RAL 300 80 15
       <div class="subtext">Eislila<br>Ice mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5D4DA" data-brightness="217.02">
       RAL 300 85 05
       <div class="subtext">Zartviola<br>Delicate viola</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D8D1E4" data-brightness="219.324">
       RAL 300 85 10
       <div class="subtext">Puderviolaweiß<br>Powder viola white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4E2E8" data-brightness="233.5">
       RAL 300 90 05
       <div class="subtext">Lasurweiß<br>Translucent white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F4658" data-brightness="71.004">
       RAL 310 20 20
       <div class="subtext">Obsidianlavaschwarz<br>Obsidian lava black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#51445D" data-brightness="75.201">
       RAL 310 20 25
       <div class="subtext">Operettenlila<br>Operetta mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F4261" data-brightness="73.308">
       RAL 310 20 30
       <div class="subtext">Machtviolett<br>Powerful violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F5667" data-brightness="87.484">
       RAL 310 30 15
       <div class="subtext">Dunkelgraulila<br>Dark grey mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5E536B" data-brightness="87.484">
       RAL 310 30 20
       <div class="subtext">Machtlila<br>Powerful mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#615270" data-brightness="94.572">
       RAL 310 30 25
       <div class="subtext">Englischviolett<br>English violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#635176" data-brightness="93.852">
       RAL 310 30 30
       <div class="subtext">Kristallpurpur<br>Crystal purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#625078" data-brightness="94.572">
       RAL 310 30 35
       <div class="subtext">Prägnantviolett<br>Succinct violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#654F7F" data-brightness="84.892">
       RAL 310 30 40
       <div class="subtext">Lodenpurpur<br>Loden purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#706976" data-brightness="108.316">
       RAL 310 40 10
       <div class="subtext">Dunkelpink<br>Dark pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#73687C" data-brightness="108.748">
       RAL 310 40 15
       <div class="subtext">Schleierviolett<br>Violet haze</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#756781" data-brightness="109.291">
       RAL 310 40 20
       <div class="subtext">Indigorot<br>Indigo red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#766587" data-brightness="110.332">
       RAL 310 40 25
       <div class="subtext">Charoitviolett<br>Charoite violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#76628A" data-brightness="111.052">
       RAL 310 40 30
       <div class="subtext">Königspurpur<br>Royal purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#776190" data-brightness="113.057">
       RAL 310 40 35
       <div class="subtext">Magentaveilchen<br>Magenta violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A6196" data-brightness="112.924">
       RAL 310 40 40
       <div class="subtext">Purlila<br>Pure mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#857D8C" data-brightness="125.228">
       RAL 310 50 10
       <div class="subtext">Trüblila<br>Dull mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#867C92" data-brightness="127.532">
       RAL 310 50 15
       <div class="subtext">Batikflieder<br>Batik lilac</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8A7B97" data-brightness="127.1">
       RAL 310 50 20
       <div class="subtext">Provenceviolett<br>Provence violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8C799E" data-brightness="127.532">
       RAL 310 50 25
       <div class="subtext">Baronesslila<br>Baroness mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D77A4" data-brightness="129.249">
       RAL 310 50 30
       <div class="subtext">Bitterviolett<br>Bitter violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D75A8" data-brightness="129.116">
       RAL 310 50 35
       <div class="subtext">Violettrosé<br>Violet rose</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F74AE" data-brightness="129.836">
       RAL 310 50 40
       <div class="subtext">Orchideenlila<br>Orchid mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9B93A3" data-brightness="153.116">
       RAL 310 60 10
       <div class="subtext">Dezentlila<br>Gentle mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F92AA" data-brightness="153.26">
       RAL 310 60 15
       <div class="subtext">Rauchlila<br>Smoky mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A291B0" data-brightness="160.492">
       RAL 310 60 20
       <div class="subtext">Safranblütenlila<br>Saffron blossom mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A48FB5" data-brightness="151.1">
       RAL 310 60 25
       <div class="subtext">Kokettpink<br>Flirty pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A58DBA" data-brightness="150.38">
       RAL 310 60 30
       <div class="subtext">Pfingstrosenlila<br>Peony mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A78AC3" data-brightness="151.643">
       RAL 310 60 35
       <div class="subtext">Lilapurpur<br>Lilac purple</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B5ACBB" data-brightness="173.948">
       RAL 310 70 10
       <div class="subtext">Amorphrosé<br>Amorphous rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B8AAC2" data-brightness="176.385">
       RAL 310 70 15
       <div class="subtext">Transparentlila<br>Transparent mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9A8C7" data-brightness="176.972">
       RAL 310 70 20
       <div class="subtext">Blaurosa<br>Blue pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BBA7CE" data-brightness="176.673">
       RAL 310 70 25
       <div class="subtext">Glasviolett<br>Glass violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDC5D5" data-brightness="202.556">
       RAL 310 80 10
       <div class="subtext">Puderviola<br>Powder viola</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1C4DB" data-brightness="207.628">
       RAL 310 80 15
       <div class="subtext">Orchideenviolett<br>Orchid violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D6D3DA" data-brightness="217.02">
       RAL 310 85 05
       <div class="subtext">Feenrosa<br>Fairy pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBD1E2" data-brightness="219.324">
       RAL 310 85 10
       <div class="subtext">Tricotfliederweiß<br>Tricot lilac white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8E3E7" data-brightness="233.5">
       RAL 310 90 05
       <div class="subtext">Mauerblümchenweiß<br>Wallflower white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D494E" data-brightness="68.7">
       RAL 320 20 05
       <div class="subtext">Lavaschwarz<br>Lava black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4E464F" data-brightness="68.7">
       RAL 320 20 10
       <div class="subtext">Obsidianrot<br>Obsidian red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4F4451" data-brightness="69.243">
       RAL 320 20 15
       <div class="subtext">Spinellviolett<br>Spinel violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#534455" data-brightness="74.481">
       RAL 320 20 20
       <div class="subtext">Auberginenlila<br>Aubergine mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#534259" data-brightness="75.788">
       RAL 320 20 25
       <div class="subtext">Dunkelpurpur<br>Dark purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5B565B" data-brightness="84.604">
       RAL 320 30 05
       <div class="subtext">Violettschwarz<br>Violet black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D545E" data-brightness="85.18">
       RAL 320 30 10
       <div class="subtext">Steinviolett<br>Stone violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#605161" data-brightness="91.98">
       RAL 320 30 15
       <div class="subtext">Amethystdunkelviolett<br>Amethyst dark violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#634F66" data-brightness="82.156">
       RAL 320 30 20
       <div class="subtext">Dunkelbrombeer<br>Dark blackberry</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#664E6A" data-brightness="82.577">
       RAL 320 30 25
       <div class="subtext">Purpuritviolett<br>Purpurite violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#674C6E" data-brightness="82.876">
       RAL 320 30 30
       <div class="subtext">Zwetschgenlila<br>Damson mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#694B73" data-brightness="85.18">
       RAL 320 30 35
       <div class="subtext">Traubenpurpur<br>Grape purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6C4B77" data-brightness="85.036">
       RAL 320 30 37
       <div class="subtext">Loungeviolett<br>Lounge violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6E696F" data-brightness="101.66">
       RAL 320 40 05
       <div class="subtext">Schieferlila<br>Slate mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#716672" data-brightness="108.161">
       RAL 320 40 10
       <div class="subtext">Olivinbasalt<br>Olivine basalt</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#746477" data-brightness="106.854">
       RAL 320 40 15
       <div class="subtext">Antikgrau<br>Antique grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#77627B" data-brightness="108.449">
       RAL 320 40 20
       <div class="subtext">Basilikumlila<br>Basil mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#796080" data-brightness="110.764">
       RAL 320 40 25
       <div class="subtext">Gladiolenviolett<br>Gladiola violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D5D83" data-brightness="100.486">
       RAL 320 40 30
       <div class="subtext">Krautblütenviolett<br>Cabbage blossom violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7F5A87" data-brightness="100.94">
       RAL 320 40 35
       <div class="subtext">Fliederviolett<br>Lilac violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#82598E" data-brightness="106.444">
       RAL 320 40 40
       <div class="subtext">Veilchenpurpur<br>Violet purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#827C83" data-brightness="125.228">
       RAL 320 50 05
       <div class="subtext">Grauviolett<br>Grey violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#867987" data-brightness="124.796">
       RAL 320 50 10
       <div class="subtext">Britischgrauviolett<br>British grey mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#89778C" data-brightness="124.641">
       RAL 320 50 15
       <div class="subtext">Parmagrau<br>Parma grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8E7793" data-brightness="126.945">
       RAL 320 50 20
       <div class="subtext">Alpenveilchen<br>Cyclamen</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#907397" data-brightness="131.884">
       RAL 320 50 25
       <div class="subtext">Poesielila<br>Poetry mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#94729D" data-brightness="132.316">
       RAL 320 50 30
       <div class="subtext">Violagrau<br>Viola grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#966D9F" data-brightness="122.924">
       RAL 320 50 35
       <div class="subtext">Astervioletta<br>Aster violetta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#996FA7" data-brightness="124.929">
       RAL 320 50 40
       <div class="subtext">Empireviolett<br>Empire violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9A949C" data-brightness="151.1">
       RAL 320 60 05
       <div class="subtext">Aragonitgrau<br>Aragonite grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9E929F" data-brightness="151.1">
       RAL 320 60 10
       <div class="subtext">Kreideviolett<br>Chalk violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A28FA5" data-brightness="148.796">
       RAL 320 60 15
       <div class="subtext">Amethystgrauviolett<br>Amethyst grey violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A58BA9" data-brightness="148.796">
       RAL 320 60 20
       <div class="subtext">Wunderveilchen<br>Wonder violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A889AF" data-brightness="148.796">
       RAL 320 60 25
       <div class="subtext">Nachtschattenviolett<br>Nightshade violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AC87B5" data-brightness="151.1">
       RAL 320 60 30
       <div class="subtext">Lobbylila<br>Lobby lilac</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AE85B9" data-brightness="151.1">
       RAL 320 60 35
       <div class="subtext">Sorbetbrombeer<br>Blackberry sorbet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B2ACB3" data-brightness="174.668">
       RAL 320 70 05
       <div class="subtext">Holundergrau<br>Elderberry grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6A9B9" data-brightness="174.38">
       RAL 320 70 10
       <div class="subtext">Glockenblumenviolett<br>Bellflower violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9A6BE" data-brightness="174.668">
       RAL 320 70 15
       <div class="subtext">Quarzviolett<br>Quartz violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0A5C6" data-brightness="181.756">
       RAL 320 70 20
       <div class="subtext">Strandflieder<br>Beach lilac</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C3A2CA" data-brightness="181.324">
       RAL 320 70 25
       <div class="subtext">Fliederpink<br>Lilac pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CDC7CD" data-brightness="199.964">
       RAL 320 80 05
       <div class="subtext">Hellviolett<br>Light violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1C4D3" data-brightness="207.628">
       RAL 320 80 10
       <div class="subtext">Amethysthellviolett<br>Amethyst light violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D4C0D6" data-brightness="207.628">
       RAL 320 80 15
       <div class="subtext">Kosmetiklila<br>Cosmetic mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D8D1D9" data-brightness="217.02">
       RAL 320 85 05
       <div class="subtext">Brombeercreme<br>Blackberry cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDCFDF" data-brightness="207.041">
       RAL 320 85 10
       <div class="subtext">Ametrinquarz<br>Ametrine quartz</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8E1E6" data-brightness="233.5">
       RAL 320 90 05
       <div class="subtext">Apatitrosa<br>Apatite pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#554251" data-brightness="75.489">
       RAL 330 20 20
       <div class="subtext">Tiefgrundlila<br>Profound mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5A4256" data-brightness="75.788">
       RAL 330 20 25
       <div class="subtext">Opulentlila<br>Opulent mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#645361" data-brightness="92.268">
       RAL 330 30 15
       <div class="subtext">Korinthenviolett<br>Currant violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#685164" data-brightness="92.268">
       RAL 330 30 20
       <div class="subtext">Mexikanischpurpur<br>Mexican purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6B4E66" data-brightness="82.156">
       RAL 330 30 25
       <div class="subtext">Parmapflaumenrot<br>Parma plum red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#704C6A" data-brightness="87.66">
       RAL 330 30 30
       <div class="subtext">Intensivpurpur<br>Intense purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#734B6E" data-brightness="87.66">
       RAL 330 30 35
       <div class="subtext">Opulentpurpur<br>Opulent purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#75476F" data-brightness="85.899">
       RAL 330 30 40
       <div class="subtext">Intensivlila<br>Intense mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#746772" data-brightness="106.987">
       RAL 330 40 10
       <div class="subtext">Patinaviolett<br>Patina violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#786576" data-brightness="108.316">
       RAL 330 40 15
       <div class="subtext">Dunkelpurpurgrau<br>Dark purple grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D647A" data-brightness="108.748">
       RAL 330 40 20
       <div class="subtext">Amaranthrot<br>Amaranth red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#81627D" data-brightness="113.532">
       RAL 330 40 25
       <div class="subtext">Tiefmagenta<br>Deep magenta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#885F81" data-brightness="106.145">
       RAL 330 40 30
       <div class="subtext">Phloxblumenviolett<br>Phloxflower violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8B5D84" data-brightness="106.444">
       RAL 330 40 35
       <div class="subtext">Distellila<br>Thistle mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8C5885" data-brightness="104.251">
       RAL 330 40 40
       <div class="subtext">Visionarot<br>Visiona red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F5788" data-brightness="105.724">
       RAL 330 40 45
       <div class="subtext">Purpuritrot<br>Purpurite red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#897A86" data-brightness="125.228">
       RAL 330 50 10
       <div class="subtext">Jutegrau<br>Burlap grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F7A8C" data-brightness="125.228">
       RAL 330 50 15
       <div class="subtext">Saphirrosa<br>Sapphire pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#947790" data-brightness="131.729">
       RAL 330 50 20
       <div class="subtext">Prachtnelkenlila<br>Dianthus mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#987593" data-brightness="132.316">
       RAL 330 50 25
       <div class="subtext">Kristallrosa<br>Crystal pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9D7399" data-brightness="131.596">
       RAL 330 50 30
       <div class="subtext">Tulipanviolett<br>Tulipan violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A0709C" data-brightness="135.926">
       RAL 330 50 35
       <div class="subtext">Violettrot<br>Violet red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A56D9E" data-brightness="127.708">
       RAL 330 50 40
       <div class="subtext">Orchideenpurpur<br>Orchid purple</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A1929F" data-brightness="155.884">
       RAL 330 60 10
       <div class="subtext">Greigeviolett<br>Greige violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A791A4" data-brightness="158.188">
       RAL 330 60 15
       <div class="subtext">Vesuvianviolett<br>Vesuvian violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AD8EA8" data-brightness="148.364">
       RAL 330 60 20
       <div class="subtext">Enzianviolett<br>Gentian violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AF8AA9" data-brightness="147.035">
       RAL 330 60 25
       <div class="subtext">Gründerzeitpink<br>Wilhelminian pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B588AF" data-brightness="152.993">
       RAL 330 60 30
       <div class="subtext">Bühnenlila<br>Stage mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BB85B3" data-brightness="155.585">
       RAL 330 60 35
       <div class="subtext">Pfingstnelkenlila<br>Cheddar pink mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BE82B7" data-brightness="155.884">
       RAL 330 60 40
       <div class="subtext">Colomborotlila<br>Colombo red mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BCABB9" data-brightness="172.907">
       RAL 330 70 10
       <div class="subtext">Nebelrosa<br>Mist pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFA7BB" data-brightness="173.948">
       RAL 330 70 15
       <div class="subtext">Lauchblütenpink<br>Leek blossom pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5A6C0" data-brightness="181.756">
       RAL 330 70 20
       <div class="subtext">Erdbeerrosa<br>Strawberry pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CCA3C5" data-brightness="181.457">
       RAL 330 70 25
       <div class="subtext">Purpurrosa<br>Purple pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D0A1C7" data-brightness="186.54">
       RAL 330 70 30
       <div class="subtext">Kleeblütenrosa<br>Clover pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D5C4D1" data-brightness="207.628">
       RAL 330 80 10
       <div class="subtext">Purpurweiß<br>Purple white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DAC1D5" data-brightness="207.628">
       RAL 330 80 15
       <div class="subtext">Lieblichrosa<br>Lovely pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2BFD9" data-brightness="203.02">
       RAL 330 80 20
       <div class="subtext">Designerrosa<br>Designer pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D9D3D9" data-brightness="216.444">
       RAL 330 85 05
       <div class="subtext">Hellmagnolienrosé<br>Light magnolia rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E2D1DE" data-brightness="221.084">
       RAL 330 85 10
       <div class="subtext">Himbeerrosa<br>Raspberry pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8CEE1" data-brightness="212.955">
       RAL 330 85 15
       <div class="subtext">Himmlischrosa<br>Heavenly pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBE2E5" data-brightness="233.5">
       RAL 330 90 05
       <div class="subtext">Delikatessweiß<br>Delicacy white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#4D474B" data-brightness="68.7">
       RAL 340 20 05
       <div class="subtext">Lakritzschwarz<br>Liquorice black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#50444A" data-brightness="72.897">
       RAL 340 20 10
       <div class="subtext">Mangostanoviolett<br>Mangosteen violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#53414B" data-brightness="73.484">
       RAL 340 20 15
       <div class="subtext">Sitterrot<br>Sitter red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#58414E" data-brightness="73.484">
       RAL 340 20 20
       <div class="subtext">Saftviolett<br>Juice violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F4252" data-brightness="75.5">
       RAL 340 20 25
       <div class="subtext">Prestigelila<br>Prestige mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C5559" data-brightness="83.419">
       RAL 340 30 05
       <div class="subtext">Apfelkrautschwarz<br>Apple herb black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#61525A" data-brightness="89.964">
       RAL 340 30 10
       <div class="subtext">Passionsfruchtlila<br>Passionfruit mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#66505C" data-brightness="89.665">
       RAL 340 30 15
       <div class="subtext">Dunkelfeigenviolett<br>Dark fig violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6A4E5E" data-brightness="80.284">
       RAL 340 30 20
       <div class="subtext">Rubinviolett<br>Ruby violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F4C62" data-brightness="82.876">
       RAL 340 30 25
       <div class="subtext">Brombeertiefrot<br>Blackberry deep red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#714963" data-brightness="87.66">
       RAL 340 30 30
       <div class="subtext">Tiefrot<br>Deep red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#754664" data-brightness="87.228">
       RAL 340 30 35
       <div class="subtext">Samtlila<br>Velvet mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A4366" data-brightness="87.516">
       RAL 340 30 38
       <div class="subtext">Waldfruchtrot<br>Forest fruit red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F676B" data-brightness="101.66">
       RAL 340 40 05
       <div class="subtext">Lilabraun<br>Mauve brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#76646D" data-brightness="106.444">
       RAL 340 40 10
       <div class="subtext">Spinellgrau<br>Spinel grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B6270" data-brightness="108.748">
       RAL 340 40 15
       <div class="subtext">Erdbraunviolett<br>Earth brown violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#805F72" data-brightness="104.14">
       RAL 340 40 20
       <div class="subtext">Dahlienmattrot<br>Dahlia matte red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#855D76" data-brightness="104.14">
       RAL 340 40 25
       <div class="subtext">Brokatviolett<br>Brocade violet</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#895A78" data-brightness="103.42">
       RAL 340 40 30
       <div class="subtext">Krappmagenta<br>Madder magenta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8B557A" data-brightness="103.553">
       RAL 340 40 35
       <div class="subtext">Clublila<br>Club mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#90527B" data-brightness="108.924">
       RAL 340 40 40
       <div class="subtext">Kirschrot<br>Cherry red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#994F80" data-brightness="101.537">
       RAL 340 40 45
       <div class="subtext">Magentarot<br>Magenta red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#857C81" data-brightness="125.228">
       RAL 340 50 05
       <div class="subtext">Jubiläumsgrau<br>Jubilee grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8C7984" data-brightness="125.228">
       RAL 340 50 10
       <div class="subtext">Portweinrot<br>Port wine red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#927686" data-brightness="129.724">
       RAL 340 50 15
       <div class="subtext">Rohgranatviola<br>Raw garnet viola</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#987489" data-brightness="129.292">
       RAL 340 50 20
       <div class="subtext">Kreuzblumenrot<br>Milkwort red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9D708B" data-brightness="130.012">
       RAL 340 50 25
       <div class="subtext">Rostmagenta<br>Rust magenta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A26D8E" data-brightness="125.404">
       RAL 340 50 30
       <div class="subtext">Himbeergeleerot<br>Raspberry jelly red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A56890" data-brightness="127.708">
       RAL 340 50 35
       <div class="subtext">Fingerhutrot<br>Thimble red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AD6693" data-brightness="127.121">
       RAL 340 50 40
       <div class="subtext">Cyclamonrot<br>Cyclamen red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B26295" data-brightness="131.318">
       RAL 340 50 45
       <div class="subtext">Brillantkarmin<br>Brilliant carmine</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9E959A" data-brightness="151.1">
       RAL 340 60 05
       <div class="subtext">Erikarotgrau<br>Heather red grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A48F9B" data-brightness="146.492">
       RAL 340 60 10
       <div class="subtext">Pianomauve<br>Piano mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AC8D9F" data-brightness="146.492">
       RAL 340 60 15
       <div class="subtext">Abendrosa<br>Evening pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B08AA1" data-brightness="153.58">
       RAL 340 60 20
       <div class="subtext">Caprimodepink<br>Capri fashion pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B587A3" data-brightness="153.58">
       RAL 340 60 25
       <div class="subtext">Antikpink<br>Antique hot pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BA84A6" data-brightness="153.58">
       RAL 340 60 30
       <div class="subtext">Wangenrot<br>Cheek red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C181A9" data-brightness="157.19">
       RAL 340 60 35
       <div class="subtext">Malvenblütenpink<br>Hollyhock blossom pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C87CAD" data-brightness="147.211">
       RAL 340 60 40
       <div class="subtext">Bischofsrot<br>Bishop red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B6ACB1" data-brightness="174.668">
       RAL 340 70 05
       <div class="subtext">Lilagrau<br>Mauve grey</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BCA8B3" data-brightness="174.668">
       RAL 340 70 10
       <div class="subtext">Buchweizenlila<br>Buckwheat mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C5A6B8" data-brightness="179.452">
       RAL 340 70 15
       <div class="subtext">Malvenrosa<br>Hollyhock pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CBA3BB" data-brightness="178.876">
       RAL 340 70 20
       <div class="subtext">Idyllenrosa<br>Idyllic pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CE9FBB" data-brightness="169.916">
       RAL 340 70 25
       <div class="subtext">Windenblütenpink<br>Morning glory pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D69CC0" data-brightness="177.148">
       RAL 340 70 30
       <div class="subtext">Softfuchsia<br>Soft fuschia</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DD99C4" data-brightness="176.262">
       RAL 340 70 35
       <div class="subtext">Prallpink<br>Firm pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D1C7CA" data-brightness="205.324">
       RAL 340 80 05
       <div class="subtext">Pianograurosé<br>Piano grey rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D9C4D0" data-brightness="207.628">
       RAL 340 80 10
       <div class="subtext">Minzblütenrosé<br>Mint blossom rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0C0D2" data-brightness="211.238">
       RAL 340 80 15
       <div class="subtext">Skandalrosé<br>Scandalous rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E7BDD4" data-brightness="203.02">
       RAL 340 80 20
       <div class="subtext">Bonbonrosa<br>Bonbon rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDD2D8" data-brightness="216.721">
       RAL 340 85 05
       <div class="subtext">Erdbeercreme<br>Strawberry cream</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E5CFDB" data-brightness="212.412">
       RAL 340 85 10
       <div class="subtext">Hellflamingopink<br>Light flamingo pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EECDDF" data-brightness="212.113">
       RAL 340 85 15
       <div class="subtext">Lacklila<br>Lacquer mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EBE1E4" data-brightness="233.5">
       RAL 340 90 05
       <div class="subtext">Rosenweiß<br>Rose white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1E8ED" data-brightness="238.284">
       RAL 340 93 05
       <div class="subtext">Kleiderrosa<br>Dress pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#514449" data-brightness="72.897">
       RAL 350 20 10
       <div class="subtext">Glühweinrot<br>Mulled wine red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#57444B" data-brightness="72.897">
       RAL 350 20 15
       <div class="subtext">Sanftrot<br>Soft red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5C414B" data-brightness="73.484">
       RAL 350 20 20
       <div class="subtext">Sternrubin<br>Star ruby</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5F404C" data-brightness="73.484">
       RAL 350 20 25
       <div class="subtext">Chilischwarzrot<br>Chilli black red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#653D4E" data-brightness="68.876">
       RAL 350 20 30
       <div class="subtext">Tulpenbaumpurpur<br>Tulip poplar purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#625158" data-brightness="89.964">
       RAL 350 30 10
       <div class="subtext">Sperlinggraurot<br>Sparrow grey red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#684F59" data-brightness="80.572">
       RAL 350 30 15
       <div class="subtext">Kieselrot<br>Siliceous red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#6F4E5B" data-brightness="80.572">
       RAL 350 30 20
       <div class="subtext">Likörrot<br>Liqueur red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#744B5C" data-brightness="85.356">
       RAL 350 30 25
       <div class="subtext">Weingummirot<br>Wine gummy red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7A4A5E" data-brightness="84.182">
       RAL 350 30 30
       <div class="subtext">Burlatrot<br>Burlat red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7B465E" data-brightness="85.356">
       RAL 350 30 35
       <div class="subtext">Bordeauxrot<br>Bordeaux red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#804360" data-brightness="91.868">
       RAL 350 30 40
       <div class="subtext">Trockenblumenpurpur<br>Dried flower purple</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#77656C" data-brightness="106.145">
       RAL 350 40 10
       <div class="subtext">Auberginengrau<br>Aubergine grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D626D" data-brightness="105.868">
       RAL 350 40 15
       <div class="subtext">Schlafmohnmauve<br>Opium mauve</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#835F6F" data-brightness="101.548">
       RAL 350 40 20
       <div class="subtext">Graukarmin<br>Grey carmine</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#875C6F" data-brightness="101.836">
       RAL 350 40 25
       <div class="subtext">Nektarrot<br>Nectar red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8F5971" data-brightness="104.14">
       RAL 350 40 30
       <div class="subtext">Dunkelrosé<br>Dark rose</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#945573" data-brightness="108.337">
       RAL 350 40 35
       <div class="subtext">Bonbonrot<br>Bonbon red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#985374" data-brightness="108.924">
       RAL 350 40 40
       <div class="subtext">Zyclamenrot<br>Cyclamen red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9D5075" data-brightness="108.492">
       RAL 350 40 45
       <div class="subtext">Puderrot<br>Powder red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A24B76" data-brightness="104.316">
       RAL 350 40 50
       <div class="subtext">Krapprot<br>Madder red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D7981" data-brightness="125.228">
       RAL 350 50 10
       <div class="subtext">Unschuldsrosa<br>Innocent pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#937682" data-brightness="130.012">
       RAL 350 50 15
       <div class="subtext">Stumpfviolett<br>Blunt violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9B7385" data-brightness="130.012">
       RAL 350 50 20
       <div class="subtext">Korundrot<br>Corundum red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A27086" data-brightness="134.796">
       RAL 350 50 25
       <div class="subtext">Batikpink<br>Batik pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A76E88" data-brightness="125.26">
       RAL 350 50 30
       <div class="subtext">Beryllrot<br>Beryl red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AF6888" data-brightness="125.116">
       RAL 350 50 35
       <div class="subtext">Hochrot<br>Crimson</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B56589" data-brightness="129.014">
       RAL 350 50 40
       <div class="subtext">Krapprosa<br>Madder rose</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B6618B" data-brightness="129.468">
       RAL 350 50 45
       <div class="subtext">Signalpink<br>Signal pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C25E8B" data-brightness="125.58">
       RAL 350 50 50
       <div class="subtext">Nagellackpink<br>Nail polish pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A59099" data-brightness="155.452">
       RAL 350 60 10
       <div class="subtext">Jugendstilviolett<br>Art Nouveau violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AC8C9A" data-brightness="144.598">
       RAL 350 60 15
       <div class="subtext">Mohairrosa<br>Mohair pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B58B9D" data-brightness="149.515">
       RAL 350 60 20
       <div class="subtext">Heideblumenviolett<br>Heather violet</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BC869D" data-brightness="151.276">
       RAL 350 60 25
       <div class="subtext">Heidekrautrosé<br>Heather rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C4839F" data-brightness="156.06">
       RAL 350 60 30
       <div class="subtext">Venetianerrosa<br>Venetian pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C87FA0" data-brightness="148.972">
       RAL 350 60 35
       <div class="subtext">Dolomitrot<br>Dolomite red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D07CA1" data-brightness="153.756">
       RAL 350 60 40
       <div class="subtext">Kamelienrosa<br>Camellia pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D378A4" data-brightness="153.756">
       RAL 350 60 45
       <div class="subtext">Persischrosa<br>Persian pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BFAAB1" data-brightness="174.081">
       RAL 350 70 10
       <div class="subtext">Kaltrosa<br>Cold pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C7A5B3" data-brightness="179.452">
       RAL 350 70 15
       <div class="subtext">Schattenrosa<br>Shady pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#CB9FB3" data-brightness="170.06">
       RAL 350 70 20
       <div class="subtext">Grasnelkenrosa<br>Ladyï¿½s cushions pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D69FB6" data-brightness="174.268">
       RAL 350 70 25
       <div class="subtext">Korallenrosa<br>Coral pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DE9BB8" data-brightness="174.844">
       RAL 350 70 30
       <div class="subtext">Technorosa<br>Techno pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E498B9" data-brightness="179.196">
       RAL 350 70 35
       <div class="subtext">Kaugummipink<br>Chewing gum pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D9C1CA" data-brightness="205.324">
       RAL 350 80 10
       <div class="subtext">Magnolienweiß<br>Magnolia white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0BFCB" data-brightness="200.284">
       RAL 350 80 15
       <div class="subtext">Frühlingsrosa<br>Spring pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E9BCCF" data-brightness="200.716">
       RAL 350 80 20
       <div class="subtext">Hellpink<br>Light pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DDD2D7" data-brightness="216.721">
       RAL 350 85 05
       <div class="subtext">Elfenweiß<br>Fairy white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E7CFD9" data-brightness="212.412">
       RAL 350 85 10
       <div class="subtext">Orchideenrosé<br>Orchid rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EECCD9" data-brightness="211.526">
       RAL 350 85 15
       <div class="subtext">Blasspink<br>Pale pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#EEE1E2" data-brightness="233.201">
       RAL 350 90 05
       <div class="subtext">Lichtrosé<br>Light rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4DBE5" data-brightness="228.892">
       RAL 350 90 10
       <div class="subtext">Windröschenweiß<br>Ranuncula white</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F4E8EC" data-brightness="238.284">
       RAL 350 93 05
       <div class="subtext">Clichyweiß<br>Clichy white</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#574247" data-brightness="72.908">
       RAL 360 20 15
       <div class="subtext">Piermontsteinrot<br>Piermont stone red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#5D5456" data-brightness="85.18">
       RAL 360 30 05
       <div class="subtext">Rußrot<br>Soot red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#635156" data-brightness="89.244">
       RAL 360 30 10
       <div class="subtext">Pfefferkornrot<br>Peppercorn red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#684E56" data-brightness="79.852">
       RAL 360 30 15
       <div class="subtext">Primitivoweinrot<br>Zinfandel red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#704D57" data-brightness="84.636">
       RAL 360 30 20
       <div class="subtext">Artdecorot<br>Art deco red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#734956" data-brightness="85.356">
       RAL 360 30 25
       <div class="subtext">Klinkerrot<br>Clinker red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#784657" data-brightness="84.636">
       RAL 360 30 30
       <div class="subtext">Kirschsaftrot<br>Cherry juice red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#814456" data-brightness="89.553">
       RAL 360 30 35
       <div class="subtext">Schlossrot<br>Palace red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#834257" data-brightness="90.14">
       RAL 360 30 40
       <div class="subtext">Glutrot<br>Ember red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#726769" data-brightness="104.683">
       RAL 360 40 05
       <div class="subtext">Röstschwarz<br>Roasted black</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#766369" data-brightness="106.444">
       RAL 360 40 10
       <div class="subtext">Mokkabraun<br>Mocha brown</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#7D6069" data-brightness="106.444">
       RAL 360 40 15
       <div class="subtext">Rubingrau<br>Ruby grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#845D69" data-brightness="101.836">
       RAL 360 40 20
       <div class="subtext">Barockrot<br>Baroque red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8A5A68" data-brightness="99.942">
       RAL 360 40 25
       <div class="subtext">Rauschrot<br>Frenzied red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#91576A" data-brightness="106.62">
       RAL 360 40 30
       <div class="subtext">Johannisbeerrot<br>Redcurrant</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#97546B" data-brightness="106.62">
       RAL 360 40 35
       <div class="subtext">Orientpink<br>Orient pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9E506C" data-brightness="106.62">
       RAL 360 40 40
       <div class="subtext">Auroramagenta<br>Aurora magenta</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A24D6A" data-brightness="101.292">
       RAL 360 40 45
       <div class="subtext">Kardinalrot<br>Cardinal red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A94669" data-brightness="101.436">
       RAL 360 40 50
       <div class="subtext">Salonrot<br>Parlour red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#887C7E" data-brightness="122.625">
       RAL 360 50 05
       <div class="subtext">Bimssteingrau<br>Pumice grey</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#8D787E" data-brightness="121.163">
       RAL 360 50 10
       <div class="subtext">Tresterrot<br>Pomace red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#95747E" data-brightness="127.708">
       RAL 360 50 15
       <div class="subtext">Abendpurpur<br>Evening crimson</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#9B727F" data-brightness="127.708">
       RAL 360 50 20
       <div class="subtext">Kupferrosa<br>Copper pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#A46E7D" data-brightness="123.1">
       RAL 360 50 25
       <div class="subtext">Jugendstilrosa<br>Art Nouveau pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#AA6B80" data-brightness="125.105">
       RAL 360 50 30
       <div class="subtext">Kosmetikrot<br>Cosmetic red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B26780" data-brightness="130.188">
       RAL 360 50 35
       <div class="subtext">Indischpink<br>Indian pink</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#B96680" data-brightness="129.601">
       RAL 360 50 40
       <div class="subtext">Kirroyalrosé<br>Kir royale rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C06281" data-brightness="134.972">
       RAL 360 50 45
       <div class="subtext">Hahnenkammrot<br>Cockscomb red</div>
    </div>
    <div (click)="test($event)" class="farbe " style="background-color:#C45D80" data-brightness="125.58">
       RAL 360 50 50
       <div class="subtext">Persischrot<br>Persian red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#9F9497" data-brightness="151.1">
       RAL 360 60 05
       <div class="subtext">Aschrosa<br>Ash pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#A58F95" data-brightness="145.916">
       RAL 360 60 10
       <div class="subtext">Majolikamauve<br>Majolica mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#AE8D96" data-brightness="146.492">
       RAL 360 60 15
       <div class="subtext">Feigenfruchtlila<br>Fig fruit mauve</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B78A97" data-brightness="150.7">
       RAL 360 60 20
       <div class="subtext">Edelflieder<br>Noble lilac</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#BD8597" data-brightness="151.276">
       RAL 360 60 25
       <div class="subtext">Hellrot<br>Light red</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#B9ADB0" data-brightness="174.668">
       RAL 360 70 05
       <div class="subtext">Teeblütenrosa<br>Tea blossom pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C0A7AE" data-brightness="177.148">
       RAL 360 70 10
       <div class="subtext">Quarzrosa<br>Quartz pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#C9A5AF" data-brightness="177.148">
       RAL 360 70 15
       <div class="subtext">Opalinrosa<br>Opaline pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#D3C7C9" data-brightness="205.324">
       RAL 360 80 05
       <div class="subtext">Perlmuttrosa<br>Mother-of-pearl pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#DBC2C8" data-brightness="205.324">
       RAL 360 80 10
       <div class="subtext">Venezianerrosa<br>Venetian pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E4BFC9" data-brightness="200.716">
       RAL 360 80 15
       <div class="subtext">Seifenrosa<br>Soap pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E0D2D4" data-brightness="221.804">
       RAL 360 85 05
       <div class="subtext">Theaterpuderrosé<br>Theatre powder rose</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#E8CFD5" data-brightness="212.412">
       RAL 360 85 10
       <div class="subtext">Lotusrosa<br>Lotus pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F1E2E1" data-brightness="237.708">
       RAL 360 90 05
       <div class="subtext">Eisrosa<br>Ice pink</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F7DDE3" data-brightness="228.305">
       RAL 360 90 10
       <div class="subtext">Tortencreme<br>Cake frosting</div>
    </div>
    <div (click)="test($event)" class="farbe dark" style="background-color:#F6E8E9" data-brightness="238.284">
       RAL 360 93 05
       <div class="subtext">Pfeilkrautweiß<br>Arrowhead white</div>
    </div>
 </div>
        
    `,
    styles: [
        '.row { margin: 0 0} input { margin: 0 10px } textarea { margin: 0 10px } .modal-footer {margin-top:20px; border-top:none}',
        '.subtext {pointer-events: none;}',
        '.farben {display: flex; flex-wrap:wrap;}',
        `.farbe {
            flex-direction:column;
            width: 120px;
            height: 120px;
            margin: 0 10px 10px 10px;
            color: #FFF;
            display: flex;
            text-align:center;
            justify-content: center;
            font-size: smaller; 
            font-weight: bold;
            cursor: pointer;
        }`,
        `.dark {
            color: #111;
        }`]
})


export class RalColorPickerDialogComponent {

    firstName : string = '';
    lastName : string = '';
    email : string = '';

    public event: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
    ) {}

    test(event: any)
    {   
        this.event.emit({colorString: event.target.textContent});
        this.bsModalRef.hide();
    }

}
