import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { REST_SERVER } from 'src/app/config';
import { Address } from 'src/app/model/address.model';
import { User } from 'src/app/model/user.model';
import { RepairCenter } from 'src/app/model/repair-center.model';
import { RepairCenterService } from 'src/app/service/repair-center.service';

@Component({
  selector: 'app-admin-repair-center-details',
  templateUrl: './admin-repair-center-details.component.html',
  styleUrls: ['./admin-repair-center-details.component.less']
})
export class AdminRepairCenterDetailsComponent implements OnInit {

  public sendingRegistration:boolean;
  public success:boolean;
  repairCenter = new RepairCenter();
  public editErrors:string[] = [];
  @ViewChild("editForm") editForm;
  @ViewChild('editErrorsDisplay') editErrorsDisplay:ElementRef;

  constructor(
    private _router:Router,
    private _repairCenterService:RepairCenterService,
    private _http:HttpClient,
    activatedRoute:ActivatedRoute,
  ) { 
    this.repairCenter.deliveryAddress = new Address();
    this.repairCenter.user = new User();

    activatedRoute.params.subscribe((params) => {
      let repairCenterId = parseInt(params.id);
      if(repairCenterId) {
        this._loadRepairCenter(repairCenterId);
      }
    });
  }

  ngOnInit() {
  }

  private async _loadRepairCenter(repairCenterId:number):Promise<void> {
    this.repairCenter = await this._repairCenterService.load(repairCenterId);
  }

  async onEdit():Promise<void> {
    this.editErrors = [];
    let success:boolean = false;
    this.repairCenter.deliveryAddress.name = this.repairCenter.name;
    this.sendingRegistration = true;
    try {
      await this._repairCenterService.save(this.repairCenter);
      //let res = <RepairCenter> await this._http.post(REST_SERVER + this.restPath, this.servicePartner).toPromise();
      success = true;
    } catch(exc) {
      console.log(exc);
      let code = exc.error && exc.error.error && exc.error.error.code;
      if(code === 'EMAIL_ALREADY_EXISTS') {
        this.editErrors.push("Die E-Mail-Adresse wird bereits verwendet");
      } else {
        this.editErrors.push("Unbekannter Fehler");
      }
      this.editErrorsDisplay.nativeElement.scrollIntoView(true);
      //window.scroll(0,0);
    }
    this.sendingRegistration = false;
    if(success) {
      this._router.navigate(['admin', 'repair-centers', 'list']);
    }
  }

  canSubmit():boolean {
    let result:boolean = true;
    result = result && this.editForm.form.valid;
    result = result && !this.sendingRegistration;
    return result;
  }
}
