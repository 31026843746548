import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { REST_SERVER } from 'src/app/config';
import { RepairCenter } from '../model/repair-center.model';

@Injectable({
  providedIn: 'root'
})
export class RepairCenterService {

  constructor(
    private _http:HttpClient
  ) { }

  public async load(id:number):Promise<any> {
    return await this._http.get(`${REST_SERVER}/repair-centers/${id}`).toPromise();
  }

  public async save(repairCenter:RepairCenter):Promise<void> {
    if(repairCenter.id) {
      await this._http.put(`${REST_SERVER}/repair-centers/${repairCenter.id}`, repairCenter).toPromise();
    } else {
      await this._http.post(`${REST_SERVER}/repair-centers`, repairCenter).toPromise();
    }
  }

  public async increasePriority(repairCenter:RepairCenter):Promise<any> {
    await this._http.post(`${REST_SERVER}/repair-centers/${repairCenter.id}/increase-priority`, {}).toPromise();
  }

  public async decreasePriority(repairCenter:RepairCenter):Promise<any> {
    await this._http.post(`${REST_SERVER}/repair-centers/${repairCenter.id}/decrease-priority`, {}).toPromise();
  }
}
