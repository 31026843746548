import { Address } from "./address.model";
import { User } from "./user.model";

export class ServicePartner {
    id:number;
    name:string;
    deliveryAddress:Address;
    invoiceAddress:Address;
    user:User;
    phone:string;
    state:string;
    isLogistician:boolean;
}