import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'modal-content',
    template: `
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{msg}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="close(true)">OK</button>
        <button type="button" class="btn btn-primary" (click)="close(false)">Abbrechen</button>
      </div>
    `
  })
   
  export class ConfirmDialogComponent implements OnInit {
    title: string;
    msg:string;

    public onClose: Subject<boolean> = new Subject<boolean>();
   
    constructor(public bsModalRef: BsModalRef) {}
   
    ngOnInit() {
      
    }

    public close(result:boolean): void {
      this.onClose.next(result);
      this.bsModalRef.hide();
    }
  }