import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { Invoice } from 'src/app/model/invoice.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GeneralConfirmDialogComponent } from 'src/app/components/ui/dialogs/general-confirm-dialog.component';

enum InvoiceAction {
  BOOK_PAYMENT,
};

@Component({
  selector: 'app-admin-invoice-list',
  templateUrl: './admin-invoice-list.component.html',
  styleUrls: ['./admin-invoice-list.component.less']
})
export class AdminInvoiceListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {
    ident: {
      type: "string",
      comp: "like",
      label: "Re.-nr.",
    },
    state: {
      value: "NONE",
      comp: "eq",
    },
    invoiceDate: {
      value: new Date(1970, 0, 1),
      comp: "lt",
    },
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _invoiceService:InvoiceService,
    private _modalService: BsModalService
  ) { 
    activatedRoute.params.subscribe(params => {
      this.state = params.state;
      let filterDesc = {...this.filterDesc};
      delete(filterDesc.state);
      delete(filterDesc.invoiceDate);

      if(params.state === 'new') {
        filterDesc.state = {
          value:  "PAYED",
          comp: "neq",
        };
        this.title = 'Unbezahlte Rechnungen';
      } else if(params.state === 'payed') {
        filterDesc.state = {
          value:  "PAYED",
          comp: "eq",
        };
        this.title = 'Bezahlte Rechnungen';
      } else if(params.state === 'dunning0') {
        filterDesc.state = {
          value:  "DUNNING_LEVEL_0",
          comp: "eq",
        };
        this.title = 'Zahlungserinnerungen';
      } else if(params.state === 'dunning1') {
        filterDesc.state = {
          value:  "DUNNING_LEVEL_1",
          comp: "eq",
        };
        this.title = 'Mahnung I';
      } else if(params.state === 'dunning2') {
        filterDesc.state = {
          value:  "DUNNING_LEVEL_2",
          comp: "eq",
        };
        this.title = 'Mahnung I';
      } else if(params.state === 'overdue') {
        filterDesc.state = {
          value:  "PAYED",
          comp: "neq",
        };
        let now:Date = new Date();
        let date:Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        filterDesc.invoiceDate = {
          value: date,
          comp: "lt",
        };
        this.title = 'Überfällige Rechnungen';
      }
      this.filterDesc = filterDesc;
    });
  }

  ngOnInit() {
  }

  public dateDiff(dateA, dateB):number {
    if(!dateA) {
      dateA = new Date();
    }
    if(!dateA.getFullYear) {
      dateA = new Date(dateA);
    }
    if(!dateB.getFullYear) {
      dateB = new Date(dateB);
    }
    let dateA0 = new Date(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
    let dateB0 = new Date(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());
    let diff:number = dateA0.valueOf() - dateB0.valueOf();
    return diff / (1000 * 60 * 60 * 24);
  }

  public getBaseUrl():string {
    return REST_SERVER + "/invoices";
  }

  public getActionsForInvoice(invoice:Invoice) {
    let result = [];
    if(invoice.state !== "PAYED") {
      result.push({ key: InvoiceAction.BOOK_PAYMENT, val: 'Zahlungseingang' });
    }
    return result;
  }

  public async actionSelected(invoice:Invoice, action):Promise<void> {
    switch(action.key) {
      case InvoiceAction.BOOK_PAYMENT:
        await this._invoiceService.markInvoicePayed(invoice);
        this._grid.update();
        break;
    }
  }

  createDunnings() {
    
    let initialState = {
      title: "Mahnungen erstellen",
      msg: "Möchten Sie Mahnungen für alle offenen Rechnungen erstellen?",
    }
    let ref: BsModalRef = this._modalService.show(GeneralConfirmDialogComponent, {initialState});
    ref.content.event.subscribe(() => this._invoiceService.createDunnings());
  }

  generateRepairCenterCredits() {
    this._invoiceService.generateRepairCenterCredits();
  }

  generateServicePartnerInvoices() {
    
    let initialState = {
      title: "Rechnugnen erstellen",
      msg: "Möchten Sie Rechnungen für alle abgeschlossenen Aufträge erstellen?"
    }
    let ref: BsModalRef = this._modalService.show(GeneralConfirmDialogComponent, {initialState});
    ref.content.event.subscribe(() => this._invoiceService.generateServicePartnerInvoices());
  }
}
