import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'modal-content',
    template: `
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{msg}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onConfirm()">{{closeBtnName}}</button>
      </div>
    `
  })
   
  export class MessageDialogComponent implements OnInit {
    title: string;
    closeBtnName: string = "OK";
    msg:string;

    public onClose: Subject<void> = new Subject<void>();
   
    constructor(public bsModalRef: BsModalRef) {}
   
    ngOnInit() {
      
    }

    public onConfirm(): void {
        this.onClose.next();
        this.bsModalRef.hide();
    }
  }