import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { ActivatedRoute } from '@angular/router';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-service-partner-create',
  templateUrl: './admin-service-partner-create.component.html',
  styleUrls: ['./admin-service-partner-create.component.less']
})
export class AdminServicePartnerCreateComponent implements OnInit {

  ngOnInit() {
  }
}
