import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateIf'
})
export class DateIfPipe implements PipeTransform {

  transform(value:any, args?: any): any {
    var result:string = value;
    if(value && value.match && value.match(/\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/)) {
      result = '';
      var d:Date = new Date(value);
      result += ("00" + d.getDate()).substr(-2) + ".";
      result += ("00" + (d.getMonth() + 1)).substr(-2) + ".";
      result += d.getFullYear() + " ";
      result += ("00" + d.getHours()).substr(-2) + ":";
      result += ("00" + d.getMinutes()).substr(-2) + ":";
      result += ("00" + d.getSeconds()).substr(-2);
    }
    return result;
  }

}
