import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CmsService } from 'src/app/service/cms.service';
import { CmsBlock } from 'src/app/model/cms-block.model';
import { CmsPage } from 'src/app/model/cms-page.model';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { REST_SERVER } from '../../../config';
import { UserService } from '../../../service/user.service';
import { User } from '../../../model/user.model';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { FeedbackService} from 'src/app/service/feedback.service';

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.less'],
})
export class CmsPageComponent {

    private static _cache:any = {};

    @Input() page:CmsPage;
    private _pageIdent:string;

    constructor(
        private _cmsService:CmsService,
        private _userService:UserService,
        private _servicePartnerService:ServicePartnerService,
        private _router:Router,
        private _ngZone:NgZone,
        private _titleService:Title,
        private _metaService:Meta,
        private _http:HttpClient,
        private _feedbackService:FeedbackService,
    ) {
        
    }

    ngOnInit():void {
        let windowAny:any = <any>window;
        windowAny.cmsComponent = this;

        let scriptPath:string = `${REST_SERVER}/cms/js`;

        //console.log("Searching script tag");
        let scripts:HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName("script");
        let found:boolean = false;
        for(let i = 0; i < scripts.length; i++) {
            let script = scripts[i];
            if(script.getAttribute("src") === scriptPath) {
                found = true;
                break;
            }
        }

        if(!found) {
            let head = document.getElementsByTagName('head')[0];
            let script = document.createElement("script");
            script.setAttribute("src", scriptPath);
            head.appendChild(script);
        }

        //load global stylesheet
        let stylesheetPath:string = `${REST_SERVER}/cms/css`;
        this.loadStyleSheet(stylesheetPath);

        //load page specific stylesheet
        stylesheetPath = `${REST_SERVER}/cms/css/${this._pageIdent}`;

        this.loadStyleSheet(stylesheetPath);
    }

    ngOnDestroy() {
        let windowAny:any = <any>window;
        if(windowAny.cmsComponent === this) {
            windowAny.cmsComponent = null;
        }

        this._titleService.setTitle("FelgenHero – Professionelle Felgenaufbereitung");
        this._metaService.removeTag('name = "description"');
    }

    @Input() 
    set pageIdent(pageIdent:string) {
        this._pageIdent = pageIdent;
        (async ():Promise<void> => {
            if(this._pageIdent && CmsPageComponent._cache[pageIdent]) {
                this.page = CmsPageComponent._cache[pageIdent];
            } else if(this._pageIdent) {
                await this._loadPage(this._pageIdent);
            }
            this._titleService.setTitle(this.page.title);
            this._metaService.addTag({name: 'description', content: this.page.description});
            
            setTimeout(() => {
                let windowAny:any = window as any;
                if(windowAny.cmsPageChanged) {
                    windowAny.cmsPageChanged(pageIdent);
                }
            }, 0);
        })();
    }

    get pageIdent():string {
        return this._pageIdent;
    }

    getPageId():string {
        let result:string = 'page';
        if(this.page && this.page.path) {
            result += "-" + this.page.path.replace(/\//g, '-');
        }
        return result;
    }

    private async _loadPage(pageIdent:string):Promise<void> {
        //console.log("Loading page", pageIdent);
        this.page = await this._cmsService.load(pageIdent);
        CmsPageComponent._cache[pageIdent] = this.page;
    }

    public searchServicePartner() {
        this._ngZone.run(() => {
            let zip = (<HTMLInputElement>document.getElementById("zipSearch")).value;
            
            if(zip.length == 5) {
                this._router.navigate(['/servicepartner-suche'], { queryParams: { q: zip }});
            }
        });
    }

    async onLogin(email, password):Promise<void> {
        this._ngZone.run(async () => {
            try {
                let loginModel = new User();
                loginModel.email = email;
                loginModel.password = password;
                var loginResult:any = await this._userService.login(loginModel);
            } catch(exc) {
                
                this._feedbackService.showError("Login fehlerhaft, bitte Email-Passwort Kombination prüfen");
            }
        });
    }

    async onRequestNewPassword(email):Promise<void> {
        await this._ngZone.run(async () => {
            try {
                await this._userService.forgotPassword({ email });

            } catch(exc) {
                this._feedbackService.showError("Passwort konnte nicht zurückgesetzt werden. Bitte überprüfen Sie ob eine bereits registrierte Email angegeben wurde.");
                throw "error";
            }
        });
    }

    navigate(path):void {
        this._ngZone.run(async () => {
            this._router.navigate([path]);
        });
    }

    async loadUser():Promise<User> {
        return this._userService.user;
    }

    async loadServicePartner():Promise<ServicePartner> {
        let user:User = this._userService.user;
        return await this._servicePartnerService.load((<any>user).servicePartnerId);
    }

    async placeOrderMarketingMaterial(orderFlyerBox:boolean, orderFlyers:boolean):Promise<void> {
        let servicePartnerId:number = this._userService.user.servicePartnerId;
        await this._servicePartnerService.placeMarketingMaterialOrder(servicePartnerId, orderFlyerBox, orderFlyers);
    //this.orderPlaced = true;
    }

    async subscribeNewsletter(newsletterEmail):Promise<void> {
        let subscriptionData = {
          recipient: {
            email: newsletterEmail,
          }, 
          lists: [],
          captcha: {},
        };
        await this._http.post('https://api.newsletter2go.com/forms/submit/t0x41u5f-goy4eymv-q2g?type=subscribe', subscriptionData).toPromise();
      }
    
    loadStyleSheet(stylesheetPath) 
    {   
        let stylesheets:HTMLCollectionOf<HTMLLinkElement> = document.getElementsByTagName("link");
        let found:boolean = false;
        for(let i = 0; i < stylesheets.length; i++) {
            let stylesheet = stylesheets[i];
            if(stylesheet.getAttribute("href") === stylesheetPath) {
                found = true;
                break;
            }
        }

        if(!found) {
            let head = document.getElementsByTagName('head')[0];
            let stylesheet = document.createElement('link');
            stylesheet.setAttribute('rel', 'stylesheet');
            stylesheet.setAttribute('href', stylesheetPath);
            head.appendChild(stylesheet);
        }
    }
}
