import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { Invoice } from 'src/app/model/invoice.model';
import { UserService } from 'src/app/service/user.service';
import { ViewerDialogComponent } from 'src/app/components/ui/dialogs/viewer-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

enum InvoiceAction {
  BOOK_PAYMENT,
};

@Component({
  selector: 'app-repair-center-invoice-list',
  templateUrl: './repair-center-invoice-list.component.html',
  styleUrls: ['./repair-center-invoice-list.component.less']
})
export class RepairCenterInvoiceListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  filterDesc = {
    ident: {
      type: "string",
      comp: "like",
      label: "Re.-nr.",
    },
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _invoiceService:InvoiceService,
    private _userService:UserService,
    private _modalService:BsModalService,
  ) { 
 
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    let repairCenterId:number = this._userService.user.repairCenterId;
    return `${REST_SERVER}/repair-centers/${repairCenterId}/invoices`;
  }

  calcInvoiceDownloadHref(invoice:Invoice):string {
    let repairCenterId:number = this._userService.user.repairCenterId;
    let token:string = this._userService.token;
    return `${REST_SERVER}/repair-centers/${repairCenterId}/invoices/${invoice.ident}?access_token=${token}`;
  }

  openInvoicePdf(invoice:Invoice) {
    let initialState = {
      title: `Abrechnung ${invoice.ident}`,
      path: this.calcInvoiceDownloadHref(invoice),
      msg: "",
    }
    let ref:BsModalRef = this._modalService.show(ViewerDialogComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true, keyboard: false});
  }
}
