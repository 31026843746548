import { Address } from './address.model';
import { User } from './user.model';

export class RepairCenter {
    id: number;
    name: string;
    deliveryAddress:Address;
    user:User;
    phone:string;
    capacitySilver:number;
    capacityColored:number;
    capacityPolish:number;
    capacityLuxury:number;
    pendingRepairOrdersSilver:number;
    pendingRepairOrdersColored:number;
    pendingRepairOrdersPolish:number;
    pendingRepairOrdersLuxury:number;
    priceSilver17:number;
    priceSilver18:number;
    priceColored17:number;
    priceColored18:number;
    pricePolish17:number;
    pricePolish18:number;
    priceLuxury17:number;
    priceLuxury18:number;
    priceMounting:number;
    priority:number;
    abbreviation: string;
}