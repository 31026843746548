import { ServicePartner } from 'src/app/model/service-partner.model';
import { Address } from 'src/app/model/address.model';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { RepairCenter } from 'src/app/model/repair-center.model';

export class InvoiceItem {
    description:string;
    amountSingleNet:number;
    amountNet:number;
    count:number;
}

export class Invoice {
    ident:string;
    type:string;
    state:string;
    address:Address
    servicePartner:ServicePartner;
    repairCenter:RepairCenter;
    repairOrder:RepairOrder;
    date:string;
    amountNet:number;
    amountVat:number;
    amountTotal:number;
    items:InvoiceItem[];
}