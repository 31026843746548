import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user.model';
import { UserService } from 'src/app/service/user.service';
import { RepairOrderService} from 'src/app/service/repair-order.service';
import { FeedbackService } from 'src/app/service/feedback.service';

@Component({
  selector: 'app-repair-center-dashboard',
  templateUrl: './repair-center-dashboard.component.html',
  styleUrls: ['./repair-center-dashboard.component.less']
})
export class RepairCenterDashboardComponent implements OnInit {

  orderId:string;
  
  searchStringNewOrder:string;
  searchStringRepairedOrder:string;

  orderIdRegex =  /^A(\d{6})\-\d{8}$/;
  @ViewChild('orderIdInput') orderIdInput;

  showingNewRimSection : boolean;
  showingFinishOrderSection : boolean;

  constructor(
    private _router:Router,
    private _userService:UserService,
    private _repairOrderService:RepairOrderService,
    private _feedbackService:FeedbackService,
  ) { 
    
  }

  ngOnInit() {
    this.orderIdInput.setFocus();
  }

  isOrderIdValid():boolean {
    return !!/^\d+$/.exec(this.orderId) || !!this.orderIdRegex.exec(this.orderId);
  }

  async process() {

    let checkedString : string;

    if(this.searchStringNewOrder)
    {
      checkedString = this.checkSearchStringNewOrder(this.searchStringNewOrder);
      console.log(checkedString);

      //if string was a sendingNr get the order with its filter
      let repairOrder = await this._repairOrderService.loadForRepairCenterFiltered({ where: { shippingOrderSend: { eq: checkedString } } }, this._userService.user.repairCenterId);
      if (repairOrder.length > 0) {
        //use shippingId
        this.orderId = repairOrder[0].id;
      } else {
        //use orderId
        this.orderId = checkedString;
      }
      
    } else if (this.searchStringRepairedOrder)
    {
      
      checkedString = this.checkSearchStringRepairedOrder(this.searchStringRepairedOrder);
      this.orderId = checkedString;
      console.log(checkedString);
    }

    if(!this.orderId)
    {
      this._feedbackService.showError("Falsches Auftragsformat. Bitte Hinweistext lesen");
      return;
    }
    
    this._router.navigate(['repair-center', 'repair-orders', 'process', this.orderId]);
  }

  get username():string {
    let user:User = this._userService.user;
    return `${user.firstName} ${user.lastName}`;
  }

  checkSearchStringNewOrder(searchString:string)
  { 
    //check if felgenheroId was used
    let regEx = /^A(\d{6})/;
    let match = regEx.exec(searchString);
    
    if(match)
    { 
      //remove zeros
      let outstring = match[1].replace(/^0+/,''); 
      return outstring;
    } 

    // check if it could be a sendingNr
    regEx = /^[0-9].+$/
    match = regEx.exec(searchString);

    if(match)
    {
      return match[0];
    }

    //with this construct you can stil find orders without specifiing the full id with A000 etc.
    //but instead only use the 'real' id after the trailing zeros 
  }

  checkSearchStringRepairedOrder(searchString: string)
  { 
    let outstring:string;

    //check if repairCenter code was used
    let regEx = /^[A-Z]{3}10(.+)$/;
    let match = regEx.exec(searchString);
    if(match)
    {
      outstring = match[1];
      //felgenheroID is left 
    } else
    {
      outstring = searchString;
    }

    //check if felgenheroId was used
    regEx = /^A(\d{6})/;
    match = regEx.exec(outstring);
    
    if(match)
    { 
      //remove zeros
      let outstring = match[1].replace(/^0+/,''); 
      return outstring;
    } 

    //for now also allow raw (only digits no trainling zeros) version of orderID
    regEx = /^[0-9].+$/
    match = regEx.exec(searchString);

    if(match)
    {
      let outstring = match[0].replace(/^0+/,''); 
      return outstring;
    }
    
  }

  showNewRimSection() {

    this.showingFinishOrderSection = false;

    if(this.showingNewRimSection) 
    {
      this.showingNewRimSection = false;
    } else {
      this.showingNewRimSection = true;
    }
  }

  showRepairedRimSection() {

    this.showingNewRimSection = false;

    if(this.showingFinishOrderSection)
    {
      this.showingFinishOrderSection = false;
    } else {
      this.showingFinishOrderSection = true;
    }
  }

}
