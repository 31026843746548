import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-inputselect',
  templateUrl: './inputselect.component.html',
  styleUrls: ['./inputselect.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputselectComponent),
      multi: true
    }
  ],
})
export class InputselectComponent implements OnInit, ControlValueAccessor {

  @Input() icon;
  @Input() label;
  @Input() loading:boolean = false;
  @Input() inline:boolean = false;

  selectedItem = null;

  private _value:string = null;
  private _items;
  
  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  constructor() {
   }

  ngOnInit() {
  }

  selectItem(item) {
    this.value = item.key;
  }

  @Input()
  get items() {
    return this._items;
  }

  set items(items) {
    console.log("Setting items", items);
    this._items = items;
    this.value = this.value;
  }

  get value():string {
    return this._value;
  }

  set value(value: string) {
    var item = this.items.find((item) => { return item.key == value });
    /*
    if(item == null) {
      value = null;
    }
    */

    if(value != this._value) {
      this._value = value;
      this.selectedItem = item;
      this._onChange(value);
    }
  }

  writeValue(value: string):void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }

}
