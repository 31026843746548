import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { ServicePartnerService } from 'src/app/service/service-partner.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.less']
})
export class ConfirmEmailComponent implements OnInit {

  sending:boolean = false;
  confirmEmailCompleted:boolean = false;

  constructor(
    activatedRoute:ActivatedRoute,
    private _servicePartnerService:ServicePartnerService,
  ) { 
    activatedRoute.queryParams.subscribe(params => {
      //this.changePasswordModel.key = params.key;
      this.onConfirmEmail(params.key)
    });
  }

  ngOnInit() {
  }

  async onConfirmEmail(key:string) {
    this.sending = true;
    try {
      await this._servicePartnerService.confirmEmail({key});
    } catch(exc) {
      // login failed
    }
    this.sending = false;
    this.confirmEmailCompleted = true;
  }

}
