import { Pipe, PipeTransform } from '@angular/core';
import { RepairOrder } from 'src/app/model/repair-order.model';

@Pipe({
  name: 'orderId'
})
export class OrderIdPipe implements PipeTransform {

  transform(repairOrder:RepairOrder, args?: any): any {
    if(!repairOrder) {
        return '';
    }
    let result = 'A';
    result += ('000000' + repairOrder.id).substr(-6);
    result += '-';
    let date = new Date(repairOrder.orderDate);
    result += date.getFullYear();
    result += ("00" + (date.getMonth() + 1)).substr(-2);
    result += ("00" + date.getDate()).substr(-2);
    return result;
  }

}