import { Component, OnInit } from '@angular/core';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { REST_SERVER } from 'src/app/config';

@Component({
  selector: 'app-service-partner-order-pickup',
  templateUrl: './service-partner-order-pickup.component.html',
  styleUrls: ['./service-partner-order-pickup.component.less']
})
export class ServicePartnerOrderPickupComponent implements OnInit {

  currentOrders:any;

  constructor(
    private activatedRoute:ActivatedRoute,
    private _router:Router,
  ) { }

  ngOnInit() {
    const orderParam = this.activatedRoute.snapshot.paramMap.get('currentOrders');
    this.currentOrders = JSON.parse(orderParam);
  }

  recordAnotherRim() {
    this._router.navigate(['service-partner', 'order', 'form', {currentOrder: JSON.stringify(this.currentOrders[0])}]);
  }

}
