import { Component, OnInit } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-repair-center-order-list',
  templateUrl: './repair-center-order-list.component.html',
  styleUrls: ['./repair-center-order-list.component.less']
})
export class RepairCenterOrderListComponent implements OnInit {
  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public icon:string = '';
  public state:string;

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {
    id: {
      type: "string",
      comp: "orderId",
      label: "Auftragsnr.",
    },
    rimCount: {
      type: "string",
      comp: "eq",
      label: "Anz. Felgen",
    },
    creditIdent: {
      type: "string",
      comp: "like",
      label: "Abrechnung Nr.",
    },
    state: {
      value: "NONE",
      comp: "eq",
    },
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _userService:UserService,
  ) { 
    activatedRoute.params.subscribe(params => {
      this.state = params.state;
      let filterDesc = {...this.filterDesc};
      filterDesc.state = {...this.filterDesc.state};
      if(params.state === 'new') {
        filterDesc.state.value = "NEW";
        this.title = 'Aktuelle Aufträge';
        this.icon = 'incoming-repair-orders';
      } else if(params.state === 'progress') {
        filterDesc.state.value = "REPAIR_IN_PROGRESS";
        this.title = 'Aufträge in Bearbeitung';
        this.icon = 'repair-orders-in-progress';
      } else if(params.state === 'finished') {
        filterDesc.state.value = "REPAIR_FINISHED";
        this.title = 'Aufträge in Rücklieferung';
        this.icon = 'repair-orders-delivery';
      } else if(params.state === 'canceled') {
        filterDesc.state.value = "CANCELED";
        this.title = 'Stornierte Aufträge';
        this.icon = 'current-repair-orders';
      }
      this.filterDesc = filterDesc;
    });
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    let repairCenterId:number = this._userService.user.repairCenterId;
    return `${REST_SERVER}/repair-centers/${repairCenterId}/repair-orders`;
  }
}
