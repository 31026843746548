import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { REST_SERVER } from 'src/app/config';
import { UserService } from 'src/app/service/user.service';
import { ConfirmDialogComponent } from 'src/app/components/ui/dialogs/confirm-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-admin-service-partner-details',
  templateUrl: './admin-service-partner-details.component.html',
  styleUrls: ['./admin-service-partner-details.component.less']
})
export class AdminServicePartnerDetailsComponent implements OnInit {

  servicePartnerData = [];
    
  currentOrdersFilterJson:string = null;

  public servicePartner = null;
  public servicePartnerFiles = [];

  constructor(
    activatedRoute:ActivatedRoute,
    private _servicePartnerService:ServicePartnerService,
    private _userService:UserService,
    private _modalService:BsModalService,
    private _location:Location,
  ) { 
    activatedRoute.params.subscribe(params => {
      this._loadServicePartner(params.id);
      this._loadServicePartnerFiles(params.id);
      this.currentOrdersFilterJson = JSON.stringify({
        where: {
          servicePartnerId: {
            eq: params.id
          }
        }
      });
    });
  }

  ngOnInit() {
  }

  private async _loadServicePartner(id:number):Promise<void> {
    this.servicePartner = await this._servicePartnerService.load(id);
    let servicePartnerData = [];
    let servicePartner:any = {...this.servicePartner};
    delete servicePartner.phone;
    delete servicePartner.lat;
    delete servicePartner.lng;
    delete servicePartner.displayOnMap;
    delete servicePartner.confirmEmailKey;
    delete servicePartner.user;
    servicePartner.state = 'service_partner_state_' + servicePartner.state.toLowerCase();
    let deliveryAddress = {...servicePartner.deliveryAddress};
    delete servicePartner.deliveryAddress;
    let invoiceAddress = {...servicePartner.invoiceAddress};
    delete servicePartner.invoiceAddress;

    for(let key in servicePartner) {
      let item = { key, value: servicePartner[key] };
      servicePartnerData.push(item);
    }
    
    delete deliveryAddress.id;
    delete deliveryAddress.name;
    deliveryAddress.contact = deliveryAddress.contactFirstName + " " + deliveryAddress.contactLastName;
    delete deliveryAddress.contactFirstName;
    delete deliveryAddress.contactLastName;
    for(let key in deliveryAddress) {
      let item = { key: `deliveryAddress_${key}`, value: deliveryAddress[key] };
      servicePartnerData.push(item);
    }
    delete invoiceAddress.id;
    delete invoiceAddress.name;
    invoiceAddress.contact = invoiceAddress.contactFirstName + " " + invoiceAddress.contactLastName;
    delete invoiceAddress.contactFirstName;
    delete invoiceAddress.contactLastName;
    for(let key in invoiceAddress) {
      let item = { key: `invoiceAddress_${key}`, value: invoiceAddress[key] };
      servicePartnerData.push(item);
    }
    this.servicePartnerData = servicePartnerData;
  }

  private async _loadServicePartnerFiles(id:number):Promise<void> {
    this.servicePartnerFiles = await this._servicePartnerService.getDocumentNames(id);
  }

  getBaseUrlOrders():string {
    return REST_SERVER + "/repair-orders";
  }

  extractFilename(filename:string) {
    return decodeURIComponent(filename);
  }

  calcDocumentDownloadHref(filename):string {
    let token:string = this._userService.token;
    return `${REST_SERVER}/service-partners/${this.servicePartner.id}/documents/${filename}?access_token=${token}`;
  }

  deleteDocument(filename):void {
    let initialState = {
      title: "Dokument löschen",
      msg: `Wollen Sie dieses Dokument wirklich löschen?`,
    }
    let ref:BsModalRef = this._modalService.show(ConfirmDialogComponent, {initialState});
    ref.content.onClose.subscribe(async result => {
      if(result) {
        await this._servicePartnerService.deleteDocument(this.servicePartner.id, filename);
        this._loadServicePartnerFiles(this.servicePartner.id);
      }
    });
  }

  handleFileSelect(evt):void {
    let files:File[] = evt.target.files;
    if(files.length > 0) {
      let file:File = files[0];
    
      var reader = new FileReader();
      
      // Closure to capture the file information.
      reader.onload = async (evt:any) => {
        let data:ArrayBuffer = evt.target.result;
        await this._servicePartnerService.addDocument(this.servicePartner.id, file.name, file.type, data);
        this._loadServicePartnerFiles(this.servicePartner.id);
      };

      // Read in the image file as a data URL.
      reader.readAsArrayBuffer(file);
    }
  }

  goBack() {
    this._location.back();
  }

  public async activateServicePartner():Promise<void> {
    await this._servicePartnerService.activate(this.servicePartner);
    this._loadServicePartner(this.servicePartner.id);
  }

  public async denyServicePartner():Promise<void> {
    await this._servicePartnerService.denyActivation(this.servicePartner);
    this._loadServicePartner(this.servicePartner.id);
  }

  public async saveDisplayOnMap():Promise<void> {
    this._servicePartnerService.saveDisplayOnMap(this.servicePartner.id, this.servicePartner.displayOnMap);
  }
}
