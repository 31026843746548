import { Injectable } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { Setting } from 'src/app/model/setting.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private priceSubject:BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(
        private _http:HttpClient,
    ) { 
        
    }

    async getServicePartnerSettingsDict():Promise<any> {
        let result = {};
        let settings:Setting[] = <Setting[]>await this._http.get(REST_SERVER + "/cms/service-partner/settings").toPromise();
        for(let setting of settings) {
            result[setting.key] = setting;
        }
        return result;
    }
}