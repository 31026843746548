import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GOOGLE_MAPS_API_KEY } from 'src/app/config';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { Router, ActivatedRoute } from '@angular/router/';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {

  @ViewChild('map') mapElem:ElementRef;

  lat:number = 51.1840819;
  lng:number = 10.2377557;
  zoom:number = 6;

  searchZip:string = '';
  searchZipInput:string = '';

  iconUrl:string = '/assets/img/mapmarker_sm.png';
  servicePartners = [];
  servicePartnersSorted = null;
  selectedServicePartner = null;

  constructor(
    private _http:HttpClient,
    private _servicePartnerService:ServicePartnerService,
    activatedRoute:ActivatedRoute,
    private _router:Router,
    private _titleService: Title,
    private _metaService:Meta,
  ) {
    activatedRoute.queryParams.subscribe((params) => {
      this.searchZip = params.q;
      if(this.searchZip) {
        this.performSearch();
      }
    });
  }

  ngOnInit() {
    this._titleService.setTitle("FelgenHero – Felgenreparatur ServicePartner Suche");
    this._metaService.addTag({name: 'description', content: 'Finden Sie Ihren FelgenHero ServicePartner in der Nähe. Einfache Postleitzahlen-Suche. Felgenaufbereitung vor Ort beim ServicePartner buchen.'});

    this._loadServicePartners();
  }

  ngOnDestroy() {
    this._titleService.setTitle("FelgenHero – Professionelle Felgenreparatur");
    this._metaService.removeTag('name = "description"');
  }

  private async _loadServicePartners():Promise<void> {
    this.servicePartners = await this._servicePartnerService.loadAll({
      where: {
        lat: {
          neq: null,
        }
      }
    });
    if(this.searchZip) {
      this._fillResultList();
    }
  }

  doSearch() {
    this._router.navigate(['/servicepartner-suche'], { queryParams: { q: this.searchZip }});
  }

  async performSearch() {
    let url:string = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.searchZip},Deutschland&key=${GOOGLE_MAPS_API_KEY}`;
    let result = <any>await this._http.get(url).toPromise();

    if(result.status === 'OK' && result.results.length > 0) {
      let location = result.results[0].geometry.location;
      this.lat = location.lat;
      this.lng = location.lng;
      this.zoom = 11;

      this._fillResultList();
    }

    this.mapElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private _fillResultList() {
    let servicePartnersClone = [...this.servicePartners];
    servicePartnersClone.map(servicePartner => {
      servicePartner.dist = this.distance(this.lat, this.lng, servicePartner.lat, servicePartner.lng);
    });
    servicePartnersClone.sort((a, b) => {
      if(a.dist == b.dist) {
        return 0;
      } else if(a.dist < b.dist) {
        return -1;
      } else {
        return 1;
      }
    });
    servicePartnersClone = servicePartnersClone.splice(0, 5);
    this.servicePartnersSorted = servicePartnersClone;
  }

  distance(lat1:number, lon1:number, lat2:number, lon2:number) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      //if (unit=="N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  roundDist(dist:number):string {
    return (Math.round(dist * 10) / 10).toFixed(1).replace(".", ",");
  }

  selectServicePartnerFocus(servicePartner) {
    this.selectedServicePartner = servicePartner;
    this.lat = servicePartner.lat;
    this.lng = servicePartner.lng;
  }

}
