import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PrinterService } from 'src/app/service/printer.service';
import { FeedbackService } from 'src/app/service/feedback.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'modal-content',
    styles: [`
        iframe {
            width: 100%; height: 40em;
            background: url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"100%\" viewBox=\"0 0 100% 100%\"><text fill=\"%23000000\" x=\"50%\" y=\"50%\" font-size=\"24\" text-anchor=\"middle\">Bitte warten ...</text></svg>') 0px 0px no-repeat;
            border: 1px solid gray;
        }
        mat-spinner {
          margin: auto;
        }
        #print-message {
          margin: 50px 0 50px 0;
        }
        #print-message p {
          text-align: center;
          font-size: larger;
        }
    `],
    template: `
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="loading">
        <p style="text-align:center;">Verbindung zum Drucker wird hergestellt</p>
        <mat-spinner></mat-spinner>
      </div>
      <div class="modal-body" *ngIf="!loading && !printing">
        <p>{{ msg }}</p>
        <iframe [srcdoc]="labelHtml" ></iframe>
      </div>
      <div id="print-message" *ngIf="printing">
          <p>Label wird gedruckt, bitte warten... </p>
          <mat-spinner></mat-spinner>
        </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="isBoxPrinterAvailable || printing" (click)="onPrintInBrowser()">{{printBrowserBtnName}}</button>
        <button type="button" class="btn btn-primary" [disabled]="!isBoxPrinterAvailable || printing" (click)="onPrintLabel()">{{printBtnName}}</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="printing">{{closeBtnName}}</button>
      </div>
    `
  })
   
  export class PrintDialogRepairCenterComponent implements OnInit {
    title: string;
    msg: string = '';
    closeBtnName: string = "OK";
    printBtnName: string = "mit Box-Drucker drucken";
    printBrowserBtnName: string = "mit Browser Drucken";
    path:string;
    isBoxPrinterAvailable:boolean = false;
    loading: boolean = false;
    printing: boolean = false;

    html:string;

    public onClose: Subject<void> = new Subject<void>();
   
    constructor(
      public bsModalRef: BsModalRef, 
      private _sanitizer:DomSanitizer,
      private _printerService:PrinterService,
      private _feedbackService:FeedbackService,
      private _http:HttpClient,
    ) {}
   
    ngOnInit() {

      (async () => {
        await this.checkPrinter();
        await this.getHtml();
        await this.setLabelUrl()
        console.log(this.html);
      })();

    }

    async getHtml() {
      this.html  = await this._http.get('./assets/labelDisplay.html', {responseType: 'text'}).toPromise();
    }

    get labelHtml() {
      return this._sanitizer.bypassSecurityTrustHtml(this.html);
    }

    async setLabelUrl() {
      let pathPrefix = window.location.protocol + "//" + window.location.host;
      this.html = this.html.replace('@@LABELURL@@', pathPrefix + this.path);
    }

    async checkPrinter() {
      /*this.loading = true;

      if (await this._printerService.checkBoxPrinter() == 200) {
        
        this.isBoxPrinterAvailable = true;
        
        //toolbar=0 added to remove print option, since we want to provide a custom print option
        //This is rather hacky and might not work on all browsers
        this.path += "#toolbar=0";
        this.loading = false;

        console.log("printer is accessible");
      } else {
        //should only be the case for a timeout from the printer app, then we still
        //want to provide the default iframe toolbar
        this.loading = false;
        console.log("Printer not accessible");
        this._feedbackService.showError("Drucker nicht erreichbar");
      }*/
      this.isBoxPrinterAvailable = true;
    }

    public async onPrintLabel(): Promise<void> {

      this.printing = true;
      try {
        await this._printerService.printShippingLabel(this.path);
      } catch(err) {
        this._feedbackService.showError(err);
      } finally {
        this.printing = false;
      }
    }

    public async onPrintInBrowser(): Promise<void> {

      const iframeWindow = document.querySelector("iframe").contentWindow;
      iframeWindow.print();
    }

    public onConfirm(): void {
        this.onClose.next();
        this.bsModalRef.hide();
    }

    get sanitizedPath() {
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.path);
    }   
  }