import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { Router } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { CmsService } from 'src/app/service/cms.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '../../../../../ui/dialogs/confirm-dialog.component';

enum RepairOrderActions {
  CREATE_INVOICE
};

@Component({
  selector: 'app-cms-files-list',
  templateUrl: './admin-cms-files-list.component.html',
  styleUrls: ['./admin-cms-files-list.component.less']
})
export class AdminCmsFilesListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {};

  constructor(
    private _cmsService:CmsService,
    private _router:Router,
    private _modalService: BsModalService,
  ) { 
    
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    return REST_SERVER + "/cms/admin/files";
  }

  public calcPath(row):string {
    return `${REST_SERVER}/cms/files/` + encodeURIComponent(row);
  }

  public calcDisplayPath(row):string {
    return '###api_url###/cms/files/' + encodeURIComponent(row);
  }

  handleFileSelect(evt):void {
    let files:File[] = evt.target.files;
    if(files.length > 0) {
      let file:File = files[0];
    
      var reader = new FileReader();
      
      // Closure to capture the file information.
      reader.onload = async (evt:any) => {
        let data:ArrayBuffer = evt.target.result;
        await this._cmsService.uploadFile(file.name, file.type, data);
        this._grid.update();
      };

      // Read in the image file as a data URL.
      reader.readAsArrayBuffer(file);
    }
  }

  deleteFile(filename):void {
    let initialState = {
      title: "Datei löschen",
      msg: `Wollen Sie wirklich die Daten ${filename} löschen?`,
    }
    let ref:BsModalRef = this._modalService.show(ConfirmDialogComponent, {initialState});
    ref.content.onClose.subscribe(async result => {
      if(result) {
        await this._cmsService.deleteFile(filename);
        this._grid.update();
      }
    });
  }
}
