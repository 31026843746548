import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CmsPage } from '../../../../../../model/cms-page.model';

@Component({
    selector: 'admin-cms-preview-dialog',
    template: `
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{cmsPage.title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-cms-page [page]="cmsPage"></app-cms-page>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onConfirm()">{{closeBtnName}}</button>
      </div>
    `
  })
   
  export class AdminCmsPreviewDialogComponent implements OnInit {
    closeBtnName: string = "OK";
    path:string;
    cmsPage:CmsPage;

    public onClose: Subject<void> = new Subject<void>();
   
    constructor(public bsModalRef: BsModalRef, 
        private _sanitizer:DomSanitizer) {}
   
    ngOnInit() {
      
    }

    public onConfirm(): void {
        this.onClose.next();
        this.bsModalRef.hide();
    }

    get sanitizedPath() {
        return this._sanitizer.bypassSecurityTrustResourceUrl(this.path);
    }   
  }