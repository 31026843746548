import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';
import { Invoice } from 'src/app/model/invoice.model';
import { UserService } from 'src/app/service/user.service';

enum InvoiceAction {
  BOOK_PAYMENT,
};

@Component({
  selector: 'app-service-partner-invoice-list',
  templateUrl: './service-partner-invoice-list.component.html',
  styleUrls: ['./service-partner-invoice-list.component.less']
})
export class ServicePartnerInvoiceListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {
    ident: {
      type: "string",
      comp: "like",
      label: "Re.-nr.",
    },
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _invoiceService:InvoiceService,
    private _userService:UserService,
  ) { 
 
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    let servicePartnerId:number = this._userService.user.servicePartnerId;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/invoices`;
  }

  calcInvoiceDownloadHref(invoice:Invoice):string {
    let servicePartnerId = this._userService.user.servicePartnerId;
    let token:string = this._userService.token;
    return `${REST_SERVER}/service-partners/${servicePartnerId}/invoices/${invoice.ident}?access_token=${token}`;
  }
}
