import { Component, OnInit, ContentChild, TemplateRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

enum GridSortOrder {
    NONE,
    ASC,
    DESC,
}

export class GridSortService {
    sortSubject:Subject<any> = new Subject<any>();

    setSorting(key:string, order:GridSortOrder):void {
        this.sortSubject.next({ key, order });
    }
}

@Component({
  selector: 'app-grid-row',
  styles: [`:host {
    display: table-row;
  }`],
  template: '<ng-content></ng-content>'
})
export class GridRowComponent {

}

@Component({
  selector: 'app-grid-data',
  styles: [`:host {
    padding: 6px 24px;
    padding-left: 1em;
    display: table-cell;
  }`,
    `
    [app-grid-header] :host {
        font-weight: bold;
    }
    `,
    `app-grid-row:nth-child(odd) :host {
        background-color: #f8f8fa;
    }`],
  template: '<ng-content></ng-content>'
})
export class GridDataComponent {

}

@Component({
    selector: 'app-grid-header',
    styles: [`:host {
      padding: 6px 24px;
      padding-left: 1em;
      display: table-cell;
      font-weight: bold;
    }`,
      `fa {
        opacity: 0.5;
      }`,
      `fa:hover, fa.active {
        opacity: 1;  
      }`,
      `app-grid-row:nth-child(odd) :host {
          background-color: #f8f8fa;
      }`],
    template: `<ng-content></ng-content>
        <br/>
        <span style='white-space: nowrap;'>
        <fa *ngIf="sortKey" [ngClass]="[isAscActive() ? 'active' : '']" name="sort-amount-asc" (click)="sortAsc()"></fa>
        <fa *ngIf="sortKey" [ngClass]="[isDescActive() ? 'active' : '']" name="sort-amount-desc" (click)="sortDesc()"></fa>
        </span>
        `

  })
  export class GridHeaderComponent {
    @Input() sortKey:string = null;
    active:GridSortOrder = GridSortOrder.NONE;

    constructor(public gridSortService:GridSortService) {
        gridSortService.sortSubject.subscribe(sorting => {
            const { key, order } = sorting;
            if(key === this.sortKey) {
                this.active = order;
            } else {
                this.active = GridSortOrder.NONE;
            }
        });
    }

    isAscActive():boolean {
        return this.active === GridSortOrder.ASC;
    }

    isDescActive():boolean {
        return this.active === GridSortOrder.DESC;
    }

    sortAsc():void {
        this.gridSortService.setSorting(this.sortKey, GridSortOrder.ASC);
    }

    sortDesc():void {
        this.gridSortService.setSorting(this.sortKey, GridSortOrder.DESC);
    }
  }

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styles: [
      `img.paginationIcon {
        height: 0.9em;   
        cursor: pointer;
      }`,
      `img.paginationIcon.disabled {
          display: none;
      }
      `,
  ],
  providers: [
      GridSortService
  ]
})
export class GridComponent {
    @ContentChild('appGridContent') gridContent:TemplateRef<any>; 
    @Input() itemsPerPage:number = 10;
    private _url:string;
    filterObj:any = {};
    private _filterJson:string = "";
    @Output('loading') loadingEmitter:EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() totalCnt:EventEmitter<number> = new EventEmitter<number>();
    private _filterDesc;
  
    private _filterChangeWaitTimeout = null;

    pageCnt:number = 0;
    curPage:number = 1;	
    _loading:boolean = false;	

    @Input() items = [];
    @Input() pagination:boolean = true;

    private _orderStr:string = null;

    private _loadKey:any = null;
		
    constructor(public http:HttpClient, 
        gridSortService:GridSortService) {
        
        gridSortService.sortSubject.subscribe(sorting => {
            const { key, order } = sorting;
            this._orderStr = key + " " + GridSortOrder[order];
            this._updateFilterJson();
        });
    }

    ngOnInit() {
        //this.load(1);
    }

    @Input()
    public set url(url:string) {
        this._url = url;
        this.update();
    }

    public get url():string {
        return this._url;
    }

    @Input()
    public set filterJson(filterJson:string) {
        this._filterJson = filterJson;
        this.update();
    }

    public get filterJson():string {
        return this._filterJson;
    }

    @Input()
    public set filterDesc(filterDesc) {
        this._filterDesc = filterDesc;
        this._updateFilterJson();
    }

    public get filterDesc() {
        return this._filterDesc;
    }

    public update() {
        if(this._url != null) { 
            this.load();
        }
    }

    private set loading(loading:boolean) {
        this._loading = loading;
        this.loadingEmitter.emit(loading);
    }

    private get loading():boolean { 
        return this._loading;
    }

    public async loadPage(page):Promise<void> {
        this.curPage = page;
        this._updateFilterJson();
        await this.load();
    }

    public async load():Promise<void> {
        if(!this._url) {
            return;
        }
        this.loading = true;
        var url:string = this._url;
        var params:any = {}; //Object.assign({}, this.params);
        if(this._filterJson) {
            params.filter = this._filterJson;
        }
        /*
        params.page = page;
        params.itemsPerPage = this.itemsPerPage;
        */
        var kv:string[] = [];
        for(var key in params) {
            kv.push(key + "=" + encodeURIComponent(params[key]));
        }
        var queryString:string = kv.join("&");
        if(queryString.length > 0) {
            var sep:string = url.indexOf('?') >= 0 ? '&' : '?';
            url += sep + queryString;
        }
        console.log("Url: " + url);

        // load key is used to check whether load result is for the
        // last load triggered or whether another load call 
        // was issued inbetween
        let loadKey = {};
        this._loadKey = loadKey;
        let res:any = await this.http.get(url, { observe: 'response' }).toPromise();
        if(this._loadKey === loadKey) {
            //console.log(res.headers.get('x-total-cnt'));
            let totalCnt = res.headers.get('x-total-cnt');
            this.pageCnt = Math.max(1, Math.ceil(totalCnt / this.itemsPerPage));
            this.items = res.body;
        }
        //console.log(res);
    };

    selectPage(page:number) {
            this.curPage = page;
            if(this.curPage <= 0) {
                this.curPage = 1;
            }
            if(this.curPage > this.pageCnt) {
                this.curPage = this.pageCnt;
            }
            
            this._updateFilterJson();
            this.load();
    };

    prevPage() {
        this.selectPage(this.curPage - 1);
    };	

    nextPage() {
        this.selectPage(this.curPage + 1);
    };

    firstPage() {
        this.selectPage(1);
    }

    lastPage() {
        this.selectPage(this.pageCnt);
    }

    private _updateFilterJson():void {
        let filterObj:any = {};
        filterObj.where = {};
        for(let key in this.filterDesc) {
          var comp:string = this.filterDesc[key].comp;
          var val:string = this.filterDesc[key].value;
          if(!val && this.filterObj[key]) {
            val = this.filterObj[key];
          }
          if(val) {
            if(comp == "like") {
                val = "%" + val + "%";
            }
            if(comp == 'orderId') {
                comp = 'eq';
                val = val.replace(/A/i, '');
                val = val.replace(/^0+/, '');
                val = val.replace(/-\d*$/, '');
                //let m = /A?0*(\d+)(\-\d*)?/.exec(val);
                //console.log(m);
                console.log("val", val);
                val = `${parseInt(val)}`;
            }
          }
          if(val) {
            filterObj.where[key] = {}
            filterObj.where[key][comp] = val;
          }
        }
        if(this._orderStr) {
            filterObj.order = this._orderStr;
        }
        filterObj.limit = this.itemsPerPage;
        filterObj.skip = (this.curPage - 1) * this.itemsPerPage;
        this._filterJson = JSON.stringify(filterObj);
        this.update();
      }
    
    public filterChanged():void {
        clearTimeout(this._filterChangeWaitTimeout);
        this._filterChangeWaitTimeout = setTimeout(() => {
            this._updateFilterJson();
        }, 500);
    }

    
}