import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT} from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class PrinterService {

    constructor(
        private _http:HttpClient,
        @Inject(DOCUMENT) private document: Document
    ) {}

    async checkBoxPrinter():Promise<any> {

        let response;
        try {
            response = await this._http.get(`https://localhost:9043/ping`, { responseType: 'json' }).toPromise();
            return response.status;
        } catch(err) {
            console.log(err);
        }
    }

    async printShippingLabel(pathToLabel: string) {

        let responseLabel;
        let responsePrinter;

        try {
            responseLabel = await this._http.get(pathToLabel, { responseType: 'arraybuffer' }).toPromise();
        } catch(err) {
            console.log("Label for printing not accessible");
            throw "Versandlabel nicht verfügbar";
        }
        
        try {
            //responsePrinter = await this._http.post(`https://localhost:9043/print`, responseLabel).toPromise();
            //encode Base64
            var binary = '';
            var bytes = new Uint8Array(responseLabel);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }

            let encoded: string = window.btoa(binary);

            this.document.location.href = 'fhprint://print?data=' + encoded;

        } catch (err) {
            console.log("Printing not successfull. Check printer or file format");
            throw "Drucken fehlgeschlagen. Bitte Drucker überprüfen";
        }
        
    }

    async testPrinter()
    {   
        let labelPath : string = '/api/repair-centers/1/repair-orders/497/shipping_label?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwic2FsdXRhdGlvbiI6bnVsbCwiZmlyc3ROYW1lIjoiTWVtbyIsImxhc3ROYW1lIjoiQ2lsaW5naXIiLCJlbWFpbCI6InJjQHRlc3QuY29tIiwicm9sZSI6InJlcGFpci1jZW50ZXIiLCJhY3RpdmF0ZWQiOnRydWUsInJlc2V0UGFzc3dvcmRLZXkiOm51bGwsInJlcGFpckNlbnRlcklkIjoxLCJpYXQiOjE2MjE5NDgyMzcsImV4cCI6MTYyMTk1MDAzN30.x9UjnJwnT4svLl46CeaFQ2i5hrtBO8YGHKcyFOmyMDw';
        let responseLabel = await this._http.get(labelPath, { responseType: 'arraybuffer' }).toPromise();

        //encode Base64
        var binary = '';
        var bytes = new Uint8Array( responseLabel );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }

        let encoded : string = window.btoa(binary);

        console.log(encoded);
        alert("Mops");

        this.document.location.href = 'fhprint://print?data=' + encoded;
    }

}


