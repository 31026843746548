import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { Router } from '@angular/router';
import { ServicePartner } from '../../../../../model/service-partner.model';
import { Address } from '../../../../../model/address.model';
import { User } from '../../../../../model/user.model';

import { BsModalService } from 'ngx-bootstrap/modal';
import { PriceListRequestDialogComponent } from 'src/app/components/ui/dialogs/price-list-request-dialog.component';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.less']
})
export class RegisterFormComponent {
  constructor(private _modalService:BsModalService){ }

  showRequestForm(){
    let initialState = {
      title: "",
      msg: "",
    }
    this._modalService.show(PriceListRequestDialogComponent, {initialState, class: 'modal-lg'});
  }
}
