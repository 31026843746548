import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {Component, Inject, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    constructor(
        private _snackBar:MatSnackBar,
    ) {}

    showError(message:string) {
        this._snackBar.openFromComponent(
            ErrorComponent,
            {   
                data: message,
                duration: 5000,
                panelClass: ['error-snackbar']
            }
        )
    }


}

@Component({
    selector:'snack-bar-error',
    styles: [
        `
        .error-title {
            color: red;
            text-align:center;
        }
        
        `
    ],
    template: `
        <div class="error-title">
            <p>Fehler :</p>
        </div>
        <div>
            <p>{{message}}</p>
        </div>
    `
})
export class ErrorComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string) {}
}