import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService } from 'src/app/service/feedback.service';
import { UserService } from 'src/app/service/user.service';
import { REST_SERVER } from '../../../../config';
import { User } from '../../../../model/user.model';

import { BsModalService } from 'ngx-bootstrap/modal';
import { NewsletterDialogComponent } from 'src/app/components/ui/dialogs/newsletter-dialog.component';

@Component({
  selector: 'app-one-pager',
  templateUrl: './one-pager.component.html',
  styleUrls: ['./one-pager.component.less']
})
export class OnePagerComponent implements OnInit {

  email: string = '';
  password: string = '';
  emailResetPw: string ='';

  headlineLoginText: string = 'Anmeldung';

  currentState: LoginDisplayState;

  constructor(
    private _ngZone:NgZone,
    private _feedbackService:FeedbackService,
    private _userService:UserService,
    private _router:Router,
    private _modalService:BsModalService
  ) { }

  private _loginPages = {
    "service-partner": ["service-partner", "dashboard"],
    "admin": ["admin", "service-partners", "list", "active"],
    "repair-center": ["repair-center", "dashboard"],
  };

  ngOnInit() {

    
    let user:User = this._userService.user;
    if(user && user.role) {

      this._router.navigate(this._loginPages[user.role]);
    }


    let scriptPath: string = `${REST_SERVER}/cms/js`;

    let scripts: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName("script");
    let found: boolean = false;
    for (let i = 0; i < scripts.length; i++) {
      let script = scripts[i];
      if (script.getAttribute("src") === scriptPath) {
        found = true;
        break;
      }
    }

    if (!found) {
      let head = document.getElementsByTagName('head')[0];
      let script = document.createElement("script");
      script.setAttribute("src", scriptPath);
      head.appendChild(script);
    }

    this.currentState = LoginDisplayState.LoginForm;
  }


  async onLogin(): Promise<void> {
    this._ngZone.run(async () => {
      try {
        let loginModel = new User();
        loginModel.email = this.email;
        loginModel.password = this.password;
        var loginResult: any = await this._userService.login(loginModel);
      } catch (exc) {

        this._feedbackService.showError("Login fehlerhaft, bitte Email-Passwort Kombination prüfen");
      }
    });
  }

  async onRequestNewPassword():Promise<void> {

    const email : any = this.emailResetPw;
    this._ngZone.run(async () => {
        try {
            await this._userService.forgotPassword({ email });

        } catch(exc) {
            this._feedbackService.showError("Passwort konnte nicht zurückgesetzt werden. Bitte überprüfen Sie ob eine bereits registrierte Email angegeben wurde.");
        }
    });

    this.currentState = LoginDisplayState.PwResetSend;
 }

  showForm(){

    const form: any = document.querySelector('app-register-form');
    form.classList.add('show');

    function scrollToElement(pageElement) {
      var positionX = 0,
        positionY = 0;

      while (pageElement != null) {
        positionX += pageElement.offsetLeft;
        positionY += pageElement.offsetTop;
        pageElement = pageElement.offsetParent;
        window.scrollTo(positionX, positionY);
      }
    }

    var pageElement = document.getElementById("sp-register-form");
    scrollToElement(pageElement);
  }

  forgotPassword(){
    this.headlineLoginText = 'Passwort Vergessen';
    this.currentState = LoginDisplayState.ForgotPwForm;
  }

  backToLogin(){
    this.headlineLoginText = 'Anmeldung';
    this.currentState = LoginDisplayState.LoginForm;
  }

  showNewsletterForm() {
    this._modalService.show(NewsletterDialogComponent, {class: 'modal-lg'});
  }

}

enum LoginDisplayState {

  LoginForm = 1,
  ForgotPwForm = 2,
  PwResetSend = 3
}
