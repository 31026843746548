import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/service/user.service';

@Component({
    selector: 'modal-content',
    template: `
    <div class="modal-header">
        <h4 class="modal-title pull-left">Newsletter</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>

    <div class="modal-body">
        <p style="padding-bottom: 10px">Melden Sie sich bei unserem Newsletter an un verpassen Sie keine Neuigkeit rund um Felgenhero.
        Selbstverständlich können Sie sich jederzeit wieder abmelden.</p>
        
      
        
        <div class="input-group mb-1 row">
            <input class="form-control col-md-6" type="text" placeholder="Vorname" [(ngModel)]="firstName">
            <input class="form-control col-md-6" type="text" placeholder="Nachname" [(ngModel)]="lastName">
        </div>
        <div class="input-group mb-1 row">
            <input class="form-control col-md-12" type="text" placeholder="Email" [(ngModel)]="email">
        </div>

      <div class="modal-footer" style="">
        <button 
            type="button" 
            class="btn btn-primary" 
            (click)="sendRequest()" 
            [disabled]="!firstName || !lastName || !email">
            Zum Newsletter anmelden</button>
      </div>
    `,
    styles: ['.row { margin: 0 0} input { margin: 0 10px } textarea { margin: 0 10px } .modal-footer {margin-top:20px; border-top:none}']
})

export class NewsletterDialogComponent {

    firstName : string = '';
    lastName : string = '';
    email : string = '';

    constructor(
        public bsModalRef: BsModalRef,
        private _userService:UserService,
    ) {}

    sendRequest()
    {
        //alert(this.companyName);

        let recipientData  = {
            'firstName': this.firstName,
            'lastName': this.lastName,
            'email': this.email,
        };

        this._userService.addRecipientToNewsletter(recipientData);

        this.bsModalRef.hide();
    }

}
