import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value:number, args?: any): any {
    let euro = Math.floor(value / 100);
    let cent = value % 100;
    //console.log(euro, cent, ("00" + cent).substr(-2));
    return euro + "," + ("00" + cent).substr(-2);
  }

}