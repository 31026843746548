import { Component, OnInit, ViewChild } from '@angular/core';
import { REST_SERVER } from 'src/app/config';
import { ActivatedRoute } from '@angular/router';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { GridComponent } from 'src/app/components/ui/grid/grid.component';

enum RepairOrderActions {
  CREATE_INVOICE,
  CANCEL,
};

@Component({
  selector: 'app-admin-repair-order-list',
  templateUrl: './admin-repair-order-list.component.html',
  styleUrls: ['./admin-repair-order-list.component.less']
})
export class AdminRepairOrderListComponent implements OnInit {
  @ViewChild('grid') private _grid:GridComponent;

  public loadingGrid:boolean = false;
  public totalCnt:number = 0;
  public title:string = '';
  public state:string = '';

  public selectionStatus = [
    { key: "status_active", val: "Aktiv" }, 
    { key: "status_inactive", val: "Inaktiv" },
  ]

  filterDesc = {
    id: {
      type: "string",
      comp: "eq",
      label: "Auftragsnr.",
    },
    rimCount: {
      type: "string",
      comp: "eq",
      label: "Anz. Felgen",
    },
    state: {
      value: "NONE",
      comp: "eq",
    }
  }

  constructor(
    activatedRoute:ActivatedRoute,
    private _invoiceService:InvoiceService,
    private _repairOrderService:RepairOrderService,
  ) { 
    activatedRoute.params.subscribe(params => {
      this.state = params.state;
      let filterDesc = {...this.filterDesc};
      filterDesc.state = {...this.filterDesc.state};
      if(params.state === 'new') {
        filterDesc.state.value = "NEW";
        this.title = 'Eingehende Aufträge';
      } else if(params.state === 'progress') {
        filterDesc.state.value = "REPAIR_IN_PROGRESS";
        this.title = 'Aufträge in Bearbeitung';
      } else if(params.state === 'finished') {
        filterDesc.state.value = "REPAIR_FINISHED";
        this.title = 'Aufträge in Rücklieferung';
      } else if(params.state === 'canceled') {
        filterDesc.state.value = "CANCELED";
        this.title = 'Stornierte Aufträge';
      }
      console.log("Filter: " + filterDesc.state.value);
      this.filterDesc = filterDesc;
    });
  }

  ngOnInit() {
  }

  public getBaseUrl():string {
    return REST_SERVER + "/repair-orders";
  }

  public getActionsForRepairOrder(repairOrder:RepairOrder) {
    let result = [];
    if(repairOrder.state == 'REPAIR_FINISHED' && !repairOrder.invoiceIdent) {
      result.push({ key: RepairOrderActions.CREATE_INVOICE, val: 'Rechnung erstellen' });
    }
    if(repairOrder.state == 'NEW') {
      result.push({ key: RepairOrderActions.CANCEL, val: 'Auftrag stornieren' });
    }
    return result;
  }

  public async actionSelected(repairOrder:RepairOrder, action):Promise<void> {
    switch(action.key) {
      case RepairOrderActions.CREATE_INVOICE:
        await this._invoiceService.createInvoiceForRepairOrder(repairOrder);
        this._grid.update();
        break;

      case RepairOrderActions.CANCEL:
        await this._repairOrderService.cancelOrder(repairOrder);
        break;
    }
  }
}
