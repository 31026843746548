import { 
  Component, 
  OnInit, 
  Input, 
  Output, 
  EventEmitter, 
  ViewChild, 
  ElementRef, 
  forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-inputfield',
  templateUrl: './inputfield.component.html',
  styleUrls: ['./inputfield.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputfieldComponent),
      multi: true
    }
  ],
})
export class InputfieldComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder:string;
  @Input() type:string = "text";
  @Input() valid:boolean = true;
  @Input() inline:boolean = false;
  @Input() readonly:boolean = false;
  @Input("ngStyle") style:any = {};
  @Output() focus:EventEmitter<void> = new EventEmitter();
  @ViewChild('input') input:ElementRef;

  private _value:string = "";

  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  constructor() { }

  ngOnInit() {

  }

  /*
  get valid():boolean {
    return this._valid;
  }
  */

  get value():string {
    return this._value;
  }

  set value(value: string) {
    if(value === null) {
      value = "";
    }
    if(value != this._value) {
      this._value = value;
      this._onChange(value);
    }
  }

  writeValue(value: string):void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }

  onTouched():void {
    this.focus.emit();
    this._onTouched();
  }

  setFocus():void {
    this.input.nativeElement.focus();
  }

}
