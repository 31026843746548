import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {

  changePasswordModel = {
    password: '',
    key: '',
  }
  passwordCheck:string = '';
  sending:boolean = false;
  changePasswordCompleted:boolean = false;

  constructor(
    activatedRoute:ActivatedRoute,
    private _userService:UserService,
  ) { 
    activatedRoute.queryParams.subscribe(params => {
      this.changePasswordModel.key = params.key;
    });
  }

  ngOnInit() {
  }

  async onChangePassword() {
    this.sending = true;
    try {
      await this._userService.changePassword(this.changePasswordModel);
    } catch(exc) {
      // login failed
    }
    this.sending = false;
    this.changePasswordCompleted = true;
  }

}
