import { Component, OnInit } from '@angular/core';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { REST_SERVER } from 'src/app/config';

@Component({
  selector: 'app-service-partner-order-packaging',
  templateUrl: './service-partner-order-packaging.component.html',
  styleUrls: ['./service-partner-order-packaging.component.less']
})
export class ServicePartnerOrderPackagingComponent implements OnInit {

  sendingOrder:boolean = false;
  orderId:number = 0;
  currentOrders:any;

  constructor(
    private _router:Router,
    private activatedRoute: ActivatedRoute,
  ) { 
  
  }

  ngOnInit() {
    const orderParam = this.activatedRoute.snapshot.paramMap.get('currentOrders');
    this.currentOrders = JSON.parse(orderParam);
  }

  goToPickup() {
    this._router.navigate(['service-partner', 'order', 'pickup', {currentOrders: JSON.stringify(this.currentOrders)}]);
  }


}
