import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { CmsService } from 'src/app/service/cms.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CmsPage } from '../../../../../../model/cms-page.model';
import { CmsBlock } from '../../../../../../model/cms-block.model';
import { AdminCmsPreviewDialogComponent } from './admin-cms-preview-dialog.component';
import { ConfirmDialogComponent } from '../../../../../ui/dialogs/confirm-dialog.component';
import { REST_SERVER } from 'src/app/config';

@Component({
  selector: 'app-admin-cms-pages-details',
  templateUrl: './admin-cms-pages-details.component.html',
  styleUrls: ['./admin-cms-pages-details.component.less']
})
export class AdminCmsPagesDetailsComponent implements OnInit {

  pageIdent:string;
  cmsPage:CmsPage = new CmsPage();
  settings:any = {};

  expanded:any = {};
  showHtml:any = {};

  currentOrdersFilterJson:string = null;

  public servicePartner = null;
  servicePartnerData = [];

  constructor(
    activatedRoute:ActivatedRoute,
    private _location:Location,
    private _cmsService:CmsService,
    private _modalService: BsModalService,
  ) { 
    activatedRoute.params.subscribe((params) => {
      this.pageIdent = params.ident;
      if(params.ident2) {
        this.pageIdent += '/' + params.ident2;
      }
      this.loadCurrentPage();
      this._loadSettings();
    });
  }

  async loadCurrentPage():Promise<void> {
    this.cmsPage = await this._cmsService.loadAdmin(this.pageIdent);
  }

  async _loadSettings():Promise<void> {
    this.settings = await this._cmsService.loadSettingsDict();
  }

  ngOnInit() {
  }

  swapBlocks(a:number, b:number):void {
    let first = Math.min(a, b);
    let second = Math.max(a, b);

    let removed = this.cmsPage.blocks.splice(second, 1);
    removed = this.cmsPage.blocks.splice(first, 1, ...removed);
    this.cmsPage.blocks.splice(second, 0, ...removed);
  }

  addBlock():void {
    let newBlock = new CmsBlock();
    newBlock.key = "neuer-block";
    newBlock.html = "<p>Text</p>";
    this.cmsPage.blocks.unshift(newBlock);
  }

  deleteBlock(idx:number):void {
    this.cmsPage.blocks.splice(idx, 1);
  }

  preview():void {
    let previewPage:CmsPage = {...this.cmsPage};
    let previewBlocks:CmsBlock[] = [];
    for(let block of previewPage.blocks) {
      if(block.active) {
        let previewBlock = {...block};
        previewBlock.html = previewBlock.html.replace(/###([a-zA-Z0-9_]+?)###/g, (substring, key) => {
          return this.settings[key];
        });
        previewBlocks.push(previewBlock);
      }
    }
    previewPage.blocks = previewBlocks;

    let initialState = {
      cmsPage: previewPage,
      //path: this.calcAinDownloadHref(),
      //msg: "Bitte ausdrucken und jeder einzelnen Felge beilegen",
    }
    this._modalService.show(AdminCmsPreviewDialogComponent, {initialState, class: 'modal-xl'});
  }

  async save():Promise<void> {
    await this._cmsService.saveAdmin(this.cmsPage);
  }

  reset():void {
    let initialState = {
      title: "Änderungen verwerfen",
      msg: `Wollen Sie wirklich alle Änderungen an dieser Seite verwerfen?`,
    }
    let ref:BsModalRef = this._modalService.show(ConfirmDialogComponent, {initialState});
    ref.content.onClose.subscribe(async result => {
      if(result) {
        await this.loadCurrentPage();
      }
    });
  }

  assembleWysiwyg(idx:number):string {
    let html = this.cmsPage.blocks[idx].html;
    html = html.replace(/###api_url###/g, REST_SERVER);
    return html;
  }

  updateWysiwyg(idx:number, evt):void {
    let newHtml = evt.target.innerHTML;
    newHtml = newHtml.replace(new RegExp(REST_SERVER, 'g'), '###api_url###');
    this.cmsPage.blocks[idx].html = newHtml;
    //console.log(idx, evt, newHtml);
  }
  
  calcPageId():string {
    let result:string = 'page';
    if(this.cmsPage && this.cmsPage.path) {
      result += '-' + this.cmsPage.path.replace(/\//g, '-');
    }
    return result;
  }
}
