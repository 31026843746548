import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCase'
})
export class CamelCasePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let result = value;
    if(value && value.replace) {
        console.log(value);
        result = value.replace(/[A-Z]/g, arg => ("_" + arg.toLowerCase()));
    }
    return result;
  }

}