import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { UserService } from 'src/app/service/user.service';
import { Observable } from 'rxjs';
import { REST_SERVER } from 'src/app/config';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(
      private _userService:UserService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.startsWith(REST_SERVER)) {
        var token:string = this._userService.token;
        if(token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
    }

    return next.handle(request);
  }
}