import { CmsPage } from 'src/app/model/cms-page.model';
import { CmsBlock } from 'src/app/model/cms-block.model';
import { REST_SERVER } from 'src/app/config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    constructor(
        private _http:HttpClient,
    ) { }

    async load(pageIdent:string):Promise<CmsPage> {
        return <CmsPage>await this._http.get(`${REST_SERVER}/cms/page/${pageIdent}`).toPromise();
    }

    async loadAdmin(pageIdent:string):Promise<CmsPage> {
        return <CmsPage>await this._http.get(`${REST_SERVER}/cms/admin/page/${pageIdent}`).toPromise();
    }

    async saveAdmin(page:CmsPage):Promise<void> {
        await this._http.put(`${REST_SERVER}/cms/admin/page/${page.path}`, page).toPromise();
    }

    async deletePage(page:CmsPage):Promise<void> {
        await this._http.delete(`${REST_SERVER}/cms/admin/page/${page.path}`).toPromise();
    }

    async loadSettingsDict():Promise<any> {
        let settings:any[] = <any[]>(await this._http.get(`${REST_SERVER}/cms/admin/settings`).toPromise());
        let result:any = {};
        for(let s of settings) {
            result[s.key] = s.value;
        }
        return result;
    }

    async loadCss():Promise<string> {
        return <string> (await this._http.get(`${REST_SERVER}/cms/css`, {responseType: 'text'}).toPromise());
    }

    async loadAdminCss():Promise<string> {
        return <string> (await this._http.get(`${REST_SERVER}/cms/admin/css`, {responseType: 'text'}).toPromise());
    }

    async saveAdminCss(css:string):Promise<void> {
        await this._http.put(`${REST_SERVER}/cms/admin/css`, css).toPromise();
    }

    async loadJs():Promise<string> {
        return <string> (await this._http.get(`${REST_SERVER}/cms/js`, {responseType: 'text'}).toPromise());
    }

    async loadAdminJs():Promise<string> {
        return <string> (await this._http.get(`${REST_SERVER}/cms/admin/js`, {responseType: 'text'}).toPromise());
    }

    async saveAdminJs(js:string):Promise<void> {
        await this._http.put(`${REST_SERVER}/cms/admin/js`, js).toPromise();
    }

    async saveSetting(setting):Promise<void> {
        await this._http.put(`${REST_SERVER}/cms/admin/settings/${setting.id}`, setting).toPromise();
    }

    public async uploadFile(filename:string, type:string, data:ArrayBuffer):Promise<any> {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  type,
          })
        };
        filename = encodeURIComponent(filename);
        await this._http.put(`${REST_SERVER}/cms/admin/files/${filename}`, data, httpOptions).toPromise();
    }
    
    public async deleteFile(filename):Promise<void> {
        await this._http.delete(`${REST_SERVER}/cms/admin/files/${filename}`).toPromise();
    }

}