import { Component, EventEmitter, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: 'modal-content',
    template: `
      <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4>
      </div>

      <div class="modal-body">
        {{msg}}
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirm()">{{title}}</button>
        <button type="button" class="btn btn-primary" (click)="close()">Abbrechen</button>
      </div>
    `
  })

  export class GeneralConfirmDialogComponent implements OnInit {

    title:string;
    msg:string;
    public event: EventEmitter<any> = new EventEmitter();

    constructor(public _bsModalRef: BsModalRef) {}

    ngOnInit() {
        
    }

    confirm() {
        this.event.emit();
        setTimeout(
            () => this._bsModalRef.hide(),
            1000
        )
    }

    close()
    {
        this._bsModalRef.hide();
    }
  }
   