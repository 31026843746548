export class Address {
    name:string;
    contactFirstName:string;
    contactLastName:string;
    contactEmail:string;
    contactPhone:string;
    street:string;
    streetNr:string;
    zipCode:string;
    city:string;
}