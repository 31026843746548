import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-trash',
  templateUrl: './filter-trash.component.html',
  styleUrls: ['./filter-trash.component.less']
})
export class FilterTrashComponent implements OnInit {

  @Input() disabled:boolean = false;
  @Output() click:EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
