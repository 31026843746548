import { 
  Component, 
  OnInit, 
  Input, 
  Output, 
  EventEmitter, 
  ViewChild, 
  ElementRef, 
  forwardRef } from '@angular/core';
import { ControlValueAccessor, Validator, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'app-inputcurrencyfield',
  templateUrl: './inputcurrencyfield.component.html',
  styleUrls: ['./inputcurrencyfield.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputcurrencyfieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputcurrencyfieldComponent),
      multi: true,
    }
  ],
})
export class InputcurrencyfieldComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() placeholder:string;
  @Input() type:string = "text";
  @Input() valid:boolean = true;
  @Input() inline:boolean = false;
  @Input() readonly:boolean = false;
  @Output() focus:EventEmitter<void> = new EventEmitter();
  @ViewChild('input') input:ElementRef;
  private _value:string = "0,00";

  private _onChange = (value: number) => {};
  private _onTouched = () => {};

  constructor() { }

  ngOnInit() {

  }
  
  get value():string {
    return this._value;
  }

  set value(value: string) {
    if(value === null) {
      value = "0";
    }
    if(value !== this._value) {
      this._value = value;
      let intValue:number = Math.round(parseFloat(value.replace(",", ".")) * 100);
      console.log("Int value", intValue);
      this._onChange(intValue);
    }
  }

  isValueNumber():boolean {
    return ("" + parseInt(this.value)) == this.value;
  }

  writeValue(value: number):void {
    console.log("Writing value", value);
    if(value == null) {
      value = 0;
    }
    if("" + value == "NaN") {
      value = 0;
    }
    this.value = (value / 100).toFixed(2).replace(".", ",");
  }

  registerOnChange(onChange: (value: number) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }

  onTouched():void {
    this.focus.emit();
    this._onTouched();
  }

  setFocus():void {
    this.input.nativeElement.focus();
  }

  format():void {
    let intValue:number = Math.round(parseFloat(this.value.replace(",", ".")) * 100);
    this.value = (intValue / 100).toFixed(2).replace(".", ",");
  }

  public validate(c:FormControl) {
    return null;
    /*
    let match:boolean = this._value.match(/^\d+|\d+,|\d+,\d{1,2}|,\d{1,2}$/);
    console.log("Matching", match);
    return match ? null : {
      currencyParseError: {
        valid: false,
      }
    };
    */
  }

}
