import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import * as internal from 'assert';

@Component({
    selector: 'modal-content',
    styles: [`
        iframe {
            width: 100%; height: 40em;
            background: url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"100%\" viewBox=\"0 0 100% 100%\"><text fill=\"%23000000\" x=\"50%\" y=\"50%\" font-size=\"24\" text-anchor=\"middle\">Bitte warten ...</text></svg>') 0px 0px no-repeat;
            border: 1px solid gray;
        }`,
        `.nextLabel { width: 40px;}`,
        `.nextLabel img { width: 100%;}`,
        `#numHint { margin: 0 20px; display: inline; font-size: larger}`,
        `#multiLabelHeader { margin: 30px 0px;}`,
        `#multiLabelControls { display: inline; float: right;}`,
        `#labelInfo { display: inline; font-size: larger; font-weight: bold;}`,
      ],
    template: `
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div id="multiLabelHeader">
          <div id=labelInfo>
            Drucken Sie jetzt Label Nr: {{currentLabelIndex + 1}}
          </div>
          <div id="multiLabelControls">
            <button type="button" class="btn btn-primary nextLabel" [disabled]="currentLabelIndex == 0" (click)="previousLabel()">
              <img src="/assets/img/icon/left-arrow.png">
            </button>
            <div id="numHint">{{currentLabelIndex + 1}}/{{amountLabels}}</div>
            <button type="button" class="btn btn-primary nextLabel" [disabled]="currentLabelIndex == (paths.length - 1)" (click)="nextLabel()">
              <img src="/assets/img/icon/right-arrow.png">
            </button>
          </div>
        </div>

        <iframe [srcdoc]="labelHtml" ></iframe>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onPrintInBrowser()">{{printBrowserBtnName}}</button>
        <button type="button" class="btn btn-primary" [disabled]="currentLabelIndex < (paths.length - 1)" (click)="onConfirm()">{{closeBtnName}}</button>
        
      </div>
    `
  })
   
  export class MultiLabelViewerComponent implements OnInit {
    title: string;
    
    closeBtnName: string = "OK";
    printBrowserBtnName: string = "mit Browser Drucken";
    switchTest: string = "wechsel testen";
    paths:any
    path:string;

    currentLabelIndex: number;
    amountLabels: number;

    html:string;

    public onClose: Subject<void> = new Subject<void>();
   
    constructor(
      public bsModalRef: BsModalRef, 
      private _sanitizer:DomSanitizer,
      private _http:HttpClient,) {}
   
    ngOnInit() {
      console.log(this.paths);
      this.path = this.paths[0];

      this.amountLabels = this.paths.length;
      this.currentLabelIndex = 0;
      

      (async () => {
        await this.getHtml();
        await this.setLabelUrl()
      })();
    }

    async getHtml() {
      this.html  = await this._http.get('./assets/labelDisplay.html', {responseType: 'text'}).toPromise();
    }

    async setLabelUrl() {
      
      let pathPrefix = window.location.protocol + "//" + window.location.host;
      this.html = this.html.replace('@@LABELURL@@', pathPrefix + this.path);
    }

    get labelHtml() {
      return this._sanitizer.bypassSecurityTrustHtml(this.html);
    }

    public async onPrintInBrowser(): Promise<void> {

      const iframeWindow = document.querySelector("iframe").contentWindow;
      iframeWindow.print();
    }

    public onConfirm(): void {
        this.onClose.next();
        this.bsModalRef.hide();
    }

    get sanitizedPath() {
      
        return this._sanitizer.bypassSecurityTrustResourceUrl(this.path);
    }   

    nextLabel()
    {
      this.currentLabelIndex ++;
      this.showCurrentLabel();
    }

    previousLabel()
    {
      this.currentLabelIndex --;
      this.showCurrentLabel();
    }

    async showCurrentLabel()
    { 
      this.path = this.paths[this.currentLabelIndex];

      await this.getHtml();
      await this.setLabelUrl()
    }
  }