import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'modal-content',
    styles: [`
        iframe {
            width: 100%; height: 40em;
            background: url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"100%\" height=\"100%\" viewBox=\"0 0 100% 100%\"><text fill=\"%23000000\" x=\"50%\" y=\"50%\" font-size=\"24\" text-anchor=\"middle\">Bitte warten ...</text></svg>') 0px 0px no-repeat;
            border: 1px solid gray;
        }
    `],
    template: `
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onConfirm()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ msg }}</p>
        <iframe [srcdoc]="labelHtml" ></iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onPrintInBrowser()">{{printBrowserBtnName}}</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">{{closeBtnName}}</button>
      </div>
    `
  })
   
  export class ViewerDialogComponent implements OnInit {
    title: string;
    msg: string = '';
    closeBtnName: string = "OK";
    printBrowserBtnName: string = "mit Browser Drucken";
    path:string;

    html:string;

    public onClose: Subject<void> = new Subject<void>();
   
    constructor(
      public bsModalRef: BsModalRef, 
      private _sanitizer:DomSanitizer,
      private _http:HttpClient,) {}
   
    ngOnInit() {
      console.log(this.path);

      (async () => {
        await this.getHtml();
        await this.setLabelUrl()
      })();
    }

    async getHtml() {
      this.html  = await this._http.get('./assets/labelDisplay.html', {responseType: 'text'}).toPromise();
    }

    async setLabelUrl() {
      
      let pathPrefix = window.location.protocol + "//" + window.location.host;
      this.html = this.html.replace('@@LABELURL@@', pathPrefix + this.path);
    }

    get labelHtml() {
      return this._sanitizer.bypassSecurityTrustHtml(this.html);
    }

    public async onPrintInBrowser(): Promise<void> {

      const iframeWindow = document.querySelector("iframe").contentWindow;
      iframeWindow.print();
    }

    public onConfirm(): void {
        this.onClose.next();
        this.bsModalRef.hide();
    }

    get sanitizedPath() {
      
        return this._sanitizer.bypassSecurityTrustResourceUrl(this.path);
    }   
  }