import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ServicePartnerService } from 'src/app/service/service-partner.service';

@Component({
    selector: 'modal-content',
    template: `
    <div class="modal-header">
        <h4 class="modal-title pull-left">Preisliste anfordern</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
    </div>

    <div class="modal-body">
        <p style="padding-bottom: 10px">Bitte geben Sie uns einige Informationen über sich. Sobald wir diese geprüft haben, senden
        wir Ihnen eine Email mit unserer aktuellen Preisliste zu.</p>
        
      
        <div class="input-group mb-1 row">
            <input class="form-control col-md-12" type="text" placeholder="Firma" [(ngModel)]="companyName">
        </div>
        <div class="input-group mb-1 row">
            <input class="form-control col-md-6" type="text" placeholder="Vorname" [(ngModel)]="firstName">
            <input class="form-control col-md-6" type="text" placeholder="Nachname" [(ngModel)]="lastName">
        </div>
        <div class="input-group mb-1 row">
            <input class="form-control col-md-9" type="text" placeholder="Straße" [(ngModel)]="street">
            <input class="form-control col-md-3" type="text" placeholder="Straßen-Nr" [(ngModel)]="streetNr">
        </div>
        <div class="input-group mb-1 row">
            <input class="form-control col-md-3" type="text" placeholder="PLZ" [(ngModel)]="postCode">
            <input class="form-control col-md-9" type="text" placeholder="Stadt" [(ngModel)]="city">
        </div>
        <div class="input-group mb-1 row">
            <input class="form-control col-md-6" type="text" placeholder="Email" [(ngModel)]="email">
            <input class="form-control col-md-6" type="text" placeholder="Telefonnummer" [(ngModel)]="phone">
        </div>
        <div class="input-group mb-1 row">
            <textarea class="form-control col-md-12" placeholder="Bemerkung" [(ngModel)]="note"></textarea>
        </div>

      <div class="modal-footer" >
        <button 
            type="button" 
            class="btn btn-primary" 
            (click)="sendRequest()" 
            [disabled]="!companyName || !firstName || !lastName || !street || !streetNr || !postCode || !city || !email">
            Anfrage absenden</button>
      </div>
    `,
    styles: ['.row { margin: 0 0} input { margin: 0 10px } textarea { margin: 0 10px }']
})

export class PriceListRequestDialogComponent implements OnInit {

    companyName : string = '';
    firstName : string = '';
    lastName : string = '';
    street : string = '';
    streetNr : string = '';
    postCode : string = '';
    city : string = '';
    email : string = '';
    phone : string = '';
    note : string = '';
    
    constructor(
        public bsModalRef: BsModalRef,
        private _servicePartnerService: ServicePartnerService,
    ) {}

    ngOnInit() {

    }

    sendRequest()
    {
        //alert(this.companyName);

        let personalData  = {
            'companyName': this.companyName,
            'firstName': this.firstName,
            'lastName': this.lastName,
            'street': this.street,
            'streetNr': this.streetNr,
            'postCode': this.postCode,
            'city': this.city,
            'email': this.email,
            'phone': this.phone,
            'note': this.note,
        };

        this._servicePartnerService.requestPricelist(personalData);

        this.bsModalRef.hide();
    }

    close()
    {
        this.bsModalRef.hide();
    }
}