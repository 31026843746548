import { 
  Component, 
  OnInit, 
  Input, 
  Output, 
  EventEmitter, 
  ViewChild, 
  ElementRef, 
  forwardRef } from '@angular/core';
import { ControlValueAccessor, Validator, FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'app-inputnumberfield',
  templateUrl: './inputnumberfield.component.html',
  styleUrls: ['./inputnumberfield.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputnumberfieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputnumberfieldComponent),
      multi: true,
    }
  ],
})
export class InputnumberfieldComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() placeholder:string;
  @Input() type:string = "text";
  @Input() valid:boolean = true;
  @Input() inline:boolean = false;
  @Input() readonly:boolean = false;
  @Output() focus:EventEmitter<void> = new EventEmitter();
  @ViewChild('input') input:ElementRef;
  private _value:number = 0;

  private _onChange = (value: number) => {};
  private _onTouched = () => {};

  constructor() { }

  ngOnInit() {

  }
  
  get value():number {
    return this._value;
  }

  set value(value: number) {
    if(value === null) {
      value = 0;
    }
    if(value !== this._value) {
      this._value = value;
      this._onChange(value);
    }
  }

  isValueNumber():boolean {
    return ("" + parseInt("" + this.value)) == "" + this.value;
  }

  writeValue(value: number):void {
    this.value = value;
  }

  registerOnChange(onChange: (value: number) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }

  onTouched():void {
    this.focus.emit();
    this._onTouched();
  }

  setFocus():void {
    this.input.nativeElement.focus();
  }

  public validate(c:FormControl) {
    return this.isValueNumber() ? null : {
      numberParseError: {
        valid: false,
      }
    };
  }

}
