import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageDialogComponent } from 'src/app/components/ui/dialogs/message-dialog/message-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/service/user.service';
import { REST_SERVER } from 'src/app/config';

@Component({
  selector: 'app-repair-center-order-process-image-upload',
  templateUrl: './repair-center-order-process-image-upload.component.html',
  styleUrls: ['./repair-center-order-process-image-upload.component.less']
})
export class RepairCenterOrderProcessImageUploadComponent implements OnInit {

  @Input() before:boolean = true;
  private _repairOrder:RepairOrder;
  imageNames:string[] = [];
  missingImageNames:string[] = [];
  hoverImage = {};
  private _updateImagesInterval = null;
  @Output() ready:EventEmitter<boolean> = new EventEmitter<boolean>();
  takingPhoto:boolean = false;

  constructor(
    private _repairOrderService:RepairOrderService,
    private _userService:UserService,
    private _http:HttpClient,
  ) { 
  }

  ngOnInit() {
    this._updateImages();
  }

  ngOnDestroy() {
    clearInterval(this._updateImagesInterval);
  }

  async takePhoto() {
    this.takingPhoto = true;
    let server:string = REST_SERVER;
    if(server.startsWith('/')) {
      let url = location.href;
      let m = /(https?:\/\/.*?)\//.exec(url);
      server = m[1] + server;;
    }
    let state = this.before ? 'before' : 'after';
    let uploadUrl = `${server}/repair-centers/${this._userService.user.repairCenterId}/repair-orders/${this._repairOrder.id}/images/${state}`;
    uploadUrl = encodeURIComponent(uploadUrl);

    await this._http.get(`https://localhost:8080/take-photo?uploadUrl=${uploadUrl}`).toPromise();
    this._updateImages();
    this.takingPhoto = false;
  }

  @Input()
  set repairOrder(repairOrder:RepairOrder) {
    this._repairOrder = repairOrder;
    this._updateImages();
  }

  get repairOrder():RepairOrder {
    return this._repairOrder;
  }

  private async _updateImages():Promise<void> {
    if(this.repairOrder) {
      this.imageNames = await this._repairOrderService.getAbsoluteImageNamesForRepairCenter(
        this.repairOrder, this._userService.user.repairCenterId, this.before);
      //let reqImgCnt:number = this.repairOrder.rimCount;
      let reqImgCnt:number = 1;
      //let missingImgCnt:number = this.imageNames.length - reqImgCnt;
      this.missingImageNames = [];
      for(let i = this.imageNames.length + 1; i <= reqImgCnt; i++) {
        this.missingImageNames.push(`Felge ${i}`);
      }
      this.ready.emit(this.imageNames.length === reqImgCnt);
    }
  }
 
  handleFileSelect(evt):void {
    let files:File[] = evt.target.files; // FileList object
    if(files.length > 0) {
      let file:File = files[0];

      let type:string = file.type;
      
      if (!file.type.match('image.*')) {
        return;
      }

      var reader = new FileReader();
      
      // Closure to capture the file information.
      reader.onload = async (evt:any) => {
        let data:ArrayBuffer = evt.target.result;
        await this._repairOrderService.uploadImageForRepairCenter(
          this.repairOrder, type, data, this.before, this._userService.user.repairCenterId);
        await this._updateImages();
      };

      // Read in the image file as a data URL.
      reader.readAsArrayBuffer(file);
    }
  }

  public async deleteImage(filename:string):Promise<void> {
    await this._repairOrderService.deleteImage(this.repairOrder, filename);
    await this._updateImages();
  }
}
