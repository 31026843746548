import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';
import { ServicePartnerService } from 'src/app/service/service-partner.service';
import { REST_SERVER } from 'src/app/config';
import { RepairOrderService } from 'src/app/service/repair-order.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RepairOrder } from 'src/app/model/repair-order.model';
import { MessageDialogComponent } from 'src/app/components/ui/dialogs/message-dialog/message-dialog.component';
import { Invoice } from 'src/app/model/invoice.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { UserService } from 'src/app/service/user.service';
import { AmountPipe } from 'src/app/pipes/amount.pipe';

@Component({
  selector: 'app-admin-invoice-details',
  templateUrl: './admin-invoice-details.component.html',
  styleUrls: ['./admin-invoice-details.component.less']
})
export class AdminInvoiceDetailsComponent implements OnInit {

  invoiceIdent:string;
  invoice:Invoice;
  invoiceData = [];
  invoiceItems = [];

  currentOrdersFilterJson:string = null;

  public servicePartner = null;

  constructor(
    activatedRoute:ActivatedRoute,
    private _location:Location,
    private _invoiceService:InvoiceService,
    private _modalService: BsModalService,
    private _userService:UserService,
  ) { 
    activatedRoute.params.subscribe((params) => {
      this.invoiceIdent = params.ident;
      this.loadCurrentInvoice();
    });
  }

  async loadCurrentInvoice():Promise<void> {
    this.invoice = await this._invoiceService.load(this.invoiceIdent);
    if(!this.invoice) {
      let initialState = {
        title: "Fehler",
        msg: `Rechnung Nr. ${this.invoiceIdent} wurde nicht gefunden`,
      }
      let ref:BsModalRef = this._modalService.show(MessageDialogComponent, {initialState});
      ref.content.onClose.subscribe(result => {
        this._location.back();
      });
    }

    this.invoiceItems = this.invoice.items;
    let invoiceData = [];
    let invoice:any = {...this.invoice};
    delete invoice.cnt;
    delete invoice.year;
    invoice.recipient = invoice.address.name;
    delete invoice.servicePartner;
    delete invoice.repairCenter;
    delete invoice.address;
    delete invoice.items;
    if(!invoice.dunning0Date) {
      delete invoice.dunning0Date;
    }
    if(!invoice.dunning1Date) {
      delete invoice.dunning1Date;
    }
    if(!invoice.dunning2Date) {
      delete invoice.dunning2Date;
    }
    /*
    delete repairOrder.servicePartnerReference;
    delete repairOrder.servicePartner;
    delete repairOrder.servicePartnerId;
    */
    invoice.type = 'invoice_type_' + invoice.type.toLowerCase();
    invoice.state = 'invoice_state_' + invoice.state.toLowerCase();
    let amountPipe:AmountPipe = new AmountPipe();
    invoice.amountNet = amountPipe.transform(invoice.amountNet) + ' €';
    invoice.amountVat = amountPipe.transform(invoice.amountVat) + ' €';
    invoice.amountTotal = amountPipe.transform(invoice.amountTotal) + ' €';
    for(let key in invoice) {
      let item = { key, value: invoice[key] };
      invoiceData.push(item);
    }
    this.invoiceData = invoiceData;
  }

  ngOnInit() {
  }

  calcInvoiceDownloadHref():string {
    let token:string = this._userService.token;
    return REST_SERVER + '/invoices/' + this.invoiceIdent + '/pdf/invoice?access_token=' + token;
  }

  calcDunning0DownloadHref():string {
    let token:string = this._userService.token;
    return REST_SERVER + '/invoices/' + this.invoiceIdent + '/pdf/dunning0?access_token=' + token;
  }

  calcDunning1DownloadHref():string {
    let token:string = this._userService.token;
    return REST_SERVER + '/invoices/' + this.invoiceIdent + '/pdf/dunning1?access_token=' + token;
  }

  calcDunning2DownloadHref():string {
    let token:string = this._userService.token;
    return REST_SERVER + '/invoices/' + this.invoiceIdent + '/pdf/dunning2?access_token=' + token;
  }
}
