import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { REST_SERVER } from 'src/app/config';
import { Address } from 'src/app/model/address.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Invoice, InvoiceItem } from 'src/app/model/invoice.model';
import { SelectPartnerDialogComponent, SelectPartnerResult } from 'src/app/components/ui/dialogs/select-partner-dialog/select-partner-dialog.component';
import { ServicePartner } from 'src/app/model/service-partner.model';
import { InvoiceService } from 'src/app/service/invoice.service';
import { UserService } from 'src/app/service/user.service';
import { ViewerDialogComponent } from 'src/app/components/ui/dialogs/viewer-dialog.component';

@Component({
  selector: 'app-admin-invoice-create',
  templateUrl: './admin-invoice-create.component.html',
  styleUrls: ['./admin-invoice-create.component.less']
})
export class AdminInvoiceCreateComponent implements OnInit {

  public sendingInvoice:boolean;
  public success:boolean;
  invoice = new Invoice();
  public editErrors:string[] = [];
  @ViewChild("editForm") editForm;
  @ViewChild('editErrorsDisplay') editErrorsDisplay:ElementRef;

  constructor(
    private _modalService:BsModalService,
    private _invoiceService:InvoiceService,
    private _userService:UserService,
    private _router:Router,
  ) { 
    this.invoice.address = new Address();
    this.invoice.items = [];
    this.invoice.items.push(new InvoiceItem());
    this.invoice.items[0].count = 1;
  }

  ngOnInit() {
  }

  async onEdit():Promise<void> {
    this.editErrors = [];
    let success:boolean = false;
    this.sendingInvoice = true;
    try {
      this.invoice = await this._invoiceService.createInvoice(this.invoice);
      this.openInvoicePdf();
      //await this._repairCenterService.save(this.repairCenter);
      success = true;
    } catch(exc) {
      console.log(exc);
      let code = exc.error && exc.error.error && exc.error.error.code;
      if(code === 'EMAIL_ALREADY_EXISTS') {
        this.editErrors.push("Die E-Mail-Adresse wird bereits verwendet");
      } else {
        this.editErrors.push("Unbekannter Fehler");
      }
      this.editErrorsDisplay.nativeElement.scrollIntoView(true);
      //window.scroll(0,0);
    }
    this.sendingInvoice = false;
    
  }

  canSubmit():boolean {
    let result:boolean = true;
    result = result && this.editForm.form.valid;
    result = result && !this.sendingInvoice;
    result = result && !this.invoice.ident;
    return result;
  }

  selectPartner():void {
    let initialState = {};
    let ref:BsModalRef = this._modalService.show(SelectPartnerDialogComponent, {initialState, class: 'modal-lg'});
    ref.content.onClose.subscribe((result:SelectPartnerResult) => {
      if(result && result.success) {
        //await this._servicePartnerService.deleteServicePartner(servicePartner);
        if(result.servicePartner) {
          this.invoice.address = {...result.servicePartner.invoiceAddress};
          this.invoice.servicePartner = result.servicePartner;
        } else if(result.repairCenter) {
          this.invoice.address = {...result.repairCenter.deliveryAddress};
          this.invoice.repairCenter = result.repairCenter;
        }
      }
    });
  }

  calcInvoiceDownloadHref():string {
    let token:string = this._userService.token;
    return `${REST_SERVER}/invoices/${this.invoice.ident}/pdf/invoice?access_token=${token}`;
  }

  openInvoicePdf() {
    let initialState = {
      title: `Rechnung / Gutschrift ${this.invoice.ident}`,
      path: this.calcInvoiceDownloadHref(),
      msg: "",
    }
    let ref:BsModalRef = this._modalService.show(ViewerDialogComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true, keyboard: false});
    ref.content.onClose.subscribe((result) => {
      //console.log("dialog closed");
      this._router.navigate(['admin', 'invoices', 'list', 'new']);
    });
  }

  generateRepairCenterCredits() {
    this._invoiceService.generateRepairCenterCredits();
  }
}
