import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from 'src/app/components/pages/public/index/index.component';
import { RegisterFormComponent } from 'src/app/components/pages/public/register/form/register-form.component';
import { RegisterSuccessComponent } from 'src/app/components/pages/public/register/success/register-success.component';
import { AuthGuard } from 'src/app/util/auth.guard';
import { ServicePartnerOrderFormComponent } from 'src/app/components/pages/service-partner/order/form/service-partner-order-form.component';
import { ServicePartnerOrderSuccessComponent } from 'src/app/components/pages/service-partner/order/success/service-partner-order-success.component';
import { RepairCenterDashboardComponent } from 'src/app/components/pages/repair-center/dashboard/repair-center-dashboard.component';
import { RepairCenterOrderProcessComponent } from 'src/app/components/pages/repair-center/repair-order/process/repair-center-order-process.component';
import { RepairCenterOrderListComponent } from 'src/app/components/pages/repair-center/repair-order/list/repair-center-order-list.component';
import { RepairCenterOrderDetailsComponent } from 'src/app/components/pages/repair-center/repair-order/details/repair-center-order-details.component';
import { AdminRepairOrderListComponent } from 'src/app/components/pages/admin/repair-order/list/admin-repair-order-list.component';
import { AdminRepairOrderDetailsComponent } from 'src/app/components/pages/admin/repair-order/details/admin-repair-order-details.component';
import { AdminInvoiceListComponent } from 'src/app/components/pages/admin/invoice/list/admin-invoice-list.component';
import { AdminInvoiceDetailsComponent } from 'src/app/components/pages/admin/invoice/details/admin-invoice-details.component';
import { ServicePartnerOrderListComponent } from 'src/app/components/pages/service-partner/repair-order/list/service-partner-order-list.component';
import { ServicePartnerOrderDetailsComponent } from 'src/app/components/pages/service-partner/repair-order/details/service-partner-order-details.component';
import { ServicePartnerInvoiceListComponent } from 'src/app/components/pages/service-partner/invoice/list/service-partner-invoice-list.component';
import { SearchComponent } from 'src/app/components/pages/public/search/search.component';
import { ServicePartnerSettingsComponent } from 'src/app/components/pages/service-partner/settings/service-partner-settings.component';
import { ResetPasswordComponent } from 'src/app/components/pages/public/reset-password/reset-password.component';
import { ConfirmEmailComponent } from 'src/app/components/pages/public/register/confirm-email/confirm-email.component';
import { AdminServicePartnersComponent } from 'src/app/components/pages/admin/admin-service-partners/list/admin-service-partners.component';
import { AdminServicePartnerDetailsComponent } from 'src/app/components/pages/admin/admin-service-partners/details/admin-service-partner-details.component';
import { ServicePartnerOrderPackagingComponent } from 'src/app/components/pages/service-partner/order/packaging/service-partner-order-packaging.component';
import { ServicePartnerOrderPickupComponent } from 'src/app/components/pages/service-partner/order/pickup/service-partner-order-pickup.component';
import { AdminServicePartnerCreateComponent } from 'src/app/components/pages/admin/admin-service-partners/create/admin-service-partner-create.component';
import { AdminCmsPagesListComponent } from './components/pages/admin/cms/pages/list/admin-cms-pages-list.component';
import { AdminCmsPagesDetailsComponent } from './components/pages/admin/cms/pages/details/admin-cms-pages-details.component';
import { CmsPublicPageComponent } from './components/pages/public/cms-public-page/cms-public-page.component';
import { AdminCmsSettingsListComponent } from './components/pages/admin/cms/settings/list/admin-cms-settings-list.component';
import { AdminCmsFilesListComponent } from './components/pages/admin/cms/files/list/admin-cms-files-list.component';
import { AdminCmsCssJsComponent } from './components/pages/admin/cms/css-js/admin-cms-css-js.component';
import { AdminRepairCenterListComponent } from 'src/app/components/pages/admin/repair-center/list/admin-repair-center-list.component';
import { AdminRepairCenterDetailsComponent } from 'src/app/components/pages/admin/repair-center/details/admin-repair-center-details.component';
import { AdminInvoiceCreateComponent } from 'src/app/components/pages/admin/invoice/create/admin-invoice-create.component';
import { RepairCenterInvoiceListComponent } from 'src/app/components/pages/repair-center/invoice/list/repair-center-invoice-list.component';
import { ServicePartnerDashboardComponent } from './components/pages/service-partner/dashboard/service-partner-dashboard.component';
import { AdminRepairCenterAssignmentComponent } from './components/pages/admin/repair-center/assignment-test/admin-repair-center-assignment.component';

const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'servicepartner-suche', component: SearchComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'confirm-email', component: ConfirmEmailComponent },
  { 
    path: 'register', 
    children: [
      { path: 'form', component: RegisterFormComponent },
      { path: 'success', component: RegisterSuccessComponent },
    ]
  },
  //agbs should be accessible before logging in
  { path: 'service-partner/agb', component:CmsPublicPageComponent},
  { 
    path: 'service-partner',
    canActivate: [AuthGuard],
    data: { roles: ['service-partner'] },
    children: [
      {
        path: 'dashboard',
        component: ServicePartnerDashboardComponent
      },
      { 
        path: 'order',
        children: [
          { path: 'form', component: ServicePartnerOrderFormComponent },
          { path: 'success/:id', component: ServicePartnerOrderSuccessComponent },
          { path: 'success', component: ServicePartnerOrderSuccessComponent },
          { path: 'packaging', component: ServicePartnerOrderPackagingComponent },
          { path: 'pickup', component: ServicePartnerOrderPickupComponent },
        ]
      },
      {
        path: 'repair-orders',
        children: [
          { path: 'list/:state', component:ServicePartnerOrderListComponent },
          { path: 'details/:id', component:ServicePartnerOrderDetailsComponent },
        ]
      },
      {
        path: 'invoices',
        children: [
          { path: 'list', component: ServicePartnerInvoiceListComponent },
        ]
      },
      { path: 'settings', component: ServicePartnerSettingsComponent },
      { path: '**', component: CmsPublicPageComponent },
    ]
  },
  {
    path: 'repair-center',
    canActivate: [AuthGuard],
    data: { roles: ['repair-center'] },
    children: [
      { path: 'dashboard', component: RepairCenterDashboardComponent },
      {
        path: 'repair-orders',
        children: [
          { path: 'process/:id', component:RepairCenterOrderProcessComponent },
          { path: 'list/:state', component:RepairCenterOrderListComponent },
          { path: 'details/:id', component:RepairCenterOrderDetailsComponent },
        ]
      },
      {
         path: 'invoices',
         children: [
           { path: 'list', component: RepairCenterInvoiceListComponent },
         ]
      }
    ]
  },
  { 
    path: 'admin',
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
    children: [
      { 
        path: 'service-partners',
        children: [
          { path: 'list/:state', component: AdminServicePartnersComponent },
          { path: 'details/:id', component: AdminServicePartnerDetailsComponent },
          { path: 'create', component: AdminServicePartnerCreateComponent },
        ] 
      },
      {
        path: 'repair-orders',
        children: [
          { path: 'list/:state', component:AdminRepairOrderListComponent },
          { path: 'details/:id', component:AdminRepairOrderDetailsComponent },
        ]
      },
      {
        path: 'invoices',
        children: [
          { path: 'list/:state', component:AdminInvoiceListComponent },
          { path: 'details/:ident', component:AdminInvoiceDetailsComponent },
          { path: 'create', component: AdminInvoiceCreateComponent },
        ]
      },
      {
        path: 'repair-centers',
        children: [
          { path: 'list', component: AdminRepairCenterListComponent },
          { path: 'details', component: AdminRepairCenterDetailsComponent },
          { path: 'details/:id', component: AdminRepairCenterDetailsComponent },
          { path: 'assignment', component:  AdminRepairCenterAssignmentComponent}
        ]
      },
      {
        path: 'cms',
        children: [
          { 
            path: 'pages',
            children: [
              { path: 'list', component: AdminCmsPagesListComponent },
              { path: 'details', component: AdminCmsPagesDetailsComponent },
              { path: 'details/:ident', component: AdminCmsPagesDetailsComponent },
              { path: 'details/:ident/:ident2', component: AdminCmsPagesDetailsComponent },
            ]
          },
          { path: 'css-js', component: AdminCmsCssJsComponent },
          {
            path: 'settings',
            children: [
              { path: 'list', component: AdminCmsSettingsListComponent },
            ]
          },
          {
            path: 'files',
            children: [
              { path: 'list', component: AdminCmsFilesListComponent },
            ]
          }
        ]
      }
    ]
  },
  { path: '**', component: CmsPublicPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
