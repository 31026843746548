import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilterBase } from 'src/app/components/ui/filter-inputs/util/filter-base';

@Component({
  selector: 'app-filter-inputfield',
  templateUrl: './filter-inputfield.component.html',
  styleUrls: ['./filter-inputfield.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterInputfieldComponent),
      multi: true
    }
  ],
})
export class FilterInputfieldComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder:string;

  private _value:string;

  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  constructor() { }

  ngOnInit() {
  }

  get value():string {
    return this._value;
  }

  set value(value: string) {
    if(value != this._value) {
      this._value = value;
      this._onChange(value);
    }
  }

  writeValue(value: string):void {
    this.value = value;
  }

  registerOnChange(onChange: (value: string) => void):void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void):void {
    this._onTouched = onTouched;
  }

  onTouched():void {
    this._onTouched();
  }
}
